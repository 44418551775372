import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import { Trash } from "components/newSVG";
import { X, XCircle } from "react-feather";
import theme from "theme";
import styles from "./index.module.css";
import { cloneElement } from "react";

const DeleteModal = ({
  open,
  onClose,
  onConfirm,
  title,
  subtitle,
  list,
  icon,
  confirmText,
  mainIcon,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          width: 500,
          borderRadius: 20,
        },
      }}
    >
      <Box sx={{ textAlign: "center", position: "relative", padding: 10 }}>
        <IconButton className={styles.close} onClick={onClose}>
          <X width={20} height={20} />
        </IconButton>
        <Box className={styles.iconContainer}>
          {mainIcon ? (
            cloneElement(mainIcon, { width: 40, height: 40 })
          ) : (
            <Trash width={40} height={40} />
          )}
        </Box>

        {title && (
          <Typography variant="h3" fontWeight={500} mt={4}>
            {title}
          </Typography>
        )}

        {subtitle && (
          <Typography variant="body2" my={2} color="grey.600">
            {/* Are you sure you want to delete this collection? */}
            {subtitle}
          </Typography>
        )}

        {list &&
          list.map((item, index) => (
            <Box className={styles.modalList} key={index}>
              {icon ? (
                icon
              ) : (
                <XCircle
                  color={theme.palette.error.main}
                  width={20}
                  height={20}
                />
              )}

              <Typography variant="body2" align="left">
                {item}
              </Typography>
            </Box>
          ))}

        <Box
          mt={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "22px",
          }}
        >
          <Button
            variant="outlined"
            onClick={onClose}
            className={styles.modalButton}
          >
            Cancel
          </Button>
          <Button
            className={styles.modalButton}
            startIcon={
              mainIcon ? (
                cloneElement(mainIcon, { width: 16, height: 16 })
              ) : (
                <Trash color="white" width={16} height={16} />
              )
            }
            variant="contained"
            color={"error"}
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            {confirmText ?? "Confirm and Delete"}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DeleteModal;
