import {
  Box,
  Button,
  Collapse,
  Grow,
  IconButton,
  InputAdornment,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { RedAlert } from "components/SVG";
import { useEffect, useState } from "react";
import { ChevronRight, Circle, Trash2 } from "react-feather";
import { useForm, Controller } from "react-hook-form";
import VariableDropdown from "../VariableDropdown";
import FlexBox from "components/FlexBox";
import theme from "theme";
import { languages as defaultLanguages } from "text-constants/common";
import { Eye, EyeClose, RadioButton } from "components/newSVG";
import { useSkillrToast } from "context/toast";
import { gscUriPrefix } from "text-constants/common";
import UploadFile from "../../UploadFile";
import { checkDuplicateName } from "utils/utils";

const MessageProperties = (props) => {
  const {
    data,
    onSave,
    onEdit,
    localizationMode,
    currentLanguage,
    workFlowId,
  } = props;
  const {
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      type: "text",
      message: "",
      localizedMessage: "",
      localizedAudio: "",
    },
  });

  const [showDefault, setShowDefault] = useState(false);
  const [uploadedDetails, setUploadedDetails] = useState();
  const [uploadProgress, setUploadProgress] = useState();
  const { showSkillrToast } = useSkillrToast();

  const handleOnSave = async (formData) => {
    let updatedData = { ...formData, isDirty: false };
    if (formData.type === "audio") {
      if (localizationMode && !formData.localizedAudio) {
        showSkillrToast(
          `Please upload ${
            defaultLanguages.find((l) => l.value === currentLanguage)?.name
          } file`,
          "error"
        );
        return;
      } else if (!localizationMode && !uploadedDetails?.gcsUri) {
        showSkillrToast("Please upload file", "error");
        return;
      }
    }

    if (formData.customName) {
      const isDuplicate = await checkDuplicateName(
        workFlowId,
        formData.customName,
        props.data.name
      );
      if (isDuplicate) {
        showSkillrToast("Node name already exists", "error");
        return;
      }
    }

    if (localizationMode && currentLanguage) {
      updatedData.languageVariations = {
        ...(formData?.languageVariations || {}),
        [currentLanguage]: {
          ...(formData?.languageVariations?.[currentLanguage] || {}),
          message: formData.localizedMessage,
          gcsUri: formData.localizedAudio,
        },
      };
    }
    onSave({ ...updatedData, gcsUri: uploadedDetails?.gcsUri });
  };

  const handleOnRemoveAudio = (isLocalized = false) => {
    if (isLocalized) {
      setValue("localizedAudio", "");
    } else {
      setUploadedDetails(null);
    }
    setUploadProgress();
  };

  const renderAudioSelector = (isLocalized = false) => (
    <div style={{ marginBottom: 16 }}>
      {(isLocalized ? watch("localizedAudio") : uploadedDetails?.gcsUri) ? (
        <Box display="flex" gap={3} alignItems="center">
          <audio controls>
            <source
              src={(isLocalized
                ? watch("localizedAudio")
                : uploadedDetails?.gcsUri
              ).replace("gs://", gscUriPrefix)}
              type={
                "audio/" +
                (isLocalized
                  ? watch("localizedAudio")
                  : uploadedDetails?.gcsUri
                )
                  .split(".")
                  .pop()
              }
            ></source>
          </audio>

          <IconButton onClick={() => handleOnRemoveAudio(isLocalized)}>
            <Trash2 width={16} height={16} />
          </IconButton>
        </Box>
      ) : (
        <UploadFile
          workFlowId={workFlowId}
          onUploadComplete={(details) => {
            setValue("localizedAudio", details.gcsUri);
            setUploadProgress(undefined);
          }}
        />
      )}
    </div>
  );

  useEffect(() => {
    onEdit(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (data.name) {
      reset({
        ...data,
        localizedMessage:
          data.languageVariations?.[currentLanguage]?.message || "",
        localizedAudio:
          data.languageVariations?.[currentLanguage]?.gcsUri || "",
      });
      setUploadedDetails(data);
    }
  }, [data, currentLanguage]);

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Controller
        control={control}
        name="type"
        render={({ field: { onChange, value } }) => (
          <Box display="flex" mb={4} gap={5}>
            <Box
              display="flex"
              gap={1}
              alignItems="center"
              component={"label"}
              sx={{ cursor: "pointer" }}
            >
              <Radio
                onClick={() => onChange("text")}
                checked={value === "text"}
                sx={{ padding: 0 }}
                icon={<Circle width={20} height={20} />}
                checkedIcon={<RadioButton width={20} height={20} />}
              />
              <Typography
                variant="caption"
                fontWeight={400}
                color="text.primary"
              >
                Text
              </Typography>
            </Box>
            <Box
              display="flex"
              gap={1}
              alignItems="center"
              component={"label"}
              sx={{ cursor: "pointer" }}
            >
              <Radio
                onClick={() => onChange("audio")}
                checked={value === "audio"}
                sx={{ padding: 0 }}
                icon={<Circle width={20} height={20} />}
                checkedIcon={<RadioButton width={20} height={20} />}
              />
              <Typography
                variant="caption"
                fontWeight={400}
                color="text.primary"
              >
                Audio
              </Typography>
            </Box>
          </Box>
        )}
      />
      {localizationMode ? (
        <>
          <Button
            variant="text"
            endIcon={
              showDefault ? (
                <Eye width={20} height={20} />
              ) : (
                <EyeClose width={20} height={20} />
              )
            }
            color="inherit"
            sx={{ minWidth: 0, width: "fit-content", mb: 4 }}
            onClick={() => setShowDefault(!showDefault)}
          >
            View Default
          </Button>

          <Collapse in={showDefault} mountOnEnter unmountOnExit>
            <Box
              fullWidth
              sx={{
                backgroundColor: theme.palette.grey[50],
                borderRadius: 3,
                border: `1px solid ${theme.palette.grey[100]}`,
                padding: `16px 20px`,
                mb: 4,
              }}
            >
              {watch("type") === "text" ? (
                <Typography variant="body2">{data?.message}</Typography>
              ) : (
                <Box display="flex" gap={3} alignItems="center">
                  {data?.gcsUri && (
                    <audio controls key={data?.gcsUri}>
                      <source
                        src={data?.gcsUri.replace("gs://", gscUriPrefix)}
                        type={"audio/" + data?.gcsUri.split(".").pop()}
                      ></source>
                    </audio>
                  )}
                </Box>
              )}
            </Box>
          </Collapse>

          {/* Localized audio file selector */}
          {watch("type") === "audio" && renderAudioSelector(true)}

          <Controller
            control={control}
            name="localizedMessage"
            rules={{
              required:
                watch("type") === "text"
                  ? "Message is required"
                  : "Transcript is required",
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                variant="outlined"
                multiline
                rows={5}
                fullWidth
                placeholder={
                  watch("type") === "text"
                    ? `Add message for ${
                        defaultLanguages.find(
                          (l) => l.value === currentLanguage
                        )?.name
                      }`
                    : `Add transcript for ${
                        defaultLanguages.find(
                          (l) => l.value === currentLanguage
                        )?.name
                      }`
                }
                value={value}
                onChange={onChange}
                sx={{
                  "& div": {
                    borderRadius: "12px",
                    padding: 0,
                  },
                  "& textarea": {
                    marginBottom: "32px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        alignSelf: "flex-end",
                        mb: 4,
                        mr: 2,
                        position: "absolute",
                        right: 0,
                      }}
                    >
                      {`${value?.length || 0} / 500`}
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 500 }}
              />
            )}
          />
          {errors?.localizedMessage && (
            <FlexBox
              mt={2}
              component="span"
              columnGap={1}
              fontSize={12}
              color={"error.main"}
            >
              <RedAlert style={{ marginRight: 8 }} />
              {errors?.localizedMessage?.message}
            </FlexBox>
          )}
        </>
      ) : (
        <>
          <Typography variant="caption" color="text.primary" mb={1}>
            Node Name
          </Typography>

          <Controller
            control={control}
            name="customName"
            rules={{
              validate: (value) => {
                if (/[^a-zA-Z\s]/.test(value))
                  return "Node name can only contain letters and spaces";
                return true;
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                variant="outlined"
                inputProps={{ maxLength: 20 }}
                fullWidth
                placeholder="Add Name"
                value={value}
                onChange={onChange}
                error={errors?.customName}
                helperText={errors?.customName?.message}
                sx={{
                  mb: 4,
                  "& div": {
                    borderRadius: "12px",
                    padding: 0,
                  },
                }}
              />
            )}
          />

          {watch("type") === "audio" && (
            <div style={{ marginBottom: 16 }}>
              {uploadedDetails?.gcsUri && (
                <Box display="flex" gap={3} alignItems="center">
                  <audio controls key={uploadedDetails?.gcsUri}>
                    <source
                      src={uploadedDetails?.gcsUri?.replace(
                        "gs://",
                        gscUriPrefix
                      )}
                      type={
                        "audio/" + uploadedDetails?.gcsUri?.split(".").pop()
                      }
                    ></source>
                  </audio>
                  <IconButton onClick={() => handleOnRemoveAudio(false)}>
                    <Trash2 width={16} height={16} />
                  </IconButton>
                </Box>
              )}
              {!uploadedDetails?.gcsUri && (
                <UploadFile
                  workFlowId={workFlowId}
                  onUploadComplete={(details) => {
                    setUploadedDetails(details);
                    setUploadProgress(undefined);
                  }}
                />
              )}
            </div>
          )}

          <Controller
            control={control}
            name="message"
            rules={{
              required: "Message is required",
            }}
            render={({ field: { onChange, value } }) => (
              <VariableDropdown
                control={control}
                watch={watch}
                setValue={setValue}
                value={value}
                onChange={onChange}
              >
                <TextField
                  variant="outlined"
                  multiline
                  rows={5}
                  fullWidth
                  placeholder={"Add Message"}
                  sx={{
                    "& div": {
                      // overflow: "hidden",
                      borderRadius: "12px",
                      padding: 0,
                    },
                    "& textarea": {
                      marginBottom: "32px",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          alignSelf: "flex-end",
                          mb: 4,
                          mr: 2,
                          position: "absolute",
                          right: 0,
                        }}
                      >
                        {`${watch("message")?.length} / 500`}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 500 }}
                />
              </VariableDropdown>
            )}
          />
          {errors?.message && (
            <FlexBox
              mt={2}
              component="span"
              columnGap={1}
              fontSize={12}
              color={"error.main"}
            >
              <RedAlert style={{ marginRight: 8 }} />
              {errors?.message?.message}
            </FlexBox>
          )}
        </>
      )}

      <Button
        variant="dark"
        sx={{
          borderRadius: 82,
          minWidth: 0,
          marginTop: "auto",
          width: "max-content",
          mt: "auto",
        }}
        onClick={handleSubmit(handleOnSave)}
        endIcon={<ChevronRight width={20} height={20} />}
      >
        Save
      </Button>
    </div>
  );
};

export default MessageProperties;
