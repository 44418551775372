import { useState } from "react";
import styles from "./index.module.css";
import {
  Box,
  Button,
  Dialog,
  Fade,
  IconButton,
  Typography,
} from "@mui/material";
import { AlertTriangle, MoreVertical, Trash2, X } from "react-feather";
import theme from "theme";
import FlexBox from "components/FlexBox";
import { RedAlert } from "components/SVG";
import FundamentoPopup from "components/FundamentoPopup";
import { Copy, TrashSimple } from "components/newSVG";

const NodeBase = (props) => {
  const {
    isActive,
    onDelete,
    localizationMode,
    fullyLocalized,
    children,
    error,
    onDuplicate,
    onDuplicateToAnotherWorkflow,
  } = props;
  const [isParentHovered, setIsParentHovered] = useState(false);

  return (
    <div
      className={`${styles.messageNode} ${isActive ? styles.active : ""} ${
        localizationMode && !fullyLocalized ? styles.localizationMode : ""
      } ${error ? styles.error : ""}`}
      onMouseEnter={() => setIsParentHovered(true)}
      onMouseLeave={() => setIsParentHovered(false)}
    >
      {/* <Fade in={isParentHovered} mountOnEnter unmountOnExit>
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <Trash2 color={theme.palette.error.main} width={12} height={12} />
        </IconButton>
      </Fade> */}
      <FundamentoPopup
        className={styles.popup}
        onParentClick={(e) => e.stopPropagation()}
        triggeringComponent={
          <IconButton
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <MoreVertical
              width={12}
              height={12}
              color={theme.palette.text.primary}
            />
          </IconButton>
        }
      >
        <>
          <FundamentoPopup
            className={styles.popup}
            onParentClick={(e) => e.stopPropagation()}
            triggeringComponent={
              <FlexBox
                columnGap={2}
                className={styles.popupItem}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                sx={{
                  "&:hover": { backgroundColor: theme.palette.grey[100] },
                  borderBottom: `1px solid ${theme.palette.borderColor.light}`,
                }}
              >
                <Copy
                  width={24}
                  height={24}
                  color={theme.palette.text.primary}
                />
                <Typography variant="body2">Copy Node</Typography>
              </FlexBox>
            }
          >
            <>
              <FlexBox
                columnGap={2}
                className={styles.popupItem}
                sx={{
                  "&:hover": { backgroundColor: theme.palette.grey[100] },
                  borderBottom: `1px solid ${theme.palette.borderColor.light}`,
                }}
                onClick={onDuplicate}
              >
                <Typography variant="body2">
                  Copy to the same workflow
                </Typography>
              </FlexBox>
              <FlexBox
                columnGap={2}
                className={styles.popupItem}
                sx={{
                  "&:hover": { backgroundColor: theme.palette.grey[100] },
                  borderBottom: `1px solid ${theme.palette.borderColor.light}`,
                }}
                onClick={onDuplicateToAnotherWorkflow}
              >
                <Typography variant="body2">
                  Copy to a different workflow
                </Typography>
              </FlexBox>
            </>
          </FundamentoPopup>
          <FlexBox
            columnGap={2}
            className={styles.popupItem}
            sx={{
              "&:hover": { backgroundColor: theme.palette.grey[100] },
            }}
            onClick={onDelete}
          >
            <Trash2 width={24} height={24} color={theme.palette.text.primary} />
            <Typography variant="body2">Delete Node</Typography>
          </FlexBox>
        </>
      </FundamentoPopup>

      {localizationMode && !fullyLocalized && !isParentHovered && (
        <AlertTriangle
          color={theme.palette.grey[300]}
          width={12}
          height={12}
          style={{ position: "absolute", top: 18, right: 18 }}
        />
      )}

      {children}

      {error && (
        <Box mt={"auto"}>
          {error?.errorMessages.length > 0 &&
            error?.errorMessages.map((error) => (
              <FlexBox mt={2} columnGap={1} alignItems="flex-start">
                <RedAlert style={{ flexShrink: 0 }} />
                <Typography variant="caption" color="error.main">
                  {error}
                </Typography>
              </FlexBox>
            ))}
        </Box>
      )}
    </div>
  );
};

export default NodeBase;
