import ReactFlow, {
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider,
  useReactFlow,
  Panel,
  useKeyPress,
} from "reactflow";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./index.module.css";
import "reactflow/dist/style.css";
import { Check, ChevronDown, Clipboard, LogOut, Type, X } from "react-feather";
import {
  Box,
  Button,
  IconButton,
  Slide,
  Typography,
  TextField,
} from "@mui/material";
import theme from "theme";
import FlexBox from "components/FlexBox";
import StartNode from "./components/AllNodes/StartNode";
import MessageNode from "./components/AllNodes/MessageNode";
import {
  BracketsCurly,
  CodaLogo,
  DiamondsFour,
  GitBranch,
  ImagesSquare,
  ListBullets,
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
  SpeakerHigh,
  PencilSimple,
  Textbox,
  ChatBubble,
  Code,
  GoogleSheets,
  Variable,
} from "components/newSVG";
import MessageProperties from "./components/Sidebars/MessageProperties";
import InputProperties from "./components/Sidebars/InputProperties";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import InputNode from "./components/AllNodes/InputNode";
import {
  editWorkFlow,
  getSingleWorkFlow,
  getAllWorkFlow as getAllWorkFlowApi,
  reviewWorkflow as reviewWorkflowAPI,
} from "services";
import { useSkillrToast } from "context/toast";
import DecisionProperties from "./components/Sidebars/DecisionProperties";
import DeleteModal from "components/DeleteModal";
import DecisionNode from "./components/AllNodes/DecisionNode";
import TriggerWorkflowProperties from "./components/Sidebars/TriggerWorkflowProperties";
import TriggerNode from "./components/AllNodes/TriggerNode";
import RestApiProperties from "./components/Sidebars/RestApiProperties";
import RestApiNode from "./components/AllNodes/RestApiNode";
import MessageOptionsNode from "./components/AllNodes/MessageOptionsNode";
import MessageOptionsProperties from "./components/Sidebars/MessageOptionsProperties";
import AudioNode from "./components/AllNodes/AudioNode";
import AudioProperties from "./components/Sidebars/AudioProperties";
import { IconClose } from "components/SVG";
import ConversationNode from "./components/AllNodes/ConversationNode";
import ConversationProperties from "./components/Sidebars/ConversationProperties";
import DevTools from "./components/Devtools";
import ScriptNode from "./components/AllNodes/ScriptNode";
import ScriptProperties from "./components/Sidebars/ScriptProperties";
import FundamentoPopup from "components/FundamentoPopup";
import { useUserContext } from "context/user";
import { languages as defaultLanguages } from "text-constants/common";
import GoogleSheetNode from "./components/AllNodes/GoogleSheetNode";
import GoogleSheetProperties from "./components/Sidebars/GoogleSheetProperties";
import VariableNode from "./components/AllNodes/VariableNode";
import VariableProperties from "./components/Sidebars/VariableProperties";
import { get } from "lodash";
import DraftWarningModal from "./components/DraftWarningModal";
import CopyNodeModal from "./components/CopyNodeModal";

const PanelForZoom = () => {
  const { zoomIn, zoomOut } = useReactFlow();

  return (
    <Panel position="top-right" style={{ position: "fixed", zIndex: 10000 }}>
      <IconButton
        onClick={() => {
          zoomIn({ duration: 400 });
        }}
      >
        <MagnifyingGlassPlus width={20} height={20} />
      </IconButton>
      <IconButton
        onClick={() => {
          zoomOut({ duration: 400 });
        }}
      >
        <MagnifyingGlassMinus width={20} height={20} />
      </IconButton>
    </Panel>
  );
};

const ResetViewport = ({ nodes }) => {
  const { setViewport } = useReactFlow();

  const startNode = nodes?.find((node) => node.id === "0");
  useEffect(() => {
    if (startNode) {
      const x = window.innerWidth / 2 - startNode.position.x - 100;
      setViewport({ x, y: -200, zoom: 1 });
    }
  }, [startNode]);

  return <></>;
};

const Diagram = () => {
  const location = useLocation();
  const [nodes, setNodes, onNodesChange] = useNodesState();
  const [edges, setEdges, onEdgesChange] = useEdgesState();
  const nodeTypes = useMemo(
    () => ({
      startNode: StartNode,
      messageNode: MessageNode,
      inputNode: InputNode,
      decisionNode: DecisionNode,
      triggerNode: TriggerNode,
      apiNode: RestApiNode,
      messageOptionsNode: MessageOptionsNode,
      audioNode: AudioNode,
      conversationNode: ConversationNode,
      scriptNode: ScriptNode,
      googleSheetNode: GoogleSheetNode,
      variableNode: VariableNode,
    }),
    []
  );
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [rfInstance, setRfInstance] = useState(null);
  const [nodeSideBarOpen, setNodeSideBarOpen] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const workflowID = params.workflowID;
  const [workflowData, setWorkflowData] = useState(null);
  const { showSkillrToast } = useSkillrToast();
  const [messageSaved, setMessageSaved] = useState(false);
  const [clickedNode, setClickedNode] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [decisionNodeFlowType, setDecisionNodeFlowType] = useState(null);
  const [workFlowList, setWorkFlowList] = useState([]);
  const [summarySection, setSummarySection] = useState(false);
  const [workFlowSummary, setWorkFlowSummary] = useState("");
  const [workFlowEdited, setWorkFlowEdited] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState("");
  const [localizationMode, setLocalizationMode] = useState(false);
  const [nodePosition, setNodePosition] = useState({ x: 75, y: 250 });
  const prevBotVoicesRef = useRef();
  const { getBot, bot } = useUserContext();
  const token = localStorage.getItem("token");
  const keysPressed = useKeyPress("Control+Enter");
  const [showDraftWarning, setShowDraftWarning] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const initialNodes = [
    {
      id: "0",
      type: "startNode",
      position: { x: 150, y: 250 },
      data: {
        isLastNode: true,
        onPlusClick: () => {
          setNodeSideBarOpen(true);
          setClickedNode((prevClickedNode) => ({
            ...initialNodes[0],
            data: {
              ...initialNodes[0].data,
              selectedOption: "source",
            },
          }));
        },
        onMinusClick: () => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === "0") {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isLastNode: true,
                  },
                };
              } else {
                return node;
              }
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) =>
            prevEdges.filter((edge) => edge.source !== "0")
          );
          setTimeout(() => onSave(), 0);
        },
      },
      draggable: false,
    },
  ];

  const allNodesList = [
    {
      name: "messageNode",
      label: "Message",
      icon: <Type width={32} height={32} />,
    },
    {
      name: "audioNode",
      label: "Play Audio",
      icon: <SpeakerHigh width={32} height={32} />,
    },
    {
      name: "inputNode",
      label: "Input Data",
      icon: <Textbox width={32} height={32} />,
    },
    {
      name: "conversationNode",
      label: "Conversation Node",
      icon: <ChatBubble width={32} height={32} />,
    },
    {
      name: "decisionNode",
      label: "Decision Node",
      icon: <DiamondsFour width={32} height={32} />,
    },
    {
      name: "triggerNode",
      label: "Trigger Workflow",
      icon: <GitBranch width={32} height={32} />,
    },
    {
      name: "apiNode",
      label: "Rest API",
      icon: <BracketsCurly width={32} height={32} />,
    },
    {
      name: "scriptNode",
      label: "Script Node",
      icon: <Code width={32} height={32} />,
    },
    {
      name: "messageOptionsNode",
      label: "Message with Options",
      icon: <ListBullets width={32} height={32} />,
    },
    {
      name: "googleSheetNode",
      label: "Google Sheet",
      icon: <GoogleSheets width={32} height={32} />,
    },
    {
      name: "variableNode",
      label: "Set Variable",
      icon: <Variable width={32} height={32} />,
    },
  ];

  const onConnect = useCallback(
    (params) => {
      let nodes;
      setNodes((prevNodes) => {
        nodes = prevNodes;
        return prevNodes;
      });
      // First find the source node
      const sourceNode = nodes.find((node) => node.id === params.source);
      if (!sourceNode) return; // Guard clause if source node not found

      // Check if this is a special node type that needs specific handling
      const isSpecialNode = [
        "decisionNode",
        "messageOptionsNode",
        "inputNode",
        "conversationNode",
        "scriptNode",
        "apiNode",
      ].includes(sourceNode.type);

      if (isSpecialNode) {
        // Check for existing edge from that source handle
        const edgeExists = edges.some(
          (edge) =>
            edge.source === params.source &&
            edge.sourceHandle === params.sourceHandle
        );

        if (!edgeExists) {
          // Create edge with appropriate properties
          const edge = {
            ...params,
            ...(params.sourceHandle &&
              params.sourceHandle !== "source" && {
                option: params.sourceHandle,
              }),
            ...(params.sourceHandle === "default" && {
              edgeType: "default",
            }),
          };

          setEdges((e) => addEdge(edge, e));

          // Update node data based on connection type
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === params.source) {
                const updates = {
                  ...node,
                  data: {
                    ...node.data,
                    ...(params.sourceHandle === "default" && {
                      selectedOption: params.sourceHandle,
                    }),
                    ...(sourceNode.type === "decisionNode" && {
                      ...(params.sourceHandle === "true" && {
                        trueFlowAdded: true,
                      }),
                      ...(params.sourceHandle === "false" && {
                        falseFlowAdded: true,
                      }),
                    }),
                    ...(sourceNode.type === "messageOptionsNode" && {
                      connectedOptions: [
                        ...(node.data.connectedOptions || []),
                        params.sourceHandle,
                      ],
                    }),
                  },
                };
                return updates;
              }
              return node;
            })
          );
        }
      } else {
        // Handle regular nodes
        const edgeExists = edges.some((edge) => edge.source === params.source);

        if (!edgeExists) {
          setEdges((e) => addEdge(params, e));
        }
      }

      // Update isLastNode if connecting from source handle
      if (params.sourceHandle === "source") {
        setNodes((prevNodes) =>
          prevNodes.map((node) => ({
            ...node,
            data: {
              ...node.data,
              isLastNode:
                node.id === params.source ? false : node.data.isLastNode,
            },
          }))
        );
      }

      setTimeout(() => onSave(), 0);
    },
    [edges, nodes, setEdges, setNodes]
  );

  const getWorkflowData = async () => {
    try {
      const response = await getSingleWorkFlow(workflowID);

      if (response) {
        setWorkflowData(response);
        setWorkFlowSummary(response?.summary ?? "");
        response?.summary && setWorkFlowEdited(true);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error?.message ||
        "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleNodeClick = (element) => {
    if (isEditing) {
      setShowDraftWarning(true);
      return;
    }

    if (element.type !== "startNode") {
      setNodes((prevNodes) =>
        prevNodes.map((node) => {
          if (node.id === element.id && !node.data.isActive) {
            return {
              ...node,
              data: {
                ...node.data,
                isActive: true,
              },
            };
          } else {
            return {
              ...node,
              data: {
                ...node.data,
                isActive: false,
              },
            };
          }
        })
      );

      if (selectedNode && selectedNode.id === element.id) {
        // If the clicked node is already the selected node, deselect it
        setSelectedNode(null);
        setSidebarOpen(false);
      } else {
        // If the clicked node is not the selected node, select it
        setSelectedNode(element);
        setSidebarOpen(true);
      }
    }
  };

  const deleteNode = (nodeId) => {
    let latestEdges;
    setEdges((prevEdges) => {
      latestEdges = [...prevEdges];
      return prevEdges;
    });

    // Find the edge that connects to the node being deleted
    const connectedEdge = latestEdges.find((edge) => edge.target === nodeId);

    // If there is a connected edge, find the source node
    if (connectedEdge) {
      const sourceNodeId = connectedEdge.source;
      const sourceNode = nodes.find((node) => node.id === connectedEdge.source);

      // Update the nodes, setting isLastNode to true for the source node
      setNodes((prevNodes) => {
        prevNodes = prevNodes.map((node) => {
          const isLastNode = prevNodes.find((node) => node.id === nodeId)?.data
            ?.isLastNode;

          if (connectedEdge.sourceHandle === "default") {
            return node.id === sourceNodeId
              ? { ...node, data: { ...node.data, selectedOption: null } }
              : node;
          }

          if (sourceNode.type === "messageOptionsNode") {
            return node.id === sourceNodeId
              ? {
                  ...node,
                  data: {
                    ...node.data,
                    // connectedOptions: node.data.connectedOptions.filter(
                    //   (opt) => opt !== connectedEdge.option.id
                    // ),
                    connectedOptions:
                      // connectedEdge.option === "default" ||
                      // connectedEdge.option === "source"
                      //   ?
                      node.data.connectedOptions.filter(
                        (opt) => opt !== connectedEdge.option
                      ),
                    // : node.data.connectedOptions.filter(
                    //     (opt) => opt !== connectedEdge.option
                    //   ),
                  },
                }
              : node;
          }

          if (connectedEdge.sourceHandle === "true") {
            return node.id === sourceNodeId
              ? {
                  ...node,
                  data: {
                    ...node.data,
                    ...(connectedEdge.sourceHandle === "true"
                      ? { trueFlowAdded: false }
                      : {}),
                  },
                }
              : node;
          }
          if (connectedEdge.sourceHandle === "false") {
            return node.id === sourceNodeId
              ? {
                  ...node,
                  data: {
                    ...node.data,
                    ...(connectedEdge.sourceHandle === "false"
                      ? { falseFlowAdded: false }
                      : {}),
                  },
                }
              : node;
          }

          return node.id === sourceNodeId
            ? { ...node, data: { ...node.data, isLastNode: true } }
            : node;
        });
        setSidebarOpen(false);
        setNodeSideBarOpen(false);
        setIsEditing(false);
        return prevNodes;
      });
    }

    setNodes((prevNodes) => {
      const newNodes = prevNodes.filter((node) => node.id !== nodeId);

      // setTimeout(() => onSave(), 0);
      setTimeout(() => onSave(), 0);
      return newNodes;
    });

    setEdges((prevEdges) => {
      // Remove the node and any edges connected to it
      const remainingEdges = prevEdges.filter(
        (edge) => edge.source !== nodeId && edge.target !== nodeId
      );

      return [...remainingEdges];
    });
  };

  const getMessageNode = (newId) => {
    const messageNode = {
      id: `${newId}`,
      type: "messageNode",
      data: {
        title: "Text Message",
        message: "",
        isLastNode: true,
        onPlusClick: (position) => {
          setNodeSideBarOpen(true);
          setNodePosition(position);
          setClickedNode((prevClickedNode) => ({
            ...messageNode,
            data: {
              ...messageNode.data,
              selectedOption: "source",
            },
          }));
        },
        // onDelete: () => deleteNode(messageNode.id),
        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(messageNode);
        },
        onMinusClick: () => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === messageNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isLastNode: true,
                  },
                };
              }
              return node;
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) =>
            prevEdges.filter((edge) => edge.source !== messageNode.id)
          );

          setTimeout(() => onSave(), 0);
        },
        onDuplicate: () => copyNode(newId),
        onDuplicateToAnotherWorkflow: () => {
          setShowCopyModal(true);
          setClickedNode(messageNode);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };
    return messageNode;
  };

  const getInputNode = (newId) => {
    const inputNode = {
      id: `${newId}`,
      type: "inputNode",
      data: {
        title: "Input Data",
        message: "",
        isLastNode: true,
        onPlusClick: (position) => {
          setNodePosition(position);
          setNodeSideBarOpen(true);
          setClickedNode((prevClickedNode) => ({
            ...inputNode,
            data: {
              ...inputNode.data,
              selectedOption: "source",
            },
          }));
        },
        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(inputNode);
        },
        onMinusClick: () => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === inputNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isLastNode: true,
                  },
                };
              }
              return node;
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) => {
            return prevEdges.filter(
              (edge) =>
                !(
                  edge.source === inputNode.id && edge.sourceHandle === "source"
                )
            );
          });
          setTimeout(() => onSave(), 0);
        },
        onOptionPlusClick: (option, position) => {
          setNodePosition(position);
          setNodeSideBarOpen(true);
          setClickedNode((prevClickedNode) => ({
            ...inputNode,
            data: {
              ...inputNode.data,
              selectedOption: option,
            },
          }));
        },
        onOptionMinusClick: (option) => {
          // Delete the connected edge
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === inputNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    selectedOption: null,
                  },
                };
              }
              return node;
            })
          );

          setEdges((prevEdges) =>
            prevEdges.filter(
              (edge) =>
                !(edge.option === option && edge.source === inputNode.id)
            )
          );
          setTimeout(() => onSave(), 0);
        },
        onDuplicate: () => copyNode(newId),
        onDuplicateToAnotherWorkflow: () => {
          setShowCopyModal(true);
          setClickedNode(inputNode);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };
    return inputNode;
  };

  const getDecisionNode = (newId) => {
    const decisionNode = {
      id: `${newId}`,
      type: "decisionNode",
      data: {
        isLastNode: true,
        onTrueClick: (position) => {
          setNodePosition(position);
          setNodeSideBarOpen(true);
          setClickedNode((prevClickedNode) => ({
            ...decisionNode,
            data: {
              ...decisionNode.data,
              selectedOption: "true",
            },
          }));
        },
        onFalseClick: (position) => {
          setNodePosition(position);
          setNodeSideBarOpen(true);
          setClickedNode((prevClickedNode) => ({
            ...decisionNode,
            data: {
              ...decisionNode.data,
              selectedOption: "false",
            },
          }));
        },
        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(decisionNode);
        },
        onMinusClick: (option) => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === decisionNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    ...(option === "true" ? { trueFlowAdded: false } : {}),
                    ...(option === "false" ? { falseFlowAdded: false } : {}),
                  },
                };
              }
              return node;
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) =>
            prevEdges.filter(
              (edge) =>
                !(
                  edge.source === decisionNode.id &&
                  edge.sourceHandle === option
                )
            )
          );
          setTimeout(() => onSave(), 0);
        },
        onDuplicate: () => copyNode(newId),
        onDuplicateToAnotherWorkflow: () => {
          setShowCopyModal(true);
          setClickedNode(decisionNode);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };

    return decisionNode;
  };

  const getTriggerNode = (newId) => {
    const triggerNode = {
      id: `${newId}`,
      type: "triggerNode",
      data: {
        isLastNode: true,

        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(triggerNode);
        },
        onDuplicate: () => copyNode(newId),
        onDuplicateToAnotherWorkflow: () => {
          setShowCopyModal(true);
          setClickedNode(triggerNode);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };
    return triggerNode;
  };

  const getAudioNode = (newId) => {
    const audioNode = {
      id: `${newId}`,
      type: "audioNode",
      data: {
        isLastNode: true,
        onPlusClick: (position) => {
          setNodeSideBarOpen(true);
          setNodePosition(position);
          setClickedNode((prevClickedNode) => ({
            ...audioNode,
            data: {
              ...audioNode.data,
              selectedOption: "source",
            },
          }));
        },
        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(audioNode);
        },
        onMinusClick: () => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === audioNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isLastNode: true,
                  },
                };
              }
              return node;
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) =>
            prevEdges.filter((edge) => edge.source !== audioNode.id)
          );
          setTimeout(() => onSave(), 0);
        },
        onDuplicate: () => copyNode(newId),
        onDuplicateToAnotherWorkflow: () => {
          setShowCopyModal(true);
          setClickedNode(audioNode);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
      token,
    };
    return audioNode;
  };

  const getApiNode = (newId) => {
    const apiNode = {
      id: newId,
      type: "apiNode",
      data: {
        message: "",
        isLastNode: true,
        selectedWorkflow: {
          workflowName: "TransferCall Workflow",
          workflowId: workFlowList.workFlows.find(
            (w) => w.name === "TransferCall Workflow"
          )?._id,
        },
        onPlusClick: (position) => {
          setNodePosition(position);
          setNodeSideBarOpen(true);
          setClickedNode((prevClickedNode) => ({
            ...apiNode,
            data: {
              ...apiNode.data,
              selectedOption: "source",
            },
          }));
        },
        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(apiNode);
        },
        onMinusClick: () => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === apiNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isLastNode: true,
                  },
                };
              }
              return node;
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) => {
            return prevEdges.filter(
              (edge) =>
                !(edge.source === apiNode.id && edge.sourceHandle === "source")
            );
          });
          setTimeout(() => onSave(), 0);
        },
        onOptionPlusClick: (option, position) => {
          setNodePosition(position);
          setNodeSideBarOpen(true);
          setClickedNode((prevClickedNode) => ({
            ...apiNode,
            data: {
              ...apiNode.data,
              selectedOption: option,
            },
          }));
        },
        onOptionMinusClick: (option) => {
          // Delete the connected edge
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === apiNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    selectedOption: null,
                  },
                };
              }
              return node;
            })
          );

          setEdges((prevEdges) =>
            prevEdges.filter(
              (edge) => !(edge.option === option && edge.source === apiNode.id)
            )
          );
          setTimeout(() => onSave(), 0);
        },
        onDuplicate: () => copyNode(newId),
        onDuplicateToAnotherWorkflow: () => {
          setShowCopyModal(true);
          setClickedNode(apiNode);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };

    return apiNode;
  };

  const getMessageOptionsNode = (newId) => {
    const messageOptionsNode = {
      id: `${newId}`,
      type: "messageOptionsNode",
      data: {
        isLastNode: true,
        connectedOptions: [],
        onPlusClick: (position) => {
          setNodePosition(position);
          setNodeSideBarOpen(true);
          setClickedNode((prevClickedNode) => ({
            ...messageOptionsNode,
            data: {
              ...messageOptionsNode.data,
              selectedOption: "source",
            },
          }));

          setNodes((prevNodes) => {
            return prevNodes.map((node) => {
              if (node.id === messageOptionsNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    selectedOption: "source",
                  },
                };
              }
              return node;
            });
          });
        },

        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(messageOptionsNode);
        },
        onOptionPlusClick: (option, position) => {
          setNodePosition(position);
          setNodeSideBarOpen(true);
          setClickedNode((prevClickedNode) => ({
            ...messageOptionsNode,
            data: {
              ...messageOptionsNode.data,
              connectedOptions: [
                ...messageOptionsNode.data.connectedOptions,
                option === "default" || option === "source"
                  ? option
                  : option.id,
              ],
              selectedOption:
                option === "default" || option === "source"
                  ? option
                  : option.id,
            },
          }));
        },
        onOptionMinusClick: (option) => {
          // Delete the connected edge
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === messageOptionsNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    connectedOptions:
                      option === "default" || option === "source"
                        ? node.data.connectedOptions.filter(
                            (opt) => opt !== option
                          )
                        : node.data.connectedOptions.filter(
                            (opt) => opt !== option.id
                          ),
                    selectedOption: [],
                  },
                };
              }
              return node;
            })
          );

          setEdges((prevEdges) => {
            if (option === "default" || option === "source") {
              return prevEdges.filter(
                (edge) =>
                  !(
                    edge.sourceHandle === option &&
                    edge.source === messageOptionsNode.id
                  )
              );
            } else {
              return prevEdges.filter(
                (edge) =>
                  !(
                    edge.sourceHandle &&
                    edge.sourceHandle === option.id &&
                    edge.source === messageOptionsNode.id
                  )
              );
            }
          });
          setTimeout(() => onSave(), 0);
        },
        onDuplicate: () => copyNode(newId),
        onDuplicateToAnotherWorkflow: () => {
          setShowCopyModal(true);
          setClickedNode(messageOptionsNode);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };
    return messageOptionsNode;
  };

  const getConversationNode = (newId) => {
    const conversationNode = {
      id: `${newId}`,
      type: "conversationNode",
      data: {
        isLastNode: true,
        onPlusClick: (position) => {
          setNodeSideBarOpen(true);
          setNodePosition(position);
          setClickedNode((prevClickedNode) => ({
            ...conversationNode,
            data: {
              ...conversationNode.data,
              selectedOption: "source",
            },
          }));
        },
        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(conversationNode);
        },
        onMinusClick: () => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === conversationNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isLastNode: true,
                  },
                };
              }
              return node;
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) => {
            return prevEdges.filter(
              (edge) =>
                !(
                  edge.source === conversationNode.id &&
                  edge.sourceHandle === "source"
                )
            );
          });
          setTimeout(() => onSave(), 0);
        },
        onOptionPlusClick: (option, position) => {
          setNodePosition(position);
          setNodeSideBarOpen(true);
          setClickedNode((prevClickedNode) => ({
            ...conversationNode,
            data: {
              ...conversationNode.data,
              selectedOption: option,
            },
          }));

          // setNodes((prevNodes) =>
          //   prevNodes.map((node) => {
          //     if (node.id === conversationNode.id) {
          //       return {
          //         ...node,
          //         data: {
          //           ...node.data,
          //           selectedOption: option,
          //         },
          //       };
          //     }
          //     return node;
          //   })
          // );
        },
        onOptionMinusClick: (option) => {
          // Delete the connected edge
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === conversationNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    selectedOption: null,
                  },
                };
              }
              return node;
            })
          );

          setEdges((prevEdges) =>
            prevEdges.filter(
              (edge) =>
                !(edge.option === option && edge.source === conversationNode.id)
            )
          );
          setTimeout(() => onSave(), 0);
        },
        onDuplicate: () => copyNode(newId),
        onDuplicateToAnotherWorkflow: () => {
          setShowCopyModal(true);
          setClickedNode(conversationNode);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };
    return conversationNode;
  };

  const getScriptNode = (newId) => {
    const scriptNode = {
      id: newId,
      type: "scriptNode",
      data: {
        message: "",
        isLastNode: true,
        selectedWorkflow: {
          workflowName: "TransferCall Workflow",
          workflowId: workFlowList.workFlows.find(
            (w) => w.name === "TransferCall Workflow"
          )?._id,
        },
        onPlusClick: () => {
          setNodeSideBarOpen(true);
          // setClickedNode(scriptNode);
          setClickedNode((prevClickedNode) => ({
            ...scriptNode,
            data: {
              ...scriptNode.data,
              selectedOption: "source",
            },
          }));
        },
        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(scriptNode);
        },
        onMinusClick: () => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === scriptNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isLastNode: true,
                  },
                };
              }
              return node;
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) => {
            return prevEdges.filter(
              (edge) =>
                !(
                  edge.source === scriptNode.id &&
                  edge.sourceHandle === "source"
                )
            );
          });
          setTimeout(() => onSave(), 0);
        },
        onOptionPlusClick: (option, position) => {
          setNodePosition(position);
          setNodeSideBarOpen(true);
          setClickedNode((prevClickedNode) => ({
            ...scriptNode,
            data: {
              ...scriptNode.data,
              selectedOption: option,
            },
          }));
        },
        onOptionMinusClick: (option) => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === scriptNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    selectedOption: null,
                  },
                };
              }
              return node;
            })
          );

          setEdges((prevEdges) =>
            prevEdges.filter(
              (edge) =>
                !(edge.sourceHandle === option && edge.source === scriptNode.id)
            )
          );
          setTimeout(() => onSave(), 0);
        },
        onDuplicate: () => copyNode(newId),
        onDuplicateToAnotherWorkflow: () => {
          setShowCopyModal(true);
          setClickedNode(scriptNode);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };

    return scriptNode;
  };

  const getGoogleSheetNode = (newId) => {
    const googleSheetNode = {
      id: newId,
      type: "googleSheetNode",
      data: {
        message: "",
        isLastNode: true,
        onPlusClick: () => {
          setNodeSideBarOpen(true);
          // setClickedNode(googleSheetNode);
          setClickedNode((prevClickedNode) => ({
            ...googleSheetNode,
            data: {
              ...googleSheetNode.data,
              selectedOption: "source",
            },
          }));
        },
        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(googleSheetNode);
        },
        onMinusClick: () => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === googleSheetNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isLastNode: true,
                  },
                };
              }
              return node;
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) =>
            prevEdges.filter((edge) => edge.source !== googleSheetNode.id)
          );
          setTimeout(() => onSave(), 0);
        },
        onDuplicate: () => copyNode(newId),
        onDuplicateToAnotherWorkflow: () => {
          setShowCopyModal(true);
          setClickedNode(googleSheetNode);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };

    return googleSheetNode;
  };

  const getVariableNode = (newId) => {
    const variableNode = {
      id: newId,
      type: "variableNode",
      data: {
        message: "",
        isLastNode: true,
        onPlusClick: () => {
          setNodeSideBarOpen(true);
          // setClickedNode(googleSheetNode);
          setClickedNode((prevClickedNode) => ({
            ...variableNode,
            data: {
              ...variableNode.data,
              selectedOption: "source",
            },
          }));
        },
        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(variableNode);
        },
        onMinusClick: () => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === variableNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isLastNode: true,
                  },
                };
              }
              return node;
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) =>
            prevEdges.filter((edge) => edge.source !== variableNode.id)
          );
          setTimeout(() => onSave(), 0);
        },
        onDuplicate: () => copyNode(newId),
        onDuplicateToAnotherWorkflow: () => {
          setShowCopyModal(true);
          setClickedNode(variableNode);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };

    return variableNode;
  };

  const getNewNodeName = (nodeType, currentNodes) => {
    let newNodeName;
    const allNodes = currentNodes
      ? currentNodes.filter((node) => node.type === nodeType)
      : nodes.filter((node) => node.type === nodeType);
    const lastNode = allNodes[allNodes.length - 1];
    const lastNodeNumber = lastNode
      ? parseInt(lastNode.data.name.split("-").pop())
      : 0;

    const nodePrefixes = {
      messageNode: "message",
      inputNode: "input-data",
      decisionNode: "decision",
      triggerNode: "trigger-workflow",
      messageOptionsNode: "message-options",
      audioNode: "audio",
      apiNode: "api",
      conversationNode: "conversation",
      scriptNode: "script",
      googleSheetNode: "google-sheet",
      variableNode: "variable",
    };
    newNodeName = `${nodePrefixes[nodeType]}-${lastNodeNumber + 1}`;
    return newNodeName;
  };

  const addNewNode = (nodeType) => {
    let newNode;
    let newNodes = [...nodes];
    const newId = Math.random().toString(36).slice(2, 11);
    setNodeSideBarOpen(false);

    const currentClickedNode = nodes.find((node) => node.id === clickedNode.id);

    const clickedNodeIndex = nodes.findIndex((n) => clickedNode.id === n.id);
    if (clickedNodeIndex > -1 && clickedNode.data.selectedOption === "source") {
      newNodes[clickedNodeIndex].data.isLastNode = false;
    }

    if (nodeType === "messageNode") {
      newNode = getMessageNode(newId);
    } else if (nodeType === "inputNode") {
      newNode = getInputNode(newId);
    } else if (nodeType === "decisionNode") {
      newNode = getDecisionNode(newId);
    } else if (nodeType === "triggerNode") {
      newNode = getTriggerNode(newId);
    } else if (nodeType === "apiNode") {
      newNode = getApiNode(newId);
    } else if (nodeType === "messageOptionsNode") {
      newNode = getMessageOptionsNode(newId);
    } else if (nodeType === "audioNode") {
      newNode = getAudioNode(newId);
    } else if (nodeType === "conversationNode") {
      newNode = getConversationNode(newId);
    } else if (nodeType === "scriptNode") {
      newNode = getScriptNode(newId);
    } else if (nodeType === "googleSheetNode") {
      newNode = getGoogleSheetNode(newId);
    } else if (nodeType === "variableNode") {
      newNode = getVariableNode(newId);
    }

    newNode.data.name = getNewNodeName(nodeType, nodes);

    if (currentClickedNode.type === "messageOptionsNode") {
      newNode.position = {
        x: currentClickedNode.position.x + 150,
        y: currentClickedNode.position.y + 150,
      };
    } else {
      newNode.position = {
        x: currentClickedNode.position.x,
        y: currentClickedNode.position.y + 150,
      };
    }

    newNode.data.localizationMode = localizationMode;
    newNodes.push(newNode);

    // turn isActive false on every node
    newNodes = newNodes.map((node) => {
      return {
        ...node,
        data: {
          ...node.data,
          isActive: false,
        },
      };
    });
    setSidebarOpen(false);

    setNodes(newNodes);

    const currentEdges = [...edges];

    if (clickedNode.type === "decisionNode") {
      currentEdges.push({
        id: `e-from-${clickedNode.id}`,
        source: clickedNode.id,
        sourceHandle: clickedNode.data.selectedOption,
        target: newId,
        label: clickedNode.data.selectedOption === "true" ? "True" : "False",
      });

      setNodes((prevNodes) =>
        prevNodes.map((node) => {
          if (node.id === clickedNode.id) {
            return {
              ...node,
              data: {
                ...node.data,
                ...(clickedNode.data.selectedOption === "true"
                  ? { trueFlowAdded: true }
                  : {}),
                ...(clickedNode.data.selectedOption === "false"
                  ? { falseFlowAdded: true }
                  : {}),
              },
            };
          }
          return node;
        })
      );

      setDecisionNodeFlowType(null);
    } else if (clickedNode.type === "conversationNode") {
      if (clickedNode.data.selectedOption !== "source") {
        const edge = {
          id: `e-from-${clickedNode.id}-${clickedNode.data.selectedOption}`,
          source: clickedNode.id,
          sourceHandle: clickedNode.data.selectedOption,
          target: newId,
          option: clickedNode.data.selectedOption,
        };
        if (clickedNode.data.selectedOption === "default") {
          edge.edgeType = "default";
        }
        currentEdges.push(edge);
        setEdges(currentEdges);

        setNodes((prevNodes) =>
          prevNodes.map((node) => {
            if (node.id === clickedNode.id) {
              return {
                ...node,
                data: {
                  ...node.data,
                  selectedOption: clickedNode.data.selectedOption,
                },
              };
            }
            return node;
          })
        );
      } else {
        currentEdges.push({
          id: `e-from-${clickedNode.id}`,
          source: clickedNode.id,
          sourceHandle: "source",
          target: newId,
        });
        setEdges(currentEdges);
      }
      return;
    } else if (clickedNode.type === "messageOptionsNode") {
      currentEdges.push({
        id: `e-from-${clickedNode.id}-${clickedNode.data.selectedOption}`,
        source: clickedNode.id,
        sourceHandle: clickedNode.data.selectedOption,
        target: newId,
        ...(clickedNode.data.selectedOption !== "source" && {
          option: clickedNode.data.selectedOption,
        }),
        ...(clickedNode.data.selectedOption === "default" && {
          edgeType: "default",
        }),
      });

      setNodes((prevNodes) =>
        prevNodes.map((node) => {
          if (node.id === clickedNode.id) {
            return {
              ...node,
              data: {
                ...node.data,
                connectedOptions: [
                  ...node.data.connectedOptions,
                  clickedNode.data.selectedOption,
                ],
              },
            };
          }
          return node;
        })
      );
    } else if (clickedNode.type === "inputNode") {
      if (clickedNode.data.selectedOption !== "source") {
        currentEdges.push({
          id: `e-from-${clickedNode.id}`,
          source: clickedNode.id,
          sourceHandle: "default",
          edgeType: "default",
          target: newId,
          option: "default",
        });

        setNodes((prevNodes) =>
          prevNodes.map((node) => {
            if (node.id === clickedNode.id) {
              return {
                ...node,
                data: {
                  ...node.data,
                  selectedOption: clickedNode.data.selectedOption,
                },
              };
            }
            return node;
          })
        );
      } else {
        currentEdges.push({
          id: `e-from-${clickedNode.id}`,
          source: clickedNode.id,
          target: newId,
          sourceHandle: "source",
        });
      }
    } else if (clickedNode.type === "apiNode") {
      if (clickedNode.data.selectedOption !== "source") {
        currentEdges.push({
          id: `e-from-${clickedNode.id}`,
          source: clickedNode.id,
          sourceHandle: "default",
          edgeType: "default",
          target: newId,
          option: "default",
        });

        setNodes((prevNodes) =>
          prevNodes.map((node) => {
            if (node.id === clickedNode.id) {
              return {
                ...node,
                data: {
                  ...node.data,
                  selectedOption: clickedNode.data.selectedOption,
                },
              };
            }
            return node;
          })
        );
      } else {
        currentEdges.push({
          id: `e-from-${clickedNode.id}`,
          source: clickedNode.id,
          target: newId,
          sourceHandle: "source",
        });
      }
    } else if (clickedNode.type === "scriptNode") {
      if (clickedNode.data.selectedOption !== "source") {
        currentEdges.push({
          id: `e-from-${clickedNode.id}`,
          source: clickedNode.id,
          sourceHandle: "default",
          edgeType: "default",
          target: newId,
          option: "default",
        });

        setNodes((prevNodes) =>
          prevNodes.map((node) => {
            if (node.id === clickedNode.id) {
              return {
                ...node,
                data: {
                  ...node.data,
                  selectedOption: clickedNode.data.selectedOption,
                },
              };
            }
            return node;
          })
        );
      } else {
        currentEdges.push({
          id: `e-from-${clickedNode.id}`,
          source: clickedNode.id,
          target: newId,
          sourceHandle: "source",
        });
      }
    } else {
      currentEdges.push({
        id: `e-from-${clickedNode.id}`,
        source: clickedNode.id,
        target: newId,
        ...(clickedNode.data.selectedOption !== "source" && {
          option: clickedNode.data.selectedOption,
        }),
        ...(clickedNode.data.selectedOption === "default" && {
          sourceHandle: "default",
          edgeType: "default",
        }),
      });
    }

    setEdges(currentEdges);
  };

  const sendToServer = async (data) => {
    try {
      const response = await editWorkFlow(workflowID, {
        ...data,
      });

      if (response) {
        showSkillrToast("Workflow saved successfully", "success");
        if (data.nodes.nodes.length === 0) {
          // setNodes(initialNodes);
        } else {
          setNodes((prevNodes) => {
            const updatedNodes = prevNodes.map((currentNode) => {
              // Find the matching node in the response by ID
              const responseNode = response.nodes.find(
                (node) => node.id === currentNode.id
              );

              // If a matching node is found and the data is different, update the currentNode
              if (responseNode) {
                return {
                  ...currentNode,
                  data: {
                    ...responseNode.data,
                    ...currentNode.data,
                  },
                };
              }

              // If no matching node is found or the data is the same, return the currentNode unchanged
              return currentNode;
            });

            return updatedNodes;
          });
        }
        reviewWorkflow();
      }
    } catch (error) {
      showSkillrToast("Failed to save workflow", "error");
    }
  };

  const onSave = () => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      sendToServer({ nodes: flow });
    }
  };

  const getAllWorkFlow = async () => {
    try {
      const params = new URLSearchParams();
      params.set("fetchQuestionCount", false);
      const response = await getAllWorkFlowApi(params.toString());
      setWorkFlowList(response);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleOnWorkFlowSummary = () => {
    setSummarySection(true);
  };

  const handleCloseWorkFlowSummary = () => {
    setSummarySection(false);
  };

  const handleOnChangeSummaryNote = (event) => {
    setWorkFlowSummary(event.target.value);
  };

  const handleOnSaveSummary = async () => {
    setWorkFlowEdited(true);
    await editWorkFlow(workflowID, { summary: workFlowSummary }).then(() => {
      showSkillrToast("Summary saved successfully", "success");
    });
    // sendToServer({ summary: workFlowSummary });
    setWorkflowData({ ...rfInstance.toObject(), summary: workFlowSummary });
  };

  const handleOnDiscardSummary = () => {
    setWorkFlowSummary(workflowData?.summary ?? "");
    setWorkFlowEdited(true);
  };

  const handleOnEditSummary = () => {
    setWorkFlowEdited(false);
  };

  const handleOnCopyToClipBoard = () => {
    navigator.clipboard.writeText(workFlowSummary);
    showSkillrToast("Copied to clipboard", "success");
  };

  const handleOnExit = () => {
    if (location?.key === "default") {
      // If location.key equals 'default', it's the first page opened in the browser history.
      navigate("/workflows");
    } else {
      navigate(-1);
    }
  };

  const checkIfFullyLocalized = (nodeId, prevNodes) => {
    let fullyLocalized = false;
    const nonDefaultVoices = bot.botVoices.filter((voice) => !voice.default);
    let node;
    if (prevNodes) {
      node = prevNodes.find((n) => n.id === nodeId);
    } else {
      node = nodes.find((n) => n.id === nodeId);
    }

    if (node?.data?.languageVariations) {
      // Check if every non-default voice is represented in languageVariations
      fullyLocalized = nonDefaultVoices.every((voice) =>
        Object.keys(node.data.languageVariations).includes(voice.languageCode)
      );
    }

    return fullyLocalized;
  };
  const checkIfNodeLocalized = (data, language = currentLanguage) => {
    let nodeLocalized = false;

    nodeLocalized = Boolean(
      data && data.languageVariations && data.languageVariations[language]
    );

    return nodeLocalized;
  };

  const handleLanguageChange = (language) => {
    setCurrentLanguage(language);
    const defaultLanguage = bot.botVoices.find(
      (voice) => voice.languageCode === language
    ).default;
    setLocalizationMode(!defaultLanguage);

    setNodes((prevNodes) => {
      return prevNodes.map((node) => {
        if (node.id !== "0") {
          const nodeLocalized = checkIfNodeLocalized(node.data, language);
          // const fullyLocalized = checkIfFullyLocalized(node.id);

          return {
            ...node,
            data: {
              ...node.data,
              localizationMode: !defaultLanguage,
              fullyLocalized: nodeLocalized,
            },
          };
        } else {
          return node;
        }
      });
    });
  };

  const reviewWorkflow = async () => {
    try {
      const response = await reviewWorkflowAPI({ workflowIds: [workflowID] });
      let errors = response.data[workflowID].errors;

      setNodes((prevNodes) => {
        return prevNodes.map((node) => {
          if (errors.find((error) => error.nodeId === node.id)) {
            node.data.error = errors.find((error) => error.nodeId === node.id);
          } else {
            node.data.error = false;
          }
          return node;
        });
      });
    } catch (error) {
      console.log(error);
      const errorMessage =
        error?.response?.data?.message || "Unable to review workflow";
      showSkillrToast(errorMessage);
    }
  };

  const copyNode = (nodeId) => {
    let newWorkflowData;
    if (rfInstance) {
      const flow = rfInstance.toObject();
      newWorkflowData = flow;
    }

    setNodes((prevNodes) => {
      const sourceNode = prevNodes.find((node) => node.id === nodeId);
      if (!sourceNode) return prevNodes;

      const newId = Math.random().toString(36).slice(2, 11);

      const newNode = {
        ...sourceNode,
        id: newId,
        position: {
          x: sourceNode.position.x + 100,
          y: sourceNode.position.y + 100,
        },
        selected: false,
        data: {
          ...sourceNode.data,
          name: getNewNodeName(sourceNode.type, prevNodes),
          isLastNode: true,
          isActive: false,
          ...(sourceNode.type === "messageOptionsNode" && {
            deleteEdges: false,
            deleteDefault: false,
            deleteSource: false,
            connectedOptions: [],
          }),
          ...(sourceNode.type === "decisionNode" && {
            trueFlowAdded: false,
            falseFlowAdded: false,
          }),
          onDelete: () => {
            setShowDeleteModal(true);
            setClickedNode(newNode);
          },
          onDuplicate: () => copyNode(newId),
          onDuplicateToAnotherWorkflow: () => {
            setShowCopyModal(true);
            setClickedNode(newNode);
          },
        },
      };

      if (newNode.data.customName) delete newNode.data.customName;

      // Set isActive false for all existing nodes
      prevNodes = prevNodes.map((node) => ({
        ...node,
        selected: false,
        data: {
          ...node.data,
          isActive: false,
        },
      }));

      if (newWorkflowData) {
        newWorkflowData = {
          ...newWorkflowData,
          nodes: [...prevNodes, newNode],
        };
      }

      return [...prevNodes, newNode];
    });

    refreshNodes(newWorkflowData, true);
    onSave();
  };

  const copyToAnotherWorkflow = async (otherWorkflowID) => {
    try {
      const otherWorkflowData = await getSingleWorkFlow(otherWorkflowID);
      const newId = Math.random().toString(36).slice(2, 11);
      const newNode = {
        ...clickedNode,
        id: newId,
        position: {
          x: 250,
          y: 330,
        },
        selected: false,
        data: {
          ...clickedNode.data,
          name: getNewNodeName(clickedNode.type, otherWorkflowData.nodes),
          isLastNode: true,
          isActive: false,
          ...(clickedNode.type === "messageOptionsNode" && {
            deleteEdges: false,
            deleteDefault: false,
            deleteSource: false,
            connectedOptions: [],
          }),
          onDelete: () => {
            setShowDeleteModal(true);
            setClickedNode(newNode);
          },
          onDuplicate: () => copyNode(newId),
          onDuplicateToAnotherWorkflow: () => {
            setShowCopyModal(true);
            setClickedNode(newNode);
          },
        },
      };

      if (newNode.data.customName) delete newNode.data.customName;
      if (newNode.data.selectedOption) delete newNode.data.selectedOption;

      const response = await editWorkFlow(otherWorkflowID, {
        nodes: {
          nodes: [...otherWorkflowData.nodes, newNode],
        },
      });

      if (response) {
        showSkillrToast(
          `Node copied successfully to ${otherWorkflowData.name}`,
          "success"
        );
      }
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.message || "Unable to copy";
      showSkillrToast(errorMessage);
    }
  };

  const refreshNodes = (workflowData, onlyNodes = false) => {
    // accept onlyNodes prop in case we only want to update nodes
    if (workflowData) {
      if (workflowData.nodes.length === 0) {
        setNodes(initialNodes);
      } else {
        const updatedNodes = workflowData.nodes.map((node, index) => {
          node.data = {
            ...node.data,
            onDuplicate: () => copyNode(node.id),
            onDuplicateToAnotherWorkflow: () => {
              setShowCopyModal(true);
              setClickedNode(node);
            },
          };

          // workflowData.nodes.map((node, index) => {
          if (node.type === "decisionNode") {
            return {
              ...node,
              data: {
                ...node.data,
                isLastNode: false,
                error: false,
                onTrueClick: () => {
                  setNodeSideBarOpen(true);
                  // setClickedNode(node);
                  setClickedNode((prevClickedNode) => ({
                    ...node,
                    data: {
                      ...node.data,
                      selectedOption: "true",
                    },
                  }));
                },
                onFalseClick: () => {
                  setNodeSideBarOpen(true);
                  // setClickedNode(node);
                  // setDecisionNodeFlowType("false");
                  setClickedNode((prevClickedNode) => ({
                    ...node,
                    data: {
                      ...node.data,
                      selectedOption: "false",
                    },
                  }));
                },
                onDelete: () => {
                  setShowDeleteModal(true);
                  setClickedNode(node);
                },

                onMinusClick: (option) => {
                  setNodes((prevNodes) =>
                    prevNodes.map((n) => {
                      if (n.id === node.id) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            ...(option === "true"
                              ? { trueFlowAdded: false }
                              : {}),
                            ...(option === "false"
                              ? { falseFlowAdded: false }
                              : {}),
                          },
                        };
                      }
                      return n;
                    })
                  );

                  // Delete the connected edge
                  setEdges((prevEdges) =>
                    prevEdges.filter(
                      (edge) =>
                        !(
                          edge.source === node.id &&
                          edge.sourceHandle === option
                        )
                    )
                  );
                  setTimeout(() => onSave(), 0);
                },
              },
            };
          } else if (node.type === "messageOptionsNode") {
            const updatedOptions = node.data.options?.map((option, index) => {
              if (!option.id) {
                let newId = Math.random().toString(36).substring(7);
                return { ...option, id: newId };
              }
              return option;
            });

            const optionMap = updatedOptions?.reduce((acc, optionObj) => {
              if (
                optionObj.option !== "source" &&
                optionObj.option !== "default"
              ) {
                acc[optionObj.option] = optionObj.id;
              }
              return acc;
            }, {});

            // Update connectedOptions with new IDs
            const updatedConnectedOptions = node.data.connectedOptions.map(
              (option) => {
                if (option !== "source" && option !== "default") {
                  return optionMap[option] || option;
                }
                return option;
              }
            );

            return {
              ...node,
              data: {
                ...node.data,
                options: updatedOptions,
                connectedOptions: updatedConnectedOptions,
                localizationMode: false,
                fullyLocalized: false,
                error: false,
                onPlusClick: () => {
                  setNodeSideBarOpen(true);
                  setClickedNode((prevClickedNode) => ({
                    ...node,
                    data: {
                      ...node.data,
                      selectedOption: "source",
                    },
                  }));
                },
                onMinusClick: () => {
                  setNodes((prevNodes) =>
                    prevNodes.map((n) => {
                      if (n.id === node.id) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            connectedOptions: node.data.connectedOptions.filter(
                              (opt) => opt !== "source"
                            ),
                            isLastNode: true,
                          },
                        };
                      }
                      return n;
                    })
                  );

                  // Delete the connected edge
                  setEdges((prevEdges) =>
                    prevEdges.filter(
                      (edge) =>
                        edge.source !== node.id &&
                        edge.sourceHandle !== "source"
                    )
                  );
                  setTimeout(() => onSave(), 0);
                },

                onDelete: () => {
                  setShowDeleteModal(true);
                  setClickedNode(node);
                },
                onOptionPlusClick: (option) => {
                  setNodeSideBarOpen(true);
                  setClickedNode((prevClickedNode) => ({
                    ...node,
                    data: {
                      ...node.data,
                      connectedOptions: [
                        ...node.data.connectedOptions,
                        // check if option is "default" or "source"
                        option === "default" || option === "source"
                          ? option
                          : option.id,
                      ],
                      selectedOption:
                        option === "default" || option === "source"
                          ? option
                          : option.id,
                    },
                  }));
                },
                onOptionMinusClick: (option) => {
                  // Delete the connected edge
                  setNodes((prevNodes) =>
                    prevNodes.map((n) => {
                      if (n.id === node.id) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            connectedOptions:
                              option === "default" || option === "source"
                                ? n.data.connectedOptions.filter(
                                    (opt) => opt !== option
                                  )
                                : n.data.connectedOptions.filter(
                                    (opt) => opt !== option.id
                                  ),
                            selectedOption: [],
                          },
                        };
                      }
                      return n;
                    })
                  );

                  setEdges((prevEdges) => {
                    if (option === "default" || option === "source") {
                      return prevEdges.filter(
                        (edge) =>
                          !(
                            edge.sourceHandle === option &&
                            edge.source === node.id
                          )
                      );
                    } else {
                      return prevEdges.filter(
                        (edge) =>
                          !(
                            edge.sourceHandle &&
                            edge.sourceHandle === option.id &&
                            edge.source === node.id
                          )
                      );
                    }
                  });
                  setTimeout(() => onSave(), 0);
                },
              },
            };
          } else if (node.type === "inputNode") {
            return {
              ...node,
              data: {
                ...node.data,
                localizationMode: false,
                fullyLocalized: false,
                error: false,
                onPlusClick: () => {
                  setNodeSideBarOpen(true);
                  setClickedNode((prevClickedNode) => ({
                    ...node,
                    data: {
                      ...node.data,
                      selectedOption: "source",
                    },
                  }));
                },
                onDelete: () => {
                  setShowDeleteModal(true);
                  setClickedNode(node);
                },
                onMinusClick: () => {
                  setNodes((prevNodes) =>
                    prevNodes.map((n) => {
                      if (n.id === node.id) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            isLastNode: true,
                          },
                        };
                      }
                      return n;
                    })
                  );

                  // Delete the connected edge
                  setEdges((prevEdges) => {
                    return prevEdges.filter(
                      (edge) =>
                        !(
                          edge.source === node.id &&
                          edge.sourceHandle === "source"
                        )
                    );
                  });
                  setTimeout(() => onSave(), 0);
                },
                onOptionPlusClick: (option) => {
                  setNodeSideBarOpen(true);
                  setClickedNode((prevClickedNode) => ({
                    ...node,
                    data: {
                      ...node.data,
                      selectedOption: option,
                    },
                  }));
                },
                onOptionMinusClick: (option) => {
                  // Delete the connected edge
                  setNodes((prevNodes) =>
                    prevNodes.map((n) => {
                      if (n.id === node.id) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            selectedOption: null,
                          },
                        };
                      }
                      return n;
                    })
                  );

                  setEdges((prevEdges) =>
                    prevEdges.filter(
                      (edge) =>
                        !(edge.option === option && edge.source === node.id)
                    )
                  );
                  setTimeout(() => onSave(), 0);
                },
              },
            };
          } else if (node.type === "conversationNode") {
            return {
              ...node,
              data: {
                ...node.data,
                localizationMode: false,
                fullyLocalized: false,
                error: false,
                onPlusClick: () => {
                  setNodeSideBarOpen(true);
                  setClickedNode(() => ({
                    ...node,
                    data: {
                      ...node.data,
                      selectedOption: "source",
                    },
                  }));
                },
                onDelete: () => {
                  setShowDeleteModal(true);
                  setClickedNode(node);
                },
                onMinusClick: () => {
                  setNodes((prevNodes) =>
                    prevNodes.map((n) => {
                      if (n.id === node.id) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            isLastNode: true,
                          },
                        };
                      }
                      return n;
                    })
                  );

                  // Delete the connected edge which has edge.sourceHandle as "source"
                  setEdges((prevEdges) =>
                    prevEdges.filter(
                      (edge) =>
                        !(
                          edge.source === node.id &&
                          edge.sourceHandle === "source"
                        )
                    )
                  );
                  setTimeout(() => onSave(), 0);
                },
                onOptionPlusClick: (option) => {
                  setNodeSideBarOpen(true);
                  setClickedNode((prevClickedNode) => ({
                    ...node,
                    data: {
                      ...node.data,
                      selectedOption: option,
                    },
                  }));
                },
                onOptionMinusClick: (option) => {
                  setNodes((prevNodes) =>
                    prevNodes.map((n) => {
                      if (n.id === node.id) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            selectedOption: null,
                          },
                        };
                      }
                      return n;
                    })
                  );

                  // Delete the connected edge
                  setEdges((prevEdges) =>
                    prevEdges.filter(
                      (edge) =>
                        !(edge.option === option && edge.source === node.id)
                    )
                  );
                  setTimeout(() => onSave(), 0);
                },
              },
            };
          } else {
            return {
              ...node,
              data: {
                ...node.data,
                isActive: false,
                error: false,
                onPlusClick: () => {
                  setNodeSideBarOpen(true);
                  setClickedNode(node);
                  setClickedNode((prevClickedNode) => ({
                    ...node,
                    data: {
                      ...node.data,
                      selectedOption: "source",
                    },
                  }));
                },
                onDelete: () => {
                  setShowDeleteModal(true);
                  setClickedNode(node);
                },
                onMinusClick: () => {
                  setNodes((prevNodes) =>
                    prevNodes.map((n) => {
                      if (n.id === node.id) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            isLastNode: true,
                          },
                        };
                      }
                      return n;
                    })
                  );

                  // Delete the connected edge

                  setEdges((prevEdges) => {
                    return prevEdges.filter((edge) => {
                      if (edge.sourceHandle) {
                        return !(
                          edge.source === node.id &&
                          edge.sourceHandle === "source"
                        );
                      } else {
                        return edge.source !== node.id;
                      }
                    });
                  });
                  setTimeout(() => onSave(), 0);
                },

                onOptionPlusClick: (option, position) => {
                  setNodePosition(position);
                  setNodeSideBarOpen(true);
                  setClickedNode((prevClickedNode) => ({
                    ...node,
                    data: {
                      ...node.data,
                      selectedOption: option,
                    },
                  }));
                },
                onOptionMinusClick: (option) => {
                  setNodes((prevNodes) =>
                    prevNodes.map((n) => {
                      if (n.id === node.id) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            selectedOption: null,
                          },
                        };
                      }
                      return n;
                    })
                  );

                  // Delete the connected edge
                  setEdges((prevEdges) =>
                    prevEdges.filter(
                      (edge) =>
                        !(edge.option === option && edge.source === node.id)
                    )
                  );
                  setTimeout(() => onSave(), 0);
                },
              },
            };
          }
          // })
          // return node;
        });

        setTimeout(() => {
          setNodes(updatedNodes);
        }, 0);
        setNodeSideBarOpen(false);

        if (!onlyNodes) {
          const updatedEdges = workflowData.edges.map((edge) => {
            if (
              edge.sourceHandle &&
              (edge.sourceHandle !== "source" ||
                edge.sourceHandle !== "default")
            ) {
              const sourceNode = updatedNodes.find(
                (node) => node.id === edge.source
              );
              if (sourceNode && sourceNode.type === "messageOptionsNode") {
                const matchingOption = sourceNode.data.options.find(
                  (option) => option.option === edge.option
                );
                if (matchingOption) {
                  return {
                    ...edge,
                    sourceHandle: matchingOption.id,
                    option: matchingOption.id,
                  };
                }
              }
            }
            return edge;
          });

          // Use setTimeout to trigger setEdges and prevent any race condition
          setTimeout(() => {
            setEdges(updatedEdges);
          }, 0);
        }
      }

      if (workflowData.edges.length === 0) {
        setEdges([]);
      } else {
        setEdges(workflowData.edges);
      }
    }
  };

  useEffect(() => {
    if (workflowData) {
      refreshNodes(workflowData);
    }
  }, [workflowData]);

  useEffect(() => {
    if (messageSaved) {
      setTimeout(() => onSave(), 0);
      setMessageSaved(false);
    }
  }, [messageSaved]);

  useEffect(() => {
    if (bot) {
      // Check if botVoices have changed
      const voicesChanged =
        prevBotVoicesRef.current === undefined ||
        prevBotVoicesRef.current.length !== bot.botVoices.length ||
        !prevBotVoicesRef.current.every(
          (voice, index) =>
            voice.languageCode === bot.botVoices[index].languageCode
        );

      if (voicesChanged) {
        setLanguages([]); // Reset languages before setting new ones to ensure uniqueness
        bot.botVoices.forEach((voice) => {
          setLanguages((prev) => {
            if (voice.default) {
              setCurrentLanguage(voice.languageCode);
              // set localizationMode key for all nodes
              setNodes((prevNodes) => {
                return prevNodes?.map((node) => ({
                  ...node,
                  data: {
                    ...node.data,
                    localizationMode: localizationMode,
                  },
                }));
              });
              return [voice.languageCode, ...prev];
            } else {
              return [...prev, voice.languageCode];
            }
          });
        });
        // Update prevBotVoicesRef with the current botVoices
        prevBotVoicesRef.current = bot.botVoices.map((voice) => ({ ...voice }));
      }
    }
  }, [bot]);

  useEffect(() => {
    getWorkflowData();
    getAllWorkFlow();
    getBot();
  }, []);

  return (
    <>
      <Box className={styles.topBar}>
        <FlexBox
          sx={{
            borderRight: `1px solid ${theme.palette.borderColor.light}`,
            height: "100%",
          }}
        >
          <Button
            variant="outlined"
            color="inherit"
            startIcon={
              <LogOut
                color="#0f0f0f"
                style={{ transform: "rotateY(180deg)" }}
                width={20}
                height={20}
              />
            }
            sx={{ border: 0, height: "100%", borderRadius: 0 }}
            onClick={handleOnExit}
          >
            Exit
          </Button>
        </FlexBox>

        <FlexBox sx={{ padding: "0 24px" }}>
          <Typography variant="caption" color={theme.palette.text.primary}>
            {workflowData?.name}
          </Typography>

          <FlexBox ml={"auto"}></FlexBox>
        </FlexBox>

        <FundamentoPopup
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          className={styles.popup}
          disabled={languages.length === 1}
          triggeringComponent={
            <Button
              disabled={languages.length === 1}
              variant="outlined"
              endIcon={<ChevronDown width={20} height={20} />}
              sx={{
                minWidth: 0,
                width: "fit-content",
                borderRadius: 12,
              }}
            >
              {
                defaultLanguages.find((lang) => lang.value === currentLanguage)
                  ?.name
              }
            </Button>
          }
        >
          <Box>
            {languages?.map((language) => {
              if (language === currentLanguage) return <></>;
              return (
                <Box
                  className={styles.popupItem}
                  key={language}
                  onClick={() => handleLanguageChange(language)}
                >
                  <Typography variant="body2">
                    {
                      defaultLanguages.find((lang) => lang.value === language)
                        .name
                    }
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </FundamentoPopup>

        <FlexBox
          sx={{
            position: "fixed",
            right: "110px",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              fontSize: 12,
              height: 36,
              minWidth: 0,
              border: `1px solid ${theme.palette.grey[600]}`,
              color: theme.palette.text.primary,
            }}
            // onClick={() => reviewWorkflow()}
            onClick={() => onSave()}
          >
            Review Workflow
          </Button>

          <Button
            variant="dark"
            onClick={handleOnWorkFlowSummary}
            sx={{
              fontSize: 12,
              height: "36px",
              minWidth: 0,
              borderRadius: "12px",
            }}
          >
            Summary
          </Button>
        </FlexBox>
      </Box>

      {summarySection && (
        <Box
          className={styles.workflowSummary}
          sx={{ right: sidebarOpen ? "26%" : "1%" }}
        >
          <Box className={styles.workflowSummaryContainer}>
            <Box display="flex" justifyContent="space-between">
              <Typography
                variant="body2"
                color="#0f0f0f"
                sx={{ opacity: 0.8, alignSelf: "center" }}
                fontWeight={500}
              >
                Summary
              </Typography>
              <IconButton
                className={styles.workFlowSummaryCloseButton}
                onClick={handleCloseWorkFlowSummary}
              >
                <IconClose width={20} height={20} />
              </IconButton>
            </Box>

            <TextField
              variant="outlined"
              placeholder="Enter the summary here"
              multiline={true}
              minRows={3}
              maxRows={16}
              className={styles.summaryInput}
              disabled={workFlowEdited}
              onChange={handleOnChangeSummaryNote}
              value={workFlowSummary}
              inputProps={{
                style: {
                  "-webkit-text-fill-color": theme.palette.text.primary,
                },
              }}
            />

            <Box className={styles.workFlowSummaryActions}>
              {workFlowEdited ? (
                <>
                  <Button
                    onClick={handleOnCopyToClipBoard}
                    startIcon={<Clipboard width={20} height={20} />}
                    variant="text"
                    sx={{
                      color: "#575757",
                      fontSize: 12,
                      lineHeight: "130%",
                      minWidth: 0,
                    }}
                  >
                    Copy
                  </Button>

                  <Button
                    onClick={handleOnEditSummary}
                    startIcon={
                      <PencilSimple
                        width={20}
                        height={20}
                        stroke="#575757"
                        style={{ opacity: 0.8 }}
                      />
                    }
                    variant="text"
                    sx={{
                      color: "#575757",
                      fontSize: 12,
                      lineHeight: "130%",
                      minWidth: 0,
                    }}
                  >
                    Edit
                  </Button>
                </>
              ) : (
                <>
                  <IconButton
                    onClick={handleOnSaveSummary}
                    disabled={!workFlowSummary}
                  >
                    <Check width={20} height={20} />
                  </IconButton>

                  <IconButton
                    onClick={handleOnDiscardSummary}
                    disabled={!workFlowSummary}
                  >
                    <X width={20} height={20} />
                  </IconButton>
                </>
              )}
            </Box>
          </Box>
        </Box>
      )}

      {/* <WorkFlowSummary open={true} onClose={() => {}} /> */}

      <FlexBox
        columnGap={0}
        sx={{
          height: "calc(100vh - 64px)",
          width: "100%",
          "& .react-flow__attribution": { display: "none" },
        }}
      >
        <ReactFlowProvider>
          {/* <ResetViewport nodes={nodes} /> */}
          <ReactFlow
            fitView
            fitViewOptions={{ maxZoom: 2 }}
            nodeTypes={nodeTypes}
            zoomOnPinch={true}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onPaneClick={() => {
              if (nodes?.length > 0) {
                if (isEditing) {
                  setShowDraftWarning(true);
                  return;
                }
                setSidebarOpen(false);
                setNodeSideBarOpen(false);
                setSelectedNode(null);
                setNodes((prevNodes) =>
                  prevNodes.map((node) => {
                    return {
                      ...node,
                      data: {
                        ...node.data,
                        isActive: false,
                      },
                    };
                  })
                );
              }
            }}
            onNodeDragStop={(event, node) => {
              if (clickedNode && node.id === clickedNode.id) {
                setClickedNode({
                  ...clickedNode,
                  position: node.position,
                });
              }
            }}
            onNodeClick={(_, element) => handleNodeClick(element)}
            onInit={setRfInstance}
          >
            {
              // save workflow when ctrl + enter is pressed
              keysPressed && setTimeout(() => onSave(), 0)
            }
            <PanelForZoom nodes={nodes} />

            <Panel position="left" style={{ margin: 0 }}>
              <Slide
                in={nodeSideBarOpen}
                direction="right"
                mountOnEnter
                unmountOnExit
              >
                <Box
                  className={styles.nodeSidebar}
                  sx={{
                    padding: 0,
                    borderLeft: 0,
                    borderRight: `1px solid ${theme.palette.borderColor.main}`,
                  }}
                >
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: 16,
                      top: 16,
                    }}
                    onClick={() => setNodeSideBarOpen(false)}
                  >
                    <X width={20} height={20} />
                  </IconButton>
                  <Box
                    p={6}
                    sx={{
                      borderBottom: `1px solid ${theme.palette.borderColor.light}`,
                    }}
                  >
                    <Typography variant="body1" fontWeight={500}>
                      Add nodes
                    </Typography>
                  </Box>
                  <FlexBox
                    columnGap={0}
                    flexWrap="wrap"
                    alignItems="stretch"
                    sx={{ overflowY: "auto" }}
                  >
                    {allNodesList.map(
                      (node) =>
                        node.name !== "audioNode" && (
                          <Box
                            className={styles.nodeItem}
                            onClick={() => {
                              addNewNode(node.name);
                            }}
                          >
                            {node.icon}
                            <Typography
                              variant="caption"
                              fontWeight={500}
                              color="text.primary"
                            >
                              {node.label}
                            </Typography>
                          </Box>
                        )
                    )}
                  </FlexBox>
                </Box>
              </Slide>
            </Panel>

            <Panel position="right" style={{ margin: 0 }}>
              {/* <Slide
                in={sidebarOpen}
                direction="left"
                mountOnEnter
                unmountOnExit
              > */}
              {sidebarOpen && (
                <Box className={styles.sidebar}>
                  <Typography variant="body1" fontWeight={500} mb={5}>
                    Properties
                  </Typography>

                  <FlexBox mb={4}>
                    {
                      allNodesList.find(
                        (node) => node.name === selectedNode?.type
                      ).icon
                    }

                    <Box>
                      <Typography
                        component={"div"}
                        variant="caption"
                        fontSize={10}
                      >
                        Node Type
                      </Typography>

                      <Typography variant="body2" fontWeight={500}>
                        {
                          allNodesList.find(
                            (node) => node.name === selectedNode?.type
                          ).label
                        }
                      </Typography>
                    </Box>
                  </FlexBox>

                  {selectedNode?.type === "messageNode" && (
                    <MessageProperties
                      workFlowId={workflowID}
                      workflows={workFlowList}
                      currentLanguage={currentLanguage}
                      localizationMode={localizationMode}
                      botVoices={bot.botVoices}
                      key={selectedNode?.id}
                      data={selectedNode?.data}
                      onEdit={(value) => setIsEditing(value)}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              let nodeLocalized = checkIfNodeLocalized(data);
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                  // ...(data?.gcsUri && { gcsUri: data?.gcsUri }),
                                  fullyLocalized: nodeLocalized,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );

                        setIsEditing(false);

                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}

                  {selectedNode?.type === "messageOptionsNode" && (
                    <MessageOptionsProperties
                      currentLanguage={currentLanguage}
                      localizationMode={localizationMode}
                      workflows={workFlowList}
                      key={selectedNode?.id}
                      data={selectedNode?.data}
                      workFlowId={workflowID}
                      onEdit={(value) => setIsEditing(value)}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              let nodeLocalized = checkIfNodeLocalized(data);
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  isActive: false,
                                  ...data,
                                  fullyLocalized: nodeLocalized,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setMessageSaved(true);
                        setSidebarOpen(false);
                        if (data.deleteEdges) {
                          setEdges((prevEdges) =>
                            prevEdges.filter(
                              (edge) =>
                                edge.source !== selectedNode.id ||
                                (edge.source === selectedNode.id &&
                                  edge.edgeType === "default")
                            )
                          );
                          setNodes((prevNodes) =>
                            prevNodes.map((node) => {
                              if (node.id === selectedNode.id) {
                                return {
                                  ...node,
                                  data: {
                                    ...node.data,
                                    connectedOptions:
                                      node.data.connectedOptions.filter(
                                        (opt) => opt === "default"
                                      ),
                                  },
                                };
                              }
                              return node;
                            })
                          );
                        }
                        if (data.deleteDefault) {
                          setEdges((prevEdges) =>
                            prevEdges.filter(
                              (edge) =>
                                edge.source !== selectedNode.id ||
                                (edge.source === selectedNode.id &&
                                  edge.edgeType !== "default")
                            )
                          );
                          setNodes((prevNodes) =>
                            prevNodes.map((node) => {
                              if (node.id === selectedNode.id) {
                                return {
                                  ...node,
                                  data: {
                                    ...node.data,
                                    connectedOptions:
                                      node.data.connectedOptions.filter(
                                        (opt) => opt !== "default"
                                      ),
                                  },
                                };
                              }
                              return node;
                            })
                          );
                        }
                        if (data.deleteSource) {
                          setEdges((prevEdges) =>
                            prevEdges.filter(
                              (edge) =>
                                edge.source !== selectedNode.id ||
                                (edge.source === selectedNode.id &&
                                  edge.sourceHandle !== "source")
                            )
                          );
                          setNodes((prevNodes) =>
                            prevNodes.map((node) => {
                              if (node.id === selectedNode.id) {
                                return {
                                  ...node,
                                  data: {
                                    ...node.data,
                                    connectedOptions:
                                      node.data.connectedOptions.filter(
                                        (opt) => opt !== "source"
                                      ),
                                  },
                                };
                              }
                              return node;
                            })
                          );
                        }

                        setIsEditing(false);
                      }}
                    />
                  )}

                  {selectedNode?.type === "inputNode" && (
                    <InputProperties
                      localizationMode={localizationMode}
                      currentLanguage={currentLanguage}
                      workflows={workFlowList}
                      token={token}
                      workFlowId={workflowID}
                      data={selectedNode?.data}
                      onEdit={(value) => setIsEditing(value)}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              let nodeLocalized = checkIfNodeLocalized(data);
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                  fullyLocalized: nodeLocalized,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setIsEditing(false);
                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}

                  {selectedNode?.type === "decisionNode" && (
                    <DecisionProperties
                      nodes={nodes}
                      workFlowId={workflowID}
                      data={selectedNode?.data}
                      workflows={workFlowList}
                      onEdit={(value) => setIsEditing(value)}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setIsEditing(false);
                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}

                  {selectedNode?.type === "triggerNode" && (
                    <TriggerWorkflowProperties
                      workflows={workFlowList}
                      workFlowId={workflowID}
                      data={selectedNode?.data}
                      onEdit={(value) => setIsEditing(value)}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setIsEditing(false);
                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}

                  {selectedNode?.type === "audioNode" && (
                    <AudioProperties
                      localizationMode={localizationMode}
                      currentLanguage={currentLanguage}
                      token={token}
                      workFlowId={workflowID}
                      workflows={workFlowList}
                      data={selectedNode?.data}
                      onEdit={(value) => setIsEditing(value)}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              let nodeLocalized = checkIfNodeLocalized(data);
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                  fullyLocalized: nodeLocalized,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setIsEditing(false);
                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}

                  {selectedNode?.type === "apiNode" && (
                    <RestApiProperties
                      workflows={workFlowList}
                      workFlowId={workflowID}
                      data={selectedNode?.data}
                      onEdit={(value) => setIsEditing(value)}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setIsEditing(false);
                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}

                  {selectedNode?.type === "conversationNode" && (
                    <ConversationProperties
                      currentLanguage={currentLanguage}
                      localizationMode={localizationMode}
                      token={token}
                      workFlowId={workflowID}
                      workflows={workFlowList}
                      key={selectedNode?.id}
                      data={selectedNode?.data}
                      onEdit={(value) => setIsEditing(value)}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            let nodeLocalized = checkIfNodeLocalized(data);
                            if (node.id === selectedNode.id) {
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                  fullyLocalized: nodeLocalized,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setIsEditing(false);
                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}

                  {selectedNode?.type === "scriptNode" && (
                    <ScriptProperties
                      key={selectedNode?.id}
                      data={selectedNode?.data}
                      workflows={workFlowList}
                      workFlowId={workflowID}
                      onEdit={(value) => setIsEditing(value)}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setIsEditing(false);
                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}

                  {selectedNode?.type === "googleSheetNode" && (
                    <GoogleSheetProperties
                      key={selectedNode?.id}
                      data={selectedNode?.data}
                      workFlowId={workFlowList}
                      onEdit={(value) => setIsEditing(value)}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setIsEditing(false);
                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}

                  {selectedNode?.type === "variableNode" && (
                    <VariableProperties
                      key={selectedNode?.id}
                      data={selectedNode?.data}
                      workFlowId={workflowID}
                      workflows={workFlowList}
                      onEdit={(value) => setIsEditing(value)}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setIsEditing(false);
                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}
                </Box>
              )}
              {/* </Slide> */}
            </Panel>

            <Background variant="cross" gap={12} size={1} />
            {/* <DevTools /> */}
          </ReactFlow>
        </ReactFlowProvider>
      </FlexBox>

      <DeleteModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => {
          deleteNode(clickedNode.id);
          setShowDeleteModal(false);
        }}
        title="Confirm delete?"
        subtitle={"Are you sure you want to delete this node?"}
      />

      <DraftWarningModal
        open={showDraftWarning}
        onClose={() => setShowDraftWarning(false)}
        onConfirm={() => {
          setShowDraftWarning(false);
          setSidebarOpen(false);
          setSelectedNode(null);
          setIsEditing(false);
        }}
      />

      <CopyNodeModal
        open={showCopyModal}
        onClose={() => setShowCopyModal(false)}
        onConfirm={(otherWorkflowID) => {
          setShowCopyModal(false);
          copyToAnotherWorkflow(otherWorkflowID);
        }}
        workflows={workFlowList?.workFlows?.filter(
          (workflow) => workflow._id !== workflowID
        )}
      />
    </>
  );
};

export default Diagram;
