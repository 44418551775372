import { Box, Typography } from "@mui/material";
import styles from "./index.module.css";
import PageWrapper from "components/PageWrapper";
import { useEffect, useRef, useState } from "react";
import CustomStepper from "../CustomStepper";
import CreateCampaignStep1 from "../CreateCampaignStep1";
import Footer from "../Footer";
import { useNavigate, useParams } from "react-router-dom";
import CreateCampaignStep2 from "../CreateCampaignStep2";
import { createCampaign, getCampaignDetails, updateCampaign } from "services";
import { useSkillrToast } from "context/toast";
import moment from "moment";

const CreateCampaign = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { showSkillrToast } = useSkillrToast();
  const [step, setStep] = useState(1);
  const [campaignDetails, setCampaignDetails] = useState();
  const campaignStatus = useRef("active");
  const step1Ref = useRef();
  const step2Ref = useRef();
  const [loader, setLoader] = useState(false);

  const getNextButtonText = () => {
    if (params?.campaignId) {
      return step === 3 ? "Save" : "Next";
    } else {
      return step === 3 ? "Start" : "Next";
    }
  };

  const createNewCampaign = async (campaignData) => {
    try {
      setLoader(true);
      const payload = {
        ...campaignData,
        status: campaignStatus.current,
      };

      // if (
      //   payload?.status !== "draft"
      //   // && payload?.importType === "csv" && !payload?.csvUpload?.files
      // ) {
      //   setLoader(false);
      //   showSkillrToast("Please upload csv file", "error");
      // } else {
      if (payload?.durationType === "indefinite") {
        delete payload.startDate;
        delete payload.endDate;
      } else {
        payload.startDate = moment(payload?.startDate).utc().format();
        payload.endDate = moment(payload?.endDate).utc().format();
      }
      // if (payload?.csvUpload?.signedUrls) {
      //   payload.csvFile = payload.csvUpload.signedUrls[0]
      //     .split("?")[0]
      //     .replace("https://storage.googleapis.com/", "gs://");
      // }
      // delete payload.csvUpload;
      payload.webhookIdentifier = payload.webhookIdentifier
        .split("?")[1]
        .replace("importId=", "");

      params?.campaignId
        ? await updateCampaign(params?.campaignId, payload)
        : await createCampaign(payload);
      setLoader(false);
      showSkillrToast(
        campaignStatus.current === "draft"
          ? "Campaign saved as draft"
          : "New campaign created",
        "success"
      );
      navigate("/campaign");
      // }
    } catch (error) {
      setLoader(false);
      const errorMessage =
        error?.response?.data?.message ?? "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleOnBack = () => {
    if (step === 1) {
      navigate("/campaign");
    } else {
      setStep(step - 1);
    }
  };

  const handleOnNext = () => {
    if (step === 1) {
      step1Ref.current.validateForm();
    } else if (step === 2) {
      step2Ref.current.validateForm();
    } else {
      createNewCampaign(campaignDetails);
    }
  };

  const handleOnSave = (data) => {
    const updatedData = { ...campaignDetails, ...data };
    if (campaignStatus.current === "draft") {
      createNewCampaign(updatedData);
    } else {
      setCampaignDetails(updatedData);
      setStep(step + 1);
    }
  };

  const handleOnSaveDraft = () => {
    campaignStatus.current = "draft";
    handleOnNext();
  };

  const getFormattedData = (data) => {
    const formattedData = {
      name: data?.name,
      bot: data?.bot?._id,
      didNumbers: data?.didNumbers,
      startTime: data?.startTime,
      endTime: data?.endTime,
      durationType: data?.durationType,
      webhookIdentifier: `${
        process.env.REACT_APP_BACKEND_API_ENDPOINT ||
        window.location.protocol + "//" + window.location.hostname
      }/api/campaign/webhook?importId=${data?.webhookIdentifier}`,
      // parallelCalls: data?.parallelCalls,
      // importType: data?.importType,
      // csvUpload: data?.csvFile
      //   ? {
      //       files: [{ name: data?.csvFile?.split("/").pop() }],
      //       signedUrls: [
      //         data?.csvFile.replace("gs://", "https://storage.googleapis.com/"),
      //       ],
      //     }
      //   : {},
      startDate: data?.startDate
        ? moment(data?.startDate).format("YYYY-MM-DD")
        : "",
      endDate: data?.endDate ? moment(data?.endDate).format("YYYY-MM-DD") : "",
      redialConfiguration: data?.redialConfiguration,
      preProcessCampaign: data?.preProcessCampaign ?? false,
      customOutcomes: data?.customOutcomes ?? [],
    };

    return formattedData;
  };

  const getCampaignDataById = async () => {
    try {
      const campaignDataById = await getCampaignDetails(params.campaignId);
      if (campaignDataById) {
        const formattedCampaignData = getFormattedData(campaignDataById);
        setCampaignDetails(formattedCampaignData);
      }
    } catch (error) {
      const errorMessage = error?.data?.response?.message
        ? error.data.response.message
        : "Something went wrong";

      showSkillrToast(errorMessage, "error");
    }
  };

  useEffect(() => {
    if (params?.campaignId) {
      getCampaignDataById();
    }
  }, [params?.campaignId]);

  return (
    <PageWrapper className={styles.container}>
      <Box className={styles.CreateCampaignContainer}>
        <Box>
          <Typography variant="h4" fontWeight={500}>
            Create Campaign
          </Typography>
          <Typography variant="body1" color="#797979">
            Set up and customise your outbound call campaign to reach your
            audience effectively.
          </Typography>
        </Box>
        <Box className={styles.stepper}>
          <CustomStepper
            step={step - 1}
            steps={["Campaign Details", "Redial Configuration", "Preview"]}
          />
        </Box>
        <Box>
          {step === 1 && (
            <CreateCampaignStep1
              ref={step1Ref}
              campaignDetails={campaignDetails}
              onSave={handleOnSave}
            />
          )}

          {step === 2 && (
            <CreateCampaignStep2
              ref={step2Ref}
              onSave={handleOnSave}
              campaignDetails={campaignDetails}
            />
          )}

          {step === 3 && (
            <>
              <Box>
                <Typography variant="subtitle1" color="#797979" mb={4}>
                  Campaign Details
                </Typography>

                <CreateCampaignStep1
                  ref={step1Ref}
                  campaignDetails={campaignDetails}
                  onSave={handleOnSave}
                  disabled={true}
                />
              </Box>

              <Box>
                <Typography variant="subtitle1" color="#797979" mt={8} mb={4}>
                  Redial Configuration
                </Typography>

                <CreateCampaignStep2
                  campaignDetails={campaignDetails}
                  ref={step2Ref}
                  onSave={handleOnSave}
                  disabled={true}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Footer
        backButtonText="Back"
        SaveButtonText="Save as Draft"
        NextButtonText={getNextButtonText()}
        className={styles.footer}
        onBack={handleOnBack}
        OnNext={handleOnNext}
        onSave={handleOnSaveDraft}
        nextButtonDisabled={loader ? true : false}
        saveButtonDisbaled={loader ? true : false}
      />
    </PageWrapper>
  );
};

export default CreateCampaign;
