import { Fade, IconButton, Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Handle } from "reactflow";
import styles from "./index.module.css";
import { Minus, Plus, Trash2 } from "react-feather";
import theme from "theme";
import { gscUriPrefix } from "text-constants/common";
import CustomHandle from "../../CustomHandle";
import TargetHandle from "../../TargetHandle";
import AudioPlayer from "components/AudioPlayer";
import NodeBase from "../../NodeBase";

const AudioNode = (props) => {
  const {
    message,
    isActive,
    isLastNode,
    onMinusClick,
    onPlusClick,
    onDelete,
    gcsUri,
    token,
    localizationMode,
    fullyLocalized,
    error,
    customName,
  } = props.data;

  const [showPlus, setShowPlus] = useState();
  const [isParentHovered, setIsParentHovered] = useState(false);

  const handleClick = () => {
    if (!isLastNode) {
      onMinusClick();
    } else {
      onPlusClick({ x: props.xPos, y: props.yPos });
    }
  };

  useEffect(() => {
    setShowPlus(isLastNode);
  }, [isLastNode]);

  return (
    <NodeBase
      isActive={isActive}
      onDelete={onDelete}
      localizationMode={localizationMode}
      fullyLocalized={fullyLocalized}
      error={error}
      onDuplicate={props.data.onDuplicate}
      onDuplicateToAnotherWorkflow={props.data.onDuplicateToAnotherWorkflow}
    >
      <Fade in={isParentHovered} mountOnEnter unmountOnExit>
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <Trash2 color={theme.palette.error.main} width={12} height={12} />
        </IconButton>
      </Fade>

      <Typography variant="body2" fontWeight={500}>
        {customName
          ? customName
          : (props.data.name || "")
              .replace(/-/g, " ")
              .replace(/\b\w/g, (l) => l.toUpperCase())
              .replace(/ (\d+)/, " ($1)")}
      </Typography>

      <Typography variant="body2" fontSize={10} mb={3} color="text.secondary">
        {message}
      </Typography>

      {gcsUri && (
        <Box>
          <AudioPlayer
            controls
            style={{ width: "100%" }}
            key={gcsUri}
            audioPath={gcsUri}
          />
        </Box>
      )}

      <Handle
        type="target"
        position="top"
        style={{
          background: "transparent",
          border: `1px solid #666`,
          // border: 0,
          // height: 0,
          // width: 0,
          top: 5,
        }}
      />

      {/* <Handle type="source" position="bottom" /> */}

      <CustomHandle
        id="source"
        position="bottom"
        clickHandler={handleClick}
        icon={
          isLastNode ? (
            <Plus color={theme.palette.text.primary} width={12} height={12} />
          ) : (
            <Minus color={theme.palette.text.primary} width={12} height={12} />
          )
        }
        style={{
          bottom: 0,
          left: "50%",
          transform: "translate(-50%, 50%)",
        }}
      />

      {showPlus && (
        <IconButton
          className={styles.plusButton}
          onClick={(e) => {
            e.stopPropagation();
            onPlusClick();
            // setShowPlus(false);
          }}
        >
          <Plus color={theme.palette.text.primary} width={12} height={12} />
        </IconButton>
      )}
    </NodeBase>
  );
};

export default AudioNode;
