import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grow,
  IconButton,
  InputAdornment,
  Radio,
  Switch,
  TextField,
  Typography,
  Tooltip,
  Select,
  MenuItem,
  LinearProgress,
  Collapse,
} from "@mui/material";
import { ChevronDown, IconInfo2, IconUpload2, RedAlert } from "components/SVG";
import { useEffect, useRef, useState } from "react";
import { ChevronRight, Plus, Square, Trash2 } from "react-feather";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import styles from "./index.module.css";
import {
  CheckSquare,
  Circle,
  Eye,
  EyeClose,
  RadioButton,
  TextFlatMp3,
  TextFlatWav,
} from "components/newSVG";
import FlexBox from "components/FlexBox";
import theme from "theme";
import { useSkillrToast } from "context/toast";
import VariableDropdown from "../VariableDropdown";
import {
  languages as defaultLanguages,
  gscUriPrefix,
} from "text-constants/common";
import { multiModelResponseTypes } from "text-constants/diagram";
import AudioPlayer from "pages/Bots/components/AudioPlayer";
import AddFillerModal from "../../AddFillerModal";
import {
  checkDuplicateName,
  getAudioDuration,
  getNewFileName,
} from "utils/utils";
import uploadAudioFileApi from "services/uploadAudioFile";
import UploadFile from "../../UploadFile";

const MAX_AUDIO_TIME = 20; // 20 sec

const MessageOptionsProperties = (props) => {
  const {
    data,
    onSave,
    workflows,
    localizationMode,
    currentLanguage,
    workFlowId,
    onEdit,
  } = props;

  const {
    control,
    reset,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      type: "text",
      message: "",
      options: [{ option: "", id: Math.random().toString(36).substring(7) }],
      responseValidation: false,
      triggerWorkflow: true,
      setVariable: false,
      enunciateOptions: false,
      validationType: "validationCounter",
      failureMessage: "",
      variableName: "",
      defaultText: "",
      validationCount: 2,
      defaultType: "triggerWorkflow",
      selectedWorkflow: {
        workflowName: data?.workflowName || "",
        workflowId: data?.workflowId || "",
      },
      localizedMessage: "",
      localizedAudio: "",
      localizedOptions: [{ option: "" }],
      localizedFailureMessage: "",
      multiModelResponse: false,
      multiModelResponseType: "dtmf",
      multiModelFirstResponse: true,
      multiModelRetryResponse: false,
      filler: [],
      retryAudio: "",
      localizedRetryAudio: "",
    },
  });
  const { showSkillrToast } = useSkillrToast();
  const [showDefault, setShowDefault] = useState(false);
  const [deleteEdges, setDeleteEdges] = useState(false);
  const [deleteDefault, setDeleteDefault] = useState(false);
  const [deleteSource, setDeleteSource] = useState(false);
  const [showFillerModal, setShowFillerModal] = useState(false);
  const supportedFileTypes = ["audio/wav", "audio/mpeg"];

  const filesType = {
    "audio/mpeg": (
      <TextFlatMp3 width={24} height={32} style={{ flexShrink: 0 }} />
    ),
    "audio/wav": (
      <TextFlatWav width={24} height={32} style={{ flexShrink: 0 }} />
    ),
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: localizationMode ? "localizedOptions" : "options",
    rules: {
      validate: (options) =>
        options.length > 0 || "At least one option is required",
    },
  });

  const { fields: localizedFields, append: appendLocalized } = useFieldArray({
    control,
    name: "localizedOptions",
    rules: {
      validate: (options) =>
        options.length > 0 || "At least one option is required",
    },
  });

  const fileInputRef = useRef();
  const [uploadedDetails, setUploadedDetails] = useState();
  const [uploadProgress, setUploadProgress] = useState();

  const handleOnSave = async () => {
    const formData = getValues();

    const filteredFormData = {
      ...formData,
      // message: formData.message,
      // options: formData.options,
      // enunciateOptions: formData.enunciateOptions,
      // triggerWorkflow: formData.triggerWorkflow,
      // setVariable: formData.setVariable,
      // multiModelResponse: formData.multiModelResponse,
      // multiModelResponseType: formData.multiModelResponseType,
      // multiModelFirstResponse: formData.multiModelFirstResponse,
      multiModelRetryResponse: formData.responseValidation
        ? formData.multiModelRetryResponse
        : false,
      ...(deleteEdges ? { deleteEdges: true } : { deleteEdges: false }),
      ...(deleteDefault ? { deleteDefault: true } : { deleteDefault: false }),
      ...(deleteSource ? { deleteSource: true } : { deleteSource: false }),
      // filler: formData.filler,
      // type: formData.type,
      gcsUri: uploadedDetails?.gcsUri || "",
      // localizedAudio: formData.localizedAudio,
    };

    /* Checking for audio */
    if (
      filteredFormData?.type === "audio" &&
      !localizationMode &&
      !uploadedDetails?.gcsUri
    ) {
      showSkillrToast("Please upload default audio file", "error");
      return;
    }

    /* Checking for retry audio */

    if (
      filteredFormData?.type === "audio" &&
      filteredFormData?.responseValidation &&
      !filteredFormData?.retryAudio &&
      !filteredFormData?.localizedRetryAudio
    ) {
      showSkillrToast("Please upload retry audio", "error");
      return;
    }

    if (
      filteredFormData?.type === "audio" &&
      localizationMode &&
      !filteredFormData.localizedAudio
    ) {
      showSkillrToast("Please upload audio file", "error");
      return;
    }

    if (formData.setVariable) {
      filteredFormData.variableName = formData.variableName;
    } else {
      filteredFormData.options = formData.options;
      filteredFormData.defaultText = "";
      filteredFormData.variableName = "";
    }

    if (formData.responseValidation) {
      filteredFormData.responseValidation = formData.responseValidation;
      filteredFormData.validationType = formData.validationType;
      filteredFormData.failureMessage = formData.failureMessage;

      if (formData.validationType === "validationCounter") {
        filteredFormData.validationCount = formData.validationCount;
      }
    } else {
      filteredFormData.validationType = "";
      filteredFormData.failureMessage = "";
      filteredFormData.validationCount = 2;
      filteredFormData.responseValidation = false;
    }

    if (formData.triggerWorkflow) {
      filteredFormData.selectedWorkflow = formData.selectedWorkflow;
    }

    if (
      !formData.responseValidation ||
      formData.validationType !== "forceValidate"
    ) {
      filteredFormData.defaultType = formData.defaultType;

      if (
        formData.defaultType === "triggerWorkflow" &&
        formData.triggerWorkflow
      ) {
        filteredFormData.selectedWorkflow = formData.selectedWorkflow;
      }

      if (formData.setVariable) {
        filteredFormData.defaultText = formData.defaultText;
      }
    }

    if (localizationMode && currentLanguage) {
      filteredFormData.languageVariations = {
        ...(data?.languageVariations || {}),
        [currentLanguage]: {
          ...(data?.languageVariations?.[currentLanguage] || {}),
          message: formData.localizedMessage,
          gcsUri: formData.localizedAudio,
          options: formData.localizedOptions,
          failureMessage: formData.localizedFailureMessage,
          retryAudio: formData.localizedRetryAudio,
        },
      };
    }

    if (formData.type === "audio") {
      formData.gcsUri = uploadedDetails?.gcsUri;
    }

    if (
      !formData.triggerWorkflow &&
      !formData.setVariable &&
      !localizationMode
    ) {
      showSkillrToast(
        "At least one option between 'Trigger Workflow' or 'Set Variable' must be turned on",
        "error"
      );
      return;
    }

    if (
      formData.triggerWorkflow &&
      formData.selectedWorkflow?.workflowId.length === 0 &&
      (!formData.responseValidation ||
        (formData.responseValidation &&
          formData.validationType !== "forceValidate")) &&
      !localizationMode &&
      formData.defaultType === "triggerWorkflow"
    ) {
      showSkillrToast("Workflow is required", "error");
      return;
    }

    if (
      formData?.multiModelResponse &&
      !(formData?.multiModelFirstResponse || formData?.multiModelRetryResponse)
    ) {
      showSkillrToast("Multi model response type is required", "error");
      return;
    }

    if (formData.customName) {
      const isDuplicate = await checkDuplicateName(
        workFlowId,
        formData.customName,
        props.data.name
      );
      if (isDuplicate) {
        showSkillrToast("Node name already exists", "error");
        return;
      }
    }

    onSave(filteredFormData);
  };

  const handleOnSaveFillerDetails = (newFillerDetails) => {
    const currentFillers = getValues().filler || [];
    const newFiller = newFillerDetails?.text
      ? {
          type: "text",
          text: { value: newFillerDetails.text },
          language: {
            languageCode: currentLanguage,
            languageName: defaultLanguages.find(
              (l) => l.value === currentLanguage
            ).name,
          },
        }
      : {
          type: "audio",
          audio: {
            type: newFillerDetails?.file?.type,
            name: newFillerDetails?.file?.name,
            url: newFillerDetails?.fileUrl,
            duration: newFillerDetails?.fileDuration,
          },
          language: {
            languageCode: currentLanguage,
            languageName: defaultLanguages.find(
              (l) => l.value === currentLanguage
            ).name,
          },
        };

    const updatedFillers = currentFillers.filter(
      (filler) => filler.language.languageCode !== currentLanguage
    );
    updatedFillers.push(newFiller);

    setValue("filler", updatedFillers);
    setShowFillerModal(false);
  };

  const handleOnDelete = () => {
    const currentFillers = getValues().filler;
    setValue(
      "filler",
      currentFillers.filter(
        (filler) => filler.language.languageCode !== currentLanguage
      )
    );
  };

  const watchOnAllFields = () => {
    const subscribe = watch((value, { name, type }) => {
      if (name === "triggerWorkflow" || name === "setVariable") {
        if (type === "change") {
          if (name === "triggerWorkflow" && !value.triggerWorkflow) {
            setDeleteEdges(true);
          } else {
            setDeleteEdges(false);
            setDeleteSource(true);
          }
        }
      } else if ((name = "defaultType" && value.defaultType !== "addNode")) {
        if (type === "change") {
          setDeleteDefault(true);
        }
      } else if (name === "defaultType" && value.defaultType === "addNode") {
        if (type === "change") {
          setDeleteSource(true);
        }
      }
    });

    return subscribe;
  };

  const addOption = () => {
    const newId = Math.random().toString(36).substring(7);
    append({ option: "", id: newId });
  };

  const handleOnRemoveAudio = (isLocalized = false) => {
    if (isLocalized) {
      setValue("localizedAudio", "");
    } else {
      setUploadedDetails(null);
      setValue("gcsUri", "");
    }
    setUploadProgress();
  };

  const handleUploadProgress = (progressEvent) => {
    if (progressEvent?.loaded > 0) {
      setUploadProgress(
        Math.floor((progressEvent?.loaded / progressEvent?.total) * 100)
      );
    }
  };

  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("audioFile", file);
      const config = {
        onUploadProgress: handleUploadProgress,
      };
      const uploadResponse = await uploadAudioFileApi(
        workFlowId,
        formData,
        config
      );

      if (localizationMode) {
        setValue("localizedAudio", uploadResponse.gcsUri);
      } else {
        setUploadedDetails(uploadResponse);
      }
      fileInputRef.current.value = "";
    } catch (error) {
      fileInputRef.current.value = "";
      setUploadProgress();
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleFileChange = async (event) => {
    try {
      const file = event.target.files[0];
      const renamedFile = getNewFileName(file);
      const audioDuration = await getAudioDuration(renamedFile);
      if (audioDuration < MAX_AUDIO_TIME) {
        uploadFile(renamedFile);
      } else {
        showSkillrToast("Audio duration should not exceed 20 seconds", "error");
      }
    } catch (error) {
      showSkillrToast(error?.message, "error");
    }
  };

  const handleFileUpload = (isLocalized = false) => {
    fileInputRef.current.click();
  };

  const renderAudioSelector = (isLocalized = false) => (
    <div style={{ marginBottom: 16, width: "100%" }}>
      {(isLocalized ? watch("localizedAudio") : uploadedDetails?.gcsUri) ? (
        <Box display="flex" gap={3} alignItems="center">
          <audio controls>
            <source
              src={(isLocalized
                ? watch("localizedAudio")
                : uploadedDetails?.gcsUri
              ).replace("gs://", gscUriPrefix)}
              type={
                "audio/" +
                (isLocalized
                  ? watch("localizedAudio")
                  : uploadedDetails?.gcsUri
                )
                  .split(".")
                  .pop()
              }
              // src={`${
              //   process.env.REACT_APP_BACKEND_API_ENDPOINT || window.location.protocol + "//" + window.location.hostname
              // }/api/asset/${
              //   uploadedDetails?.gcsUri.split("//")[1]
              // }?authToken=${token}`}
            ></source>
          </audio>

          <IconButton onClick={() => handleOnRemoveAudio(localizationMode)}>
            <Trash2 width={16} height={16} />
          </IconButton>
        </Box>
      ) : (
        <Box
          display="flex"
          sx={{
            border: `1px dashed #0f0f0f`,
            background: "#fff",
            gap: 2,
            padding: 4,
            borderRadius: 3,
            cursor: "pointer",
          }}
          onClick={() => handleFileUpload(localizationMode)}
        >
          {uploadProgress ? (
            <LinearProgress
              value={uploadProgress}
              color="primary"
              variant="determinate"
              style={{ width: "100%" }}
              sx={{
                height: 5,
                borderRadius: 5,
                bgcolor: "#E9E9E9",
                "& .MuiLinearProgress-bar": {
                  borderRadius: 5,
                  bgcolor: "#0F0F0F",
                },
              }}
            />
          ) : (
            <>
              <IconUpload2 width={16} height={16} />
              <Typography variant="caption" color="#0f0f0f">
                Upload File
              </Typography>

              <Typography variant="caption" sx={{ fontSize: 10, ml: "auto" }}>
                Max time: 20 Sec
              </Typography>
            </>
          )}
        </Box>
      )}

      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        accept={supportedFileTypes.join(",")}
        onChange={(event) => handleFileChange(event, true)}
      />
    </div>
  );

  useEffect(() => {
    onEdit(isDirty);
  }, [isDirty]);

  useEffect(() => {
    const subscription = watchOnAllFields();
    return () => subscription.unsubscribe();
  }, []);

  // add fields in localized fields array which are there in fields array
  useEffect(() => {}, [data, currentLanguage]);

  useEffect(() => {
    if (fields.length > 9) {
      setValue("multiModelResponse", false);
    }
  }, [fields]);

  useEffect(() => {
    if (data) {
      reset({
        ...getValues(),
        ...data,
        localizedMessage:
          data.languageVariations?.[currentLanguage]?.message || "",
        localizedOptions: data.languageVariations?.[currentLanguage]
          ?.options || [{ option: "" }],
        localizedFailureMessage:
          data.languageVariations?.[currentLanguage]?.failureMessage || "",
        localizedAudio:
          data.languageVariations?.[currentLanguage]?.gcsUri || "",
        localizedRetryAudio:
          data.languageVariations?.[currentLanguage]?.retryAudio || "",
      });
      setUploadedDetails(data);
    }
  }, [data, currentLanguage]);

  useEffect(() => {
    if (localizationMode) {
      // Synchronize localizedFields with fields
      const optionsCount = watch("options")?.length;
      const localizedOptionsCount = watch("localizedOptions")?.length;

      if (optionsCount > localizedOptionsCount) {
        const diff = optionsCount - localizedOptionsCount;
        for (let i = 0; i < diff; i++) {
          appendLocalized({ option: "" });
        }
      }
    }
  }, [watch, appendLocalized, localizationMode]);

  useEffect(() => {
    if (watch("responseValidation")) {
      if (!watch("validationType")) {
        setValue("validationType", "validationCounter");
        setValue("validationCount", 2);
      }
    }
  }, [watch("responseValidation")]);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {/* a type radio selector for text or audio */}
      <Controller
        control={control}
        name="type"
        render={({ field: { onChange, value } }) => (
          <Box display="flex" mb={4} gap={5}>
            <Box
              display="flex"
              gap={1}
              alignItems="center"
              component={"label"}
              sx={{ cursor: "pointer" }}
            >
              <Radio
                onClick={() => onChange("text")}
                checked={value === "text"}
                sx={{ padding: 0 }}
                icon={<Circle width={20} height={20} />}
                checkedIcon={<RadioButton width={20} height={20} />}
              />
              <Typography
                variant="caption"
                fontWeight={400}
                color="text.primary"
              >
                Text
              </Typography>
            </Box>
            <Box
              display="flex"
              gap={1}
              alignItems="center"
              component={"label"}
              sx={{ cursor: "pointer" }}
            >
              <Radio
                onClick={() => onChange("audio")}
                checked={value === "audio"}
                sx={{ padding: 0 }}
                icon={<Circle width={20} height={20} />}
                checkedIcon={<RadioButton width={20} height={20} />}
              />
              <Typography
                variant="caption"
                fontWeight={400}
                color="text.primary"
              >
                Audio
              </Typography>
            </Box>
          </Box>
        )}
      />

      {localizationMode ? (
        <>
          <Button
            variant="text"
            endIcon={
              showDefault ? (
                <Eye width={20} height={20} />
              ) : (
                <EyeClose width={20} height={20} />
              )
            }
            color="inherit"
            sx={{ minWidth: 0, width: "fit-content", mb: 4 }}
            onClick={() => setShowDefault(!showDefault)}
          >
            View Default
          </Button>

          <Collapse in={showDefault} mountOnEnter unmountOnExit>
            <Box
              sx={{
                backgroundColor: theme.palette.grey[50],
                borderRadius: 3,
                border: `1px solid ${theme.palette.grey[100]}`,
                padding: `16px 20px`,
                mb: 4,
                width: "100%",
              }}
            >
              {watch("type") === "text" ? (
                <Typography variant="body2">
                  {data?.message
                    ? data.message
                    : "Please add message in default language"}
                </Typography>
              ) : (
                <Box display="flex" gap={3} alignItems="center" fullWidth>
                  {data?.gcsUri ? (
                    <audio controls key={data?.gcsUri}>
                      <source
                        src={data?.gcsUri.replace("gs://", gscUriPrefix)}
                        type={"audio/" + data?.gcsUri?.split(".").pop()}
                      ></source>
                    </audio>
                  ) : (
                    <Typography variant="body2">
                      Please upload audio in default language
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </Collapse>

          {/* Localized audio file selector */}
          {watch("type") === "audio" && renderAudioSelector(true)}

          <Controller
            control={control}
            name="localizedMessage"
            rules={{
              required: "Localized message is required",
            }}
            render={({ field: { onChange, value } }) => (
              <VariableDropdown
                control={control}
                watch={watch}
                setValue={setValue}
                value={value}
                onChange={onChange}
              >
                <TextField
                  variant="outlined"
                  multiline
                  rows={5}
                  fullWidth
                  placeholder={`Add message for ${
                    defaultLanguages.find((l) => l.value === currentLanguage)
                      ?.name
                  }`}
                  value={value}
                  onChange={onChange}
                  sx={{
                    "& div": {
                      borderRadius: "12px",
                      padding: 0,
                    },
                    "& textarea": {
                      marginBottom: "32px",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          alignSelf: "flex-end",
                          mb: 4,
                          mr: 2,
                          position: "absolute",
                          right: 0,
                        }}
                      >
                        {`${value?.length || 0} / 500`}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 500 }}
                />
              </VariableDropdown>
            )}
          />
          {errors?.localizedMessage && (
            <FlexBox
              mt={2}
              mb={2}
              component="span"
              columnGap={1}
              fontSize={12}
              color={"error.main"}
            >
              <RedAlert style={{ marginRight: 8 }} />
              {errors?.localizedMessage?.message}
            </FlexBox>
          )}

          <div style={{ height: 16 }} />

          {localizedFields.map((field, index) => (
            <div key={field.id} style={{ width: "100%" }}>
              <Typography variant="body2" fontWeight={500}>
                Option {index + 1}
              </Typography>
              <FlexBox sx={{ mt: 2, mb: 4 }}>
                <Controller
                  name={`localizedOptions.${index}.option`}
                  control={control}
                  defaultValue=""
                  rules={{ required: "Option is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      placeholder={`Option ${index + 1}`}
                      helperText={
                        errors?.localizedOptions?.[index]?.option && (
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                          >
                            <RedAlert style={{ marginRight: 8 }} />
                            {errors?.localizedOptions?.[index]?.option?.message}
                          </Box>
                        )
                      }
                    />
                  )}
                />

                {showDefault && (
                  <Collapse in={true} mountOnEnter unmountOnExit>
                    <Typography variant="caption" fontWeight={400}>
                      {watch("options")[index]?.option}
                    </Typography>
                  </Collapse>
                )}
              </FlexBox>

              {watch("setVariable") && (
                <FlexBox component="label" my={2}>
                  <Typography
                    variant="caption"
                    color="text.primary"
                    fontFamily={"inter"}
                    sx={{ cursor: "pointer" }}
                  >
                    Value
                  </Typography>
                  <Controller
                    name={`localizedOptions.${index}.value`}
                    control={control}
                    defaultValue=""
                    rules={{ required: "Value is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={{ "& div": { maxHeight: 40 } }}
                        fullWidth
                        variant="outlined"
                        placeholder="Value"
                        helperText={
                          errors?.options?.[index]?.value && (
                            <Box
                              component="span"
                              display="flex"
                              alignItems="center"
                            >
                              <RedAlert style={{ marginRight: 8 }} />
                              {
                                errors?.localizedOptions?.[index]?.value
                                  ?.message
                              }
                            </Box>
                          )
                        }
                      />
                    )}
                  />
                </FlexBox>
              )}
            </div>
          ))}

          {watch("responseValidation") && (
            <>
              <Collapse in={showDefault} mountOnEnter unmountOnExit>
                <Box
                  fullWidth
                  sx={{
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 3,
                    border: `1px solid ${theme.palette.grey[100]}`,
                    padding: `16px 20px`,
                    mt: 4,
                  }}
                >
                  {watch("type") === "text" ? (
                    <Typography variant="body2">
                      {data?.failureMessage}
                    </Typography>
                  ) : (
                    <Box display="flex" gap={3} alignItems="center">
                      <audio controls key={data?.retryAudio}>
                        <source
                          src={data?.retryAudio?.replace("gs://", gscUriPrefix)}
                          type={"audio/" + data?.gcsUri?.split(".").pop()}
                        ></source>
                      </audio>
                    </Box>
                  )}
                </Box>
              </Collapse>

              {watch("type") === "text" ? (
                <Box mt={3} fullWidth sx={{ width: "100%" }}>
                  <Typography variant="caption" fontWeight={500} component="p">
                    Validation Text
                  </Typography>
                  <Controller
                    name="localizedFailureMessage"
                    control={control}
                    rules={{
                      required: "Validation text is required",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        className={styles.failureMessage}
                        fullWidth
                        onChange={onChange}
                        sx={{ mb: 2 }}
                        value={value}
                        helperText={
                          errors?.localizedFailureMessage && (
                            <Box
                              component="span"
                              display="flex"
                              alignItems="center"
                            >
                              <RedAlert style={{ marginRight: 8 }} />
                              {errors?.localizedFailureMessage?.message}
                            </Box>
                          )
                        }
                      />
                    )}
                  />
                </Box>
              ) : (
                <Box mt={4} mb={3} style={{ width: "100%" }}>
                  {getValues("localizedRetryAudio") ? (
                    <Box display="flex" gap={3} alignItems="center">
                      <audio controls key={getValues("localizedRetryAudio")}>
                        <source
                          src={getValues("localizedRetryAudio")?.replace(
                            "gs://",
                            gscUriPrefix
                          )}
                          type={
                            "audio/" +
                            getValues("localizedRetryAudio")?.split(".").pop()
                          }
                        ></source>
                      </audio>

                      <IconButton
                        onClick={() => {
                          setValue("localizedRetryAudio", "", {
                            shouldValidate: true,
                          });
                        }}
                      >
                        <Trash2 width={16} height={16} />
                      </IconButton>
                    </Box>
                  ) : (
                    <UploadFile
                      workFlowId={workFlowId}
                      onUploadComplete={(uploadedData) => {
                        if (uploadedData?.gcsUri) {
                          setValue("localizedRetryAudio", uploadedData.gcsUri, {
                            shouldValidate: true,
                          });
                        }
                      }}
                    />
                  )}
                </Box>
              )}
            </>
          )}

          {watch("filler") && watch("filler").length > 0 && (
            <Box mb={4} sx={{ width: "100%" }}>
              <Typography variant="body2" fontWeight={500}>
                Filler Message
              </Typography>

              {watch("filler")
                .filter(
                  (filler) => filler.language.languageCode === currentLanguage
                )
                .map((filler, index) => (
                  <FlexBox
                    key={index}
                    sx={{
                      border: `1px solid ${theme.palette.borderColor.light}`,
                      borderRadius: 3,
                      mt: 1,
                    }}
                    padding={4}
                  >
                    {filler.type === "text" ? (
                      <Tooltip
                        title={
                          filler.text?.value?.length > 64
                            ? filler.text?.value
                            : ""
                        }
                      >
                        <Typography
                          variant="caption"
                          color={theme.palette.text.primary}
                          fontWeight={400}
                          className={styles.textOverFlow}
                          ml={2}
                        >
                          {filler.text?.value}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <>
                        {filesType[filler.audio?.type]}
                        <Tooltip
                          title={
                            filler.audio?.name?.length > 64
                              ? filler.audio?.name
                              : ""
                          }
                        >
                          <Typography
                            variant="caption"
                            color={theme.palette.text.primary}
                            fontWeight={400}
                            className={styles.textOverFlow}
                            ml={3}
                          >
                            {filler.audio?.name}
                          </Typography>
                        </Tooltip>
                        <Box sx={{ ml: "auto" }}>
                          <AudioPlayer
                            key={filler.audio?.url}
                            url={filler.audio?.url.replace(
                              "gs://",
                              gscUriPrefix
                            )}
                            type={filler.audio?.type}
                          />
                        </Box>
                      </>
                    )}
                    <IconButton
                      sx={{ marginLeft: "auto" }}
                      onClick={() => handleOnDelete(index)}
                    >
                      <Trash2 style={{ width: 20, height: 20 }} />
                    </IconButton>
                  </FlexBox>
                ))}
            </Box>
          )}

          <Button
            fullWidth
            variant="outlined"
            sx={{ borderRadius: 82, mb: 4 }}
            onClick={() => setShowFillerModal(true)}
          >
            {/* check if current language filler already exists, if it does say replace filler else add filler */}
            {watch("filler") &&
            watch("filler").find(
              (filler) => filler.language.languageCode === currentLanguage
            )
              ? "Replace Filler"
              : "Add Filler"}
          </Button>

          <AddFillerModal
            open={showFillerModal}
            onClose={() => setShowFillerModal(false)}
            onSave={handleOnSaveFillerDetails}
          />
        </>
      ) : (
        <>
          <Typography variant="caption" color="text.primary" mb={1}>
            Node Name
          </Typography>

          <Controller
            control={control}
            name="customName"
            rules={{
              validate: (value) => {
                if (/[^a-zA-Z\s]/.test(value))
                  return "Node name can only contain letters and spaces";
                return true;
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                inputProps={{ maxLength: 20 }}
                variant="outlined"
                fullWidth
                placeholder="Add Name"
                value={value}
                onChange={onChange}
                error={errors?.customName}
                helperText={errors?.customName?.message}
                sx={{
                  mb: 4,
                  "& div": {
                    borderRadius: "12px",
                    padding: 0,
                  },
                }}
              />
            )}
          />

          {/* audio file selector */}
          {watch("type") === "audio" && (
            <div style={{ marginBottom: 16, width: "100%" }}>
              {uploadedDetails?.gcsUri && (
                <Box display="flex" gap={3} alignItems="center">
                  <audio controls key={uploadedDetails?.gcsUri}>
                    <source
                      src={uploadedDetails?.gcsUri.replace(
                        "gs://",
                        gscUriPrefix
                      )}
                      type={
                        "audio/" + uploadedDetails?.gcsUri?.split(".").pop()
                      }
                    ></source>
                  </audio>
                  <IconButton onClick={() => handleOnRemoveAudio(false)}>
                    <Trash2 width={16} height={16} />
                  </IconButton>
                </Box>
              )}

              {!uploadedDetails?.gcsUri && (
                <Box
                  display="flex"
                  sx={{
                    border: `1px dashed #0f0f0f`,
                    background: "#fff",
                    gap: 2,
                    padding: 4,
                    borderRadius: 3,
                    cursor: "pointer",
                  }}
                  onClick={() => handleFileUpload()}
                >
                  {uploadProgress ? (
                    <LinearProgress
                      value={uploadProgress}
                      color="primary"
                      variant="determinate"
                      style={{ width: "100%" }}
                      sx={{
                        height: 5,
                        borderRadius: 5,
                        bgcolor: "#E9E9E9",
                        "& .MuiLinearProgress-bar": {
                          borderRadius: 5,
                          bgcolor: "#0F0F0F",
                        },
                      }}
                    />
                  ) : (
                    <>
                      <IconUpload2 width={16} height={16} />
                      <Typography variant="caption" color="#0f0f0f">
                        Upload File
                      </Typography>

                      <Typography
                        variant="caption"
                        sx={{ fontSize: 10, ml: "auto" }}
                      >
                        Max time: 20 Sec
                      </Typography>
                    </>
                  )}
                </Box>
              )}

              <input
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
                ref={fileInputRef}
                accept={supportedFileTypes.join(",")}
              />
            </div>
          )}

          <Controller
            control={control}
            name="message"
            rules={{
              required:
                watch("type") === "text"
                  ? "Message is required"
                  : "Transcript is required",
            }}
            render={({ field: { onChange, value } }) => (
              <VariableDropdown
                control={control}
                watch={watch}
                setValue={setValue}
                value={value}
                onChange={onChange}
              >
                <TextField
                  variant="outlined"
                  multiline
                  rows={5}
                  fullWidth
                  placeholder={
                    watch("type") === "text"
                      ? "Add Message*"
                      : "Add Transcript*"
                  }
                  sx={{
                    "& div": {
                      // overflow: "hidden",
                      borderRadius: "12px",
                      padding: 0,
                    },
                    "& textarea": {
                      marginBottom: "32px",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          alignSelf: "flex-end",
                          mb: 4,
                          mr: 2,
                          position: "absolute",
                          right: 0,
                        }}
                      >
                        {`${watch("message")?.length} / 500`}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 500 }}
                />
              </VariableDropdown>
            )}
          />
          {errors?.message && (
            <FlexBox
              mt={2}
              component="span"
              columnGap={1}
              fontSize={12}
              color={"error.main"}
            >
              <RedAlert style={{ marginRight: 8 }} />
              {errors?.message?.message}
            </FlexBox>
          )}
          <Typography mt={6} mb={2} variant="body2" fontWeight={500}>
            Properties
          </Typography>
          <FlexBox>
            <Controller
              control={control}
              name="triggerWorkflow"
              render={({ field: { onChange, value } }) => (
                <FlexBox
                  columnGap={1}
                  component="label"
                  sx={{ cursor: "pointer" }}
                >
                  <Checkbox
                    checked={value}
                    onChange={onChange}
                    checkedIcon={<CheckSquare width={18} height={18} />}
                    icon={<Square width={18} height={18} />}
                  />
                  <Typography
                    variant="caption"
                    fontWeight={400}
                    color="text.primary"
                  >
                    Trigger Workflow
                  </Typography>
                </FlexBox>
              )}
            />
            <Controller
              control={control}
              name="setVariable"
              render={({ field: { onChange, value } }) => (
                <FlexBox
                  columnGap={1}
                  component="label"
                  sx={{ cursor: "pointer" }}
                >
                  <Checkbox
                    checked={value}
                    onChange={onChange}
                    checkedIcon={<CheckSquare width={18} height={18} />}
                    icon={<Square width={18} height={18} />}
                  />
                  <Typography
                    variant="caption"
                    fontWeight={400}
                    color="text.primary"
                  >
                    Set Variable
                  </Typography>
                </FlexBox>
              )}
            />
          </FlexBox>

          {!watch("triggerWorkflow") && !watch("setVariable") && (
            <Box component="span" display="flex" alignItems="center" mt={1}>
              <RedAlert style={{ marginRight: 8, flexShrink: 0 }} />
              <Typography variant="caption" color="error">
                At least one option between 'Trigger Workflow' or 'Set Variable'
                must be turned on
              </Typography>
            </Box>
          )}

          {watch("type") === "text" && (
            <Controller
              control={control}
              name="enunciateOptions"
              render={({ field: { onChange, value } }) => (
                <>
                  <FlexBox
                    mt={4}
                    columnGap={2}
                    component="label"
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography variant="body2" fontWeight={500}>
                      Enunciate Options
                    </Typography>
                    <Tooltip title="Primary Node Enunciation" placement="top">
                      <IconInfo2 />
                    </Tooltip>
                    <Switch
                      checked={value}
                      onChange={onChange}
                      size="small"
                      sx={{
                        "& .MuiSwitch-thumb": {
                          backgroundColor: theme.palette.common.white,
                          boxShadow: "0px 3.2px 3.2px 0px rgba(0, 0, 0, 0.25)",
                        },
                        "& .Mui-checked+.MuiSwitch-track": {
                          opacity: `1 !important`,
                        },
                      }}
                    />
                  </FlexBox>
                </>
              )}
            />
          )}

          {watch("setVariable") && (
            <>
              <Typography variant="body2" fontWeight={500} mt={4}>
                Variable Name
              </Typography>
              <Controller
                control={control}
                name="variableName"
                rules={{ required: "Variable name is required" }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    placeholder="Variable Name"
                    sx={{ mt: 2 }}
                    value={value}
                    onChange={onChange}
                    helperText={
                      errors?.variableName && (
                        <Box
                          component="span"
                          display="flex"
                          alignItems="center"
                        >
                          <RedAlert style={{ marginRight: 8 }} />
                          {errors?.variableName?.message}
                        </Box>
                      )
                    }
                  />
                )}
              />
            </>
          )}

          {fields.map((field, index) => (
            <div key={field.id} style={{ width: "100%" }}>
              <Typography sx={{ mt: 4 }} variant="body2" fontWeight={500}>
                Option {index + 1}
              </Typography>
              <Controller
                name={`options.${index}.option`}
                control={control}
                defaultValue=""
                rules={{ required: "Option is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ mt: 2 }}
                    fullWidth
                    variant="outlined"
                    placeholder={`Option ${index + 1}`}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={() => remove(index)}>
                          <Trash2 width={12} height={12} />
                        </IconButton>
                      ),
                    }}
                    helperText={
                      errors?.options?.[index]?.option && (
                        <Box
                          component="span"
                          display="flex"
                          alignItems="center"
                        >
                          <RedAlert style={{ marginRight: 8 }} />
                          {errors?.options?.[index]?.option?.message}
                        </Box>
                      )
                    }
                  />
                )}
              />
              {watch("setVariable") && (
                <FlexBox component="label" my={2}>
                  <Typography
                    variant="caption"
                    color="text.primary"
                    fontFamily={"inter"}
                    sx={{ cursor: "pointer" }}
                  >
                    Value
                  </Typography>
                  <Controller
                    name={`options.${index}.value`}
                    control={control}
                    defaultValue=""
                    rules={{ required: "Value is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={{ "& div": { maxHeight: 40 } }}
                        fullWidth
                        variant="outlined"
                        placeholder="Value"
                        helperText={
                          errors?.options?.[index]?.value && (
                            <Box
                              component="span"
                              display="flex"
                              alignItems="center"
                            >
                              <RedAlert style={{ marginRight: 8 }} />
                              {errors?.options?.[index]?.value?.message}
                            </Box>
                          )
                        }
                      />
                    )}
                  />
                </FlexBox>
              )}
            </div>
          ))}
          {errors?.options?.root && (
            <Box component="span" display="flex" alignItems="center">
              <RedAlert style={{ marginRight: 8 }} />
              <Typography variant="caption" color="error">
                {errors?.options?.root?.message}
              </Typography>
            </Box>
          )}

          {fields.length < 20 && (
            <Button
              startIcon={<Plus width={12} height={12} />}
              onClick={() => addOption()}
              sx={{ mt: 2 }}
              variant="text"
              color="inherit"
            >
              Add Option
            </Button>
          )}

          {/* Multimodel response switch */}
          <Box mt={4}>
            <Controller
              control={control}
              name="multiModelResponse"
              render={({ field: { onChange, value } }) => {
                return (
                  <FlexBox columnGap={1}>
                    <Typography variant="body2" fontWeight={500}>
                      Multimodel Response
                    </Typography>
                    <Tooltip title="Enable this option to allow the customer to respond via voice, DTMF (keypad input), or both. NOTE: Multimodel validation is only available for up to 9 options">
                      <IconInfo2 width={16} height={16} />
                    </Tooltip>
                    <Switch
                      checked={value}
                      onChange={onChange}
                      size="small"
                      disabled={fields.length > 9}
                      sx={{
                        "& .MuiSwitch-thumb": {
                          backgroundColor: theme.palette.common.white,
                          boxShadow: "0px 3.2px 3.2px 0px rgba(0, 0, 0, 0.25)",
                        },
                        "& .Mui-checked+.MuiSwitch-track": {
                          opacity: `1 !important`,
                        },
                      }}
                    />
                  </FlexBox>
                );
              }}
            />

            {/* {watch("multiModelResponse") && ( */}
            <Collapse
              in={watch("multiModelResponse")}
              unmountOnExit
              mountOnEnter
            >
              <div>
                <Controller
                  control={control}
                  name="multiModelResponseType"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      onChange={onChange}
                      IconComponent={ChevronDown}
                      sx={{
                        mt: 4,
                        flexGrow: 1,
                        borderRadius: 3,
                        "& li": {
                          fontSize: 12,
                        },
                      }}
                      fullWidth
                      className={`${styles.select}`}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            borderRadius: 0,
                            boxShadow: "none",
                            backgroundColor: theme.palette.grey[50],
                          },
                        },
                      }}
                    >
                      {multiModelResponseTypes.map((mmtype) => (
                        <MenuItem value={mmtype.value} key={mmtype.value}>
                          {mmtype.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />

                <FlexBox display="flex" justifyContent="space-between" mt={4}>
                  <Controller
                    control={control}
                    name="multiModelFirstResponse"
                    render={({ field: { onChange, value } }) => (
                      <FlexBox
                        columnGap={1}
                        mt={3}
                        component="label"
                        sx={{ cursor: "pointer" }}
                      >
                        <Checkbox
                          onChange={onChange}
                          checked={value}
                          sx={{
                            padding: 0,
                            "& > svg": { width: 18, height: 18 },
                          }}
                          icon={<Square width={18} height={18} />}
                          checkedIcon={<CheckSquare width={18} height={18} />}
                        />
                        <Typography
                          variant="caption"
                          fontWeight={400}
                          color="text.primary"
                        >
                          First Response
                        </Typography>
                      </FlexBox>
                    )}
                  />
                  <Controller
                    control={control}
                    name="multiModelRetryResponse"
                    render={({ field: { onChange, value } }) => {
                      const responseValidation = watch("responseValidation");
                      return (
                        <FlexBox
                          columnGap={1}
                          mt={3}
                          component="label"
                          sx={{ cursor: "pointer" }}
                          className={!responseValidation && styles.disabled}
                        >
                          <Checkbox
                            onChange={onChange}
                            checked={value}
                            sx={{
                              padding: 0,
                              "& > svg": { width: 18, height: 18 },
                            }}
                            disabled={!responseValidation}
                            checkedIcon={
                              <CheckSquare
                                width={18}
                                height={18}
                                className={
                                  !responseValidation && styles.disabled
                                }
                              />
                            }
                            icon={<Square width={18} height={18} />}
                          />
                          <Typography
                            variant="caption"
                            fontWeight={400}
                            color="text.primary"
                            className={!responseValidation && styles.disabled}
                          >
                            Retry Response
                          </Typography>
                        </FlexBox>
                      );
                    }}
                  />
                </FlexBox>
                {!watch("multiModelFirstResponse") &&
                  !watch("multiModelRetryResponse") && (
                    <Box
                      component="span"
                      display="flex"
                      alignItems="center"
                      mt={2}
                    >
                      <RedAlert style={{ marginRight: 8 }} />
                      <Typography variant="caption" color="error">
                        One option needs to be selected
                      </Typography>
                    </Box>
                  )}
              </div>
            </Collapse>
            {/* )} */}
          </Box>

          <Box my={4} sx={{ width: "100%" }}>
            <Controller
              control={control}
              name="responseValidation"
              render={({ field: { onChange, value } }) => (
                // <FormControlLabel
                //   className={styles.responseValidation}
                //   control={
                //     // <Checkbox
                //     //   checked={value}
                //     //   onChange={onChange}
                //     //   checkedIcon={<CheckSquare width={20} height={20} />}
                //     // />
                //   }
                //   label={
                <FlexBox columnGap={1}>
                  <Typography variant="body2" fontWeight={500}>
                    Response validation
                  </Typography>
                  <Tooltip
                    title="Sends a failure message if the response is incorrect. Set retries by count or require until correct."
                    placement="top"
                  >
                    <IconInfo2 />
                  </Tooltip>
                  <Switch
                    checked={value}
                    onChange={onChange}
                    size="small"
                    sx={{
                      "& .MuiSwitch-thumb": {
                        backgroundColor: theme.palette.common.white,
                        boxShadow: "0px 3.2px 3.2px 0px rgba(0, 0, 0, 0.25)",
                      },
                      "& .Mui-checked+.MuiSwitch-track": {
                        opacity: `1 !important`,
                      },
                    }}
                  />
                </FlexBox>
                //   }
                // />
              )}
            />

            <Collapse
              in={watch("responseValidation")}
              unmountOnExit
              mountOnEnter
            >
              <div>
                {/* retry audio selector - to be shown when type is audio */}
                {watch("type") === "audio" && (
                  <Box mt={4} mb={3}>
                    {getValues("retryAudio") ? (
                      <Box display="flex" gap={3} alignItems="center">
                        <audio controls key={getValues("retryAudio")}>
                          <source
                            src={getValues("retryAudio")?.replace(
                              "gs://",
                              gscUriPrefix
                            )}
                            type={
                              "audio/" +
                              getValues("retryAudio")?.split(".").pop()
                            }
                          ></source>
                        </audio>

                        <IconButton
                          onClick={() => {
                            setValue("retryAudio", "", {
                              shouldValidate: true,
                            });
                          }}
                        >
                          <Trash2 width={16} height={16} />
                        </IconButton>
                      </Box>
                    ) : (
                      <UploadFile
                        workFlowId={workFlowId}
                        onUploadComplete={(uploadedData) => {
                          if (uploadedData?.gcsUri) {
                            setValue("retryAudio", uploadedData.gcsUri, {
                              shouldValidate: true,
                            });
                          }
                        }}
                      />
                    )}
                  </Box>
                )}

                <Controller
                  control={control}
                  name="validationType"
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <div style={{ width: "100%" }}>
                      <Box mt={3} display="flex" gap={5}>
                        <Box
                          display="flex"
                          gap={1}
                          alignItems="center"
                          component={"label"}
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            onClick={() => onChange("validationCounter")}
                            checked={value === "validationCounter"}
                            sx={{ padding: 0 }}
                            icon={<Circle width={20} height={20} />}
                            checkedIcon={<RadioButton width={20} height={20} />}
                          />
                          <Typography
                            variant="caption"
                            fontWeight={400}
                            color="text.primary"
                          >
                            Validation Counter
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          gap={1}
                          alignItems="center"
                          component={"label"}
                          sx={{ cursor: "pointer" }}
                        >
                          <Radio
                            onClick={() => onChange("forceValidate")}
                            checked={value === "forceValidate"}
                            sx={{ padding: 0 }}
                            icon={<Circle width={20} height={20} />}
                            checkedIcon={<RadioButton width={20} height={20} />}
                          />
                          <Typography
                            variant="caption"
                            fontWeight={400}
                            color="text.primary"
                          >
                            Force validate
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  )}
                />
                {watch("validationType") === "validationCounter" && (
                  <Box mt={3}>
                    <Typography variant="caption" color="text.primary">
                      Count
                    </Typography>

                    {/* <Controller
                        name="validationCount"
                        control={control}
                        rules={{
                          required: "Count is required",
                        }}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            type="number"
                            sx={{
                              "& *": {
                                border: 0,
                                outline: 0,
                              },
                              "& div": {
                                backgroundColor: theme.palette.grey[50],
                              },
                            }}
                            inputProps={{ min: 1, max: 10 }}
                            className={styles.count}
                            fullWidth
                            onChange={onChange}
                            value={value}
                            helperText={
                              errors?.count && (
                                <Box
                                  component="span"
                                  display="flex"
                                  alignItems="center"
                                >
                                  <RedAlert style={{ marginRight: 8 }} />
                                  {errors?.count?.message}
                                </Box>
                              )
                            }
                          />
                        )}
                      /> */}
                    <Controller
                      name="validationCount"
                      control={control}
                      rules={{
                        required: "Count is required",
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            borderRadius: 0,
                            boxShadow: "none",
                            backgroundColor: theme.palette.grey[50],
                          },
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          value={value}
                          onChange={onChange}
                          className={styles.select}
                          fullWidth
                        >
                          {[...Array(10)].map((_, i) => (
                            <MenuItem key={i} value={i + 1}>
                              {i + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </Box>
                )}

                {/* failure message/validation text - to be shown when type is text */}
                {watch("type") === "text" && (
                  <Box mt={3} fullWidth>
                    <Typography
                      variant="caption"
                      fontWeight={500}
                      component="p"
                    >
                      Validation Text
                    </Typography>
                    <Controller
                      name="failureMessage"
                      control={control}
                      rules={{
                        required: "Validation text is required",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          className={styles.failureMessage}
                          fullWidth
                          onChange={onChange}
                          value={value}
                          helperText={
                            errors?.failureMessage && (
                              <Box
                                component="span"
                                display="flex"
                                alignItems="center"
                              >
                                <RedAlert style={{ marginRight: 8 }} />
                                {errors?.failureMessage?.message}
                              </Box>
                            )
                          }
                        />
                      )}
                    />
                  </Box>
                )}
              </div>
            </Collapse>
          </Box>

          {watch("filler") && watch("filler").length > 0 && (
            <Box mb={4} sx={{ width: "100%" }}>
              <Typography variant="body2" fontWeight={500}>
                Filler Message
              </Typography>

              {watch("filler")
                .filter(
                  (filler) => filler.language.languageCode === currentLanguage
                )
                .map((filler, index) => (
                  <FlexBox
                    key={index}
                    sx={{
                      border: `1px solid ${theme.palette.borderColor.light}`,
                      borderRadius: 3,
                      mt: 1,
                    }}
                    padding={4}
                  >
                    {filler.type === "text" ? (
                      <Tooltip
                        title={
                          filler.text?.value?.length > 64
                            ? filler.text?.value
                            : ""
                        }
                      >
                        <Typography
                          variant="caption"
                          color={theme.palette.text.primary}
                          fontWeight={400}
                          className={styles.textOverFlow}
                          ml={2}
                        >
                          {filler.text?.value}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <>
                        {filesType[filler.audio?.type]}
                        <Tooltip
                          title={
                            filler.audio?.name?.length > 64
                              ? filler.audio?.name
                              : ""
                          }
                        >
                          <Typography
                            variant="caption"
                            color={theme.palette.text.primary}
                            fontWeight={400}
                            className={styles.textOverFlow}
                            ml={3}
                          >
                            {filler.audio?.name}
                          </Typography>
                        </Tooltip>
                        <Box sx={{ ml: "auto" }}>
                          <AudioPlayer
                            key={filler.audio?.url}
                            url={filler.audio?.url.replace(
                              "gs://",
                              gscUriPrefix
                            )}
                            type={filler.audio?.type}
                          />
                        </Box>
                      </>
                    )}
                    <IconButton
                      sx={{ marginLeft: "auto" }}
                      onClick={() => handleOnDelete(index)}
                    >
                      <Trash2 style={{ width: 20, height: 20 }} />
                    </IconButton>
                  </FlexBox>
                ))}
            </Box>
          )}

          <Button
            fullWidth
            variant="outlined"
            sx={{ borderRadius: 82, mb: 4 }}
            onClick={() => setShowFillerModal(true)}
          >
            {/* check if current language filler already exists, if it does say replace filler else add filler */}
            {watch("filler") &&
            watch("filler").find(
              (filler) => filler.language.languageCode === currentLanguage
            )
              ? "Replace Filler"
              : "Add Filler"}
          </Button>

          <AddFillerModal
            open={showFillerModal}
            onClose={() => setShowFillerModal(false)}
            onSave={handleOnSaveFillerDetails}
          />

          {(!watch("responseValidation") ||
            watch("validationType") !== "forceValidate") && (
            <div style={{ width: "100%" }}>
              <FlexBox columnGap={1}>
                <Typography variant="body2" fontWeight={500}>
                  Default
                </Typography>
                <Tooltip
                  title="If nothing matches, trigger workflow"
                  placement="top"
                >
                  <IconInfo2 />
                </Tooltip>
              </FlexBox>

              {watch("triggerWorkflow") && (
                <FlexBox mt={2} mb={4}>
                  <FlexBox
                    columnGap={1}
                    component="label"
                    sx={{ cursor: "pointer" }}
                  >
                    <Controller
                      control={control}
                      name="defaultType"
                      render={({ field: { onChange, value } }) => (
                        <Radio
                          onClick={() => onChange("addNode")}
                          checked={value === "addNode"}
                          sx={{ padding: 0 }}
                          icon={<Circle width={20} height={20} />}
                          checkedIcon={<RadioButton width={20} height={20} />}
                        />
                      )}
                    />

                    <Typography
                      variant="caption"
                      fontWeight={400}
                      color="text.primary"
                      sx={{ cursor: "pointer" }}
                    >
                      Add Node
                    </Typography>
                  </FlexBox>

                  <FlexBox
                    columnGap={1}
                    component="label"
                    sx={{ cursor: "pointer" }}
                  >
                    <Controller
                      control={control}
                      name="defaultType"
                      render={({ field: { onChange, value } }) => (
                        <Radio
                          onClick={() => onChange("triggerWorkflow")}
                          checked={value === "triggerWorkflow"}
                          sx={{ padding: 0 }}
                          icon={<Circle width={20} height={20} />}
                          checkedIcon={<RadioButton width={20} height={20} />}
                        />
                      )}
                    />
                    <Typography
                      variant="caption"
                      fontWeight={400}
                      color="text.primary"
                      sx={{ cursor: "pointer" }}
                    >
                      Trigger Workflow
                    </Typography>
                  </FlexBox>
                </FlexBox>
              )}

              {!watch("triggerWorkflow") && (
                <FlexBox mt={2} mb={4}>
                  <FlexBox
                    columnGap={1}
                    component="label"
                    sx={{ cursor: "pointer" }}
                  >
                    <Controller
                      control={control}
                      name="defaultType"
                      render={({ field: { onChange, value } }) => (
                        <Radio
                          onClick={() => onChange("nextNode")}
                          checked={value === "nextNode"}
                          sx={{ padding: 0 }}
                          icon={<Circle width={20} height={20} />}
                          checkedIcon={<RadioButton width={20} height={20} />}
                        />
                      )}
                    />
                    <Typography
                      variant="caption"
                      fontWeight={400}
                      color="text.primary"
                      sx={{ cursor: "pointer" }}
                    >
                      Next Node
                    </Typography>
                  </FlexBox>

                  <FlexBox
                    columnGap={1}
                    component="label"
                    sx={{ cursor: "pointer" }}
                  >
                    <Controller
                      control={control}
                      name="defaultType"
                      render={({ field: { onChange, value } }) => (
                        <Radio
                          onClick={() => onChange("addNode")}
                          checked={value === "addNode"}
                          sx={{ padding: 0 }}
                          icon={<Circle width={20} height={20} />}
                          checkedIcon={<RadioButton width={20} height={20} />}
                        />
                      )}
                    />

                    <Typography
                      variant="caption"
                      fontWeight={400}
                      color="text.primary"
                      sx={{ cursor: "pointer" }}
                    >
                      Add Node
                    </Typography>
                  </FlexBox>
                </FlexBox>
              )}

              {watch("defaultType") === "triggerWorkflow" &&
                watch("triggerWorkflow") && (
                  <Controller
                    control={control}
                    name="selectedWorkflow"
                    rules={{ required: "Workflow is required" }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        fullWidth
                        className={styles.select}
                        onChange={(e) => onChange(e.target.value)}
                        defaultValue={{
                          workflowName: data.workflowName,
                          workflowId: data.workflowId,
                        }}
                        value={value}
                        sx={{ mt: 2, mb: 4 }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              borderRadius: 0,
                              boxShadow: "none",
                              backgroundColor: theme.palette.grey[50],
                              maxHeight: "40vh",
                            },
                          },
                        }}
                        // render the selected value after selection
                        renderValue={(selected) => {
                          return (
                            <Typography
                              variant="caption"
                              color={theme.palette.grey[800]}
                            >
                              {selected.workflowName}
                            </Typography>
                          );
                        }}
                      >
                        {workflows?.workFlows?.map((workflow) => (
                          <MenuItem
                            value={{
                              workflowName: workflow.name,
                              workflowId: workflow._id,
                            }}
                            key={workflow.id}
                            classes={{ selected: styles.selected }}
                          >
                            <Typography
                              variant="caption"
                              color={theme.palette.grey[800]}
                            >
                              {workflow.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                )}
              {errors?.selectedWorkflow && (
                <Box mb={4} component="span" display="flex" alignItems="center">
                  <RedAlert style={{ marginRight: 8 }} />
                  <Typography variant="caption" color="error">
                    {errors?.selectedWorkflow?.message}
                  </Typography>
                </Box>
              )}

              {watch("setVariable") && (
                <FlexBox component="label" mb={4} sx={{ width: "100%" }}>
                  <Typography
                    variant="caption"
                    color="text.primary"
                    fontFamily={"inter"}
                    sx={{ cursor: "pointer" }}
                  >
                    Value
                  </Typography>

                  <Controller
                    control={control}
                    name="defaultText"
                    rules={{ required: "Default text is required" }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        placeholder="Default Text"
                        sx={{ "& div": { maxHeight: "40px" } }}
                        value={value}
                        onChange={onChange}
                        helperText={
                          errors?.defaultText && (
                            <Box
                              component="span"
                              display="flex"
                              alignItems="center"
                            >
                              <RedAlert style={{ marginRight: 8 }} />
                              {errors?.defaultText?.message}
                            </Box>
                          )
                        }
                      />
                    )}
                  />
                  <Tooltip title="Default value to be set" placement="top">
                    <IconInfo2 sx={{ flexShrink: 0 }} width={16} height={16} />
                  </Tooltip>
                </FlexBox>
              )}
            </div>
          )}
        </>
      )}
      <Button
        variant="dark"
        sx={{
          borderRadius: 82,
          minWidth: 0,
          marginTop: "auto",
          width: "max-content",
          mt: "auto",
        }}
        onClick={handleSubmit(handleOnSave)}
        endIcon={<ChevronRight width={20} height={20} />}
      >
        Save
      </Button>
    </div>
  );
};

export default MessageOptionsProperties;
