import { Chip, Typography } from "@mui/material";
import { useState } from "react";
import { Handle } from "reactflow";
import { Minus, Plus } from "react-feather";
import theme from "theme";
import FlexBox from "components/FlexBox";
import CustomHandle from "../../CustomHandle";
import NodeBase from "../../NodeBase";

const VariableNode = (props) => {
  const {
    name,
    customName,
    variables,
    isActive,
    isLastNode,
    onPlusClick,
    onMinusClick,
    onDelete,
    error,
  } = props.data;

  const [isParentHovered, setIsParentHovered] = useState(false);

  const renderVariableLabel = (variable) => {
    return variable.type === "custom"
      ? `${
          variable.type.slice(0, 1).toUpperCase() + variable.type.slice(1)
        } : ${variable.name}`
      : variable.type === "subCategory"
      ? "Sub-Category"
      : variable.type.slice(0, 1).toUpperCase() + variable.type.slice(1);
  };

  const handleClick = () => {
    if (!isLastNode) {
      onMinusClick();
    } else {
      onPlusClick({ x: props.xPos, y: props.yPos });
    }
  };

  return (
    <NodeBase
      isActive={isActive}
      onDelete={onDelete}
      error={error}
      onDuplicate={props.data.onDuplicate}
      onDuplicateToAnotherWorkflow={props.data.onDuplicateToAnotherWorkflow}
    >
      <Typography variant="body2" fontWeight={500} mb={2}>
        {customName
          ? customName
          : (name || "")
              .replace(/-/g, " ")
              .replace(/\b\w/g, (l) => l.toUpperCase())
              .replace(/ (\d+)/, " ($1)")}
      </Typography>

      <FlexBox sx={{ gap: 2, flexWrap: "wrap" }}>
        {variables?.length > 0 ? (
          variables?.map((variable) => (
            <Chip
              label={renderVariableLabel(variable)}
              sx={{
                "& span": { padding: 0, lineHeight: 1.2 },
                padding: "4px 8px",
                minHeight: 0,
                height: "1.5rem",
                fontSize: 10,
                borderRadius: 12,
                background: theme.palette.grey[100],
              }}
            />
          ))
        ) : (
          <Typography variant="body2" color="text.secondary">
            Configure
          </Typography>
        )}
      </FlexBox>

      <Handle
        type="target"
        position="top"
        style={{
          background: "transparent",
          border: `1px solid #666`,

          top: 5,
        }}
      />

      <CustomHandle
        id="source"
        position="bottom"
        clickHandler={handleClick}
        icon={
          isLastNode ? (
            <Plus color={theme.palette.text.primary} width={12} height={12} />
          ) : (
            <Minus color={theme.palette.text.primary} width={12} height={12} />
          )
        }
        style={{
          bottom: 0,
          left: "50%",
          transform: "translate(-50%, 50%)",
        }}
      />
    </NodeBase>
  );
};

export default VariableNode;
