import { Outlet } from "react-router";
import { useEffect, useState } from "react";
import { useUserContext } from "context/user";
import Header from "components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import PageWrapper from "components/PageWrapper";
import styles from "./index.module.css";
import { useURLState } from "custom-hooks/useUrlState";

const Bot = () => {
  const { playgroundCurrentChat } = useUserContext();
  const tabs = ["assistant", "history"];
  const currentRoute = useLocation().pathname;
  const navigate = useNavigate();
  const location = useLocation();

  const getInitialActiveTab = () => {
    return currentRoute.includes("history") ? "history" : "assistant";
  };
  const [activeTab, setActiveTab] = useState(getInitialActiveTab);
  const [search, setSearch] = useURLState("", "search");
  const [advancedModeValue, setAdvancedModeValue] = useState(false);

  const handleOnSearchValueChange = (newSearch) => {
    setSearch(newSearch);
  };

  const searchBarCondition = () => {
    const [, parentPath, childPath, id] = location.pathname.split("/");

    if (childPath === "playground") {
      return "playground";
    } else if (childPath === "history" && id) {
      return "historyDetail";
    } else if (childPath === "history") {
      return "history";
    }

    return false;
  };

  useEffect(() => {
    /**
     * We are storing the user current chat in playground object and
     * We are deleting the playground chat because user is moving
     * to another screen, so we are clearing the recorded chat.
     */
    const newPlayGroundChat = playgroundCurrentChat.current;
    delete newPlayGroundChat?.current?.playgroundChat;
    return () => {
      playgroundCurrentChat.current = newPlayGroundChat;
    };
  }, []);

  useEffect(() => {
    setActiveTab(getInitialActiveTab);

    if (currentRoute === "/bot/playground") {
      setActiveTab("assistant");
    } else {
      setActiveTab("history");
    }
  }, [currentRoute]);

  return (
    <>
      <PageWrapper className={styles.pageWrapper}>
        <Header
          title="Playground"
          subTitle="Test your virtual agent in the playground before you deploy."
          tabs={tabs}
          defaultActiveTab={activeTab}
          searchBox={searchBarCondition() === "history"}
          onSearch={handleOnSearchValueChange}
          defaultSearchValue={search}
          onTabChange={(tab) => {
            tab === "assistant"
              ? navigate("/bot/playground")
              : navigate("/bot/history");
            setActiveTab(tab);
            // setShowHistoryDetail(false);
          }}
          onClearSearch={() => setSearch("")}
          showAdvancedMode={searchBarCondition() === "historyDetail"}
          advancedModeValue={advancedModeValue}
          changeAdvancedModeValue={(value) => setAdvancedModeValue(value)}
        />

        <Outlet context={{ search, advancedModeValue }} />
      </PageWrapper>
    </>
  );
};

export default Bot;
