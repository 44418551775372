import { Fade, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Handle } from "reactflow";
import styles from "./index.module.css";
import { Minus, Plus, Trash2 } from "react-feather";
import theme from "theme";
import CustomHandle from "../../CustomHandle";
import TargetHandle from "../../TargetHandle";
import FlexBox from "components/FlexBox";
import NodeBase from "../../NodeBase";

const RestApiNode = (props) => {
  const {
    name,
    customName,
    message,
    isActive,
    isLastNode,
    onPlusClick,
    onMinusClick,
    onDelete,
    defaultType,
    selectedOption,
    onOptionMinusClick,
    onOptionPlusClick,
    localizationMode,
    fullyLocalized,
    error,
  } = props.data;
  const [showPlus, setShowPlus] = useState();
  const [isParentHovered, setIsParentHovered] = useState(false);

  const handleClick = () => {
    if (!isLastNode) {
      onMinusClick();
    } else {
      onPlusClick({ x: props.xPos, y: props.yPos });
    }
  };

  // useEffect(() => {
  //   setShowPlus(isLastNode);
  // }, [isLastNode]);

  return (
    <NodeBase
      isActive={isActive}
      onDelete={onDelete}
      // localizationMode={localizationMode}
      fullyLocalized={fullyLocalized}
      error={error}
      onDuplicate={props.data.onDuplicate}
      onDuplicateToAnotherWorkflow={props.data.onDuplicateToAnotherWorkflow}
    >
      <Typography
        variant="body2"
        fontWeight={500}
        style={{
          maxWidth: "40ch",
          wordWrap: "break-word",
        }}
      >
        {customName
          ? customName
          : (name || "")
              .replace(/-/g, " ")
              .replace(/ (\d+)/, " ($1)")
              .toUpperCase()}
      </Typography>

      <Typography variant="body2" fontSize={10} mb={3} color="text.secondary">
        {props.data.apiUrl
          ? props.data.apiUrl.length > 35
            ? props.data.apiUrl.slice(0, 35) + "..."
            : props.data.apiUrl
          : "configure"}
      </Typography>

      <Handle
        type="target"
        position="top"
        style={{
          background: "transparent",
          border: `1px solid #666`,
          // border: 0,
          // height: 0,
          // width: 0,
          top: 5,
        }}
      />

      <CustomHandle
        id="source"
        position="bottom"
        clickHandler={handleClick}
        icon={
          isLastNode ? (
            <Plus color={theme.palette.text.primary} width={12} height={12} />
          ) : (
            <Minus color={theme.palette.text.primary} width={12} height={12} />
          )
        }
        style={{
          bottom: 0,
          left: "50%",
          transform: "translate(-50%, 50%)",
        }}
      />

      {/* <Handle
        type="source"
        position="bottom"
        // style={{ background: "transparent", border: 0, height: 0, width: 0 }}
      /> */}

      {/* {showPlus && (
        <IconButton
          className={styles.plusButton}
          onClick={(e) => {
            e.stopPropagation();
            onPlusClick({ x: props.xPos, y: props.yPos });
            // setShowPlus(false);
          }}
        >
          <Plus color={theme.palette.text.primary} width={12} height={12} />
        </IconButton>
      )} */}

      {defaultType === "addNode" && (
        <FlexBox
          sx={{
            p: "8px 16px",
            backgroundColor: theme.palette.grey[100],
            borderRadius: "8px",
            mt: 4,
            minHeight: 51,
            position: "relative",
          }}
        >
          <Typography variant="caption">Default</Typography>

          <CustomHandle
            id={"default"}
            position={"right"}
            style={{ right: 8 }}
            clickHandler={() => {
              selectedOption
                ? onOptionMinusClick("default")
                : onOptionPlusClick("default", {
                    x: props.xPos,
                    y: props.yPos,
                  });
            }}
            icon={
              selectedOption ? (
                <Minus
                  color={theme.palette.text.primary}
                  width={12}
                  height={12}
                />
              ) : (
                <Plus
                  color={theme.palette.text.primary}
                  width={12}
                  height={12}
                />
              )
            }
          />
        </FlexBox>
      )}
    </NodeBase>
  );
};

export default RestApiNode;
