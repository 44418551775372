import { getRequest } from "./common";

const getSignedMediaUrl = (fileName, queryParams, config = {}) => {
  return getRequest(
    `/process/media/getSignedMediaUrlToUpload/${fileName}?${queryParams}`,
    config
  );
};

export default getSignedMediaUrl;
