import { Fade, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Handle } from "reactflow";
import styles from "./index.module.css";
import { Plus, Trash2 } from "react-feather";
import theme from "theme";
import TargetHandle from "../../TargetHandle";
import { Link } from "react-router-dom";
import FlexBox from "components/FlexBox";
import { FlowArrow } from "components/newSVG";
import NodeBase from "../../NodeBase";

const TriggerNode = (props) => {
  const {
    name,
    customName,
    isActive,
    isLastNode,
    onPlusClick,
    onDelete,
    workflowName,
    workflowId,
    localizationMode,
    fullyLocalized,
    error,
  } = props.data;
  const [showPlus, setShowPlus] = useState();
  const [isParentHovered, setIsParentHovered] = useState(false);

  useEffect(() => {
    setShowPlus(isLastNode);
  }, [isLastNode]);

  return (
    <NodeBase
      isActive={isActive}
      onDelete={onDelete}
      error={error}
      onDuplicate={props.data.onDuplicate}
      onDuplicateToAnotherWorkflow={props.data.onDuplicateToAnotherWorkflow}
    >
      <Typography variant="body2" fontWeight={500}>
        {/* Trigger Workflow */}
        {customName
          ? customName
          : (name || "")
              .replace(/-/g, " ")
              .replace(/\b\w/g, (l) => l.toUpperCase())
              .replace(/ (\d+)/, " ($1)")}
      </Typography>

      <Typography
        variant="body2"
        fontWeight={500}
        mt={2}
        color={"text.secondary"}
      >
        {workflowName ? (
          <FlexBox columnGap={1}>
            <FlowArrow
              width={12}
              height={12}
              className={styles.flowArrow}
              gap={1}
            />
            <Link
              to={`/workflows/${workflowId}`}
              target="_blank"
              className={styles.link}
            >
              <Typography fontSize={10} color={theme.palette.primary.main}>
                Workflow linked: {workflowName}
              </Typography>
            </Link>
          </FlexBox>
        ) : (
          "Select a workflow"
        )}
      </Typography>

      <Handle
        type="target"
        position="top"
        style={{
          background: "transparent",
          border: `1px solid #666`,
          // border: 0,
          // height: 0,
          // width: 0,
          top: 5,
        }}
      />
    </NodeBase>
  );
};

export default TriggerNode;
