import { Button, Typography } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import styles from "./index.module.css";
import FlexBox from "components/FlexBox";
import FundamentoTable from "components/FundamentoTable";
import { useFileUpload } from "custom-hooks/useFileUpload";
import { Check, Loader } from "react-feather";
import { useEffect, useState } from "react";
import { useSkillrToast } from "context/toast";
import ProgressBar from "components/ProgressBar";
import { importSmartDb as importSmartDbApi } from "services";
import { getSmartDb as getSmartDbApi } from "services";
import { runInquiries as runInquiriesApi } from "services";

const SmartDB = () => {
  const { showSkillrToast } = useSkillrToast();
  const [smartDbRecords, setSmartDbRecords] = useState([]);
  const acceptedFileTypes = ["text/csv"];

  const {
    fileInputRef,
    handleFileChange,
    triggerFileInput,
    uploadStatus,
    uploadFiles,
    removeFile,
    completeFileUpload,
  } = useFileUpload({
    fileTypes: acceptedFileTypes,
    maxUploadLimit: 0,
    sizeAlreadyConsumed: 0,
  });

  const columns = [
    {
      field: "_id",
      header: "Record Id",
    },
    {
      field: "contactId",
      header: "contact Id",
    },
    {
      field: "isClosed",
      header: "Is Closed",
      render: (row) => (row.isClosed ? "Yes" : "No"),
    },
    {
      field: "metadata",
      header: "Metadata",
      render: (row) => JSON.stringify(row.metadata),
    },
  ];

  const handleUpload = () => {
    triggerFileInput();
  };

  const handleStartCall = () => {
    runInquiriesApi();
  };

  const trackUploadStatus = async () => {
    try {
      if (uploadStatus?.status === "completed") {
        const csvFiles = uploadStatus.signedUrls.map((url) =>
          url
            ?.split("?")[0]
            ?.replace("https://storage.googleapis.com/", "gs://")
        );
        await importSmartDbApi({ csvFiles });
        completeFileUpload();
        showSkillrToast("Files uploaded successfully", "success");
        callSmartRecords();
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error ??
        error?.response?.data?.message ??
        "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  useEffect(() => {
    console.log("uploadStatus", uploadStatus);
    if (
      uploadStatus?.files?.length > 0 &&
      uploadStatus?.status === "filesValidated"
    ) {
      uploadFiles(uploadStatus.files);
    }
  }, [uploadStatus?.files, uploadStatus?.status]);

  useEffect(() => {
    trackUploadStatus();
  }, [uploadStatus]);

  const callSmartRecords = async () => {
    const response = await getSmartDbApi();
    console.log({ response });
    setSmartDbRecords(response);
  };

  useEffect(() => {
    callSmartRecords();
  }, []);

  return (
    <PageWrapper className={styles.pageWrapper}>
      {["started", "processing"].indexOf(uploadStatus?.status) > -1 && (
        <ProgressBar
          startIcon={
            uploadStatus?.status === "completed" ? (
              <Check width={16} height={16} />
            ) : (
              <Loader className={styles.rotate} width={16} height={16} />
            )
          }
          message={
            uploadStatus?.status === "completed"
              ? "File uploaded"
              : `${uploadStatus?.totalFiles} files are being processed`
          }
          progress={Math.ceil(
            (uploadStatus?.processedFiles / uploadStatus?.totalFiles) * 100
          )}
          cancelGeneration={false}
          handleCancelGeneration={() => {}}
        />
      )}

      <div style={{ height: 20 }} />

      <FlexBox justifyContent="space-between" mb={4}>
        <Typography variant="h3" fontWeight={"bold"}>
          Smart DB
        </Typography>

        <div>
          <Button
            style={{ marginRight: "20px" }}
            onClick={handleStartCall}
            variant="dark"
            sx={{ borderRadius: "12px" }}
          >
            Start Call
          </Button>

          <Button
            onClick={handleUpload}
            variant="dark"
            sx={{ borderRadius: "12px" }}
          >
            Upload
          </Button>
        </div>
      </FlexBox>

      <input
        type="file"
        multiple
        hidden
        ref={fileInputRef}
        onChange={handleFileChange}
        accept={acceptedFileTypes.join(",")}
      />
      {smartDbRecords.length > 0 && (
        <FundamentoTable
          columns={columns}
          data={smartDbRecords}
          passRowToRender={true}
        />
      )}
    </PageWrapper>
  );
};

export default SmartDB;
