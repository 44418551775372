import { Box, Button, Typography } from "@mui/material";
import Header from "components/Header";
import styles from "./index.module.css";
import {
  APICloud,
  GoogleSheet,
  HubSpot,
  Salesforce,
  Webhook,
  Zapier,
} from "components/newSVG";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import getIntegrations from "services/getIntegrations";

const tabs = ["Enterprise Apps"];

const Integration = () => {
  const navigate = useNavigate();
  const [googleIntegrations, setGoogleIntegrations] = useState([]);
  const [webhookIntegrations, setWebhookIntegrations] = useState([]);

  const handleTabChange = (selectedTab) => {
    // navigate(selectedTab);
  };

  const getAllIntegrations = async () => {
    try {
      const response = await getIntegrations("google-sheet");
      setGoogleIntegrations(response);
      const webhookResponse = await getIntegrations("webhook");
      setWebhookIntegrations(webhookResponse);
    } catch (error) {
      console.log("Failed to fetch Google Sheet Integrations:", error);
    }
  };

  useEffect(() => {
    getAllIntegrations();
  }, []);

  return (
    <Box>
      <Header
        title="Integration"
        subTitle="Seamlessly Connect your Favorite Tools with Yara"
        tabs={tabs}
        defaultActiveTab={tabs[0]}
        onTabChange={handleTabChange}
      />

      <Box className={styles.cardContainer}>
        <Link to="google-sheet">
          <Box className={styles.card}>
            <GoogleSheet width={48} height={48} />
            <Typography
              mt={2}
              variant="body1"
              fontWeight={500}
              color="text.primary"
            >
              Google Sheet
            </Typography>

            <Typography
              mt={2}
              component={"div"}
              variant="caption"
              color={"text.primary"}
            >
              Accounts Connected ({googleIntegrations?.length || 0})
            </Typography>

            <Typography
              mt={4}
              component={"div"}
              variant="caption"
              color={"text.primary"}
              fontWeight={400}
            >
              Connect your bot to Google Sheet to create a centralised database
            </Typography>

            <Button
              variant="outlined"
              sx={{ mt: 6, width: "100px", height: "32px" }}
            >
              Connect
            </Button>
          </Box>
        </Link>
        <Link to="webhook">
          <Box className={styles.card}>
            <Webhook width={48} height={48} />
            <Typography
              mt={2}
              variant="body1"
              fontWeight={500}
              color="text.primary"
            >
              Webhook
            </Typography>

            <Typography
              mt={2}
              component={"div"}
              variant="caption"
              color={"text.primary"}
            >
              Accounts Connected ({webhookIntegrations?.length || 0})
            </Typography>

            <Typography
              mt={4}
              component={"div"}
              variant="caption"
              color={"text.primary"}
              fontWeight={400}
              mb={6}
            >
              Connect your bot to external webhooks
            </Typography>

            <Button
              variant="outlined"
              sx={{ mt: "auto", width: "100px", height: "32px" }}
            >
              Connect
            </Button>
          </Box>
        </Link>
        {/* <Box className={styles.card}>
          <HubSpot width={48} height={48} />
          <Typography mt={2} variant="body1" fontWeight={500}>
            Hub Spot
          </Typography>

          <Typography
            mt={2}
            component={"div"}
            variant="caption"
            color={"text.primary"}
          >
            Accounts Connected (0)
          </Typography>

          <Typography
            mt={4}
            component={"div"}
            variant="caption"
            color={"text.primary"}
            fontWeight={400}
          >
            Connect your bot to Hub Spot to create a centralised database
          </Typography>

          <Button
            variant="outlined"
            sx={{ mt: 6, width: "100px", height: "32px" }}
          >
            Connect
          </Button>
        </Box>
        <Box className={styles.card}>
          <Salesforce width={48} height={48} />
          <Typography mt={2} variant="body1" fontWeight={500}>
            Salesforce
          </Typography>

          <Typography
            mt={2}
            component={"div"}
            variant="caption"
            color={"text.primary"}
          >
            Accounts Connected (0)
          </Typography>

          <Typography
            mt={4}
            component={"div"}
            variant="caption"
            color={"text.primary"}
            fontWeight={400}
          >
            Connect your bot to SalesForce to create a centralised database
          </Typography>

          <Button
            variant="outlined"
            sx={{ mt: 6, width: "100px", height: "32px" }}
          >
            Connect
          </Button>
        </Box>
        <Box className={styles.card}>
          <Zapier width={48} height={48} />
          <Typography mt={2} variant="body1" fontWeight={500}>
            Zapier
          </Typography>

          <Typography
            mt={2}
            component={"div"}
            variant="caption"
            color={"text.primary"}
          >
            Accounts Connected (0)
          </Typography>

          <Typography
            mt={4}
            component={"div"}
            variant="caption"
            color={"text.primary"}
            fontWeight={400}
          >
            Connect your bot to Zapier to create a centralised database
          </Typography>

          <Button
            variant="outlined"
            sx={{ mt: 6, width: "100px", height: "32px" }}
          >
            Connect
          </Button>
        </Box>
        <Box className={styles.card}>
          <APICloud width={48} height={48} />
          <Typography mt={2} variant="body1" fontWeight={500}>
            API
          </Typography>

          <Typography
            mt={2}
            component={"div"}
            variant="caption"
            color={"text.primary"}
          >
            Accounts Connected (0)
          </Typography>

          <Typography
            mt={4}
            component={"div"}
            variant="caption"
            color={"text.primary"}
            fontWeight={400}
          >
            Connect your bot to external API to create a centralised database
          </Typography>

          <Button
            variant="outlined"
            sx={{ mt: 6, width: "100px", height: "32px" }}
          >
            Connect
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Integration;
