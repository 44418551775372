import { Box, TextField, Typography } from "@mui/material";
import styles from "./index.module.css";
import { Controller, useForm } from "react-hook-form";
import { WarningRed } from "components/newSVG";

const ApiNode = (props) => {
  const { onSave, timeout, setLoader } = props;

  const {
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      timeout: timeout ?? 4,
    },
    mode: "onChange",
  });

  return (
    <Box padding="32px 32px 0 32px" width="100%">
      <Box className={styles.cardContainer}>
        <Typography variant="subtitle1">Timeout Configuration</Typography>
        <Typography variant="subtitle2" mt={1} color="#505050" fontWeight={400}>
          Configure the maximum wait time for an API response before
          automatically terminating the request.
        </Typography>

        <Box mt={4}>
          <Typography variant="caption" color="#0f0f0f">
            Duration
          </Typography>

          <Box display="flex" gap={2}>
            <Controller
              rules={{
                required: "Duration is required",
                pattern: {
                  value: /^(?:[1-9]|[1-9][0-9]|1[01][0-9]|120)$/,
                  message: "Please enter value between 1 and 120",
                },
              }}
              control={control}
              name="timeout"
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ width: 76 }}
                  type="number"
                  onChange={(e) => {
                    setLoader(false);
                    onChange(e.target.value);
                    onSave(e.target.value);
                  }}
                  value={value}
                />
              )}
            />
            <Box className={styles.seconds}>
              <Typography variant="body2">Seconds</Typography>
            </Box>
          </Box>

          {errors?.timeout && (
            <Box
              component="span"
              display="flex"
              alignItems="center"
              padding="5px 4px"
            >
              <WarningRed style={{ marginRight: 8 }} />
              <Typography
                fontSize={10}
                fontWeight={500}
                color="error"
                sx={{ opacity: 0.8 }}
              >
                {errors?.timeout?.message}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ApiNode;
