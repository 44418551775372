import {
  Box,
  Button,
  Dialog,
  IconButton,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import avatarMale from "assets/images/voice-male.png";
import avatarFemale from "assets/images/voice-female.png";
import avatarNeutral from "assets/images/voice-neutral.png";
import elevenLabs from "assets/images/elevenLabs.png";
import sarvam from "assets/images/sarvam.png";
import smallest from "assets/images/smallest.jpeg";
import { X } from "react-feather";
import { IconGoogle, IconIconTick, IconReload } from "components/SVG";
import FundamentoPopup from "components/FundamentoPopup";
import styles from "./index.module.css";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { SpinnerGap, WarningRed } from "components/newSVG";
import {
  updateVoiceConfig,
  addVoiceConfig,
  getAudio as getAudioApi,
} from "services";
import { useSkillrToast } from "context/toast";

const CustomSlider = ({
  configuration,
  companyVoiceConfig,
  currentLanguage,
  control,
  path,
}) => {
  return (
    <FundamentoPopup
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      triggeringComponent={
        <Button
          color="inherit"
          variant="outlined"
          className={styles.popupButtons}
        >
          {configuration.label}
        </Button>
      }
    >
      <Controller
        control={control}
        name={path}
        render={({ field: { onChange, value } }) => (
          <FlexBox
            sx={{
              border: `1px solid #efefef`,
              p: 4,
              borderRadius: "12px",
              width: 200,
            }}
            justifyContent={"center"}
          >
            <Typography variant="body2" fontWeight={500}>
              {configuration.min}
            </Typography>
            <Slider
              // defaultValue={defaultValue}
              valueLabelDisplay="auto"
              min={configuration.min}
              max={configuration.max}
              step={configuration.step || 0.1}
              // marks={[{ value: configuration.min }, { value: configuration.max }]}
              sx={{
                width: "90%",
                "& .MuiSlider-rail": { backgroundColor: "#DADADA" },
                "& .MuiSlider-track": {
                  backgroundColor: "#0F0F0F",
                  border: 0,
                },
                "& .MuiSlider-thumb": {
                  backgroundColor: "#0F0F0F",
                  width: 15,
                  height: 15,
                },
              }}
              onChange={(e, value) => {
                onChange(value);
              }}
              value={value}
            />
            <Typography variant="body2" fontWeight={500}>
              {configuration.max}
            </Typography>
          </FlexBox>
        )}
      />
    </FundamentoPopup>
  );
};

const DropDown = ({
  configuration,
  companyVoiceConfig,
  currentLanguage,
  control,
  path,
}) => {
  // const configurationValues = companyVoiceConfig.find(
  //   (config) => config.languageCode === currentLanguage
  // )?.configurationValues;
  // const key = configurationValues?.type;
  // const value = configurationValues
  //   ? configurationValues[key]
  //   : configuration.default;

  return (
    <FundamentoPopup
      className={styles.stylePopup}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      triggeringComponent={
        <Button
          color="inherit"
          variant="outlined"
          className={styles.popupButtons}
        >
          {configuration.label}
        </Button>
      }
    >
      <Controller
        control={control}
        name={path}
        render={({ field: { onChange, value } }) => (
          <Box>
            {configuration.options.map((option) => (
              <Box
                sx={{
                  padding: "10px 12px",
                  borderBottom: `1px solid #efefef`,
                  cursor: "pointer",
                  display: "flex",
                  gap: 24,
                  alignItems: "center",
                }}
                onClick={() => onChange(option)}
              >
                <Typography variant="body2" fontWeight={500} key={option}>
                  {option}
                </Typography>

                {value === option && <IconIconTick />}
              </Box>
            ))}
          </Box>
        )}
      />
    </FundamentoPopup>
  );
};

const ConfigureModal = ({
  open,
  onClose,
  voice,
  voices,
  currentLanguage,
  bot,
  onSave,
}) => {
  const { showSkillrToast } = useSkillrToast();
  const {
    control,
    getValues,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      testSpeech: "",
      configuration: {},
      audioInBase64: "",
      loading: false,
    },
  });

  const getDefaultValues = () => {
    if (voice) {
      const companyVoiceConfig = voices.find(
        (v) => v.name === voice.name
      )?.companyVoiceConfig;

      const configurationValues = companyVoiceConfig.find(
        (config) => config.languageCode === currentLanguage
      )?.configurationValues;

      const values = voices
        .find((v) => v.name === voice.name)
        .configuration.reduce((acc, current) => {
          acc[current.type] = configurationValues
            ? configurationValues[current.type]
            : current.default;
          return acc;
        }, {});

      return values;
    } else {
      return {};
    }
  };

  const getAudio = async (apiData) => {
    try {
      setValue("loading", true);
      const response = await getAudioApi(apiData);
      setValue("audioInBase64", response);
      setValue("loading", false);
    } catch (error) {
      setValue("loading", false);
      const errorMessage = error?.response?.data
        ? error.response.data
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleTestSpeech = () => {
    const voiceConfig = getValues();
    if (voiceConfig.testSpeech) {
      getAudio({
        text: voiceConfig.testSpeech,
        botVoice: {
          name: voice.name,
          languageCode: voice.languageCode,
          ...(voice.provider === "ElevenLab" && { elevenLab: true }),
          configurationValues: voiceConfig.configuration,
          botId: bot._id,
        },
      });
    } else {
      showSkillrToast("Text Required", "error");
    }
  };

  const handleApply = (voiceConfiguration) => {
    updateModalConfiguration(voiceConfiguration);
  };

  const updateModalConfiguration = async (data) => {
    try {
      const selectedVoice = voices.find((v) => v.name === voice.name);
      let companyVoiceConfig = selectedVoice.companyVoiceConfig || [];
      let updateConfig = companyVoiceConfig.find(
        (item) =>
          item.name === voice.name && item.languageCode === currentLanguage
      );
      let response;

      if (updateConfig) {
        // update case
        response = await updateVoiceConfig(updateConfig._id, {
          botId: bot._id,
          companyId: bot.companyId,
          name: voice.name,
          languageCode: currentLanguage,
          configurationValues: {
            ...data?.configuration,
          },
        });
      } else {
        // add case
        updateConfig = {
          botId: bot._id,
          companyId: bot.companyId,
          name: voice.name,
          languageCode: currentLanguage,
          favourite: false,
          configurationValues: {
            ...data?.configuration,
          },
          pronounciation: {},
        };
        response = await addVoiceConfig(updateConfig);
      }

      if (response) {
        onSave();
      }
    } catch (error) {
      const errorMessage = error?.response?.data
        ? error.response.data
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleResetToDefault = () => {
    const currentValues = getValues();
    const values = voices
      .find((v) => v.name === voice.name)
      .configuration.reduce((acc, current) => {
        acc[current.type] = current.default;
        return acc;
      }, {});
    reset({ ...currentValues, configuration: values });
    showSkillrToast("Configuration reset to default", "success");
  };

  useEffect(() => {
    if (open) {
      const currentValues = getValues();
      reset({
        ...currentValues,
        configuration: getDefaultValues(),
        testSpeech: "",
        audioInBase64: "",
      });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          width: 1000,
          borderRadius: 5,
        },
      }}
    >
      <Box sx={{ padding: "24px", position: "relative" }}>
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 20, right: 20 }}
        >
          <X width={20} height={20} />
        </IconButton>
        <Typography variant="h3" fontWeight={500} textAlign={"center"} mb={4}>
          Configure Voice
        </Typography>
        {voices.length > 0 && voice && (
          <Box p={4}>
            <FlexBox mb={2}>
              <img
                src={
                  voices.find((v) => v.name === voice.name)?.gender === "FEMALE"
                    ? avatarFemale
                    : voices.find((v) => v.name === voice.name)?.gender ===
                      "MALE"
                    ? avatarMale
                    : voices.find((v) => v.name === voice.name)?.gender ===
                      "NEUTRAL"
                    ? avatarNeutral
                    : avatarNeutral
                }
                alt="avatar"
                width={56}
                height={56}
              />
              <Box>
                <FlexBox>
                  <Typography variant="body1" fontWeight={500}>
                    {voices.find((v) => v.name === voice.name)?.displayName ||
                      voice?.name ||
                      ""}
                  </Typography>
                </FlexBox>

                <Typography variant="body2" color="grey.600">
                  {voices.find((v) => v.name === voice.name)?.description || ""}
                </Typography>

                <FlexBox columnGap={1}>
                  {voices.find((v) => v.name === voice.name)?.provider ===
                  "google" ? (
                    <IconGoogle width={11} height={11} />
                  ) : voices.find((v) => v.name === voice.name)?.provider ===
                    "elevenLabs" ? (
                    <img
                      src={elevenLabs}
                      width={11}
                      height={11}
                      alt="elevenLabs"
                    />
                  ) : voices.find((v) => v.name === voice.name)?.provider ===
                    "sarvam" ? (
                    <img src={sarvam} width={11} height={11} alt="sarvam" />
                  ) : voices.find((v) => v.name === voice.name)?.provider ===
                    "smallest" ? (
                    <img src={smallest} width={11} height={11} alt="smallest" />
                  ) : null}
                  <Typography fontSize={10} color="grey.400">
                    {voices.find((v) => v.name === voice.name)?.provider ===
                    "google"
                      ? "Google"
                      : voices.find((v) => v.name === voice.name)?.provider ===
                        "elevenLabs"
                      ? "Eleven Labs"
                      : voices.find((v) => v.name === voice.name)?.provider ===
                        "sarvam"
                      ? "Sarvam"
                      : voices.find((v) => v.name === voice.name)?.provider ===
                        "smallest"
                      ? "Smallest AI"
                      : voices.find((v) => v.name === voice.name)?.provider}
                  </Typography>
                </FlexBox>
              </Box>
            </FlexBox>

            <FlexBox mb={4}>
              {voices
                .find((v) => v.name === voice.name)
                ?.configuration.map((configuration) =>
                  configuration?.element === "slider" ? (
                    <CustomSlider
                      configuration={configuration}
                      companyVoiceConfig={
                        voices.find((v) => v.name === voice.name)
                          ?.companyVoiceConfig
                      }
                      currentLanguage={currentLanguage}
                      control={control}
                      path={`configuration.${configuration.type}`}
                      key={configuration.type}
                    />
                  ) : (
                    <DropDown
                      configuration={configuration}
                      companyVoiceConfig={
                        voices.find((v) => v.name === voice.name)
                          ?.companyVoiceConfig
                      }
                      currentLanguage={currentLanguage}
                      control={control}
                      path={`configuration.${configuration.type}`}
                      key={configuration.type}
                    />
                  )
                )}

              <Button
                variant="outline"
                startIcon={<IconReload />}
                onClick={handleResetToDefault}
                sx={{ marginLeft: "auto" }}
              >
                Reset to Default
              </Button>
            </FlexBox>

            {watch("audioInBase64") && (
              <audio
                controls
                style={{ width: "100%", marginBottom: 16 }}
                key={getValues("audioInBase64")}
              >
                <source
                  src={`data:audio/wav;base64,${getValues("audioInBase64")}`}
                  type="audio/wav"
                />
              </audio>
            )}

            <Controller
              control={control}
              name="testSpeech"
              render={({ field }) => {
                const loader = watch("loading");
                return (
                  <TextField
                    {...field}
                    placeholder="enter test text here"
                    variant="outlined"
                    multiline
                    rows={5}
                    sx={{
                      width: "100%",
                      "& div": {
                        padding: 0,
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <Button
                          color="inherit"
                          variant="text"
                          sx={{ alignSelf: "flex-end", mb: 1, mr: 1 }}
                          onClick={handleTestSpeech}
                          disabled={loader}
                        >
                          {loader ? (
                            <SpinnerGap width={24} height={24} />
                          ) : (
                            "Test speech"
                          )}
                        </Button>
                      ),
                    }}
                  />
                );
              }}
            />
          </Box>
        )}

        <FlexBox justifyContent={"center"} mt={4}>
          <Button
            variant="whiteRound"
            onClick={onClose}
            sx={{ borderRadius: "12px" }}
            className={styles.modalButton}
          >
            Cancel
          </Button>

          <Button
            className={styles.modalButton}
            variant="dark"
            sx={{ borderRadius: "12px" }}
            onClick={handleSubmit(handleApply)}
          >
            Apply
          </Button>
        </FlexBox>
      </Box>
    </Dialog>
  );
};

export default ConfigureModal;
