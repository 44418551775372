import { useSkillrToast } from "context/toast";
import { useRef, useState } from "react";
import { getSignedMediaUrl as getSignedMediaUrlApi } from "services";

const fileUploadStatus = {
  started: "started",
  processing: "processing",
  completed: "completed",
  cancelled: "cancelled",
  storageLimitError: "storageLimitError",
  filesValidated: "filesValidated",
};

const defaultAcceptedFileTypes = [
  "image/png",
  "video/mp4*",
  "audio/mp3*",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
const defaultUploadSizeLimit = 500; // 500MB;
const defaultSizeAlreadyConsumed = 0; // 0MB;
const defaultProcessUploadedFiles = false;

const defaultAdditionalProcessing = () => {
  return Promise.resolve();
};

export const useFileUpload = (parameters) => {
  const {
    fileTypes = defaultAcceptedFileTypes,
    maxUploadLimit = defaultUploadSizeLimit,
    sizeAlreadyConsumed = defaultSizeAlreadyConsumed,
    additionalProcessing = defaultAdditionalProcessing,
  } = parameters;
  const acceptedFileTypes = fileTypes ?? defaultAcceptedFileTypes;
  const fileInputRef = useRef();
  const [fileUploadError, setFileUploadError] = useState("");
  const [uploadStatus, setUploadStatus] = useState({});
  const { showSkillrToast } = useSkillrToast();
  const cancelUpload = useRef(false);
  const MAX_DOCUMENT_SIZE = 4 * 1024 * 1024; // 4MB
  const MAX_AUDIO_SIZE = 50 * 1024 * 1024; // 50MB

  const createSignedUrl = async (file) => {
    try {
      const params = new URLSearchParams();
      params.set("contentType", file.type);
      const response = await getSignedMediaUrlApi(
        file.newFileName,
        params.toString()
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const uploadFileToBucket = async (url, file) => {
    try {
      const response = await fetch(url, {
        method: "PUT",
        body: file,
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const uploadFiles = async (files) => {
    try {
      const signedUrls = [];
      let originalFileName;
      let fileExtension;
      setUploadStatus({
        ...uploadStatus,
        status: fileUploadStatus.started,
        totalFiles: files.length,
        processedFiles: signedUrls.length,
      });
      for (let file of files) {
        const lastDotIndex = file.name.lastIndexOf(".");
        originalFileName = file.name
          .slice(0, lastDotIndex)
          .replace(/[\s%?&#/\\:*|"<>+\-()]/g, ""); // remove special character and spaces which might create a problem
        fileExtension = file.name.slice(lastDotIndex + 1);
        file.newFileName = `${originalFileName}_${new Date().getTime()}.${fileExtension}`;
        file.extension = fileExtension;
        if (cancelUpload.current) {
          return;
        }
        const { signedUrl } = await createSignedUrl(file);
        const response = await uploadFileToBucket(signedUrl, file);
        await additionalProcessing({ signedUrl, file });
        signedUrls.push(response.url);
        setUploadStatus({
          ...uploadStatus,
          status: fileUploadStatus.processing,
          totalFiles: files.length,
          processedFiles: signedUrls.length,
          signedUrls,
        });
      }
      setUploadStatus({
        ...uploadStatus,
        status: fileUploadStatus.completed,
        totalFiles: files.length,
        processedFiles: signedUrls.length,
        signedUrls,
      });
    } catch (error) {
      const errorMessage = error?.message ?? "Something went wrong";
      showSkillrToast(errorMessage, "error");
      setUploadStatus({ ...uploadStatus, status: "error" });
    }
  };

  const cancelFileUpload = () => {
    cancelUpload.current = true;
    fileInputRef.current.value = "";
    setUploadStatus({ ...uploadStatus, status: "cancelled" });
  };

  const checkFileUploadLimit = async (files) => {
    try {
      const totalFileSize = files.reduce((acc, file) => {
        return acc + file.size;
      }, 0);
      const totalFileSizeInMB = totalFileSize / 1024 / 1024; // Converting bytes to MB;
      const finalSize = Math.round(sizeAlreadyConsumed + totalFileSizeInMB);

      if (finalSize < maxUploadLimit) {
        setUploadStatus({
          ...uploadStatus,
          status: fileUploadStatus.filesValidated,
          files,
        });
        // uploadFiles(files);
      } else {
        setFileUploadError("Upload file size exceed");
        setUploadStatus({
          ...uploadStatus,
          status: fileUploadStatus.storageLimitError,
        });
      }
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const checkUploadedFilesType = (files) => {
    const regexPattern = acceptedFileTypes.join("|");
    const regex = new RegExp(regexPattern, "i");
    const validFiles = files.filter((file) => regex.test(file.type));
    if (validFiles.length === files.length) {
      return true;
    } else {
      setFileUploadError("Invalid file type");
      showSkillrToast(
        <>
          Invalid file <br />
          supported file types are {acceptedFileTypes.join(",")}
        </>
      );
      return false;
    }
  };

  const handleFileChange = () => {
    const files = Array.from(fileInputRef.current.files);
    if (files?.length > 0) {
      const allFilesTypeAreValid = checkUploadedFilesType(files);
      if (allFilesTypeAreValid) {
        const validFiles = files.filter((file) => {
          if (file.type.startsWith("audio/") && file.size > MAX_AUDIO_SIZE) {
            showSkillrToast(
              `Audio file is too large, please pick a smaller file.`,
              "error"
            );
            return false;
          } else if (
            !file.type.startsWith("audio/") &&
            file.size > MAX_DOCUMENT_SIZE
          ) {
            showSkillrToast(
              `Document is too large, please pick a smaller file.`,
              "error"
            );
            return false;
          }
          return true;
        });
        setUploadStatus({
          ...uploadStatus,
          status: fileUploadStatus.filesValidated,
          files: validFiles,
        });
      }
    }
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const removeFile = (fileIndex) => {
    if (uploadStatus?.files?.length > 0) {
      const newUploadStatus = { ...uploadStatus };
      uploadStatus?.files?.splice(fileIndex, 1);
      setUploadStatus(newUploadStatus);
    }
  };

  const completeFileUpload = () => {
    fileInputRef.current.value = "";
    setUploadStatus({});
  };

  return {
    fileInputRef,
    handleFileChange,
    triggerFileInput,
    fileUploadError,
    uploadStatus,
    cancelFileUpload,
    uploadFiles,
    fileUploadStatus,
    removeFile,
    completeFileUpload,
  };
};
