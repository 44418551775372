import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import { Controller, get, useFieldArray, useForm } from "react-hook-form";
import { ChevronDown, IconInfo, IconPlus } from "components/SVG";
import { TrashSimple, WarningRed } from "components/newSVG";
import { useEffect, useState } from "react";
import { getAllVariables } from "services";
import theme from "theme";

const operators = [
  { id: "contains", name: "Contains" },
  { id: "equalTo", name: "Equal to" },
  { id: "notEqualTo", name: "Not equal to" },
  { id: "isEmpty", name: "Is empty" },
  { id: "isNotEmpty", name: "Is not empty" },
  { id: "greaterThan", name: "Greater than" },
  { id: "lessThan", name: "Less than" },
];

const AddCustomCallOutCome = (props) => {
  const {
    open,
    onClose,
    onSave,
    exisitingCustomOutcomesNames,
    editCustomOutCome,
  } = props;
  const [variables, setVariables] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      variableDefinitions: [
        {
          variableType: "",
          operator: "contains",
          value: "",
        },
      ],
      formula: "A",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "variableDefinitions",
  });

  const handleOnSave = (formData) => {
    // in variableDefinitions for each variable add prefix and suffix {{}}
    const updatedFormData = {
      ...formData,
      variableDefinitions: formData.variableDefinitions.map((variable) => ({
        ...variable,
        variableType: `{{${variable.variableType}}}`,
      })),
    };

    onSave(updatedFormData);
  };

  const handleAddVariable = () => {
    append({
      variableType: "",
      operator: "",
      value: "",
    });
  };

  const getVariables = async () => {
    try {
      const params = new URLSearchParams();
      const response = await getAllVariables(params);
      // Remove any duplicate variables based on the variable property
      const uniqueVariables = Array.from(
        new Map(response.map((item) => [item.variable, item])).values()
      );

      // sort the variables
      uniqueVariables.sort((a, b) => {
        if (a.type === "system") return -1;
        if (b.type === "system") return 1;
        if (a.type === "session") return -1;
        if (b.type === "session") return 1;
        return 0;
      });

      setVariables(uniqueVariables);
    } catch (error) {
      console.error("Error fetching variables", error);
    }
  };

  useEffect(() => {
    if (!open) {
      reset({
        name: "",
        description: "",
        variableDefinitions: [
          {
            variableType: "",
            operator: "contains",
            value: "",
          },
        ],
        formula: "A",
      });
    }
  }, [open, reset]);

  useEffect(() => {
    if (editCustomOutCome) {
      // Transform the variableDefinitions to remove the {{}} from variableType
      const transformedData = {
        ...editCustomOutCome,
        variableDefinitions: editCustomOutCome.variableDefinitions.map(
          (def) => ({
            ...def,
            variableType: def.variableType.replace(/[{}]/g, ""),
          })
        ),
      };
      reset(transformedData);
    }
  }, [editCustomOutCome, reset]);

  useEffect(() => {
    getVariables();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        style: {
          borderRadius: 20,
          width: 800,
          minHeight: 400,
        },
      }}
    >
      <Box padding="24px" display="flex" flexDirection="column" gap={8}>
        <Typography
          fontSize={26}
          fontWeight={500}
          color="#0f0f0f"
          textAlign="center"
        >
          Add Call Outcome
        </Typography>
        <Box>
          <Typography variant="caption" color="#0f0f0f">
            Call Outcome Name
          </Typography>

          <Controller
            control={control}
            name="name"
            rules={{
              required: "Name is required",
              validate: (value) => {
                return exisitingCustomOutcomesNames
                  .map((innerValues) => innerValues.toLowerCase())
                  .includes(value.toLowerCase())
                  ? "Please use another outcome name, its already used"
                  : true;
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                sx={{ mb: 4 }}
                fullWidth
                className={styles.name}
                placeholder="Enter outcome name"
                onChange={onChange}
                value={value}
                error={errors?.name ? true : false}
                helperText={
                  errors?.name && (
                    <Box component="span" display="flex" alignItems="center">
                      <WarningRed style={{ marginRight: 8 }} />
                      <Typography
                        fontSize={10}
                        fontWeight={500}
                        color="error"
                        sx={{ opacity: 0.8 }}
                      >
                        {errors?.name?.message}
                      </Typography>
                    </Box>
                  )
                }
              />
            )}
          />

          <Typography variant="caption" color="#0f0f0f">
            Call Outcome Description
          </Typography>

          <Controller
            control={control}
            name="description"
            rules={{ required: "Description is required" }}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                className={styles.description}
                placeholder="Enter outcome description"
                multiline
                minRows={2}
                onChange={onChange}
                value={value}
                error={errors?.description ? true : false}
                helperText={
                  errors?.description && (
                    <Box component="span" display="flex" alignItems="center">
                      <WarningRed style={{ marginRight: 8 }} />
                      <Typography
                        fontSize={10}
                        fontWeight={500}
                        color="error"
                        sx={{ opacity: 0.8 }}
                      >
                        {errors?.description?.message}
                      </Typography>
                    </Box>
                  )
                }
              />
            )}
          />

          <Box display="flex" alignItems="center" gap={2} mt={4}>
            <Typography
              variant="body2"
              color="#0F0F0F"
              fontWeight={500}
              sx={{ opacity: 0.8 }}
            >
              Variable Definition
            </Typography>

            <Tooltip title="Set variables to determine the call outcome." arrow>
              <IconInfo width={20} height={20} />
            </Tooltip>
          </Box>

          {fields.map(({ id }, index) => (
            <Box my={4} display="flex" gap={4} key={id}>
              <Box flex={1}>
                <Typography
                  variant="caption"
                  color="#0f0f0f"
                  component="p"
                  mb={2}
                >
                  Variable {String.fromCharCode(65 + index)}
                </Typography>
                <Controller
                  name={`variableDefinitions.${index}.variableType`}
                  control={control}
                  rules={{ required: "Variable type is required" }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      options={variables}
                      popupIcon={<ChevronDown />}
                      value={
                        variables.find((v) => v.variable === value) || null
                      }
                      onChange={(_, newValue) =>
                        onChange(newValue?.variable || "")
                      }
                      getOptionLabel={(option) => option.variable}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Variable"
                          error={
                            get(
                              errors,
                              `variableDefinitions.${index}.variableType`
                            )
                              ? true
                              : false
                          }
                          className={styles.dropDown}
                        />
                      )}
                      ListboxProps={{
                        style: { maxHeight: "40vh" },
                      }}
                      PaperComponent={({ children }) => (
                        <Box
                          sx={{
                            borderRadius: "12px",
                            width: "30vw",
                            backgroundColor: theme.palette.background.paper,
                          }}
                        >
                          {children}
                        </Box>
                      )}
                      renderOption={(props, option) => (
                        <MenuItem
                          {...props}
                          sx={{
                            width: "100%",
                            display: "flex !important",
                            alignItems: "center",
                            gap: 3,
                            height: 56,
                            justifyContent: "space-between",
                            borderBottom: `1px solid ${theme.palette.borderColor.light}`,
                          }}
                        >
                          <Typography variant="body2">
                            {option.variable}
                          </Typography>
                          <Chip
                            label={
                              option.type.charAt(0).toUpperCase() +
                              option.type.slice(1)
                            }
                            sx={
                              option.type !== "custom"
                                ? {
                                    backgroundColor: "#C9E4F1",
                                    color: "#224C61",
                                    borderRadius: 50,
                                    ml: "auto",
                                  }
                                : {
                                    backgroundColor: theme.palette.grey[100],
                                    color: theme.palette.grey[600],
                                    borderRadius: 50,
                                    ml: "auto",
                                  }
                            }
                          />
                        </MenuItem>
                      )}
                      isOptionEqualToValue={(option, value) => {
                        if (!option || !value) return false;
                        return (
                          option.variable ===
                          (typeof value === "string" ? value : value.variable)
                        );
                      }}
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Typography
                  variant="caption"
                  color="#0f0f0f"
                  component="p"
                  mb={2}
                >
                  Operator
                </Typography>
                <Controller
                  name={`variableDefinitions.${index}.operator`}
                  control={control}
                  rules={{ required: "Operator is required" }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Select
                        fullWidth
                        IconComponent={ChevronDown}
                        onChange={onChange}
                        value={value}
                        placeholder="Select Operator"
                        className={styles.dropDown}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: "40vh",
                              width: "20vw",
                            },
                          },
                        }}
                        error={
                          get(errors, `variableDefinitions.${index}.operator`)
                            ? true
                            : false
                        }
                      >
                        {operators.map((operator) => (
                          <MenuItem
                            value={operator.id}
                            sx={{ gap: 3 }}
                            key={operator.id}
                          >
                            <Typography variant="body2">
                              {operator.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>

                      {get(errors, `variableDefinitions.${index}.operator`) && (
                        <FormHelperText>
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                          >
                            <WarningRed style={{ marginRight: 8 }} />
                            <Typography
                              fontSize={10}
                              fontWeight={500}
                              color="error"
                              sx={{ opacity: 0.8 }}
                            >
                              {
                                get(
                                  errors,
                                  `variableDefinitions.${index}.operator`
                                )?.message
                              }
                            </Typography>
                          </Box>
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Box>
              <Controller
                name={`variableDefinitions.${index}.operator`}
                control={control}
                render={({ field: { value: operatorValue } }) =>
                  !["isEmpty", "isNotEmpty"].includes(operatorValue) ? (
                    <Box flex={1}>
                      <Typography
                        variant="caption"
                        color="#0f0f0f"
                        component="p"
                        mb={2}
                      >
                        Value
                      </Typography>
                      <Controller
                        name={`variableDefinitions.${index}.value`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            onChange={onChange}
                            value={value}
                            placeholder="Value"
                            type={
                              ["greaterThan", "lessThan"].includes(
                                operatorValue
                              )
                                ? "number"
                                : "string"
                            }
                          />
                        )}
                      />
                    </Box>
                  ) : (
                    <Box flex={1}>
                      <Typography
                        variant="caption"
                        color="#0f0f0f"
                        component="p"
                        mb={2}
                      ></Typography>
                    </Box>
                  )
                }
              />
              <Box
                sx={{
                  position: "relative",
                  top: 32,
                  visibility: index === 0 ? "hidden" : "visible",
                }}
              >
                <IconButton onClick={() => remove(index)}>
                  <TrashSimple width={16} height={16} />
                </IconButton>
              </Box>
            </Box>
          ))}

          <Button
            sx={{ fontSize: 12, paddingLeft: 0 }}
            variant="outline"
            startIcon={<IconPlus width={16} height={16} />}
            onClick={handleAddVariable}
          >
            Add variable
          </Button>

          <Box mt={4}>
            <Typography variant="body1" fontWeight={500} mb={2}>
              Formula
            </Typography>

            <Controller
              name="formula"
              control={control}
              rules={{ required: "Formula is required" }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  className={styles.formula}
                  fullWidth
                  multiline
                  minRows={4}
                  placeholder="eg:(A AND B) OR (C AND D)"
                  error={errors?.formula ? true : false}
                  helperText={
                    errors?.formula && (
                      <Box component="span" display="flex" alignItems="center">
                        <WarningRed style={{ marginRight: 8 }} />
                        <Typography
                          fontSize={10}
                          fontWeight={500}
                          color="error"
                          sx={{ opacity: 0.8 }}
                        >
                          {errors?.formula?.message}
                        </Typography>
                      </Box>
                    )
                  }
                />
              )}
            />
          </Box>
        </Box>

        <Box className={styles.buttonContainer}>
          <Button variant="outline" className={styles.button} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="dark"
            className={styles.button}
            onClick={handleSubmit(handleOnSave)}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddCustomCallOutCome;
