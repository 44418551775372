import {
  Dialog,
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { languages } from "text-constants/common";
import styles from "./index.module.css";
import theme from "theme";
import {
  USFlag,
  IndiaFlag,
  BahasaFlag,
  SpanishFlag,
  ArabFlag,
  GermanyFlag,
  JapanFlag,
  ChinaFlag,
} from "components/newSVG";
import FlexBox from "components/FlexBox";

const AddLanguageModal = ({ open, onClose, onConfirm, botVoices }) => {
  const { control, getValues } = useForm();
  const languageImageMapping = {
    "en-US": <USFlag />,
    "hi-IN": <IndiaFlag />,
    "id-ID": <BahasaFlag />,
    "es-ES": <SpanishFlag />,
    "mr-IN": <IndiaFlag />,
    "ml-IN": <IndiaFlag />,
    "pa-IN": <IndiaFlag />,
    "ta-IN": <IndiaFlag />,
    "bn-IN": <IndiaFlag />,
    "te-IN": <IndiaFlag />,
    "gu-IN": <IndiaFlag />,
    "ar-XA": <ArabFlag />,
    "de-DE": <GermanyFlag />,
    "ja-JP": <JapanFlag />,
    "cmn-CN": <ChinaFlag />,
  };

  const submitLanguage = () => {
    const selectedLanguage = getValues("selectedLanguage");

    onConfirm(selectedLanguage);
  };

  // sample botVoices data
  // [
  //     {
  //         "languageCode": "en-US",
  //         "name": "en-IN-Standard-C",
  //         "elevenLab": false,
  //         "default": true
  //     },
  //     {
  //         "languageCode": "hi-IN",
  //         "name": "hi-IN-Neural2-A",
  //         "elevenLab": false,
  //         "default": false
  //     },
  //     {
  //         "languageCode": "te-IN",
  //         "name": "te-IN-Standard-B",
  //         "elevenLab": false,
  //         "default": false
  //     },
  //     {
  //         "languageCode": "ar-XA",
  //         "name": "ar-XA-Wavenet-D",
  //         "elevenLab": false,
  //         "default": false
  //     }
  // ]

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 5,
        },
      }}
    >
      <Box sx={{ padding: "40px 20px" }}>
        <Typography variant="h3" fontWeight={500} textAlign={"center"}>
          Add language
        </Typography>
        <Box sx={{ marginTop: 2 }}>
          <Controller
            control={control}
            name="selectedLanguage"
            rules={{ required: "Language is required" }}
            render={({ field: { onChange, value } }) => (
              <Select
                displayEmpty
                onChange={onChange}
                value={value}
                fullWidth
                placeholder="Select a language"
                className={styles.select}
                sx={{ mt: 2, mb: 4 }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      borderRadius: 0,
                      boxShadow: "none",
                      maxHeight: "40vh",
                    },
                  },
                }}
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <Typography variant="body2">Select a language</Typography>
                    );
                  }

                  return (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {languageImageMapping[selected]}
                      <Typography variant="body2" sx={{ ml: 2 }}>
                        {languages.find((l) => l.value === selected).name}
                      </Typography>
                    </Box>
                  );
                }}
              >
                {languages
                  .filter(
                    (lang) =>
                      !botVoices.some(
                        (voice) => voice.languageCode === lang.value
                      )
                  )
                  .map((l) => (
                    <MenuItem
                      value={l.value}
                      sx={{
                        gap: 3,
                        padding: "12px 16px",
                        borderBottom: `1px solid ${theme.palette.borderColor.light}`,
                      }}
                      key={l.value}
                      classes={{ selected: styles.selected }}
                    >
                      {languageImageMapping[l.value]}
                      <Typography variant="body2">{l.name}</Typography>
                    </MenuItem>
                  ))}
              </Select>
            )}
          />
        </Box>

        <FlexBox sx={{ mt: 2 }} justifyContent={"center"}>
          <Button variant="whiteRound" onClick={onClose}>
            Cancel
          </Button>

          <Button variant="dark" sx={{ minWidth: 0 }} onClick={submitLanguage}>
            Add
          </Button>
        </FlexBox>
      </Box>
    </Dialog>
  );
};

export default AddLanguageModal;
