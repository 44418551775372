import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  Fade,
  FormControl,
  FormControlLabel,
  Grow,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import theme from "theme";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import { useSkillrToast } from "context/toast";
import getParentCategoriesApi from "services/getParentCategories";
import FunCollapse from "components/FunCollapse";
import FlexBox from "components/FlexBox";
import { ChevronDown, Circle, Loader, Square } from "react-feather";
import { CheckSquare, RadioButton, SquareMinus } from "components/newSVG";
import { Controller, useForm } from "react-hook-form";
import { debounce } from "lodash";

const AISettings = (props) => {
  const { workFlows, data, onSave } = props;
  const { showSkillrToast } = useSkillrToast();

  const { control, watch } = useForm({
    defaultValues: {
      threshold: data?.threshold,
      inactiveCategoriesDefaultWorkFlow:
        data?.inactiveCategoriesDefaultWorkFlow,
      // categories: data?.categories,
      waitTimeFallbackWorkFlow: data?.waitTimeFallbackWorkFlow,
      intentDigression: data?.intentDigression,
      waitTimeFallbackFlowMessage: data?.waitTimeFallbackFlow.message,
      digressionThreshold: data?.digressionThreshold,
      sttService: data?.sttService,
      selectedValidationType: data?.selectedValidationType || "name",
      nodeLevelSTT: {
        inputNode: {
          name: {
            service: data?.nodeLevelSTT?.inputNode?.name?.service,
          },
          number: {
            service: data?.nodeLevelSTT?.inputNode?.number?.service,
          },
          phoneNumber: {
            service: data?.nodeLevelSTT?.inputNode?.phoneNumber?.service,
          },
          date: {
            service: data?.nodeLevelSTT?.inputNode?.date?.service,
          },
          time: {
            service: data?.nodeLevelSTT?.inputNode?.time?.service,
          },
          dateTime: {
            service: data?.nodeLevelSTT?.inputNode?.dateTime?.service,
          },
          alphanumeric: {
            service: data?.nodeLevelSTT?.inputNode?.alphanumeric?.service,
          },
        },
        conversationNode: {
          service: data?.nodeLevelSTT?.conversationNode?.service,
        },
      },
    },
  });

  const STTServices = [
    // { label: "Assembly", value: "assembly" },
    { label: "Google V1", value: "google" },
    { label: "Google V2", value: "googleV2" },
    { label: "Google V2 Short", value: "googleV2Short" },
    { label: "Google V2 Chirp2", value: "googleV2Chirp2" },
    { label: "Neural Space", value: "neuralSpace" },
  ];
  const nodeLevelSTTtabs = [
    { label: "Input Node", value: "inputNode" },
    { label: "Conversation Node", value: "conversationNode" },
  ];
  const inputNodeValidationTypes = [
    { name: "Name", value: "name" },
    { name: "Number", value: "number" },
    { name: "Phone Number", value: "phoneNumber" },
    { name: "Date", value: "date" },
    { name: "Time", value: "time" },
    { name: "Date & Time", value: "dateTime" },
    { name: "Alphanumeric", value: "alphanumeric" },
  ];

  const [activeTab, setActiveTab] = useState("inputNode");

  // const watchOnAllFields = () => {
  //   const watchSubscription = watch((value, fields) => {
  //     onSave({
  //       threshold: data?.threshold,
  //       inactiveCategoriesDefaultWorkFlow:
  //         value?.inactiveCategoriesDefaultWorkFlow,
  //       responseWaitTime: value?.responseWaitTime,
  //       responseTimeMaxTries: value?.responseTimeMaxTries,
  //       waitTimeFallbackWorkFlow: value?.waitTimeFallbackWorkFlow,
  //       responseTimeExceededWorkFlow: value?.responseTimeExceededWorkFlow,
  //       intentDigression: value?.intentDigression,
  //       categories: checkedCategories,
  //       waitTimeFallbackFlow: {
  //         type: value?.waitTimeFallbackFlowType,
  //         message: value?.waitTimeFallbackFlowMessage,
  //       },
  //       digressionThreshold: value?.digressionThreshold,
  //     });
  //   });
  //   return watchSubscription;
  // };

  // useEffect(() => {
  //   const subscription = watchOnAllFields();
  //   return () => {
  //     subscription.unsubscribe();
  //   };
  // }, [checkedCategories]);

  const debouncedOnSave = debounce((values) => {
    onSave(values);
  }, 1000);

  const watchFields = [
    "threshold",
    "intentDigression",
    "digressionThreshold",
    "sttService",
  ];

  const watchOnSpecificFields = () => {
    const subscription = watch((value, { name, type }) => {
      if (watchFields.includes(name)) {
        debouncedOnSave({
          [name]: value[name],
        });
      }
    });
    return subscription;
  };

  const watchOnNodeLevelSTTFields = () => {
    const subscription = watch((value, { name, type }) => {
      if (name.startsWith("nodeLevelSTT")) {
        onSave({
          nodeLevelSTT: value.nodeLevelSTT,
        });
      }
    });
    return subscription;
  };

  useEffect(() => {
    const subscription1 = watchOnSpecificFields();
    const subscription2 = watchOnNodeLevelSTTFields();

    return () => {
      subscription1.unsubscribe();
      subscription2.unsubscribe();
    };
  }, []);

  return (
    <>
      <Box className={styles.container}>
        {/* threshold selector */}
        <Box>
          <Typography variant="body1" fontWeight="bold">
            Match Threshold
          </Typography>
          <Typography variant="body2" color={"grey.600"}>
            Set the threshold for semantic search for detecting the customer's
            intent
          </Typography>

          <Box sx={{ maxWidth: "48%" }}>
            <Controller
              control={control}
              name="threshold"
              render={({ field: { onChange, value } }) => (
                <Slider
                  min={0}
                  max={0.25}
                  valueLabelDisplay="auto"
                  step={0.01}
                  sx={{
                    "& .MuiSlider-rail": {
                      backgroundColor: theme.palette.grey[200],
                    },
                    "& .MuiSlider-track": {
                      backgroundColor: theme.palette.text.primary,
                      border: 0,
                    },
                    "& .MuiSlider-thumb": {
                      backgroundColor: theme.palette.text.primary,
                      width: 12,
                      height: 12,
                    },
                    marginTop: 2,
                    marginBottom: 0,
                  }}
                  marks={[
                    {
                      value: 0,
                      label: (
                        <Typography
                          variant="body2"
                          fontFamily={"monospace"}
                          fontWeight={500}
                        >
                          0
                        </Typography>
                      ),
                    },
                    {
                      value: 0.25,
                      label: (
                        <Typography
                          variant="body2"
                          fontFamily={"monospace"}
                          fontWeight={500}
                        >
                          0.25
                        </Typography>
                      ),
                    },
                  ]}
                  value={value}
                  onChange={(e, value) => {
                    onChange(value);
                  }}
                />
              )}
            />
          </Box>
        </Box>

        {/* intent digression radio */}
        <Box>
          <Box>
            <Typography variant="body1" fontWeight={"bold"}>
              Intent Digression
            </Typography>
            <Typography variant="body2" color="grey.600" mb={2}>
              Actively listen for intent digression during an ongoing
              conversation?
            </Typography>
          </Box>
          <Box>
            <Controller
              control={control}
              name="intentDigression"
              render={({ field: { onChange, value } }) => (
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="virtual-agent-voice"
                    // onChange={onChange}
                    onChange={(e) => onChange(e.target.value === "true")}
                    value={value}
                  >
                    <FormControlLabel
                      value={"false"}
                      control={
                        <Radio
                          checked={value === false}
                          icon={<Circle width={20} height={20} />}
                          checkedIcon={<RadioButton width={20} height={20} />}
                          sx={{ "& > span": { padding: "0 !important" } }}
                        />
                      }
                      label={
                        <Typography variant="caption" color="#0f0f0f">
                          Don't Allow
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={"true"}
                      control={
                        <Radio
                          checked={value === true}
                          icon={<Circle width={20} height={20} />}
                          checkedIcon={<RadioButton width={20} height={20} />}
                          sx={{ "& > span": { padding: "0 !important" } }}
                        />
                      }
                      label={
                        <Typography variant="caption" color="#0f0f0f">
                          Allow
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Box>
        </Box>

        {/* digression threshold slider */}
        <Collapse in={watch("intentDigression")} unmountOnExit mountOnEnter>
          <Box>
            <Box>
              <Typography variant="body1" fontWeight={"bold"}>
                Digression Threshold
              </Typography>
              <Typography variant="body2" color="grey.600" mb={2}>
                Set the sensitivity for digression while the virtual agent is
                already answering an intent
              </Typography>
            </Box>
            <Box sx={{ maxWidth: "48%" }}>
              <Controller
                control={control}
                name="digressionThreshold"
                render={({ field: { onChange, value } }) => (
                  <Slider
                    min={0}
                    max={0.25}
                    valueLabelDisplay="auto"
                    step={0.01}
                    sx={{
                      "& .MuiSlider-rail": {
                        backgroundColor: theme.palette.grey[200],
                      },
                      "& .MuiSlider-track": {
                        backgroundColor: theme.palette.text.primary,
                        border: 0,
                      },
                      "& .MuiSlider-thumb": {
                        backgroundColor: theme.palette.text.primary,
                        width: 12,
                        height: 12,
                      },
                      marginTop: 2,
                      marginBottom: 0,
                    }}
                    marks={[
                      {
                        value: 0,
                        label: (
                          <Typography
                            variant="body2"
                            fontFamily={"monospace"}
                            fontWeight={500}
                          >
                            0
                          </Typography>
                        ),
                      },
                      {
                        value: 0.25,
                        label: (
                          <Typography
                            variant="body2"
                            fontFamily={"monospace"}
                            fontWeight={500}
                          >
                            0.25
                          </Typography>
                        ),
                      },
                    ]}
                    value={value}
                    onChange={(e, value) => {
                      onChange(value);
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        </Collapse>
      </Box>
      <Box className={styles.container} mb={8}>
        <Box>
          <Typography variant="body1" fontWeight={500}>
            AI Model Configuration
          </Typography>
          <Typography variant="body2" color="grey.600">
            Configure default STT models for your bot and customise models at
            the node level to optimise performance.
          </Typography>
        </Box>
        {/*  stt service selector */}
        <Box width={"50%"}>
          <Box>
            <Typography variant="body1" fontWeight={500}>
              Select Default STT
            </Typography>
            <Typography variant="body2" color="grey.600" mb={2}>
              Choose your default bot Speech to Text service
            </Typography>
          </Box>

          <Box>
            <Controller
              control={control}
              name="sttService"
              render={({ field: { onChange, value } }) => (
                <Select
                  displayEmpty={true}
                  onChange={onChange}
                  value={value}
                  // placeholder="Select STT service"
                  className={styles.dropDown}
                  fullWidth
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "40vh",
                        width: "20vw",
                      },
                    },
                  }}
                  renderValue={(value) => {
                    if (!value) {
                      return (
                        <Typography variant="body2">
                          Select STT service
                        </Typography>
                      );
                    }
                    return (
                      <Typography variant="body2">
                        {
                          STTServices.find((service) => service.value === value)
                            ?.label
                        }
                      </Typography>
                    );
                  }}
                >
                  {STTServices.map((service) => (
                    <MenuItem
                      classes={{ selected: styles.selected }}
                      value={service.value}
                      sx={{ gap: 3 }}
                      key={service.value}
                    >
                      <Typography variant="body2">{service.label}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>
        </Box>

        {/* node level stt selector */}
        <Box>
          <Box>
            <Typography variant="body1" fontWeight={500}>
              Select Node Level STT
            </Typography>
            <Typography variant="body2" color="grey.600" mb={2}>
              Choose your node level Speech to Text service
            </Typography>
          </Box>

          <Box>
            <Tabs
              className={styles.tabs}
              TabIndicatorProps={{
                style: {
                  background: "#0f0f0f",
                  borderRadius: "10px 10px 0 0",
                  height: 3,
                },
              }}
              sx={{
                mb: 4,
                borderBottom: `1px solid ${theme.palette.borderColor.light}`,
              }}
              value={activeTab}
              onChange={(e, value) => setActiveTab(value)}
            >
              {nodeLevelSTTtabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: 500,
                    fontSize: 14,
                    color: theme.palette.text.primary,
                  }}
                  className={styles.tab}
                />
              ))}
            </Tabs>

            {activeTab === "inputNode" && (
              <Box>
                {inputNodeValidationTypes.map((type, index) => (
                  <Box className={styles.nodeLevelcontainer} key={index}>
                    <FunCollapse
                      header={
                        <FlexBox>
                          <Typography variant="body1" fontWeight="bold">
                            {type.name}
                          </Typography>
                          {watch(
                            `nodeLevelSTT.inputNode.${type.value}.service`
                          ) && (
                            <Chip
                              className={styles.label}
                              label={
                                STTServices.find(
                                  (service) =>
                                    service.value ===
                                    watch(
                                      `nodeLevelSTT.inputNode.${type.value}.service`
                                    )
                                )?.label
                              }
                            />
                          )}
                        </FlexBox>
                      }
                      key={type.name}
                    >
                      <Box
                        className={styles.container}
                        style={{
                          padding: "0",
                          display: "block",
                          margin: 0,
                        }}
                      >
                        {STTServices.map((service) => (
                          <FormControlLabel
                            key={service.value}
                            label={
                              <Typography
                                variant="caption"
                                color="text.primary"
                                fontWeight={400}
                              >
                                {service.label}
                              </Typography>
                            }
                            className={styles.serviceBlock}
                            sx={{}}
                            control={
                              <Controller
                                control={control}
                                name={`nodeLevelSTT.inputNode.${type.value}.service`}
                                render={({ field: { onChange, value } }) => (
                                  <Radio
                                    checked={value === service.value}
                                    onChange={(e) => onChange(e.target.value)}
                                    value={service.value}
                                    icon={<Circle width={20} height={20} />}
                                    checkedIcon={
                                      <RadioButton width={20} height={20} />
                                    }
                                    sx={{
                                      "& > span": { padding: "0 !important" },
                                    }}
                                  />
                                )}
                              />
                            }
                          />
                        ))}
                      </Box>
                    </FunCollapse>
                  </Box>
                ))}

                {/* <Controller
                  control={control}
                  name="selectedValidationType"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      displayEmpty={true}
                      onChange={onChange}
                      value={value}
                      className={styles.dropDown}
                      fullWidth
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: "40vh",
                            width: "20vw",
                          },
                        },
                      }}
                      renderValue={(value) => {
                        if (!value) {
                          return (
                            <Typography variant="body2">
                              Select validation type
                            </Typography>
                          );
                        }
                        return (
                          <Typography variant="body2">
                            {
                              inputNodeValidationTypes.find(
                                (type) => type.value === value
                              )?.name
                            }
                          </Typography>
                        );
                      }}
                    >
                      {inputNodeValidationTypes.map((type) => (
                        <MenuItem
                            classes={{ selected: styles.selected }}
                          value={type.value}
                          sx={{ gap: 3 }}
                          key={type.value}
                        >
                          <Typography variant="body2">{type.name}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                /> */}

                {/* {watch("selectedValidationType") && (
                  <Box mt={4}>
                    <Typography variant="body1" fontWeight={500} mb={2}>
                      STT Service for{" "}
                      {
                        inputNodeValidationTypes.find(
                          (type) =>
                            type.value === watch("selectedValidationType")
                        )?.name
                      }
                    </Typography>
                    <Controller
                      control={control}
                      name={`nodeLevelSTT.inputNode.${watch(
                        "selectedValidationType"
                      )}.service`}
                      key={watch("selectedValidationType")}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          displayEmpty={true}
                          onChange={onChange}
                          value={value}
                          className={styles.dropDown}
                          fullWidth
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: "40vh",
                                width: "20vw",
                              },
                            },
                          }}
                          renderValue={(value) => {
                            if (!value) {
                              return (
                                <Typography variant="body2">
                                  Select STT service
                                </Typography>
                              );
                            }
                            return (
                              <Typography variant="body2">
                                {
                                  STTServices.find(
                                    (service) => service.value === value
                                  )?.label
                                }
                              </Typography>
                            );
                          }}
                        >
                          {STTServices.map((service) => (
                            <MenuItem
                            classes={{ selected: styles.selected }}
                              value={service.value}
                              sx={{ gap: 3 }}
                              key={service.value}
                            >
                              <Typography variant="body2">
                                {service.label}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </Box>
                )} */}
              </Box>
            )}

            {activeTab === "conversationNode" && (
              <Box>
                <Box mt={4} width={"50%"}>
                  <Typography variant="body1" fontWeight={500} mb={2}>
                    STT Service
                  </Typography>
                  <Controller
                    control={control}
                    name={`nodeLevelSTT.conversationNode.service`}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        displayEmpty={true}
                        onChange={onChange}
                        value={value}
                        className={styles.dropDown}
                        fullWidth
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: "40vh",
                              width: "20vw",
                            },
                          },
                        }}
                        renderValue={(value) => {
                          if (!value) {
                            return (
                              <Typography variant="body2">
                                Select STT service
                              </Typography>
                            );
                          }
                          return (
                            <Typography variant="body2">
                              {
                                STTServices.find(
                                  (service) => service.value === value
                                )?.label
                              }
                            </Typography>
                          );
                        }}
                      >
                        {STTServices.map((service) => (
                          <MenuItem
                            classes={{ selected: styles.selected }}
                            value={service.value}
                            sx={{ gap: 3 }}
                            key={service.value}
                          >
                            <Typography variant="body2">
                              {service.label}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AISettings;
