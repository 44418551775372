import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Slider,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import { Controller, get, useFieldArray, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  AlertTriangle,
  ChevronDown,
  Loader,
  Plus,
  Square,
  Trash2,
  X,
} from "react-feather";
import theme from "theme";
import { RedAlert } from "components/SVG";
import { didNumberExists } from "services";
import _, { debounce } from "lodash";
import FlexBox from "components/FlexBox";
import getParentCategoriesApi from "services/getParentCategories";
import { useSkillrToast } from "context/toast";
import FunCollapse from "components/FunCollapse";
import { CheckSquare, SquareMinus } from "components/newSVG";

const checkDidNumberExists = async (number) => {
  try {
    if (number) {
      const numberExists = await didNumberExists(number);
      return numberExists;
    }
  } catch (error) {
    return error;
  }
};

const debouncedDidNumberExists = _.debounce(checkDidNumberExists, 3000);

const validate = async (value, formData, apiData) => {
  const numberValidationRegex = /^\d{7,}$/;
  if (!numberValidationRegex.test(value)) {
    return "Number should have the length greater or equal to 7";
  }

  // Checking if the number already exists in
  if (apiData?.didNumbers.includes(value)) {
    return "Number already exists";
    // return true;
  }

  const numberExists = await checkDidNumberExists(value);

  return numberExists?.exists ? "Number already exists" : true;
};
const StyledChevronDown = () => (
  <ChevronDown
    width={20}
    height={20}
    color={theme.palette.text.primary}
    style={{ transitionDuration: "0.3s" }}
  />
);
const BasicSettings = (props) => {
  const { workFlows, onSave, data, apiData } = props;

  const watchFields = [
    "inactiveCategoriesDefaultWorkFlow",
    "welcomeWorkFlow",
    "fallBackWorkFlow",
    "intentMaxTries",
    "intentExceededWorkFlow",
    "postIntentWorkFlow",
    // "didNumbers",
    "responseWaitTime",
    "responseTimeMaxTries",
    "waitTimeFallbackFlowType",
    "responseTimeExceededWorkFlow",
  ];
  const TTSServices = [
    { label: "Eleven Labs", value: "elevenLabs" },
    { label: "Google", value: "google" },
    { label: "Neural Space", value: "neuralSpace" },
  ];

  const [loading, setLoading] = useState(false);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [advancedSettings, setAdvancedSettings] = useState({});
  const [allCategories, setAllCategories] = useState([]);
  const { showSkillrToast } = useSkillrToast();

  const {
    control,
    watch,
    setValue,
    formState: { errors },
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      responseWaitTime: data?.responseWaitTime,
      responseTimeMaxTries: data?.responseTimeMaxTries,
      waitTimeFallbackFlowType: data?.waitTimeFallbackFlow.type,
      responseTimeExceededWorkFlow: data?.responseTimeExceededWorkFlow,
      inactiveCategoriesDefaultWorkFlow:
        data?.inactiveCategoriesDefaultWorkFlow,
      welcomeWorkFlow: data?.welcomeWorkFlow,
      fallBackWorkFlow: data?.fallBackWorkFlow,
      intentMaxTries: data?.intentMaxTries,
      intentExceededWorkFlow: data?.intentExceededWorkFlow,
      postIntentWorkFlow: data?.postIntentWorkFlow,
      // ttsService: data?.ttsService,
      didNumbers: data?.didNumbers,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "didNumbers",
  });

  const getParentCategories = async () => {
    setLoading(true);
    try {
      const allParentCategories = await getParentCategoriesApi();
      setAllCategories(allParentCategories);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data
        ? error.response.data
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleCheckboxChange = (categoryId, subcategoryId = null) => {
    setCheckedCategories((prevState) => {
      let newState = [...prevState];
      if (subcategoryId) {
        // If a subcategory checkbox is clicked
        const index = newState.indexOf(subcategoryId);
        if (index > -1) {
          newState.splice(index, 1);
        } else {
          newState.push(subcategoryId);
        }
      } else {
        // If a category checkbox is clicked

        const subCategories = allCategories
          .find((category) => category._id === categoryId)
          .categories.map((subCategory) => subCategory._id);
        if (subCategories.every((subCatId) => newState.includes(subCatId))) {
          newState = newState.filter((id) => !subCategories.includes(id));
        } else {
          // Only add a subcategory if it's not already included in newState
          subCategories.forEach((subCatId) => {
            if (!newState.includes(subCatId)) {
              newState.push(subCatId);
            }
          });
        }
      }
      onSave({
        categories: newState,
      });
      return newState;
    });
  };

  const debouncedOnSave = debounce((values) => {
    onSave(values);
  }, 1000);

  const watchOnSpecificFields = () => {
    const subscription = watch((value, { name, type }) => {
      if (watchFields.includes(name)) {
        if (name === "waitTimeFallbackFlowType") {
          // Special handling for waitTimeFallbackFlowType
          debouncedOnSave({
            waitTimeFallbackFlow: { type: value[name] },
          });
        } else {
          debouncedOnSave({
            [name]: value[name],
          });
        }
      }
    });
    return subscription;
  };

  useEffect(() => {
    const subscription = watchOnSpecificFields();

    return () => {
      subscription.unsubscribe();
    };
  }, [errors]);

  // useEffect(() => {
  //   onSave({
  //     categories: checkedCategories,
  //   });
  // }, [checkedCategories]);

  useEffect(() => {
    getParentCategories();
    if (data?.categories) {
      setCheckedCategories(data.categories);
    }
  }, []);

  return (
    <>
      <Box className={styles.container}>
        {/* did number select */}
        <Box sx={{ width: "100%" }}>
          <Box>
            <Typography variant="body1" fontWeight={500}>
              DID Number
            </Typography>
            <Typography variant="body2" mb={2} color={"grey.600"}>
              Assign one or more DID numbers. These numbers will be used for
              customer calls.
            </Typography>
          </Box>

          {/* {fields.map((item, index) => (
          <Box key={item.id} display="flex" alignItems="center" mb={2}>
            <Controller
              name={`didNumbers[${index}]`}
              control={control}
              defaultValue={item}
              rules={{
                required: "DID number is required",
                validate: (value, formData) =>
                  validate(value, formData, apiData),
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  placeholder="DID Number"
                  variant="outlined"
                  type="tel"
                  fullWidth
                  sx={{ mr: 1, minWidth: "50%" }}
                  inputProps={{
                    style: {
                      fontSize: 12,
                    },
                  }}
                  error={get(errors, `didNumbers[${index}]`)}
                  helperText={
                    get(errors, `didNumbers[${index}]`) && (
                      <Box component="span" display="flex" alignItems="center">
                        <RedAlert
                          style={{ marginRight: 8 }}
                          width={12}
                          height={12}
                        />
                        <Typography variant="caption" color="error">
                          {get(errors, `didNumbers[${index}]`)?.message}
                        </Typography>
                      </Box>
                    )
                  }
                />
              )}
            />
            <IconButton
              // sx={{ ml: "auto" }}
              onClick={() => {
                remove(index);
                setValue("didNumbers", getValues("didNumbers"));
              }}
              // disabled={fields.length === 1} // Prevent removing the last field
            >
              <Trash2 width={16} height={16} color={theme.palette.grey[600]} />
            </IconButton>
          </Box>
        ))} */}
          {getValues("didNumbers").map((item, index) => (
            <FlexBox mb={1} columnGap={2} key={index}>
              <Tooltip title={item.length > 17 ? item : ""}>
                <Typography
                  key={item.id}
                  variant="body2"
                  style={{
                    textOverflow: "ellipsis",
                    width: "150px",
                    maxWidth: "150px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item}
                </Typography>
              </Tooltip>

              <IconButton
                onClick={() => {
                  remove(index);
                  onSave({
                    didNumbers: getValues("didNumbers"),
                  });
                }}
              >
                <Trash2
                  width={16}
                  height={16}
                  color={theme.palette.grey[600]}
                />
              </IconButton>
            </FlexBox>
          ))}

          <Controller
            name="newDidNumber"
            control={control}
            defaultValue=""
            rules={{
              validate: (value, formData) => validate(value, formData, apiData),
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value}
                onChange={onChange}
                placeholder="press enter to add a DID Number"
                variant="outlined"
                type="tel"
                sx={{ width: "50%" }}
                inputProps={{
                  style: {
                    fontSize: 12,
                  },
                }}
                error={get(errors, "newDidNumber")}
                helperText={
                  get(errors, "newDidNumber") && (
                    <Box component="span" display="flex" alignItems="center">
                      <RedAlert
                        style={{ marginRight: 8 }}
                        width={12}
                        height={12}
                      />
                      <Typography variant="caption" color="error">
                        {get(errors, "newDidNumber")?.message}
                      </Typography>
                    </Box>
                  )
                }
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    if (value && !get(errors, "newDidNumber")) {
                      append(value);
                      onSave({
                        didNumbers: getValues("didNumbers"),
                      });
                      setValue("newDidNumber", "");
                    }
                  }
                }}
              />
            )}
          />
          <Button
            variant="text"
            color="inherit"
            sx={{ mt: 2, display: "flex" }}
            startIcon={<Plus width={16} height={16} />}
            onClick={() => {
              if (watch("newDidNumber") && !get(errors, "newDidNumber")) {
                append(watch("newDidNumber"));
                onSave({
                  didNumbers: getValues("didNumbers"),
                });
                setValue("newDidNumber", "");
              }
            }}
          >
            Add Number
          </Button>
        </Box>

        {/* welcome workflow select */}
        <Box>
          <Box>
            <Typography variant="body1" fontWeight={500}>
              Welcome Flow
            </Typography>
            <Typography variant="body2" mb={2} color={"grey.600"}>
              This selection determines the virtual assistant’s welcome flow
            </Typography>
          </Box>
          <Box className={styles.workflowContainer}>
            <Box className={styles.workFlowDropDown}>
              <Controller
                control={control}
                name="welcomeWorkFlow"
                render={({ field: { onChange, value } }) => (
                  <Select
                    onChange={onChange}
                    value={value}
                    placeholder="Select workflow"
                    className={styles.dropDown}
                    IconComponent={ChevronDown}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: "40vh",
                          width: "20vw",
                        },
                      },
                    }}
                    inputProps={{
                      style: {
                        backgroundColor: theme.palette.grey[100],
                      },
                    }}
                  >
                    {workFlows?.map((workFlow) => (
                      <MenuItem
                        classes={{ selected: styles.selected }}
                        value={workFlow._id}
                        sx={{ gap: 3 }}
                        key={workFlow._id}
                      >
                        <Typography variant="body2">
                          {workFlow?.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
          </Box>
        </Box>

        {/* fallback workflow select */}
        <Box>
          <Box>
            <Typography variant="body1" fontWeight={500}>
              Fallback Flow
            </Typography>
            <Typography variant="body2" color="grey.600" mb={2}>
              This selection determines the fallback flow if the customer’s
              intent couldn’t be matched
            </Typography>
          </Box>
          <Box className={styles.workflowContainer}>
            <Box className={styles.workFlowDropDown}>
              <Controller
                control={control}
                name="fallBackWorkFlow"
                render={({ field: { onChange, value } }) => (
                  <Select
                    onChange={onChange}
                    value={value}
                    placeholder="Select workflow"
                    className={styles.dropDown}
                    IconComponent={ChevronDown}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: "40vh",
                          width: "20vw",
                        },
                      },
                    }}
                  >
                    {workFlows?.map((workFlow) => (
                      <MenuItem
                        classes={{ selected: styles.selected }}
                        value={workFlow._id}
                        sx={{ gap: 3 }}
                        key={workFlow._id}
                      >
                        <Typography variant="body2">
                          {workFlow?.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
          </Box>
        </Box>

        {/* number of tries textfield */}
        <Box>
          <Typography variant="body1" fontWeight={"bold"}>
            Number Of Tries
          </Typography>
          <Typography variant="body2" color="grey.600" mb={2}>
            Maximum number of attempts that are made to detect the customer’s
            intent
          </Typography>

          <Box>
            <Controller
              control={control}
              name="intentMaxTries"
              rules={{
                min: 1,
                max: 60,
                validate: (value) =>
                  (value >= 1 && value <= 60) ||
                  "Please enter a number between 1 and 60",
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  sx={{ marginTop: 2 }}
                  className={styles.intentMaxTries}
                  // onChange={onChange}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (newValue >= 1 && newValue <= 60) {
                      onChange(e);
                    }
                  }}
                  value={value}
                  type="number"
                  inputProps={{
                    min: 1,
                    max: 60,
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Box>
        </Box>

        {/* try exceeded workflow select */}
        <Box>
          <Box>
            <Typography variant="body1" fontWeight={500}>
              Try Exceeded
            </Typography>
            <Typography variant="body2" color="grey.600" mb={2}>
              What happens if the number of tries exceed
            </Typography>
          </Box>
          <Box className={styles.workflowContainer}>
            <Box className={styles.workFlowDropDown}>
              <Controller
                control={control}
                name="intentExceededWorkFlow"
                render={({ field: { onChange, value } }) => (
                  <Select
                    onChange={onChange}
                    value={value}
                    placeholder="Select workflow"
                    className={styles.dropDown}
                    IconComponent={ChevronDown}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: "40vh",
                          width: "20vw",
                        },
                      },
                    }}
                  >
                    {workFlows?.map((workFlow) => (
                      <MenuItem
                        classes={{ selected: styles.selected }}
                        value={workFlow._id}
                        sx={{ gap: 3 }}
                        key={workFlow._id}
                      >
                        <Typography variant="body2">
                          {workFlow?.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
          </Box>
        </Box>

        {/* post intent workflow select */}
        <Box>
          <Box>
            <Typography variant="body1" fontWeight={500}>
              Post Intent
            </Typography>
            <Typography variant="body2" color="grey.600" mb={2}>
              What happens after an intent has been successfully answered
            </Typography>
          </Box>
          <Box className={styles.workflowContainer}>
            <Box className={styles.workFlowDropDown}>
              <Controller
                control={control}
                name="postIntentWorkFlow"
                render={({ field: { onChange, value } }) => (
                  <Select
                    onChange={onChange}
                    value={value}
                    placeholder="Select workflow"
                    className={styles.dropDown}
                    IconComponent={ChevronDown}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: "40vh",
                          width: "20vw",
                        },
                      },
                    }}
                  >
                    {workFlows?.map((workFlow) => (
                      <MenuItem
                        classes={{ selected: styles.selected }}
                        value={workFlow._id}
                        sx={{ gap: 3 }}
                        key={workFlow._id}
                      >
                        <Typography variant="body2">
                          {workFlow?.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={styles.container}>
        {/* active categories select */}
        <Box>
          <Typography variant="body1" fontWeight={"bold"}>
            Active Categories
          </Typography>
          <Typography variant="body2" color={"grey.600"} mb={2}>
            Select the categories for which the virtual agent is active
          </Typography>

          <Box
            className={styles.categoriesContainer}
            // onChange={() => {
            //   setAdvancedSettings((prevData) => ({
            //     ...prevData,
            //     categories: checkedCategories,
            //   }));

            //   // onSave({ advancedSettings: { categories: checkedCategories } });
            // }}
          >
            {loading && (
              <Box
                sx={{
                  height: "150px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Loader width={32} height={32} color="#555" />
              </Box>
            )}
            {allCategories?.map((category) => (
              <Box
                key={category._id}
                className={styles.category}
                style={
                  category.categories.length === 0
                    ? { pointerEvents: "none", opacity: 0.6 }
                    : {}
                }
              >
                <FunCollapse
                  iconPosition={"start"}
                  sx={{
                    borderBottom: `1px solid ${theme.palette.borderColor.light}`,
                  }}
                  header={
                    <FlexBox columnGap={1} sx={{}}>
                      <label>
                        <Checkbox
                          onClick={(e) => e.stopPropagation()}
                          icon={<Square width={20} height={20} />}
                          checkedIcon={<CheckSquare width={20} height={20} />}
                          onChange={() => handleCheckboxChange(category._id)}
                          indeterminate={
                            !category.categories.every((subCategory) =>
                              checkedCategories.includes(subCategory._id)
                            ) &&
                            category.categories.some((subCategory) =>
                              checkedCategories.includes(subCategory._id)
                            )
                          }
                          checked={
                            category.categories.length &&
                            category.categories.every((subCategory) =>
                              checkedCategories.includes(subCategory._id)
                            )
                          }
                          indeterminateIcon={
                            <SquareMinus width={20} height={20} />
                          }
                          disabled={category.categories.length === 0}
                        />
                        <Typography
                          variant="caption"
                          fontWeight={400}
                          color={"text.primary"}
                        >
                          {category.name}
                        </Typography>
                      </label>
                    </FlexBox>
                  }
                >
                  {category?.categories?.map((subCategory) => (
                    <Box className={styles.subCategory} key={subCategory._id}>
                      <label style={{ marginLeft: 8 }}>
                        <Checkbox
                          onClick={(e) => e.stopPropagation()}
                          onChange={() =>
                            handleCheckboxChange(category._id, subCategory._id)
                          }
                          icon={<Square width={16} height={16} />}
                          checkedIcon={<CheckSquare width={16} height={16} />}
                          checked={checkedCategories.includes(subCategory._id)}
                        />
                        <Typography
                          variant="caption"
                          fontWeight={400}
                          color={"text.primary"}
                        >
                          {subCategory.name}
                        </Typography>
                      </label>
                    </Box>
                  ))}
                </FunCollapse>
              </Box>
            ))}
          </Box>
        </Box>

        {/* inactive categories workflow select */}
        <Box>
          <Typography variant="body1" fontWeight={"bold"}>
            Default behaviour (for inactive categories)
          </Typography>
          <Typography variant="body2" color={theme.palette.grey[600]} mb={2}>
            Set the default behavior for inactive categories
          </Typography>

          <Box className={styles.workflowContainer}>
            <Box className={styles.workFlowDropDown}>
              <Controller
                control={control}
                name="inactiveCategoriesDefaultWorkFlow"
                render={({ field: { onChange, value } }) => (
                  <Select
                    onChange={onChange}
                    value={value}
                    placeholder="Select workflow"
                    className={styles.dropDown}
                    IconComponent={ChevronDown}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: "40vh",
                          width: "20vw",
                        },
                      },
                    }}
                  >
                    {workFlows?.map((workFlow) => (
                      <MenuItem
                        classes={{ selected: styles.selected }}
                        value={workFlow._id}
                        sx={{ gap: 3 }}
                        key={workFlow._id}
                      >
                        <Typography variant="body2">
                          {workFlow?.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={styles.container} mb={8}>
        <FlexBox columnGap={2}>
          <AlertTriangle color="#FBC400" width={20} height={20} />
          <Typography variant="body2" color="grey.600">
            All calls are limited to 10 minutes and will disconnect
            automatically after that time.
          </Typography>
        </FlexBox>

        {/* wait time textfield */}
        <Box>
          <Typography variant="body1" fontWeight={"bold"}>
            Wait Time
          </Typography>
          <Typography variant="body2" color="grey.600" mb={2}>
            How long will the virtual agent wait for a response
          </Typography>

          <FlexBox>
            <Controller
              control={control}
              name="responseWaitTime"
              rules={{
                min: 1,
                max: 60,
                validate: (value) =>
                  (value >= 1 && value <= 60) ||
                  "Please enter a number between 1 and 60",
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  sx={{ marginTop: 2 }}
                  className={styles.numberOfTries}
                  // onChange={onChange}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (newValue >= 1 && newValue <= 60) {
                      onChange(e);
                    }
                  }}
                  value={value}
                  type="number"
                  inputProps={{
                    min: 1,
                    max: 60,
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />

            <Typography variant="body2">second(s)</Typography>
          </FlexBox>
        </Box>

        {/* wait time fallback flow type select */}
        <Box>
          <Typography variant="body1" fontWeight={"bold"}>
            Wait Time Fallback Flow
          </Typography>
          <Typography variant="body2" color={theme.palette.grey[600]} mb={2}>
            What happens if the wait time is exceeded{" "}
          </Typography>

          <Box
            className={styles.workflowContainer}
            sx={{ alignItems: "center" }}
          >
            <Box className={styles.workFlowDropDown}>
              <Controller
                control={control}
                name="waitTimeFallbackFlowType"
                // name="responseTimeExceededWorkFlow"
                render={({ field: { onChange, value } }) => (
                  <Select
                    onChange={onChange}
                    value={value}
                    placeholder="Select workflow"
                    className={styles.dropDown}
                    IconComponent={ChevronDown}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: "40vh",
                          width: "20vw",
                        },
                      },
                    }}
                  >
                    <MenuItem
                      classes={{ selected: styles.selected }}
                      value={"lastMessage"}
                      sx={{ gap: 3 }}
                    >
                      <Typography variant="body2">Play last message</Typography>
                    </MenuItem>
                    <MenuItem
                      classes={{ selected: styles.selected }}
                      value={"waitTimeFiller"}
                      sx={{ gap: 3 }}
                    >
                      <Typography variant="body2">
                        Play wait time filler
                      </Typography>
                    </MenuItem>
                  </Select>
                )}
              />
            </Box>
            {watch("waitTimeFallbackFlowType") === "customMessage" && (
              <Box className={styles.workFlowDropDown}>
                <Controller
                  control={control}
                  name="waitTimeFallbackFlowMessage"
                  render={({ field: { onChange, value } }) => (
                    <TextField fullWidth onChange={onChange} value={value} />
                  )}
                />
              </Box>
            )}
          </Box>
        </Box>

        {/* number of tries textfield for wait time fallback */}
        <Box>
          <Typography variant="body1" fontWeight={"bold"}>
            Number Of Tries
          </Typography>
          <Typography variant="body2" color="grey.600" mb={2}>
            Number of tries for the wait time fallback flow
          </Typography>

          <Box>
            <Controller
              control={control}
              name="responseTimeMaxTries"
              rules={{
                min: 1,
                max: 60,
                validate: (value) =>
                  (value >= 1 && value <= 60) ||
                  "Please enter a number between 1 and 60",
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  sx={{ marginTop: 2 }}
                  className={styles.numberOfTries}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (newValue >= 1 && newValue <= 60) {
                      onChange(e);
                    }
                  }}
                  value={value}
                  type="number"
                  inputProps={{
                    min: 1,
                    max: 60,
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Box>
        </Box>

        {/* wait time fallback flow try exceeded  select*/}
        <Box>
          <Typography variant="body1" fontWeight={"bold"}>
            Try Exceeded
          </Typography>
          <Typography variant="body2" color={theme.palette.grey[600]} mb={2}>
            What happens if the number of tries for wait time fallback flow
            exceed{" "}
          </Typography>
          <Box className={styles.workflowContainer}>
            <Box className={styles.workFlowDropDown}>
              <Controller
                control={control}
                name="responseTimeExceededWorkFlow"
                render={({ field: { onChange, value } }) => (
                  <Select
                    onChange={onChange}
                    value={value}
                    placeholder="Select workflow"
                    className={styles.dropDown}
                    IconComponent={ChevronDown}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: "40vh",
                          width: "20vw",
                        },
                      },
                    }}
                  >
                    {workFlows?.map((workFlow) => (
                      <MenuItem
                        classes={{ selected: styles.selected }}
                        value={workFlow._id}
                        sx={{ gap: 3 }}
                        key={workFlow._id}
                      >
                        <Typography variant="body2">
                          {workFlow?.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
          </Box>
          {/* <Box className={styles.workflowContainer}>
          <Box className={styles.workFlowText}>
            <Typography variant="body2" color={"#0f0f0f"}>
              Trigger Workflow
            </Typography>
          </Box>
          <Box className={styles.workFlowDropDown}>
            <Controller
              control={control}
              name="waitTimeFallbackWorkFlow"
              render={({ field: { onChange, value } }) => (
                <Select
                  onChange={onChange}
                  value={value}
                  placeholder="Select workflow"
                  className={styles.dropDown}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "40vh",
                        width: "20vw",
                      },
                    },
                  }}
                >
                  {workFlows?.map((workFlow) => (
                    <MenuItem
                            classes={{ selected: styles.selected }}
                      value={workFlow._id}
                      sx={{ gap: 3 }}
                      key={workFlow._id}
                    >
                      <Typography variant="body2">{workFlow?.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>
        </Box> */}
        </Box>
      </Box>
    </>
  );
};

BasicSettings.propTypes = {
  workFlows: PropTypes.array,
  onSave: PropTypes.func,
  data: PropTypes.object,
};

export default BasicSettings;
