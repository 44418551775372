import {
  Box,
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import avatarMale from "assets/images/voice-male.png";
import avatarFemale from "assets/images/voice-female.png";
import avatarNeutral from "assets/images/voice-neutral.png";
import elevenLabs from "assets/images/elevenLabs.png";
import sarvam from "assets/images/sarvam.png";
import smallest from "assets/images/smallest.jpeg";
import { Plus, Trash2, X } from "react-feather";
import { IconGoogle } from "components/SVG";
import styles from "./index.module.css";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useEffect } from "react";
import theme from "theme";
import { updateVoiceConfig, addVoiceConfig } from "services";
import { useSkillrToast } from "context/toast";
import { WarningRed } from "components/newSVG";

const ManagePronunciationModal = ({
  open,
  onClose,
  voice,
  voices,
  bot,
  getVoices,
  currentLanguage,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      newWord: "",
      newPronounciation: "",
      words: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "words",
  });

  const { showSkillrToast } = useSkillrToast();

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleOnAddNewPrononunciation = (formData) => {
    // Fetch existing words and normalize to lowercase
    const existingWords = getValues().words.map((wordObj) => ({
      word: wordObj.word.toLowerCase(),
      pronunciation: wordObj.pronunciation,
    }));

    // Normalize the new word and pronunciation
    const newWordEntry = {
      word: formData.newWord.toLowerCase(),
      pronunciation: formData.newPronounciation,
    };

    // Check if the new word already exists, replace if it does
    const updatedWords = [
      ...existingWords.filter((entry) => entry.word !== newWordEntry.word),
      newWordEntry,
    ];

    // Clear existing entries from the form
    for (let i = getValues().words.length - 1; i >= 0; i--) {
      remove(i);
    }

    // Append the deduplicated and updated words back to the form
    updatedWords.forEach(({ word, pronunciation }) =>
      append({
        word,
        pronunciation,
      })
    );

    setValue("newWord", "");
    setValue("newPronounciation", "");
  };

  const editVoiceConfig = async (voice) => {
    try {
      const selectedVoice = voices.find((v) => v.name === voice.name);
      // const voiceFromVoiceLibrary = voices.find((v) => v.name === voice.name);
      const words = getValues().words.reduce((acc, curr) => {
        acc[curr.word] = curr.pronunciation;
        return acc;
      }, {});

      let companyVoiceConfig = selectedVoice.companyVoiceConfig || [];
      let updateConfig = companyVoiceConfig.find((item) => {
        return (
          item.name === selectedVoice.name &&
          item.languageCode === currentLanguage
        );
      });
      let response;

      if (updateConfig) {
        // If config exists, toggle the favourite status
        // update case
        response = await updateVoiceConfig(updateConfig._id, {
          botId: bot._id,
          companyId: bot.companyId,
          name: selectedVoice.name,
          languageCode: currentLanguage,
          pronounciation: words,
        });
      } else {
        // If config doesn't exist, create a new one
        // add case
        updateConfig = {
          botId: bot._id,
          companyId: bot.companyId,
          name: selectedVoice.name,
          languageCode: currentLanguage,
          favourite: false,
          configurationValues: {},
          pronounciation: words,
        };
        companyVoiceConfig.push(updateConfig);
        response = await addVoiceConfig(updateConfig);
      }

      if (response) {
        getVoices();
        showSkillrToast("Pronounciation updated successfully", "success");
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (voice && voices) {
      reset();
      const selectedVoice = voices?.find((v) => v.name === voice?.name);
      let companyVoiceConfig = selectedVoice?.companyVoiceConfig || [];
      let updateConfig = companyVoiceConfig.find((item) => {
        return (
          item.name === selectedVoice.name &&
          item.languageCode === currentLanguage
        );
      });
      if (updateConfig && updateConfig.pronounciation) {
        Object.entries(updateConfig.pronounciation).forEach(([key, value]) => {
          append({ word: key, pronunciation: value });
        });
      } else {
        // append({
        //   word: "",
        //   pronunciation: "",
        // });
      }
    }
  }, [voice, voices]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          width: 1000,
          borderRadius: 5,
        },
      }}
    >
      <Box sx={{ padding: "24px", position: "relative" }}>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 20, right: 20 }}
        >
          <X width={20} height={20} />
        </IconButton>
        <Typography variant="h3" fontWeight={500} textAlign={"center"} mb={4}>
          Manage Pronunciation
        </Typography>

        {voices.length > 0 && voice && (
          <Box p={4}>
            <FlexBox>
              <img
                src={
                  voices.find((v) => v.name === voice.name)?.gender === "FEMALE"
                    ? avatarFemale
                    : voices.find((v) => v.name === voice.name)?.gender ===
                      "MALE"
                    ? avatarMale
                    : voices.find((v) => v.name === voice.name)?.gender ===
                      "NEUTRAL"
                    ? avatarNeutral
                    : avatarNeutral
                }
                alt="avatar"
                width={56}
                height={56}
              />
              <Box>
                <FlexBox>
                  <Typography variant="body1" fontWeight={500}>
                    {voices.find((v) => v.name === voice.name)?.displayName ||
                      voice?.name ||
                      ""}
                  </Typography>
                </FlexBox>

                <Typography variant="body2" color="grey.600">
                  {voices.find((v) => v.name === voice.name)?.description || ""}
                </Typography>

                <FlexBox columnGap={1}>
                  {voices.find((v) => v.name === voice.name)?.provider ===
                  "google" ? (
                    <IconGoogle width={11} height={11} />
                  ) : voices.find((v) => v.name === voice.name)?.provider ===
                    "elevenLabs" ? (
                    <img
                      src={elevenLabs}
                      width={11}
                      height={11}
                      alt="elevenLabs"
                    />
                  ) : voices.find((v) => v.name === voice.name)?.provider ===
                    "sarvam" ? (
                    <img src={sarvam} width={11} height={11} alt="sarvam" />
                  ) : voices.find((v) => v.name === voice.name)?.provider ===
                    "smallest" ? (
                    <img src={smallest} width={11} height={11} alt="smallest" />
                  ) : null}
                  <Typography fontSize={10} color="grey.400">
                    {voices.find((v) => v.name === voice.name)?.provider ===
                    "google"
                      ? "Google"
                      : voices.find((v) => v.name === voice.name)?.provider ===
                        "elevenLabs"
                      ? "Eleven Labs"
                      : voices.find((v) => v.name === voice.name)?.provider ===
                        "sarvam"
                      ? "Sarvam"
                      : voices.find((v) => v.name === voice.name)?.provider ===
                        "smallest"
                      ? "Smallest AI"
                      : voices.find((v) => v.name === voice.name)?.provider}
                  </Typography>
                </FlexBox>
              </Box>
            </FlexBox>
            <FlexBox mt={4} mb={1}>
              <Typography
                variant="caption"
                sx={{ width: "45%", opacity: 0.8 }}
                color="text.primary"
              >
                Root Word
              </Typography>
              <Typography
                variant="caption"
                sx={{ width: "45%", opacity: 0.8 }}
                color="text.primary"
              >
                Pronunciation
              </Typography>
            </FlexBox>
            <FlexBox mt={1}>
              <Controller
                control={control}
                name={`newWord`}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <TextField
                    sx={{ width: "45%", "& > p": { marginTop: 0 } }}
                    {...field}
                    placeholder="enter word"
                    error={errors?.newWord ? true : false}
                    helperText={
                      errors?.newWord && (
                        <Box
                          component="span"
                          display="flex"
                          alignItems="center"
                          padding="5px 4px"
                          position="absolute"
                          bottom="-28px"
                        >
                          <WarningRed style={{ marginRight: 8 }} />
                          <Typography
                            fontSize={10}
                            fontWeight={500}
                            color="error"
                            sx={{ opacity: 0.8 }}
                          >
                            {errors?.newWord?.message}
                          </Typography>
                        </Box>
                      )
                    }
                  />
                )}
              />

              <Controller
                control={control}
                name={`newPronounciation`}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <TextField
                    sx={{ width: "45%", "& > p": { marginTop: 0 } }}
                    {...field}
                    placeholder="enter how it's pronounced"
                    error={errors?.newPronounciation ? true : false}
                    helperText={
                      errors?.newPronounciation && (
                        <Box
                          component="span"
                          display="flex"
                          alignItems="center"
                          padding="5px 4px"
                          position="absolute"
                          bottom="-28px"
                        >
                          <WarningRed style={{ marginRight: 8 }} />
                          <Typography
                            fontSize={10}
                            fontWeight={500}
                            color="error"
                            sx={{ opacity: 0.8 }}
                          >
                            {errors?.newPronounciation?.message}
                          </Typography>
                        </Box>
                      )
                    }
                  />
                )}
              />

              <IconButton
                sx={{
                  height: 48,
                  width: 48,
                  borderRadius: "12px",
                  backgroundColor: theme.palette.grey[100],
                }}
                onClick={handleSubmit(handleOnAddNewPrononunciation)}
              >
                <Plus width={20} height={20} />
              </IconButton>
            </FlexBox>

            {fields.map((word, index) => (
              <FlexBox
                key={word.id}
                mt={Object.keys(errors).length > 0 ? 6 : 4}
              >
                <Controller
                  control={control}
                  name={`words.${index}.word`}
                  // rules={{ required: "This field is required" }}
                  // disabled
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ width: "45%" }}
                      placeholder="enter word"
                      disabled={true}
                      // error={errors?.words?.[index]?.word}
                      // helperText={errors?.words?.[index]?.word?.message}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`words.${index}.pronunciation`}
                  // rules={{ required: "This field is required" }}
                  // disabled
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ width: "45%" }}
                      placeholder="enter how it's pronounced"
                      error={errors?.words?.[index]?.pronunciation}
                      helperText={
                        errors?.words?.[index]?.pronunciation?.message
                      }
                      disabled={true}
                    />
                  )}
                />

                <IconButton
                  sx={{
                    height: 48,
                    width: 48,
                    borderRadius: "12px",
                    backgroundColor: theme.palette.grey[100],
                  }}
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <Trash2 width={20} height={20} />
                </IconButton>
              </FlexBox>
            ))}
          </Box>
        )}

        <FlexBox justifyContent={"center"} mt={4}>
          <Button
            variant="whiteRound"
            onClick={handleClose}
            sx={{ borderRadius: "12px" }}
            className={styles.modalButton}
          >
            Cancel
          </Button>

          <Button
            className={styles.modalButton}
            variant="dark"
            sx={{ borderRadius: "12px" }}
            // onClick={handleSubmit(() => editVoiceConfig(voice))}
            onClick={() => editVoiceConfig(voice)}
          >
            Apply
          </Button>
        </FlexBox>
      </Box>
    </Dialog>
  );
};

export default ManagePronunciationModal;
