import {
  Typography,
  Box,
  Radio,
  Select,
  MenuItem,
  Button,
  TextField,
  Grow,
  Tooltip,
} from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { RadioButton } from "components/newSVG";
import { Circle, Plus } from "react-feather";
import theme from "theme";
import PropTypes from "prop-types";
import { ChevronRight, IconInfo2, RedAlert } from "components/SVG";
import { useEffect, useState } from "react";
import FlexBox from "components/FlexBox";
import styles from "./index.module.css";
import ConditionBlock from "./ConditionBlock";
import { useSkillrToast } from "context/toast";
import { checkDuplicateName } from "utils/utils";

const DecisionProperties = (props) => {
  const { onSave, data, onEdit, workFlowId } = props;
  const { showSkillrToast } = useSkillrToast();
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      conditions: [],
      formula: "",
      flowType: "addNode",
      trueWorkflow: {
        trueWorkflowName: "",
        trueWorkflowId: "",
      },
      falseWorkflow: {
        falseWorkflowName: "",
        falseWorkflowId: "",
      },
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "conditions",
    control,
  });

  const handleAddCondition = () => {
    // const lastCondition = fields[fields.length - 1];
    // const nextLetter = String.fromCharCode(65 + fields.length);

    append({
      // name: `Condition ${nextLetter}`,
      argument: "",
      operator: "",
      value: "",
    });
  };

  const removeCondition = (conditionIndex) => {
    remove(conditionIndex);
  };

  const handleOnSave = async (data) => {
    if (data.conditions.length === 0) {
      showSkillrToast("Please add at least one condition", "error");
      return;
    }

    const hasMissingFields = data.conditions.some((condition) => {
      return !condition.argument || !condition.operator;
    });

    if (hasMissingFields) {
      showSkillrToast("Please fill all the fields in conditions", "error");
      return;
    }

    let newData = { ...data };
    newData.conditions = newData.conditions.map((condition, index) => {
      return {
        ...condition,
        name: `Condition ${String.fromCharCode(65 + index)}`,
      };
    });

    if (data.customName) {
      const isDuplicate = await checkDuplicateName(
        workFlowId,
        data.customName,
        props.data.name
      );
      if (isDuplicate) {
        showSkillrToast("Node name already exists", "error");
        return;
      }
    }

    onSave(newData);
  };

  useEffect(() => {
    onEdit(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (data?.formula) {
      reset({
        ...data,
      });
    }
  }, [data]);

  return (
    <Box mt={"10px"} sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <Typography variant="caption" color="text.primary" mb={1}>
        Node Name
      </Typography>

      <Controller
        control={control}
        name="customName"
        rules={{
          validate: (value) => {
            if (/[^a-zA-Z\s]/.test(value))
              return "Node name can only contain letters and spaces";
            return true;
          },
        }}
        render={({ field: { onChange, value } }) => (
          <TextField
            variant="outlined"
            inputProps={{ maxLength: 20 }}
            fullWidth
            placeholder="Add Name"
            value={value}
            onChange={onChange}
            error={errors?.customName}
            helperText={errors?.customName?.message}
            sx={{
              mb: 4,
              "& div": {
                borderRadius: "12px",
                padding: 0,
              },
            }}
          />
        )}
      />

      {fields.map((field, index) => (
        <ConditionBlock
          key={field.id}
          id={field.id}
          argument={field.argument}
          operator={field.operator}
          value={field.value}
          onChange={(field, value) => {
            setValue(`conditions.[${index}].${field}`, value);
          }}
          onRemove={() => removeCondition(index)}
          fields={fields}
          onEdit={onEdit}
        />
      ))}

      {fields[fields.length - 1]?.name !== "Condition Z" &&
        (fields.length === 0 ? (
          <Button
            variant="outlined"
            fullWidth
            sx={{ borderRadius: 82 }}
            onClick={handleAddCondition}
          >
            Add Conditions
          </Button>
        ) : (
          <Button
            sx={{ minWidth: 0, width: "max-content", mt: 2 }}
            variant="text"
            endIcon={<Plus width={20} height={20} />}
            color="inherit"
            onClick={handleAddCondition}
          >
            Add Condition
          </Button>
        ))}
      <Typography variant="body1" fontWeight={500} mt={4}>
        Formula
      </Typography>

      <Controller
        name="formula"
        control={control}
        rules={{ required: "Formula is required" }}
        render={({ field: { onChange, value } }) => (
          <>
            <TextField
              onChange={onChange}
              value={value}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              placeholder="eg: (A AND B) OR (C AND D)"
              sx={{
                mt: 1,
                "& div": {
                  padding: 0,
                  borderRadius: 3,
                },
              }}
            />
            {errors?.formula && (
              <FlexBox
                mt={1}
                mb={2}
                columnGap={0}
                fontSize={12}
                color={"error.main"}
              >
                <RedAlert style={{ marginRight: 8 }} />
                {errors?.formula?.message}
              </FlexBox>
            )}
          </>
        )}
      />

      <div style={{ marginTop: 16 }} />

      {/* <FlexBox mt={6} columnGap={1}>
        <Typography variant="caption" color={"text.primary"}>
          Configure Flow
        </Typography>
        <Tooltip
          arrow
          placement="top"
          title="Select 'Add Node' to continue with another node or 'Trigger Workflow' to start a new workflow."
        >
          <IconInfo2
            width={20}
            height={20}
            color={theme.palette.text.secondary}
          />
        </Tooltip>
      </FlexBox> */}

      {/* <FlexBox mt={2} mb={4}>
        <FlexBox columnGap={0} component="label" sx={{ cursor: "pointer" }}>
          <Controller
            name="flowType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Radio
                value="addNode"
                checked={value === "addNode"}
                onChange={() => onChange("addNode")}
                icon={<Circle width={20} height={20} />}
                checkedIcon={<RadioButton width={20} height={20} />}
              />
            )}
          />
          <Typography variant="body2">Add Node</Typography>
        </FlexBox>

        <FlexBox columnGap={0} component="label" sx={{ cursor: "pointer" }}>
          <Controller
            name="flowType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Radio
                value="triggerWorkflow"
                checked={value === "triggerWorkflow"}
                onChange={() => onChange("triggerWorkflow")}
                icon={<Circle width={20} height={20} />}
                checkedIcon={<RadioButton width={20} height={20} />}
              />
            )}
          />
          <Typography variant="body2">Trigger Workflow</Typography>
        </FlexBox>
      </FlexBox> */}

      {/* <Grow in={watch("flowType") === "triggerWorkflow"}>
        <div>
          <Typography variant="caption" color={"text.primary"}>
            True Workflow
          </Typography>

          <Controller
            control={control}
            name="trueWorkflow"
            rules={{ required: "True workflow is required" }}
            render={({ field: { onChange, value } }) => (
              <Select
                fullWidth
                className={styles.select}
                onChange={(e) => onChange(e.target.value)}
                defaultValue={{
                  trueWorkflowName: data.trueWorkflowName,
                  trueWorkflowId: data.trueWorkflowId,
                }}
                value={value}
                sx={{ mt: 2, mb: 4 }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      borderRadius: 0,
                      boxShadow: "none",
                      backgroundColor: theme.palette.grey[50],
                      maxHeight: "40vh",
                    },
                  },
                }}
                // render the selected value after selection
                renderValue={(selected) => {
                  return (
                    <Typography
                      variant="caption"
                      color={theme.palette.grey[800]}
                    >
                      {selected.workflowName}
                    </Typography>
                  );
                }}
              >
                {workflows?.workFlows?.map((workflow) => (
                  <MenuItem
                    value={{
                      workflowName: workflow.name,
                      workflowId: workflow._id,
                    }}
                    key={workflow.id}
                    classes={{ selected: styles.selected }}
                  >
                    <Typography
                      variant="caption"
                      color={theme.palette.grey[800]}
                    >
                      {workflow.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            )}
          />

          <Typography variant="caption" color={"text.primary"}>
            False Workflow
          </Typography>

          <Controller
            control={control}
            name="falseWorkflow"
            rules={{ required: "False workflow is required" }}
            render={({ field: { onChange, value } }) => (
              <Select
                fullWidth
                className={styles.select}
                onChange={(e) => onChange(e.target.value)}
                defaultValue={{
                  falseWorkflowName: data.falseWorkflowName,
                  falseWorkflowId: data.falseWorkflowId,
                }}
                value={value}
                sx={{ mt: 2, mb: 4 }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      borderRadius: 0,
                      boxShadow: "none",
                      backgroundColor: theme.palette.grey[50],
                      maxHeight: "40vh",
                    },
                  },
                }}
                // render the selected value after selection
                renderValue={(selected) => {
                  return (
                    <Typography
                      variant="caption"
                      color={theme.palette.grey[800]}
                    >
                      {selected.workflowName}
                    </Typography>
                  );
                }}
              >
                {workflows?.workFlows?.map((workflow) => (
                  <MenuItem
                    value={{
                      workflowName: workflow.name,
                      workflowId: workflow._id,
                    }}
                    key={workflow.id}
                    classes={{ selected: styles.selected }}
                  >
                    <Typography
                      variant="caption"
                      color={theme.palette.grey[800]}
                    >
                      {workflow.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </div>
      </Grow> */}

      <Button
        variant="dark"
        sx={{
          borderRadius: 82,
          minWidth: 0,
          mt: "auto",
          width: "max-content",
        }}
        onClick={handleSubmit(handleOnSave)}
        endIcon={<ChevronRight width={20} height={20} />}
      >
        Save
      </Button>
    </Box>
  );
};

DecisionProperties.propTypes = {
  nodes: PropTypes.array,
};

export default DecisionProperties;
