import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Grow,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import { X } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import theme from "theme";
import styles from "./index.style";
import { useEffect, useState, useRef } from "react";
import { exportAnalytics, exportCampaign, getAllVariables } from "services";
import { useSkillrToast } from "context/toast";
import moment from "moment";

const ExportModal = (props) => {
  const {
    open,
    onClose,
    totalCalls,
    fields,
    campaignId,
    startDate,
    endDate,
    filters,
    search,
  } = props;
  const { showSkillrToast } = useSkillrToast();
  const [variables, setVariables] = useState({});
  const selectAllRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      systemVariables: true,
      sessionVariables: true,
      customVariables: true,
      selectAllVariables: true,
      system: {},
      session: {},
    },
  });

  const getVariables = async () => {
    try {
      const response = await getAllVariables();
      setVariables(response);
      return response;
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const renderVariables = (variables) => {
    const groupedVariables = variables?.reduce((acc, variable) => {
      const { type } = variable;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(variable);
      return acc;
    }, {});

    return (
      <Box>
        {Object.entries(groupedVariables).map(([type, vars]) => (
          <Box key={type} mt={2}>
            <FlexBox justifyContent="space-between">
              <Typography variant="body1" fontWeight={500}>
                {type.charAt(0).toUpperCase() + type.slice(1)} Variables
              </Typography>

              <Controller
                name={`${type}Variables`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      type === "system" &&
                        handleSystemVariablesChange(e.target.checked);
                      type === "session" &&
                        handleSessionVariablesChange(e.target.checked);
                      type === "custom" &&
                        handleCustomVariablesChange(e.target.checked);
                    }}
                    color="primary"
                  />
                )}
              />
            </FlexBox>

            <FlexBox>
              <Typography variant="body1" fontWeight={500}></Typography>
            </FlexBox>
            <Box sx={{ columnCount: 2 }}>
              {vars.map((variable, index) => (
                <FlexBox
                  columnGap={0}
                  key={index}
                  component="label"
                  sx={{ cursor: "pointer" }}
                >
                  <Controller
                    name={`${type}.${variable.variable}`}
                    control={control}
                    defaultValue={true}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        checked={value}
                        // disabled={
                        //   (type === "session" && !watch("sessionVariables")) ||
                        //   (type === "system" && !watch("systemVariables")) ||
                        //   (type === "custom" && !watch("customVariables"))
                        // }
                        onChange={(e) => {
                          onChange(e.target.checked);
                          handleIndividualVariableChange(
                            type,
                            variable.variable,
                            e.target.checked
                          );
                        }}
                      />
                    )}
                  />
                  <Typography variant="body2">{variable.variable}</Typography>
                </FlexBox>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const updateSystemVariables = (checked) => {
    const currentValues = getValues();
    if (currentValues.system && currentValues.system.system) {
      const updatedSystemVariables = Object.keys(
        currentValues.system.system
      ).reduce((acc, variable) => {
        acc[variable] = checked;
        return acc;
      }, {});
      setValue("system.system", updatedSystemVariables);
    }
    updateSelectAllVariables();
  };

  const updateSessionVariables = (checked) => {
    const currentValues = getValues();
    if (currentValues.session && currentValues.session.session) {
      const updatedSessionVariables = Object.keys(
        currentValues.session.session
      ).reduce((acc, variable) => {
        acc[variable] = checked;
        return acc;
      }, {});
      setValue("session.session", updatedSessionVariables);
    }
    updateSelectAllVariables();
  };

  const updateCustomVariables = (checked) => {
    const currentValues = getValues();
    if (currentValues.custom) {
      const updatedCustomVariables = Object.keys(currentValues.custom).reduce(
        (acc, variable) => {
          if (typeof currentValues.custom[variable] === "boolean") {
            acc[variable] = checked;
          } else if (typeof currentValues.custom[variable] === "object") {
            acc[variable] = Object.keys(currentValues.custom[variable]).reduce(
              (nestedAcc, nestedVar) => {
                nestedAcc[nestedVar] = checked;
                return nestedAcc;
              },
              {}
            );
          }
          return acc;
        },
        {}
      );
      setValue("custom", updatedCustomVariables);
    }
    updateSelectAllVariables();
  };

  const updateSelectAllVariables = () => {
    const systemVariables = watch("systemVariables");
    const sessionVariables = watch("sessionVariables");
    const customVariables = watch("customVariables");

    const allSelected = systemVariables && sessionVariables && customVariables;
    const someSelected = systemVariables || sessionVariables || customVariables;

    setValue("selectAllVariables", allSelected);

    if (selectAllRef.current) {
      selectAllRef.current.indeterminate = someSelected && !allSelected;
    }
  };

  const handleSelectAllChange = (checked) => {
    setValue("selectAllVariables", checked);
    setValue("systemVariables", checked);
    setValue("sessionVariables", checked);
    setValue("customVariables", checked);
    updateSystemVariables(checked);
    updateSessionVariables(checked);
    updateCustomVariables(checked);

    if (Array.isArray(variables)) {
      variables.forEach((variable) => {
        setValue(`${variable.type}.${variable.variable}`, checked);
      });
    }

    if (selectAllRef.current) {
      selectAllRef.current.indeterminate = false;
    }
  };

  const handleSystemVariablesChange = (checked) => {
    setValue("systemVariables", checked);
    updateSystemVariables(checked);
  };

  const handleSessionVariablesChange = (checked) => {
    setValue("sessionVariables", checked);
    updateSessionVariables(checked);
  };

  const handleCustomVariablesChange = (checked) => {
    setValue("customVariables", checked);
    updateCustomVariables(checked);
  };

  const handleIndividualVariableChange = (category, variable, checked) => {
    setValue(`${variable}`, checked);
    const anyCategoryVariableChecked = variables
      .filter((v) => v.type === category)
      .some((v) => watch(`${category}.${v.variable}`));

    // Update the category switch
    setValue(`${category}Variables`, anyCategoryVariableChecked);

    updateSelectAllVariables();
  };

  const onSubmit = (data) => {
    const selectiveFields = [
      ...fields
        .filter((field) => data[field.value])
        .map((field) => field.value),
    ];

    // Handle system variables
    if (data.systemVariables) {
      Object.keys(data.system.system).forEach((variable) => {
        if (data.system.system[variable]) {
          selectiveFields.push(`system.${variable}`);
        }
      });
    }

    // Handle session variables
    if (data.sessionVariables) {
      Object.keys(data.session.session).forEach((variable) => {
        if (data.session.session[variable]) {
          selectiveFields.push(`session.${variable}`);
        }
      });
    }

    // Handle custom variables
    if (data.customVariables) {
      Object.keys(data.custom).forEach((variable) => {
        if (data.custom[variable] === true) {
          selectiveFields.push(`custom.${variable}`);
        } else if (typeof data.custom[variable] === "object") {
          // Handle nested objects like 'session' in custom
          Object.keys(data.custom[variable]).forEach((nestedVar) => {
            if (data.custom[variable][nestedVar]) {
              selectiveFields.push(`custom.${nestedVar}`);
            }
          });
        }
      });
    }

    handleExport({
      selectiveFields: selectiveFields.join(","),
    });
  };

  const handleExport = async (data) => {
    setLoading(true);
    try {
      const params = new URLSearchParams();

      // Add search filter
      if (search) {
        params.append("search", search);
      }

      // Add date range parameters
      if ((startDate && endDate) || (filters.startDate && filters.endDate)) {
        params.append(
          "startDate",
          moment(startDate || filters.startDate).startOf("day")
        );
        params.append(
          "endDate",
          moment(endDate || filters.endDate).endOf("day")
        );
        params.append("filter", "range");
      }

      if (
        !campaignId &&
        filters.parentCategoryFilter &&
        filters.parentCategoryFilter.length > 0
      ) {
        params.append("parentCategory", filters.parentCategoryFilter.join(","));
      }

      // Handle childCategory filter (previously subCategory)
      if (
        !campaignId &&
        filters.childCategoryFilter &&
        filters.childCategoryFilter.length > 0
      ) {
        params.append("subCategory", filters.childCategoryFilter.join(","));
      }

      if (filters.minDurationFilter) {
        params.append("minDuration", filters.minDurationFilter);
      }

      if (filters.maxDurationFilter) {
        params.append("maxDuration", filters.maxDurationFilter);
      }

      if (filters.statusFilter) {
        params.append("status", filters.statusFilter.join(","));
      }

      if (data.selectiveFields)
        params.append("selectiveFields", data.selectiveFields);

      if (filters.feedback1Filter) {
        params.append("feedback1", filters.feedback1Filter.join(","));
      }
      if (filters.feedback2Filter) {
        params.append("feedback2", filters.feedback2Filter.join(","));
      }

      // handling filters for campaign
      if (campaignId && filters.dateFilter) {
        params.append("dateFilter", filters.dateFilter);
      }
      if (filters.minDuration) {
        params.append("minDuration", filters.minDuration);
      }
      if (filters.maxDuration) {
        params.append("maxDuration", filters.maxDuration);
      }
      if (filters.status) {
        params.append("status", filters.status.join(","));
      }

      let response;
      if (campaignId) {
        response = await exportCampaign(campaignId, params.toString());
      } else {
        response = await exportAnalytics(params.toString());
      }

      if (response && response.downloadLink) {
        window.open(response.downloadLink, "_blank");
        showSkillrToast("Data exported successfully", "success");
        setLoading(false);
        onClose();
      }
    } catch (error) {
      setLoading(false);
      if (error.response.data.message === "No data found") {
        showSkillrToast("No data found", "error");
      } else {
        console.error("Error exporting data:", error);
        showSkillrToast("Failed to export data", "error");
      }
    }
  };
  useEffect(() => {
    if (open) {
      getVariables();
    }
  }, [open]);

  useEffect(() => {
    updateSelectAllVariables();
  }, [
    watch("systemVariables"),
    watch("sessionVariables"),
    watch("customVariables"),
  ]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      TransitionComponent={Grow}
      PaperProps={{
        sx: styles.dialogPaper,
      }}
    >
      <Box p={8} pt={0}>
        <Box sx={styles.titleContainer}>
          <IconButton
            onClick={onClose}
            style={{ position: "absolute", top: 20, right: 0 }}
          >
            <X width={20} height={20} />
          </IconButton>
          <Typography variant="h3" fontWeight={500} mb={2}>
            Export Data
          </Typography>

          <Typography variant="body2" mb={8}>
            Export details for all {totalCalls} calls.
          </Typography>
        </Box>

        <Box sx={styles.contentContainer}>
          <Typography variant="body1" fontWeight={500}>
            Call Details
          </Typography>

          <Box mt={4}>
            {fields.map((field, index) => (
              <Box key={index} mb={index === fields.length - 1 ? 0 : 2}>
                <FlexBox alignItems="center" justifyContent="space-between">
                  <Typography
                    variant="caption"
                    color={theme.palette.text.primary}
                    fontWeight={400}
                  >
                    {field.label}
                  </Typography>

                  <Controller
                    name={field.value}
                    control={control}
                    defaultValue={true}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        disabled={index < 2}
                        checked={value}
                        onChange={onChange}
                        color="primary"
                      />
                    )}
                  />
                </FlexBox>
              </Box>
            ))}
          </Box>
        </Box>

        <Box sx={styles.contentContainer} mt={4}>
          <Typography variant="body1" fontWeight={500} mb={4}>
            Call Metadata
          </Typography>

          <Typography variant="body2">
            By default all call metadata is selected for export. To customise
            click on show more.
          </Typography>

          <Box my={4}>
            <FlexBox columnGap={0} component="label" sx={{ cursor: "pointer" }}>
              <Controller
                name="selectAllVariables"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    indeterminate={selectAllRef.current?.indeterminate}
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      handleSelectAllChange(e.target.checked);
                    }}
                    inputRef={selectAllRef}
                  />
                )}
              />
              <Typography variant="body2">Select / Deselect All</Typography>
            </FlexBox>
          </Box>

          {/* <FlexBox justifyContent="space-between">
            <Typography variant="body1" fontWeight={500}>
              System Variables
            </Typography>

            <Controller
              name="systemVariables"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Switch
                  checked={value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    handleSystemVariablesChange(e.target.checked);
                  }}
                  color="primary"
                />
              )}
            />
          </FlexBox> */}

          {variables && variables.length > 0 && renderVariables(variables)}
        </Box>

        <FlexBox justifyContent="center" mt={8}>
          <Button variant="outlined" sx={styles.button} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="dark"
            sx={styles.button}
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >
            Export
          </Button>
        </FlexBox>
      </Box>
    </Dialog>
  );
};

export default ExportModal;
