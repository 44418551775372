import { Box, Button, Radio, Typography } from "@mui/material";
import styles from "./index.module.css";
import FlexBox from "components/FlexBox";
import FundamentoPopup from "components/FundamentoPopup";
import { ChevronDown } from "components/SVG";
import { Circle, RadioButton } from "components/newSVG";
import { useEffect, useState } from "react";
import theme from "theme";
import ConfigurePrompt from "../ConfigurePrompt";
import ConfigureModel from "../ConfigureModel";
import ConfigurePlayground from "../ConfigurePlayground";
import ConfigureWorkFlowNodes from "../ConfigureWorkFlowNodes";
import getAILabsConfigApi from "services/getAILabsConfig";
import updateAILabsConfigApi from "services/updateAILabsConfig";
import { useSkillrToast } from "context/toast";
import { useUserContext } from "context/user";
import _ from "lodash";
import { Loader } from "react-feather";
import ApiNode from "../ApiNode";
import MessageWithOptionsNode from "../MessageWithOptionsNode";

const Configure = () => {
  const { showSkillrToast } = useSkillrToast();
  const { allBot } = useUserContext();
  const types = [
    { name: "Question Generation", value: "questionGeneration" },
    {
      name: "Smart+",
      value: "smartPlus",
    },
    {
      name: "Workflow",
      value: "workflow",
    },
  ];
  const workFlowNodes = [
    { name: "Decision Node", value: "decisionNode" },
    { name: "Input Node", value: "inputNode" },
    { name: "Conversational Node", value: "conversationalNode" },
    { name: "API Node", value: "apiNode" },
    { name: "Message With Options Node", value: "messageWithOptionsNode" },
  ];
  const [selectedType, setSelectedType] = useState(types[0]);
  const [selectedBot, setSelectedBot] = useState(allBot?.[0]);
  const [configuration, setConfiguration] = useState({
    inputNodeValidationType: "name",
  });
  const [workFlowTab, setWorkFlowTab] = useState(workFlowNodes[0]?.name);
  const [aiLabConfig, setAILabConfig] = useState();
  const [loader, setLoader] = useState(false);

  const handleSelectType = (newTypeIndex) => {
    setSelectedType(types[newTypeIndex]);
  };

  const handleSelectBot = (newBotId) => {
    const botDetails = allBot?.find((b) => b._id === newBotId);
    setSelectedBot(botDetails);
  };

  const handleSavePrompt = (newPrompt) => {
    setConfiguration((prevConfig) => {
      let newConfig = structuredClone(prevConfig);

      if (selectedType.value === "workflow" && workFlowTab === "Input Node") {
        newConfig[selectedType.value][_.camelCase(workFlowTab)].prompt[
          prevConfig.inputNodeValidationType
        ] = newPrompt;
      } else if (
        selectedType.value === "workflow" &&
        workFlowTab !== "Input Node"
      ) {
        newConfig[selectedType.value][_.camelCase(workFlowTab)].prompt =
          newPrompt;
      } else {
        newConfig[selectedType.value].prompt = newPrompt;
      }
      return newConfig;
    });
  };

  const handleSaveModelConfig = (modelConfig) => {
    setConfiguration((prevConfig) => {
      let newConfig = structuredClone(prevConfig);
      if (selectedType.value === "workflow") {
        newConfig[selectedType.value][_.camelCase(workFlowTab)].modelConfig = {
          model: modelConfig.model,
          temperature: modelConfig.temperature,
          outputTokens: modelConfig.outputTokens,
          candidateCount: modelConfig.candidateCount,
          top_p: modelConfig.top_p,
        };

        if (workFlowTab === "Input Node") {
          newConfig[selectedType.value][_.camelCase(workFlowTab)].example[
            newConfig.inputNodeValidationType
          ] = modelConfig.promptExample;
        }

        if (workFlowTab === "Decision Node") {
          newConfig[selectedType.value][_.camelCase(workFlowTab)].example =
            modelConfig.promptExample;
        }

        if (workFlowTab === "Conversational Node") {
          newConfig[selectedType.value][
            _.camelCase(workFlowTab)
          ].additionalConfig = {
            distance: modelConfig.distance,
            examples: modelConfig.examples,
          };
        }
      } else {
        newConfig[selectedType.value].modelConfig = {
          model: modelConfig.model,
          temperature: modelConfig.temperature,
          outputTokens: modelConfig.outputTokens,
          candidateCount: modelConfig.candidateCount,
          top_p: modelConfig.top_p,
        };
        newConfig[selectedType.value].example = modelConfig.promptExample;
      }
      newConfig.inputNodeValidationType = modelConfig.inputNodeValidationType;

      return newConfig;
    });
  };

  const getDefaultConfig = () => {
    let defaultData = {};
    if (selectedType.value === "workflow" && workFlowTab !== "Input Node") {
      defaultData.modelConfig =
        configuration[selectedType.value][_.camelCase(workFlowTab)].modelConfig;
      defaultData.modelConfig.promptExample =
        configuration[selectedType.value][_.camelCase(workFlowTab)].example;
      defaultData.additionalConfig =
        configuration[selectedType.value][
          _.camelCase(workFlowTab)
        ].additionalConfig;
    } else if (
      selectedType.value === "workflow" &&
      workFlowTab === "Input Node"
    ) {
      defaultData.modelConfig =
        configuration[selectedType.value][_.camelCase(workFlowTab)].modelConfig;
      defaultData.modelConfig.promptExample =
        configuration[selectedType.value][_.camelCase(workFlowTab)].example[
          configuration.inputNodeValidationType
        ];
    } else {
      defaultData.modelConfig =
        configuration?.[selectedType?.value]?.modelConfig;
      defaultData.modelConfig &&
        (defaultData.modelConfig.promptExample =
          configuration?.[selectedType?.value]?.example);
    }
    return defaultData;
  };

  const handleOnChangeApiNode = (newTimeOut) => {
    let newConfig = structuredClone(configuration);

    newConfig[selectedType.value][_.camelCase(workFlowTab)] = {
      timeout: newTimeOut,
    };

    setConfiguration(newConfig);
  };

  const handleOnChangeMessageWithOptionsNode = (messageWithOptionDistance) => {
    let newConfig = structuredClone(configuration);

    newConfig[selectedType.value][_.camelCase(workFlowTab)] = {
      distance: messageWithOptionDistance,
    };

    setConfiguration(newConfig);
  };

  const getApiNodeTimeOutData = () => {
    return (
      configuration[selectedType.value][_.camelCase(workFlowTab)]?.timeout ?? 4
    );
  };

  const getMessageWithOptionsNodeData = () => {
    return (
      configuration[selectedType.value][_.camelCase(workFlowTab)]?.distance ??
      0.17
    );
  };

  const handleTabChange = (event, newTab) => {
    setWorkFlowTab(newTab);
    // setConfiguration({ ...configuration, workFlowTab: newTab });
  };

  const getAILabConfig = async () => {
    try {
      const aiLabConfig = await getAILabsConfigApi(selectedBot?._id);
      setAILabConfig(aiLabConfig);
      setConfiguration({ ...configuration, ...aiLabConfig });
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const updateAILabConfig = async (configurationDetails) => {
    try {
      setLoader(true);
      await updateAILabsConfigApi(selectedBot?._id, configurationDetails);
      setLoader(false);
      showSkillrToast("Configuration updated", "success");
    } catch (error) {
      setLoader(false);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getFormattedData = (configDetails) => {
    const newData = structuredClone(configDetails);
    const timeoutValidationRegex = /^(?:[1-9]|[1-9][0-9]|1[01][0-9]|120)$/;

    if (!("apiNode" in newData?.workflow)) {
      newData.workflow.apiNode = { timeout: 4 };
    } else if (
      !timeoutValidationRegex.test(newData?.workflow?.apiNode?.timeout)
    ) {
      showSkillrToast("Please enter valid timeout for api node", "error");
      return;
    }

    if (!("messageWithOptionsNode" in newData?.workflow)) {
      newData.workflow.messageWithOptionsNode = { distance: 0.17 };
    } else if (
      isNaN(newData?.workflow?.messageWithOptionsNode?.distance) ||
      (!isNaN(newData?.workflow?.messageWithOptionsNode?.distance) &&
        (newData?.workflow?.messageWithOptionsNode?.distance < 0.1 ||
          newData?.workflow?.messageWithOptionsNode?.distance > 1))
    ) {
      showSkillrToast(
        "Message with Option node duration should be in between of 0.1 to 1",
        "error"
      );
      return;
    }

    newData.workflow.apiNode.timeout = +newData.workflow.apiNode.timeout;
    newData["smartPlus"].example = null;
    delete newData.inputNodeValidationType;
    return newData;
  };

  const handleOnSave = () => {
    const formattedData = getFormattedData(configuration);
    if (formattedData) {
      updateAILabConfig(formattedData);
    }
  };

  const getConfigurePrompt = () => {
    if (selectedType.value === "workflow" && workFlowTab === "Input Node") {
      return configuration[selectedType.value][_.camelCase(workFlowTab)].prompt[
        configuration.inputNodeValidationType
      ];
    } else if (
      selectedType.value === "workflow" &&
      workFlowTab !== "Input Node" &&
      configuration[selectedType.value][_.camelCase(workFlowTab)].prompt
    ) {
      return configuration?.[selectedType.value][_.camelCase(workFlowTab)]
        ?.prompt;
    } else {
      return configuration?.[selectedType?.value]?.prompt ?? "";
    }
  };

  useEffect(() => {
    if (!aiLabConfig && selectedBot) {
      getAILabConfig();
    }
  }, [selectedBot]);

  useEffect(() => {
    if (!selectedBot && allBot?.length) {
      setSelectedBot(allBot?.[0]);
    }
  }, [allBot]);

  return (
    <>
      <Box className={styles.headerContainer}>
        <FlexBox>
          <FundamentoPopup
            className={styles.typesPopup}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            triggeringComponent={
              <Button
                variant="outline"
                sx={{ borderRadius: 82, padding: "14px 24px", minWidth: 0 }}
                endIcon={<ChevronDown width={20} height={20} />}
              >
                {selectedType?.name}
              </Button>
            }
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                minWidth: "256px",
              }}
            >
              {types?.map((type, typeIndex) => (
                <FlexBox
                  columnGap={2}
                  component="label"
                  key={type.value}
                  className={styles.types}
                >
                  <Radio
                    checkedIcon={<RadioButton width={20} height={20} />}
                    icon={<Circle width={20} height={20} />}
                    value={type.value}
                    onChange={(e) => handleSelectType(typeIndex)}
                    checked={type.value === selectedType.value}
                    sx={{ padding: 0 }}
                  />
                  <Typography
                    variant="caption"
                    color={theme.palette.text.primary}
                    fontWeight={400}
                  >
                    {type.name}
                  </Typography>
                </FlexBox>
              ))}
            </Box>
          </FundamentoPopup>
        </FlexBox>
        <FlexBox sx={{ ml: "auto" }}>
          <FundamentoPopup
            className={styles.typesPopup}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            triggeringComponent={
              <Button
                variant="outlined"
                sx={{
                  borderRadius: 82,
                  padding: "14px 24px",
                  minWidth: 0,
                  fontSize: 12,
                  fontFamily: "Inter",
                }}
                endIcon={<ChevronDown width={20} height={20} />}
              >
                {selectedBot?.name}
              </Button>
            }
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {allBot?.map((bot) => (
                <FlexBox
                  columnGap={2}
                  component="label"
                  key={bot?._id}
                  className={styles.types}
                >
                  <Radio
                    checkedIcon={<RadioButton width={20} height={20} />}
                    icon={<Circle width={20} height={20} />}
                    value={bot?._id}
                    onChange={(e) => handleSelectBot(e.target.value)}
                    checked={bot?.id === selectedBot?._id}
                    sx={{ padding: 0 }}
                  />
                  <Typography
                    variant="caption"
                    color={theme.palette.text.primary}
                    fontWeight={400}
                  >
                    {bot?.name}
                  </Typography>
                </FlexBox>
              ))}
            </Box>
          </FundamentoPopup>

          <Button
            variant="dark"
            sx={{
              minWidth: "89px",
              fontSize: 12,
              lineHeight: "130%",
              fontFamily: "Inter",
            }}
            onClick={handleOnSave}
            disabled={loader}
          >
            Save
          </Button>
        </FlexBox>
      </Box>
      {/* <Divider sx={{ borderColor: theme.palette.borderColor.light }} /> */}

      {aiLabConfig ? (
        <FlexBox
          sx={{
            alignItems: "stretch",
            columnGap: 0,
            flex: 1,
            // height: "calc(100% - 74px)",
            // overflowY: "auto",
          }}
        >
          <FlexBox
            sx={{
              width: "70%",
              height: "100%",
            }}
            flexDirection="column"
            alignItems="flex-start"
          >
            {selectedType.name === "Workflow" && (
              <ConfigureWorkFlowNodes
                tabs={workFlowNodes}
                selectedTab={workFlowTab}
                onTabChange={handleTabChange}
              />
            )}

            {["API Node", "Message With Options Node"].indexOf(workFlowTab) ===
              -1 && (
              <ConfigurePrompt
                onSave={handleSavePrompt}
                prompt={getConfigurePrompt()}
              />
            )}

            {["API Node", "Message With Options Node"].indexOf(workFlowTab) >
              -1 &&
              (workFlowTab === "API Node" ? (
                <ApiNode
                  onSave={handleOnChangeApiNode}
                  timeout={getApiNodeTimeOutData()}
                  setLoader={setLoader}
                />
              ) : (
                <MessageWithOptionsNode
                  onSave={handleOnChangeMessageWithOptionsNode}
                  distance={getMessageWithOptionsNodeData()}
                  setLoader={setLoader}
                />
              ))}

            {/* <ConfigurePlayground /> */}
          </FlexBox>

          <Box
            sx={{
              width: "30%",
              borderLeft: `1px solid ${theme.palette.borderColor.light}`,
            }}
          >
            {["API Node", "Message With Options Node"].indexOf(workFlowTab) ===
              -1 && (
              <ConfigureModel
                onSave={handleSaveModelConfig}
                type={selectedType.name}
                workflow={workFlowTab}
                data={getDefaultConfig()}
              />
            )}
          </Box>
        </FlexBox>
      ) : (
        <Box className={styles.loaderContainer}>
          <Loader width={40} height={40} color={theme.palette.grey[100]} />
        </Box>
      )}
    </>
  );
};

export default Configure;
