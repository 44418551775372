import {
  Box,
  Button,
  Checkbox,
  Dialog,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import CustomStepper from "pages/Campaign/components/CustomStepper";
import { useEffect, useState } from "react";
import { ChevronDown, ChevronLeft, Loader, X } from "react-feather";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import theme from "theme";
import styles from "./index.module.css";
import getGoogleSheets from "services/getGoogleSheets";
import { useSkillrToast } from "context/toast";
import getWorksheets from "services/getWorksheets";
import getColumnsInWorksheet from "services/getColumnsInWorksheet";
import VariableDropdown from "pages/Diagram/components/Sidebars/VariableDropdown";
import { useUserContext } from "context/user";
import { createExportEvent, getCampaigns } from "services";

const CreateEventModal = ({ open, onClose, integrations, onConfirm }) => {
  const [step, setStep] = useState(1);
  const [googleSheets, setGoogleSheets] = useState([]);
  const [worksheets, setWorksheets] = useState([]);
  const [columns, setColumns] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);

  const { showSkillrToast } = useSkillrToast();
  const { allBot } = useUserContext();
  const {
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const { fields } = useFieldArray({
    control,
    name: "variableMappings",
  });

  const handleClose = () => {
    setStep(1);
    reset();
    onClose();
  };

  const handleSave = async () => {
    try {
      let formData = getValues();
      let finalFormData = {};

      const selectedBots = formData.bots
        ? Object.keys(formData.bots).filter((key) => formData.bots[key])
        : [];

      const selectedCampaigns = formData.campaigns
        ? Object.keys(formData.campaigns).filter(
            (key) => formData.campaigns[key]
          )
        : [];

      finalFormData.bots = selectedBots;
      finalFormData.campaigns = selectedCampaigns;

      finalFormData.callType = [];
      if (formData.inbound) {
        finalFormData.callType.push("inbound");
      }

      if (formData.outbound) {
        finalFormData.callType.push("outbound");
      }

      // convert variableMappings from array of objects to object
      let variableMappings = {};
      formData.variableMappings.forEach((mapping) => {
        variableMappings = { ...variableMappings, ...mapping };
      });

      finalFormData = {
        name: formData.eventName,
        type: "google-sheet",
        integration: formData.selectedAccount._id,
        details: {
          sheetId: formData.selectedGoogleSheet.id,
          tabName: formData.selectedWorksheet.title,
        },
        variableMappings: variableMappings,
        bots: selectedBots,
        campaigns: selectedCampaigns,
        callType: finalFormData.callType,
      };

      if (finalFormData.callType.length === 0) {
        showSkillrToast("Please select at least one call type", "error");
        return;
      }

      if (selectedBots.length === 0 && selectedCampaigns.length === 0) {
        showSkillrToast("Please select at least one bot or campaign", "error");
        return;
      }

      const response = await createExportEvent(finalFormData);
      if (response) {
        showSkillrToast("Event created successfully", "success");
        onConfirm();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleNext = async () => {
    if (step === 1) {
      // show error if no variableMappings value present
      const hasAnyValue = watch("variableMappings").some((field) => {
        // Get the first key-value pair from the mapping object
        const [key, value] = Object.entries(field)[0] || [];
        return value && value.trim() !== "";
      });

      if (!hasAnyValue) {
        showSkillrToast("Please map at least one variable", "error");
        return;
      }

      handleSubmit(async (data) => {
        if (Object.keys(errors).length === 0) {
          setStep(2);
        }
      })();
    } else {
      handleSave();
    }
  };

  const getAllSheetsFromAccount = async () => {
    setLoading(true);
    try {
      const response = await getGoogleSheets(watch("selectedAccount")._id);
      setGoogleSheets(response);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
      console.log(error);
    }
  };

  const getAllWorksheets = async () => {
    setLoading(true);
    try {
      const response = await getWorksheets({
        integrationId: watch("selectedAccount")._id,
        sheetId: watch("selectedGoogleSheet").id,
      });
      setWorksheets(response);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
      console.log(error);
    }
  };

  const getAllColumns = async () => {
    try {
      const response = await getColumnsInWorksheet({
        integrationId: watch("selectedAccount")._id,
        sheetId: watch("selectedGoogleSheet").id,
        tabName: watch("selectedWorksheet").title,
      });
      setColumns(response);
      return response;
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
      console.log(error);
    }
  };

  const getAllCampaigns = async () => {
    try {
      const queryParams = new URLSearchParams();
      // queryParams.set("offset", (currentPage - 1) * countPerPage);
      // queryParams.set("count", countPerPage);
      const response = await getCampaigns(queryParams.toString());
      setAllCampaigns(response.data);
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  useEffect(() => {
    if (watch("selectedAccount")) {
      getAllSheetsFromAccount();
    }
    setValue("selectedGoogleSheet", null);
  }, [watch("selectedAccount")]);

  useEffect(() => {
    if (watch("selectedGoogleSheet")?.id) {
      getAllWorksheets();
    }
    setValue("selectedWorksheet", null);
  }, [watch("selectedGoogleSheet")]);

  useEffect(() => {
    if (watch("selectedWorksheet")?.title) {
      setValue("variableMappings", []);
      getAllColumns();
    }
  }, [watch("selectedWorksheet")]);

  useEffect(() => {
    if (open) {
      getAllCampaigns();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: 5,
          padding: 6,
          boxShadow: 10,
          width: 800,
          position: "relative",
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", top: 20, right: 20 }}
      >
        <X width={20} height={20} />
      </IconButton>
      <Box>
        <Typography mb={8} variant="h4" fontWeight={500} textAlign="center">
          Create Event
        </Typography>

        <Box className={styles.stepper}>
          <CustomStepper
            step={step - 1}
            steps={["Event Details", "Assign Event"]}
          />
        </Box>

        {step === 1 && (
          <>
            <Controller
              name="eventName"
              control={control}
              rules={{
                required: "Event name is required",
                maxLength: {
                  value: 30,
                  message: "Name should be less than 30 characters",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  placeholder="Enter event name"
                  variant="outlined"
                  fullWidth
                  sx={{ borderRadius: "12px" }}
                  error={errors?.eventName}
                  helperText={errors?.eventName?.message}
                />
              )}
            />

            <Controller
              name="selectedAccount"
              control={control}
              rules={{ required: "Google Account is required" }}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  IconComponent={ChevronDown}
                  sx={{
                    borderRadius: 4,
                    "& li": {
                      fontSize: 12,
                    },
                    mt: 4,
                  }}
                  fullWidth
                  className={styles.select}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        borderRadius: 0,
                        boxShadow: "none",
                        backgroundColor: theme.palette.grey[50],
                      },
                    },
                  }}
                  displayEmpty
                  renderValue={(value) => {
                    if (!value) {
                      return (
                        <Typography
                          variant="caption"
                          // color={theme.palette.grey[800]}
                          color="#919191"
                          fontWeight={400}
                        >
                          Select Google Account
                        </Typography>
                      );
                    } else {
                      return (
                        <Typography
                          variant="caption"
                          color={theme.palette.grey[800]}
                        >
                          {value.name}
                        </Typography>
                      );
                    }
                  }}
                >
                  {loading ? (
                    <MenuItem>
                      <Typography
                        variant="caption"
                        color={theme.palette.grey[800]}
                        textAlign={"center"}
                      >
                        <Loader
                          width={12}
                          height={12}
                          color={theme.palette.grey[600]}
                        />
                      </Typography>
                    </MenuItem>
                  ) : (
                    integrations?.map(
                      (integration) =>
                        integration.type === "google-sheet" && (
                          <MenuItem key={integration._id} value={integration}>
                            <Typography
                              variant="caption"
                              color={theme.palette.grey[800]}
                            >
                              {integration.name}
                            </Typography>
                          </MenuItem>
                        )
                    )
                  )}
                </Select>
              )}
            />
            {errors?.selectedAccount && (
              <Typography color="error" variant="caption">
                {errors?.selectedAccount?.message}
              </Typography>
            )}

            {watch("selectedAccount") && (
              <>
                <Controller
                  name="selectedGoogleSheet"
                  control={control}
                  rules={{ required: "Google Sheet is required" }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      onChange={onChange}
                      IconComponent={ChevronDown}
                      sx={{
                        borderRadius: 4,
                        "& li": {
                          fontSize: 12,
                        },
                        mt: 4,
                      }}
                      fullWidth
                      className={styles.select}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            borderRadius: 0,
                            boxShadow: "none",
                            backgroundColor: theme.palette.grey[50],
                          },
                        },
                      }}
                      displayEmpty
                      renderValue={(value) => {
                        if (!value) {
                          return (
                            <Typography
                              variant="caption"
                              // color={theme.palette.grey[800]}
                              color="#919191"
                              fontWeight={400}
                            >
                              Select Google Sheet
                            </Typography>
                          );
                        } else {
                          return (
                            <Typography
                              variant="caption"
                              color={theme.palette.grey[800]}
                            >
                              {value?.name}
                            </Typography>
                          );
                        }
                      }}
                    >
                      {loading ? (
                        <MenuItem>
                          <Typography
                            variant="caption"
                            color={theme.palette.grey[800]}
                            textAlign={"center"}
                          >
                            <Loader
                              width={12}
                              height={12}
                              color={theme.palette.grey[600]}
                            />
                          </Typography>
                        </MenuItem>
                      ) : (
                        googleSheets?.map((sheet) => (
                          <MenuItem value={sheet} key={sheet.id}>
                            <Typography
                              variant="caption"
                              color={theme.palette.grey[800]}
                            >
                              {sheet.name}
                            </Typography>
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  )}
                />
                {errors?.selectedGoogleSheet && (
                  <Typography color="error" variant="caption">
                    {errors?.selectedGoogleSheet?.message}
                  </Typography>
                )}
              </>
            )}

            {watch("selectedGoogleSheet") && (
              <>
                <Controller
                  name="selectedWorksheet"
                  control={control}
                  rules={{ required: "Worksheet is required" }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      onChange={onChange}
                      IconComponent={ChevronDown}
                      sx={{
                        borderRadius: 4,
                        "& li": {
                          fontSize: 12,
                        },
                        mt: 4,
                      }}
                      fullWidth
                      className={styles.select}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            borderRadius: 0,
                            boxShadow: "none",
                            backgroundColor: theme.palette.grey[50],
                          },
                        },
                      }}
                      displayEmpty
                      renderValue={(value) => {
                        if (!value) {
                          return (
                            <Typography
                              variant="caption"
                              // color={theme.palette.grey[800]}
                              color="#919191"
                              fontWeight={400}
                            >
                              Select Work Sheet
                            </Typography>
                          );
                        } else {
                          return (
                            <Typography
                              variant="caption"
                              color={theme.palette.grey[800]}
                            >
                              {value?.title}
                            </Typography>
                          );
                        }
                      }}
                    >
                      {loading ? (
                        <MenuItem>
                          <Typography
                            variant="caption"
                            color={theme.palette.grey[800]}
                            textAlign={"center"}
                          >
                            <Loader
                              width={12}
                              height={12}
                              color={theme.palette.grey[600]}
                            />
                          </Typography>
                        </MenuItem>
                      ) : (
                        worksheets?.map((sheet) => (
                          <MenuItem value={sheet} key={sheet.id}>
                            <Typography
                              variant="caption"
                              color={theme.palette.grey[800]}
                            >
                              {sheet.title}
                            </Typography>
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  )}
                />
                {errors?.selectedWorksheet && (
                  <Typography color="error" variant="caption">
                    {errors?.selectedWorksheet?.message}
                  </Typography>
                )}
              </>
            )}

            {watch("selectedWorksheet") && (
              <>
                <Typography variant="body2" fontWeight={500} mt={8}>
                  Variable Mapping
                </Typography>

                {columns?.map(
                  (column, index) =>
                    column.length > 0 && (
                      <Box sx={{ mt: 4 }} key={index}>
                        <Typography
                          variant="caption"
                          component="div"
                          fontWeight={500}
                          color="text.primary"
                        >
                          {column}
                        </Typography>

                        <Controller
                          name={`variableMappings.${index}.${column}`}
                          control={control}
                          // rules={{ required: "Value is required" }}
                          render={({ field: { onChange, value } }) => (
                            <VariableDropdown
                              control={control}
                              watch={watch}
                              setValue={setValue}
                              value={value}
                              onChange={onChange}
                            >
                              <TextField
                                fullWidth
                                onChange={onChange}
                                value={value}
                                sx={{ mt: 2 }}
                              />
                            </VariableDropdown>
                          )}
                        />
                        {errors?.variableMappings?.[index]?.[column] && (
                          <Typography color="error" variant="caption">
                            {
                              errors?.variableMappings?.[index]?.[column]
                                ?.message
                            }
                          </Typography>
                        )}
                      </Box>
                    )
                )}
              </>
            )}
          </>
        )}

        {step === 2 && (
          <>
            <Box className={styles.container}>
              <Typography variant="subtitle1" fontWeight={500}>
                Data to Export
              </Typography>

              <FlexBox>
                <FlexBox
                  component="label"
                  sx={{ cursor: "pointer" }}
                  columnGap={1}
                >
                  <Controller
                    name="inbound"
                    control={control}
                    defaultValue={true}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox checked={value} onChange={onChange} />
                    )}
                  />
                  <Typography
                    variant="caption"
                    fontWeight={400}
                    color={"text.primary"}
                  >
                    Inbound
                  </Typography>
                </FlexBox>
                <FlexBox
                  component="label"
                  sx={{ cursor: "pointer" }}
                  columnGap={1}
                >
                  <Controller
                    name="outbound"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox checked={value} onChange={onChange} />
                    )}
                  />
                  <Typography
                    variant="caption"
                    fontWeight={400}
                    color={"text.primary"}
                  >
                    Outbound
                  </Typography>
                </FlexBox>
              </FlexBox>

              {watch("inbound") && (
                <>
                  <Typography mt={4} variant="subtitle1" fontWeight={500}>
                    Bots
                  </Typography>

                  <FlexBox>
                    {allBot?.map((bot) => (
                      <FlexBox
                        component="label"
                        sx={{ cursor: "pointer" }}
                        columnGap={1}
                        key={bot._id}
                      >
                        <Controller
                          name={`bots.${bot._id}`}
                          control={control}
                          defaultValue={true}
                          render={({ field: { onChange, value } }) => (
                            <Checkbox checked={value} onChange={onChange} />
                          )}
                        />
                        <Typography
                          variant="caption"
                          fontWeight={400}
                          color={"text.primary"}
                        >
                          {bot.name}
                        </Typography>
                      </FlexBox>
                    ))}
                  </FlexBox>
                </>
              )}

              {watch("outbound") && (
                <>
                  <Typography mt={4} variant="subtitle1" fontWeight={500}>
                    Campaigns
                  </Typography>

                  <Box sx={{ columnCount: 2 }}>
                    {allCampaigns?.map((campaign) => (
                      <FlexBox
                        component="label"
                        sx={{ cursor: "pointer" }}
                        columnGap={1}
                        key={campaign._id}
                      >
                        <Controller
                          name={`campaigns.${campaign._id}`}
                          control={control}
                          defaultValue={true}
                          render={({ field: { onChange, value } }) => (
                            <Checkbox checked={value} onChange={onChange} />
                          )}
                        />
                        <Typography
                          variant="caption"
                          fontWeight={400}
                          color={"text.primary"}
                        >
                          {campaign.name}
                        </Typography>
                      </FlexBox>
                    ))}
                  </Box>
                </>
              )}
            </Box>
          </>
        )}
      </Box>

      <FlexBox sx={{ mt: 4 }} justifyContent="flex-end">
        {step === 2 && (
          <Button
            variant="text"
            startIcon={<ChevronLeft width={16} height={16} />}
            sx={{ marginRight: "auto" }}
            color="inherit"
            onClick={() => setStep(1)}
          >
            Back
          </Button>
        )}

        <Button
          variant="whiteRound"
          className={`${styles.modalButton} ${styles.bordered}`}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="dark"
          className={styles.modalButton}
          onClick={handleNext}
        >
          {step === 1 ? "Next" : "Save"}
        </Button>
      </FlexBox>
    </Dialog>
  );
};

export default CreateEventModal;
