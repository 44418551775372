import { Box, Button, Fade, IconButton, Typography } from "@mui/material";
import styles from "./index.module.css";
import FlexBox from "components/FlexBox";
import { X, Zap } from "react-feather";
import theme from "theme";
import FunCollapse from "components/FunCollapse";
import { FlowArrow, PencilSimple } from "components/newSVG";
import EditContainer from "./components/EditContainer";
import { useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { gscUriPrefix } from "text-constants/common";
import AudioPlayer from "components/AudioPlayer";
import CallDetail from "pages/Calls/container/CallDetail";

const HistoryDetail = () => {
  const { advancedModeValue } = useOutletContext();

  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;
  const [editContainer, setEditContainer] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const token = localStorage.getItem("token");

  return (
    <Fade
      in={true}
      timeout={300}
      exit={false}
      unmountOnExit
      mountOnEnter
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        flex: 1,
      }}
    >
      <div>
        <CallDetail
          advancedModeValue={advancedModeValue}
          playgroundHistory={true}
        />
      </div>
    </Fade>
  );
};

export default HistoryDetail;
