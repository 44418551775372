import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import { Trash } from "components/newSVG";
import { AlertTriangle, X, XCircle } from "react-feather";
import theme from "theme";
import styles from "./index.module.css";

const DraftWarningModal = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          width: 500,
          borderRadius: 20,
        },
      }}
    >
      <Box sx={{ textAlign: "center", position: "relative", padding: 10 }}>
        <IconButton className={styles.close} onClick={onClose}>
          <X width={20} height={20} />
        </IconButton>
        <Box className={styles.iconContainer}>
          <AlertTriangle color="#FBC400" width={40} height={40} />
        </Box>

        <Typography variant="h3" fontWeight={500} mt={4}>
          Phone Number Unavailable
        </Typography>

        <Typography variant="body2" my={2} color="grey.600">
          The phone number assigned to this campaign has been deleted. Please
          update the phone number to proceed.
        </Typography>

        <Box
          mt={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "22px",
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.yellow[400],
              "&:hover": {
                backgroundColor: theme.palette.yellow[900],
              },
            }}
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            Proceed
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DraftWarningModal;
