import { Box, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import theme from "theme";
import styles from "./index.module.css";
import { languages } from "text-constants/common";
import { ChevronDown } from "react-feather";
import { editBot, updateCompany } from "services";
import {
  checkIfKBLanguageCanChange as checkIfKBLanguageCanChangeAPI,
  getVoices as getVoicesAPI,
} from "services";
import { useSkillrToast } from "context/toast";
import { useUserContext } from "context/user";
import { useEffect, useState } from "react";

const SystemConfiguration = () => {
  const { showSkillrToast } = useSkillrToast();
  const { getCompanyDetails, getBot, bot, companyDetails } = useUserContext();
  const [language, setLanguage] = useState("");
  const [botVoices, setBotVoices] = useState();
  const [allowKBLanguageChange, setAllowKBLanguageChange] = useState(false);
  const [voices, setVoices] = useState([]);

  const getVoices = async () => {
    try {
      const response = await getVoicesAPI();
      setVoices(response.voiceData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLanguageChange = async (e) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);

    try {
      const response = await updateCompany({ KBLanguage: newLanguage });
      if (response) {
        showSkillrToast("Language updated successfully", "success");
      }
      checkBotVoices(newLanguage);
    } catch (error) {
      showSkillrToast("Failed to update language", "error");
    }
  };

  const checkBotVoices = async (language) => {
    if (botVoices) {
      const selectedLanguage = botVoices.find(
        (voice) => voice.languageCode === language
      );

      if (!selectedLanguage) {
        try {
          const response = await editBot(bot._id, {
            ...bot,
            botVoices: [
              ...bot.botVoices.map((voice) => ({
                ...voice,
                default: false,
              })),
              {
                languageCode: language,
                name: voices.find((voice) => voice.languageCode === language)
                  .name,
                default: true,
              },
            ],
          });

          if (response) {
            // showSkillrToast("Bot voices updated successfully", "success");
            console.log("Bot voices updated successfully");
          } else {
            // showSkillrToast("Failed to update bot voices", "error");
            console.log("Failed to update bot voices");
          }
        } catch (error) {
          console.log("Failed to update bot voices");
        }
      } else {
        const response = await editBot(bot._id, {
          ...bot,
          botVoices: bot.botVoices.map((voice) => {
            if (voice.languageCode === language) {
              return { ...voice, default: true };
            }
            return { ...voice, default: false };
          }),
        });

        if (response) {
          showSkillrToast("Bot voices updated successfully", "success");
        } else {
          showSkillrToast("Failed to update bot voices", "error");
        }
      }
    }
  };

  const checkIfKBlanguageCanChange = async () => {
    try {
      const response = await checkIfKBLanguageCanChangeAPI();
      if (response) {
        setAllowKBLanguageChange(response.canUserChangeKBLanguage);
      }
    } catch (error) {
      console.log("Failed to check if KB language can change");
    }
  };

  useEffect(() => {
    if (companyDetails?.KBLanguage) {
      setLanguage(companyDetails.KBLanguage);
    } else {
      setLanguage("");
    }
  }, [companyDetails]);

  useEffect(() => {
    if (bot) {
      setBotVoices(bot.botVoices);
    }
  }, [bot]);

  useEffect(() => {
    checkIfKBlanguageCanChange();
    getBot();
    getCompanyDetails();
    getVoices();
  }, []);

  return (
    <Box p={12}>
      <Box
        sx={{
          p: 6,
          border: `1px solid ${theme.palette.borderColor.light}`,
          borderRadius: 3,
        }}
      >
        <Typography variant="body1" fontWeight={500}>
          KB Language
        </Typography>

        <Typography variant="body2" color="grey.600" mt={2}>
          The default language used for the Knowledge Base (KB) responses. This
          language will be used to provide answers and information from the KB
          unless another language is specified or auto-detection is enabled.
        </Typography>
        <Tooltip
          arrow
          title={
            !allowKBLanguageChange &&
            "KB language can only be changed when you have no data in the KB nor any custom workflows"
          }
        >
          <Select
            disabled={!allowKBLanguageChange}
            value={language}
            onChange={handleLanguageChange}
            fullWidth
            placeholder="Select a language"
            className={styles.select}
            displayEmpty
            sx={{
              mt: 4,
              maxWidth: "40%",
              "& svg": {
                width: "20px",
                height: "20px",
                color: `${theme.palette.grey[600]} !important`,
                top: "calc(50% - 10px)",
              },
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  borderRadius: 0,
                  boxShadow: "none",
                  backgroundColor: theme.palette.grey[50],
                  maxHeight: "40vh",
                },
              },
            }}
            IconComponent={ChevronDown}
            renderValue={(selected) => {
              if (!selected) {
                return (
                  <Typography
                    variant="caption"
                    fontWeight={400}
                    color="inherit"
                  >
                    Select a language
                  </Typography>
                );
              } else {
                return (
                  <Typography
                    variant="caption"
                    fontWeight={400}
                    color="inherit"
                  >
                    {languages.find((l) => l.value === selected)?.name ||
                      "Select a language"}
                  </Typography>
                );
              }
            }}
          >
            {languages.map((l) => (
              <MenuItem
                value={l.value}
                sx={{ gap: 3 }}
                key={l.value}
                classes={{ selected: styles.selected }}
              >
                <Typography variant="body2">{l.name}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default SystemConfiguration;
