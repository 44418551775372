import styles from "./index.module.css";
import { Box, Button, IconButton, Typography } from "@mui/material";
import theme from "theme";
import FunCollapse from "components/FunCollapse";
import {
  IndiaFlag,
  USFlag,
  BahasaFlag,
  SpanishFlag,
  ArabFlag,
  GermanyFlag,
  JapanFlag,
  ChinaFlag,
  ChatBubble,
} from "components/newSVG";
import { IconGoogle } from "components/SVG";
import FundamentoPopup from "components/FundamentoPopup";
import { ChevronDown, Plus, RefreshCw, Settings, Trash2 } from "react-feather";
import avatarMale from "assets/images/voice-male.png";
import avatarFemale from "assets/images/voice-female.png";
import avatarNeutral from "assets/images/voice-neutral.png";
import elevenLabs from "assets/images/elevenLabs.png";
import sarvam from "assets/images/sarvam.png";
import smallest from "assets/images/smallest.jpeg";
import AddLanguageModal from "pages/Bots/components/AddLanguageModal";
import { useEffect, useState } from "react";
import FlexBox from "components/FlexBox";
import ChangeVoiceModal from "../../components/ChangeVoiceModal";
import ConfigureModal from "../../components/ConfigureModal";
import ManagePronunciationModal from "../../components/ManagePronunciationModal";
import { useUserContext } from "context/user";
import { languages } from "text-constants/common";
import { editBot, getVoices as getVoicesAPI } from "services";
import { useSkillrToast } from "context/toast";
import DeleteModal from "components/DeleteModal";
import ConversationManagement from "pages/Bots/components/ConversationManagement";

const BotPersonality = (props) => {
  const { onSave } = props;
  const [addLanguageModal, setAddLanguageModal] = useState(false);
  const [changeVoiceModal, setChangeVoiceModal] = useState(false);
  const [configureModal, setConfigureModal] = useState(false);
  const [managePronunciationModal, setManagePronunciationModal] =
    useState(false);
  const { allBot, getAllBot } = useUserContext();
  const [bot, setBot] = useState();
  const [botVoices, setBotVoices] = useState();
  const { showSkillrToast } = useSkillrToast();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteVoice, setDeleteVoice] = useState();
  const [currentVoice, setCurrentVoice] = useState();
  const [voices, setVoices] = useState([]);
  const { companyDetails } = useUserContext();
  const preProcessMessage = companyDetails?.preProcessMessage ?? false;
  const [botDetails, setBotDetails] = useState({});

  const languageImageMapping = {
    "en-US": <USFlag />,
    "hi-IN": <IndiaFlag />,
    "id-ID": <BahasaFlag />,
    "es-ES": <SpanishFlag />,
    "mr-IN": <IndiaFlag />,
    "ml-IN": <IndiaFlag />,
    "pa-IN": <IndiaFlag />,
    "ta-IN": <IndiaFlag />,
    "bn-IN": <IndiaFlag />,
    "te-IN": <IndiaFlag />,
    "gu-IN": <IndiaFlag />,
    "ar-XA": <ArabFlag />,
    "de-DE": <GermanyFlag />,
    "ja-JP": <JapanFlag />,
    "cmn-CN": <ChinaFlag />,
  };

  // const handleUpdate = (updatedData) => {
  //   setBotDetails({ ...botDetails, ...updatedData });
  // };

  const handleEditBot = async (data) => {
    try {
      const response = await editBot(bot._id, data);
      setBot(response.data);
      showSkillrToast("Bot updated successfully", "success");
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddLanguage = async (selectedLanguage) => {
    // find a voice from voice library filtered by google to add as default
    const languageName = voices.find(
      (l) => l.languageCode === selectedLanguage && l.provider === "google"
    ).name;
    if (
      selectedLanguage &&
      !botVoices.some((voice) => voice.languageCode === selectedLanguage)
    ) {
      const newBotVoices = [
        ...botVoices,
        {
          languageCode: selectedLanguage,
          name: languageName,
          default: false,
          provider: voices.find((l) => l.languageCode === selectedLanguage)
            .provider,
        },
      ];
      setBotVoices(newBotVoices);
      setAddLanguageModal(false);
      handleEditBot({ botVoices: newBotVoices });
    }
  };

  const handleDeleteVoice = (languageCode) => {
    const updatedBotVoices = botVoices.filter(
      (v) => v.languageCode !== languageCode
    );
    setBotVoices(updatedBotVoices);
    handleEditBot({ botVoices: updatedBotVoices });
  };

  const getVoices = async () => {
    try {
      const response = await getVoicesAPI();
      setVoices(response.voiceData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnSaveChangeVoice = (typeOfSave) => {
    getVoices();
    getAllBot();
    if (typeOfSave === "voiceSelected") {
      setChangeVoiceModal(false);
    }
  };

  const handleOnSaveConfigureModal = () => {
    getVoices();
    getAllBot();
    setConfigureModal(false);
  };

  useEffect(() => {
    getVoices();
    getAllBot();
  }, []);

  useEffect(() => {
    if (allBot && allBot[0]) {
      setBot(allBot[0]);
      setBotVoices(allBot[0].botVoices);
    }
  }, [allBot]);

  return (
    <>
      <Box className={styles.container}>
        <div>
          <Typography variant="body2" fontWeight={500}>
            Pick the virtual agent voice
          </Typography>
          <Typography variant="body2" color="grey.600">
            Choose how you want your agent to sound
          </Typography>

          {bot &&
            voices &&
            bot?.botVoices?.map((voice, index) => (
              <Box className={styles.collapsible} key={index}>
                <FunCollapse
                  header={
                    <>
                      <FlexBox columnGap={2}>
                        <Typography variant="body2" fontWeight={500}>
                          {
                            languages.find(
                              (item) => item.value === voice.languageCode
                            ).name
                          }
                        </Typography>
                        {languageImageMapping[voice.languageCode]}
                        {voice.default && (
                          <Box className={styles.label}>Default</Box>
                        )}

                        {!voice.default && !preProcessMessage && (
                          <IconButton
                            sx={{
                              ml: "auto",
                            }}
                            size="small"
                            onClick={() => {
                              setDeleteModal(true);
                              setDeleteVoice(voice);
                            }}
                          >
                            <Trash2 width={16} height={16} />
                          </IconButton>
                        )}
                      </FlexBox>
                    </>
                  }
                  iconPosition={"end"}
                >
                  <FlexBox
                    sx={{
                      mt: 3,
                      p: 4,
                      borderRadius: 3,
                      border: `1px solid ${theme.palette.borderColor.light}`,
                    }}
                  >
                    <img
                      src={
                        voices.find((v) => v.name === voice.name)?.gender ===
                        "FEMALE"
                          ? avatarFemale
                          : voices.find((v) => v.name === voice.name)
                              ?.gender === "MALE"
                          ? avatarMale
                          : voices.find((v) => v.name === voice.name)
                              ?.gender === "NEUTRAL"
                          ? avatarNeutral
                          : avatarNeutral
                      }
                      alt="avatar"
                      width={56}
                      height={56}
                    />
                    <Box>
                      <FlexBox columnGap={2}>
                        <Typography variant="body2" fontWeight={500}>
                          {voices.find((v) => v.name === voice.name)
                            ?.displayName || voice.name}
                        </Typography>
                        <Box className={styles.label}>Default</Box>
                      </FlexBox>

                      <Typography variant="body2" color="grey.600">
                        {voices.find((v) => v.name === voice.name)
                          ?.description || ""}
                      </Typography>

                      <FlexBox columnGap={1}>
                        {voices.find((v) => v.name === voice.name)?.provider ===
                        "google" ? (
                          <IconGoogle width={11} height={11} />
                        ) : voices.find((v) => v.name === voice.name)
                            ?.provider === "elevenLabs" ? (
                          <img
                            src={elevenLabs}
                            width={11}
                            height={11}
                            alt="elevenLabs"
                          />
                        ) : voices.find((v) => v.name === voice.name)
                            ?.provider === "sarvam" ? (
                          <img
                            src={sarvam}
                            width={11}
                            height={11}
                            alt="sarvam"
                          />
                        ) : voices.find((v) => v.name === voice.name)
                            ?.provider === "smallest" ? (
                          <img
                            src={smallest}
                            width={11}
                            height={11}
                            alt="smallest"
                          />
                        ) : null}

                        <Typography fontSize={10} color="grey.400">
                          {voices.find((v) => v.name === voice.name)
                            ?.provider === "google"
                            ? "Google"
                            : voices.find((v) => v.name === voice.name)
                                ?.provider === "elevenLabs"
                            ? "Eleven Labs"
                            : voices.find((v) => v.name === voice.name)
                                ?.provider === "sarvam"
                            ? "Sarvam"
                            : voices.find((v) => v.name === voice.name)
                                ?.provider === "smallest"
                            ? "Smallest AI"
                            : voices.find((v) => v.name === voice.name)
                                ?.provider}
                        </Typography>
                      </FlexBox>
                    </Box>
                    {!preProcessMessage && (
                      <Box ml="auto">
                        <FundamentoPopup
                          className={styles.popup}
                          triggeringComponent={
                            <Button
                              variant="outlined"
                              className={styles.actionButton}
                              endIcon={<ChevronDown width={12} height={12} />}
                            >
                              Actions
                            </Button>
                          }
                        >
                          <Box>
                            <FlexBox
                              className={styles.popupItem}
                              onClick={() => {
                                setChangeVoiceModal(true);
                                setCurrentVoice(voice);
                              }}
                            >
                              <RefreshCw
                                color={theme.palette.grey[600]}
                                width={16}
                                height={16}
                              />
                              <Typography variant="body2" color={"grey.600"}>
                                Change Voice
                              </Typography>
                            </FlexBox>
                            <FlexBox
                              className={styles.popupItem}
                              onClick={() => {
                                setConfigureModal(true);
                                setCurrentVoice(voice);
                              }}
                            >
                              <Settings
                                color={theme.palette.grey[600]}
                                width={16}
                                height={16}
                              />
                              <Typography variant="body2" color={"grey.600"}>
                                Configure
                              </Typography>
                            </FlexBox>
                            <FlexBox
                              className={styles.popupItem}
                              onClick={() => {
                                setManagePronunciationModal(true);
                                setCurrentVoice(voice);
                              }}
                            >
                              <ChatBubble
                                color={theme.palette.grey[600]}
                                width={16}
                                height={16}
                              />
                              <Typography variant="body2" color={"grey.600"}>
                                Manage Pronunciation
                              </Typography>
                            </FlexBox>
                          </Box>
                        </FundamentoPopup>
                        {/* <FlexBox mt={1} columnGap={1}>
                        <Typography variant="caption" color="text.primary">
                          Set as default
                        </Typography>
                        <Switch size="small" />
                      </FlexBox> */}
                      </Box>
                    )}
                  </FlexBox>
                </FunCollapse>
              </Box>
            ))}

          <Button
            color="inherit"
            variant="text"
            endIcon={<Plus width={20} height={20} />}
            sx={{ minWidth: 0, width: "max-content", mt: 2 }}
            onClick={() => setAddLanguageModal(true)}
          >
            Add language
          </Button>

          <AddLanguageModal
            open={addLanguageModal}
            onClose={() => setAddLanguageModal(false)}
            onConfirm={(selectedLanguage) => {
              handleAddLanguage(selectedLanguage);
            }}
            botVoices={botVoices}
          />

          <ChangeVoiceModal
            open={changeVoiceModal}
            onClose={() => setChangeVoiceModal(false)}
            currentLanguage={currentVoice?.languageCode}
            bot={bot}
            voices={voices}
            onSave={handleOnSaveChangeVoice}
            voice={currentVoice}
          />

          <ConfigureModal
            open={configureModal}
            onClose={() => {
              setConfigureModal(false);
              setCurrentVoice();
            }}
            voice={currentVoice}
            voices={voices}
            currentLanguage={currentVoice?.languageCode}
            bot={bot}
            onSave={handleOnSaveConfigureModal}
          />

          <ManagePronunciationModal
            open={managePronunciationModal}
            onClose={() => {
              setManagePronunciationModal(false);
              setCurrentVoice();
            }}
            voice={currentVoice}
            voices={voices}
            bot={bot}
            getVoices={getVoices}
            currentLanguage={currentVoice?.languageCode}
          />

          <DeleteModal
            title="Are you sure you want to delete this voice?"
            open={deleteModal}
            onClose={() => setDeleteModal(false)}
            onConfirm={() => handleDeleteVoice(deleteVoice.languageCode)}
          />
        </div>
      </Box>

      <Box className={styles.container} mb={8}>
        <ConversationManagement
          data={bot?.fillersConfig}
          onSave={(data) => onSave(data)}
        />
      </Box>
    </>
  );
};

export default BotPersonality;
