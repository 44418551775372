import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Grow,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import { Minus, Plus, Trash2 } from "react-feather";
import PropTypes from "prop-types";
import theme from "theme";
import { CheckSquare, TextFlatMp3, TextFlatWav } from "components/newSVG";
import AddFillerModal from "../AddFillerModal";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import React, { useEffect } from "react";
import AudioPlayer from "../AudioPlayer";
import { gscUriPrefix } from "text-constants/common";
import FlexBox from "components/FlexBox";
import { IconInfo2 } from "components/SVG";
import { useSkillrToast } from "context/toast";

const filesType = {
  "audio/mpeg": <TextFlatMp3 width={24} height={32} />,
  "audio/wav": <TextFlatWav width={24} height={32} />,
};

const ConversationManagement = (props) => {
  const { data, onSave } = props;
  const { showSkillrToast } = useSkillrToast();

  const { control, getValues, setValue, watch, reset } = useForm({
    defaultValues: {
      shuffle: true,
      // intermittentLatencyCheckbox: false,
      intermittentLatencyDuration: 0,
      types: {
        general: [],
        conversationNode: [],
        inputNode: [],
        messageWithOptions: [],
        intermittentLatency: [],
        waitTime: [],
      },
      addFillerType: "",
      showFillerDetails: "",
      selectedFillerAudio: "",
    },
  });

  const handleAddFiller = (type) => {
    setValue("addFillerType", type);
  };

  const handleOnCloseFillerModal = () => {
    setValue("addFillerType", "");
  };

  const handleOnShowFillerDetails = (type) => {
    setValue("showFillerDetails", type);
  };

  const handleOnHideFillerDetails = () => {
    setValue("showFillerDetails", "");
  };

  const handleOnSaveFillerDetails = (newFillerDetails) => {
    const existingFillerDetails = structuredClone(getValues());
    if (newFillerDetails?.text) {
      existingFillerDetails.types[existingFillerDetails?.addFillerType].push({
        text: { value: newFillerDetails.text },
        ...(newFillerDetails.language && {
          language: newFillerDetails.language,
        }),
      });
    } else {
      existingFillerDetails.types[existingFillerDetails?.addFillerType].push({
        audio: {
          type: newFillerDetails?.file?.type,
          name: newFillerDetails?.file?.name,
          url: newFillerDetails?.fileUrl,
          duration: newFillerDetails?.fileDuration,
          ...(newFillerDetails.language && {
            language: newFillerDetails.language,
          }),
        },
      });
    }

    existingFillerDetails.addFillerType = "";
    reset(existingFillerDetails);
    delete existingFillerDetails.types.shuffle;
    onSave({
      fillersConfig: {
        shuffle: existingFillerDetails?.shuffle,
        types: existingFillerDetails?.types,
        // intermittentLatencyCheckbox:
        //   existingFillerDetails?.intermittentLatencyCheckbox,
        intermittentLatencyDuration:
          existingFillerDetails?.intermittentLatencyDuration,
      },
    });
  };

  const handleOnDelete = (type, fIndex) => {
    const newFillerDetails = structuredClone(getValues());
    newFillerDetails.types[type].splice(fIndex, 1);
    reset(newFillerDetails);
    onSave({
      fillersConfig: {
        shuffle: newFillerDetails?.shuffle,
        types: newFillerDetails?.types,
        // intermittentLatencyCheckbox:
        //   newFillerDetails?.intermittentLatencyCheckbox,
        intermittentLatencyDuration:
          newFillerDetails?.intermittentLatencyDuration,
      },
    });
  };

  const watchOnAllFields = () => {
    const subscribe = watch((value, fields) => {
      if (fields?.type === "change") {
        onSave({
          fillersConfig: {
            shuffle: value?.shuffle,
            types: value?.types,
            // intermittentLatencyCheckbox: value?.intermittentLatencyCheckbox,
            intermittentLatencyDuration: value?.intermittentLatencyDuration,
          },
        });
      }
    });

    return subscribe;
  };

  useEffect(() => {
    const subscription = watchOnAllFields();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (data) {
      reset({
        ...getValues(),
        ...data,
      });
    }
  }, [data]);

  return (
    <Box className={styles.container}>
      <Box>
        <Typography variant="body2" fontWeight={500}>
          Filler Message
        </Typography>
        <Typography
          variant="caption"
          mt={1}
          fontWeight={400}
          display="inline-block"
          color="#1e1e1e"
          opacity={0.8}
        >
          Customize conversational prompts and engagement cues with Filler
          Messages. The type of filler message used will be based on the
          previous message
        </Typography>
      </Box>

      <Box>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="body2" fontWeight={500}>
              Filler Type
            </Typography>
            <Typography
              variant="caption"
              mt={1}
              fontWeight={400}
              display="inline-block"
              color="#1e1e1e"
              opacity={0.8}
            >
              Choose message type to customize responses and engage users.
            </Typography>
          </Box>

          {/* <Box padding={4} display="flex" alignItems="center">
            <Typography variant="caption" color="#575757" mr={2}>
              Shuffle
            </Typography>
            <Shuffle width={20} height={20} />

            <Controller
              control={control}
              name="shuffle"
              render={({ field: { value, onChange } }) => (
                <Switch
                  sx={{ marginLeft: 1 }}
                  size="small"
                  checked={value}
                  onChange={onChange}
                />
              )}
            />
          </Box> */}
        </Box>

        {Object.keys(getValues("types")).map((type) => (
          <React.Fragment key={type}>
            <Box className={styles.fillerContainer}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={
                  watch("showFillerDetails") === type
                    ? handleOnHideFillerDetails
                    : () => handleOnShowFillerDetails(type)
                }
              >
                <Typography variant="body2">
                  {_.capitalize(type.replace(/([A-Z])/g, " $1"))}
                </Typography>

                {watch("showFillerDetails") === type ? (
                  <IconButton
                    sx={{ padding: 0 }}
                    // onClick={handleOnHideFillerDetails}
                  >
                    <Minus width={20} height={20} />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{ padding: 0 }}
                    // onClick={() => handleOnShowFillerDetails(type)}
                  >
                    <Plus width={20} height={20} />
                  </IconButton>
                )}
              </Box>
              <Collapse
                in={watch("showFillerDetails") === type}
                unmountOnExit={true}
              >
                <Box className={styles.fillerCollapsible}>
                  {getValues(`types.${type}`).map((filler, fillerIndex) => (
                    <React.Fragment key={fillerIndex}>
                      {filler?.text && (
                        <FlexBox className={styles.fillerBox}>
                          <Tooltip
                            title={
                              filler?.text?.value?.length > 64
                                ? filler?.text?.value
                                : ""
                            }
                          >
                            <Typography
                              variant="caption"
                              color={theme.palette.text.primary}
                              fontWeight={400}
                              className={styles.textOverFlow}
                              ml={2}
                            >
                              {filler?.text?.value}
                            </Typography>
                          </Tooltip>

                          {filler?.language && (
                            <Typography
                              variant="caption"
                              color={theme.palette.text.primary}
                              fontWeight={400}
                              className={styles.label}
                            >
                              {filler?.language.languageCode
                                .slice(0, 2)
                                .toUpperCase()}
                            </Typography>
                          )}

                          <IconButton
                            sx={{ ml: "auto", width: 32, height: 32 }}
                            onClick={() => handleOnDelete(type, fillerIndex)}
                          >
                            <Trash2 style={{ width: 20, height: 20 }} />
                          </IconButton>
                        </FlexBox>
                      )}

                      {filler?.audio && (
                        <FlexBox className={styles.fillerBox}>
                          {filesType[filler?.audio?.type]}

                          <Typography
                            variant="caption"
                            color={theme.palette.text.primary}
                            fontWeight={400}
                            className={styles.textOverFlow}
                            ml={3}
                          >
                            {filler?.audio?.name}
                          </Typography>

                          <Box
                            sx={{ ml: "auto" }}
                            onMouseOver={(e) => e.stopPropagation()}
                            className={styles.playerBox}
                          >
                            <AudioPlayer
                              key={filler?.audio?.url}
                              url={filler?.audio?.url.replace(
                                "gs://",
                                gscUriPrefix
                              )}
                              type={filler?.audio?.type}
                            />
                          </Box>

                          {filler?.audio?.language && (
                            <Typography
                              variant="caption"
                              color={theme.palette.text.primary}
                              fontWeight={400}
                              className={styles.label}
                              style={{ marginLeft: "unset" }}
                            >
                              {filler?.audio?.language.languageCode
                                .slice(0, 2)
                                .toUpperCase()}
                            </Typography>
                          )}

                          <IconButton
                            sx={{ width: 32, height: 32 }}
                            onClick={() => handleOnDelete(type, fillerIndex)}
                          >
                            <Trash2 style={{ width: 20, height: 20 }} />
                          </IconButton>
                        </FlexBox>
                      )}
                    </React.Fragment>
                  ))}
                </Box>
                {type === "intermittentLatency" && (
                  <>
                    {/* <FlexBox columnGap={1} my={2}>
                      <Controller
                        control={control}
                        name="intermittentLatencyCheckbox"
                        render={({ field: { value, onChange } }) => (
                          <Checkbox
                            checkedIcon={<CheckSquare />}
                            checked={value}
                            onChange={(e) => onChange(e.target.checked)}
                          />
                        )}
                      />

                      <Typography
                        sx={{ mr: 2 }}
                        variant="body2"
                        fontWeight={500}
                      >
                        Latency
                      </Typography>

                      <Tooltip>
                        <IconInfo2 width={20} height={20} />
                      </Tooltip>
                    </FlexBox> */}

                    {/* <Grow
                      in={watch("intermittentLatencyCheckbox")}
                      mountOnEnter
                      unmountOnExit
                    > */}
                    <FlexBox sx={{ mt: 2 }}>
                      <Controller
                        control={control}
                        name="intermittentLatencyDuration"
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            variant="outlined"
                            size="small"
                            value={value}
                            onChange={onChange}
                            sx={{ width: 75, borderRadius: 3 }}
                            inputProps={{
                              type: "number",
                              min: 5,
                              max: 60,
                            }}
                          />
                        )}
                      />

                      <Typography variant="body2">seconds</Typography>
                    </FlexBox>
                    {/* </Grow> */}
                  </>
                )}
              </Collapse>
            </Box>

            <Button
              variant="text"
              sx={{ color: "#0f0f0f", mt: 1, padding: 4 }}
              endIcon={<Plus width={20} height={20} />}
              onClick={() => handleAddFiller(type)}
            >
              Add Filler
            </Button>
          </React.Fragment>
        ))}
      </Box>

      <AddFillerModal
        open={Boolean(watch("addFillerType"))}
        onClose={handleOnCloseFillerModal}
        onSave={handleOnSaveFillerDetails}
      />
    </Box>
  );
};

ConversationManagement.propTypes = {
  data: PropTypes.object,
};

export default ConversationManagement;
