import {
  Box,
  Breadcrumbs,
  Button,
  Fade,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import EmptyState from "components/EmptyState";
import FlexBox from "components/FlexBox";
import { IconPlus, IconTrash } from "components/SVG";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./index.module.css";
import { ChevronRight, Loader, MoreVertical, X } from "react-feather";
import theme from "theme";
import FundamentoPopup from "components/FundamentoPopup";
import {
  PencilSimple,
  Webhook as IconWebhook,
  TrashSimple,
  Pencil,
} from "components/newSVG";
import {
  deleteExportEvent,
  deleteIntegration as deleteIntegrationAPI,
  editEvent,
  editIntegration,
  getIntegrations,
} from "services";
import DeleteModal from "components/DeleteModal";
import { useSkillrToast } from "context/toast";
import getExportEvents from "services/getExportEvents";
import AddWebhookModal from "./components/AddWebhookModal";
import CreateWebhookEventModal from "./components/CreateWebhookEventModal";
import NameModal from "components/NameModal";

const Webhook = () => {
  const { showSkillrToast } = useSkillrToast();
  const [show, setShow] = useState(false);
  const [allIntegrations, setAllIntegrations] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [code, setCode] = useState("");
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [prevName, setPrevName] = useState();
  const [deleteIntegrationModal, setDeleteIntegrationModal] = useState(false);
  const [deleteEventModal, setDeleteEventModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [currentTab, setCurrentTab] = useState("myWebhooks");
  const [createEventModal, setCreateEventModal] = useState(false);
  const [allExportEvents, setAllExportEvents] = useState([]);
  const [selected, setSelected] = useState();
  const [nameModal, setNameModal] = useState(false);

  const getAllIntegrations = async () => {
    setLoading(true);
    try {
      const response = await getIntegrations("webhook");
      setAllIntegrations(response);
    } catch (error) {
      console.log("Failed to fetch Webhook integrations:", error);
    } finally {
      setLoading(false);
    }
  };

  const renameIntegration = async (newName) => {
    try {
      const response = await editIntegration(selected._id, newName);
      if (response) {
        setNameModal(false);
        getAllIntegrations();
        setSelected();
        showSkillrToast("Integration renamed successfully", "success");
      }
    } catch (error) {
      console.log("Failed to rename integration:", error);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const renameEvent = async (newName) => {
    try {
      const response = await editEvent(selected._id, newName);
      if (response) {
        setNameModal(false);
        getAllEvents();
        setSelected();
        showSkillrToast("Event renamed successfully", "success");
      }
    } catch (error) {
      console.log("Failed to rename integration/event", error);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const deleteIntegration = async (id) => {
    try {
      const response = await deleteIntegrationAPI(id);
      if (response) {
        // getAllIntegrations();
        setAllIntegrations((prev) =>
          prev.filter((integration) => integration._id !== id)
        );
        setDeleteId();
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
      console.log("Failed to delete integration:", error);
    }
  };

  const deleteEvent = async (id) => {
    try {
      const response = await deleteExportEvent(id);
      if (response) {
        // getAllIntegrations();
        setAllExportEvents((prev) =>
          prev.filter((integration) => integration._id !== id)
        );
        setDeleteId();
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
      console.log("Failed to delete event:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const getAllEvents = async () => {
    setLoading(true);
    try {
      const response = await getExportEvents("webhook");
      setAllExportEvents(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.message ?? "Something went wrong";
      showSkillrToast(errorMessage, "error");
      console.log(error);
    }
  };

  useEffect(() => {
    getAllIntegrations();
    getAllEvents();
  }, []);

  return (
    <Box p={8} height="100vh">
      <Breadcrumbs
        separator={<ChevronRight width={20} height={20} color="#7b7b7b" />}
      >
        <Link to="/integrations">
          <Typography variant="body2" color="#7b7b7b">
            Integration
          </Typography>
        </Link>
        <Typography variant="body2" color="#295B74">
          Webhook
        </Typography>
      </Breadcrumbs>

      <FlexBox alignItems={"flex-end"} mt={2} justifyContent={"space-between"}>
        <Box>
          <Typography variant="h4" fontWeight={500}>
            Webhook
          </Typography>

          <Typography variant="body1" color={"text.secondary"}>
            Connect your bots with external webhooks
          </Typography>
        </Box>

        {currentTab === "myWebhooks" && (
          <Button
            variant="dark"
            startIcon={<IconPlus width={20} height={20} />}
            sx={{ borderRadius: 3, width: 143, height: 40, padding: 0 }}
            onClick={() => setShow(true)}
          >
            Add Webhook
          </Button>
        )}

        {currentTab === "events" && (
          <Button
            variant="dark"
            startIcon={<IconPlus width={20} height={20} />}
            sx={{ borderRadius: 3, width: 143, height: 40, padding: 0 }}
            onClick={() => setCreateEventModal(true)}
          >
            Create Event
          </Button>
        )}
      </FlexBox>

      <Box className={styles.tabContainer}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          className={styles.tabs}
          TabIndicatorProps={{ style: { background: "#0f0f0f" } }}
          sx={{
            width: "50%",
            "& span.MuiTabs-indicator": {
              borderRadius: "10px 10px 0 0",
              height: 3,
            },
          }}
        >
          <Tab
            label={
              <Typography fontFamily="DM Sans" variant="body2" fontWeight={500}>
                My Webhooks
              </Typography>
            }
            value="myWebhooks"
          />
          <Tab
            label={
              <Typography fontFamily="DM Sans" variant="body2" fontWeight={500}>
                Events
              </Typography>
            }
            value="events"
          />
        </Tabs>
      </Box>

      {/* {currentTab === "myWebhooks" && ( */}
      <Fade in={currentTab === "myWebhooks"} mountOnEnter unmountOnExit>
        <Box height="80%" width="100%" mt={8}>
          {loading ? (
            <Box className={styles.emptyContainer}>
              <Loader width={40} height={40} color={theme.palette.grey[400]} />
            </Box>
          ) : (
            allIntegrations?.map((integration) => (
              <Box className={styles.container}>
                <FlexBox>
                  <IconWebhook width={48} height={48} />

                  <Box>
                    <Typography variant="body1" fontWeight={500}>
                      {integration.name}
                    </Typography>
                  </Box>

                  <Box sx={{ marginLeft: "auto" }}>
                    <FundamentoPopup
                      className={styles.popup}
                      triggeringComponent={
                        <>
                          <IconButton sx={{ borderRadius: "12px" }}>
                            <MoreVertical width={20} height={20} />
                          </IconButton>
                        </>
                      }
                    >
                      <Box className={styles.popupButtons}>
                        <FlexBox
                          sx={{
                            borderBottom: `1px solid ${theme.palette.borderColor.light}`,
                          }}
                          onClick={() => {
                            setSelected(integration);
                            setNameModal(true);
                          }}
                        >
                          <PencilSimple width={16} height={16} />
                          <Typography
                            variant="caption"
                            color={theme.palette.text.primary}
                          >
                            Rename
                          </Typography>
                        </FlexBox>
                        <FlexBox
                          onClick={() => {
                            setDeleteIntegrationModal(true);
                            setDeleteId(integration._id);
                          }}
                        >
                          <X width={16} height={16} />
                          <Typography
                            color={theme.palette.text.primary}
                            variant="caption"
                          >
                            Remove
                          </Typography>
                        </FlexBox>
                      </Box>
                    </FundamentoPopup>
                  </Box>
                </FlexBox>
              </Box>
            ))
          )}

          {!loading && allIntegrations?.length === 0 && (
            <EmptyState
              style={{ height: "85%" }}
              title="No Data Available"
              description={"There's no data to show"}
            />
          )}

          <AddWebhookModal
            open={show}
            onClose={() => setShow(false)}
            onConfirm={() => {
              getAllIntegrations();
            }}
          />

          <DeleteModal
            open={deleteIntegrationModal}
            onClose={() => setDeleteIntegrationModal(false)}
            onConfirm={() => deleteIntegration(deleteId)}
            title={"Delete Integration"}
            subtitle={"Are you sure you want to delete this integration?"}
          />
        </Box>
      </Fade>
      {/* )} */}

      {/* {currentTab === "events" && ( */}
      <Fade in={currentTab === "events"} mountOnEnter unmountOnExit>
        <Box height="80%" width="100%" mt={8}>
          {loading ? (
            <Box className={styles.emptyContainer}>
              <Loader width={40} height={40} color={theme.palette.grey[400]} />
            </Box>
          ) : (
            allExportEvents?.length > 0 &&
            allExportEvents?.map(
              (event) =>
                event?.type === "webhook" && (
                  <FlexBox
                    p={4}
                    className={styles.eventContainer}
                    // alignItems="flex-start"
                  >
                    <IconWebhook />
                    <Box mr="auto">
                      <FlexBox>
                        <Typography variant="body1" fontWeight={500}>
                          {event?.name}
                        </Typography>

                        {/* name of google account */}
                        {/* <Box className={styles.label}>Account 1</Box> */}
                      </FlexBox>

                      {/* last updated time of the google sheet */}
                      {/* <Typography
                        variant="caption"
                        component={"div"}
                        color="grey.600"
                        mt={2}
                      >
                        Updated 7hrs ago
                      </Typography> */}

                      {/* name of google sheet and worksheet assigned */}
                      {/* <Typography
                        variant="caption"
                        component={"div"}
                        color="grey.600"
                        mt={2}
                      >
                        <Typography
                          variant="caption"
                          color="text.primary"
                          mb={2}
                        >
                          Location:{" "}
                        </Typography>
                        Gsheet File 1 / Wsheet 1
                      </Typography> */}

                      {/* variables assigned to this event */}
                      {/* <Typography
                        variant="caption"
                        color="grey.600"
                        component={"div"}
                        mt={2}
                      >
                        <Typography
                          variant="caption"
                          color="text.primary"
                          mb={2}
                        >
                          Variables Assigned:{" "}
                        </Typography>
                        {user_name}, {user_email}, {user_address}, {user_street}
                      </Typography> */}
                    </Box>

                    <FundamentoPopup
                      className={styles.popup}
                      disableCloseOnClick={false}
                      triggeringComponent={
                        <IconButton sx={{ borderRadius: "12px" }}>
                          <MoreVertical width={20} height={20} />
                        </IconButton>
                      }
                    >
                      <Box className={styles.popupButtons}>
                        <FlexBox
                          sx={{
                            borderBottom: `1px solid ${theme.palette.borderColor.light}`,
                          }}
                          onClick={() => {
                            setSelected(event);
                            setNameModal(true);
                          }}
                        >
                          <PencilSimple width={16} height={16} />
                          <Typography
                            variant="caption"
                            color={theme.palette.text.primary}
                          >
                            Rename
                          </Typography>
                        </FlexBox>
                        <FlexBox
                          onClick={() => {
                            setDeleteEventModal(true);
                            setDeleteId(event._id);
                          }}
                        >
                          <X width={16} height={16} />
                          <Typography
                            color={theme.palette.text.primary}
                            variant="caption"
                          >
                            Remove
                          </Typography>
                        </FlexBox>
                      </Box>
                    </FundamentoPopup>
                  </FlexBox>
                )
            )
          )}

          {!loading && allExportEvents?.length === 0 && (
            <EmptyState
              title="No Data Available"
              description={"There's no data to show"}
            />
          )}
          <CreateWebhookEventModal
            open={createEventModal}
            onClose={() => setCreateEventModal(false)}
            onConfirm={() => getAllEvents()}
            integrations={allIntegrations}
          />

          <DeleteModal
            open={deleteEventModal}
            onClose={() => setDeleteEventModal(false)}
            onConfirm={() => deleteEvent(deleteId)}
            title={"Delete Export Event"}
            subtitle={"Are you sure you want to delete this export event?"}
          />
        </Box>
      </Fade>
      {/* )} */}

      <NameModal
        open={nameModal}
        onClose={() => setNameModal(false)}
        onConfirm={(newName) =>
          currentTab === "myWebhooks"
            ? renameIntegration(newName)
            : renameEvent(newName)
        }
        prevName={selected?.name}
        titleOnRename={
          currentTab === "myWebhooks" ? "Rename Integration" : "Rename Event"
        }
        submitOnRename={"Rename"}
      />
    </Box>
  );
};

export default Webhook;
