import { lighten, alpha, darken, createTheme } from "@mui/material/styles";

const defaultTheme = createTheme();
const { typography, breakpoints } = defaultTheme;

const theme = createTheme({
  spacing: 4,
  direction: "ltr",
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    mode: "light",
    common: {
      black: "#000",
      white: "#fff",
    },
    background: {
      paper: "#FFFFFF",
      default: "#FFFFFF",
      primary: "#0094FF",
      gradient: "linear-gradient(90.95deg, #01A2FF 0%, #01D5B4 100%)",
    },
    text: {
      primary: "#262626",
      secondary: "#9D9D9D",
      disabled: "#C4C4C4",
      link: "#0094FF",
    },
    borderColor: {
      main: "#DAE3E8",
      light: "#EFEFEF",
    },
    primary: {
      lighter: "#F6FBFF",
      light: "#E4F3F8",
      main: "#0094FF",
    },
    error: {
      light: "#feebef",
      main: "#EB5757",
      contrastText: "#FFFFFF",
    },
    success: {
      light: "#F2FDF9",
      main: "#00D087",
    },
    warning: {
      light: "#fff8e1",
      main: "#ffa200",
    },
    grey: {
      50: "#F9F9F9",
      100: "#EFEFEF",
      300: "#C4C4C4",
      400: "#9D9D9D",
      600: "#555555",
      800: "#262626",
      900: "#000000",
    },
    green: {
      50: "#F2FDF9",
      100: "#CCF6E7",
      200: "#66E3B7",
      400: "#00D087",
    },
    purple: {
      50: "#F8F6FF",
      100: "#E2DDFE",
      200: "#A998FD",
      400: "#7054FC",
    },
    tag: {
      main: "#00C8C2",
    },
    yellow: {
      50: "#FFF8E1",
      400: "#FFC400",
      700: "#ffa200",
      900: "#E99A01",
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },

      styleOverrides: {
        contained: {
          backgroundColor: "#ffffff",
          color: "#262626",
          boxShadow: "0 0 16px 0 rgba(0, 0, 0, 0.12)",
          "&:hover": {
            backgroundColor: "#F5F5F5",
          },
        },
        containedPrimary: {
          "&.Mui-focusVisible": {
            backgroundColor: darken("#0094FF", 0.2),
          },
        },
        outlined: {
          padding: "10px 20px",
          borderColor: "#DAE3E8",
          color: "#555555",
        },
        sizeSmall: {
          fontSize: typography.pxToRem(12),
          lineHeight: typography.pxToRem(16),
          padding: "8px 16px",
        },
        sizeLarge: {
          fontSize: typography.pxToRem(16),
          lineHeight: typography.pxToRem(24),
          padding: "12px 24px",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontFamily: "DM Sans, sans-serif",
          fontWeight: 500,
          fontSize: typography.pxToRem(14),
          lineHeight: typography.pxToRem(20),
          padding: "10px 20px",
          borderRadius: 12,
          textTransform: "initial",
          minWidth: 128,
        },
        outlined: {
          padding: "10px 20px",
          borderColor: "#DAE3E8",
          color: "#555555",
        },
        sizeSmall: {
          fontSize: typography.pxToRem(12),
          lineHeight: typography.pxToRem(16),
          padding: "8px 16px",
        },
        sizeLarge: {
          fontSize: typography.pxToRem(16),
          lineHeight: typography.pxToRem(24),
          padding: "12px 24px",
        },
        startIcon: {
          marginLeft: 0,
        },
      },

      variants: [
        {
          props: { variant: "dark" },
          style: {
            display: "inline-flex",
            padding: "14px 24px",
            justifyContent: "center",
            alignItems: "center",
            // gap: 8,
            borderRadius: 82,
            backgroundColor: "#0F0F0F",
            color: "#fff",
            "&:hover": {
              backgroundColor: lighten("#0F0F0F", 0.5),
            },
            disabled: {
              backgroundColor: "red",
            },
            "&:focus-visible": {
              outline: 0,
              borderRadius: 82,
            },
          },
        },
        {
          props: { variant: "dark", disabled: true },
          style: {
            backgroundColor: lighten("#0F0F0F", 0.5),
            color: "#fff !important",
          },
        },
        {
          props: { variant: "whiteRound" },
          style: {
            padding: "14px 24px",
            borderRadius: 82,
            minWidth: 0,
            height: 52,
            backgroundColor: "#fff",
            color: "#0f0f0fb3",
            boxShadow: "0px 5px 22px 0px rgba(0, 0, 0, 0.06)",
            "&:hover": {
              backgroundColor: darken("#fff", 0.1),
            },
            "&:focus-visible": {
              outline: 0,
              // borderRadius: 82,
            },
          },
        },
        {
          props: { variant: "whiteRound", disabled: true },
          style: {
            backgroundColor: darken("#fff", 0.5),
            color: "#0f0f0fb3 !important",
          },
        },
      ],
    },
    MuiFab: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          minHeight: 30,
          backgroundColor: "#DAE3E8",
          color: "#262626",
          boxShadow: "none",

          "&:active": {
            boxShadow: "none",
          },
        },
        sizeSmall: {
          width: 32,
          height: 32,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {},
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
        colorPrimary: {
          "&.Mui-focusVisible": {
            backgroundColor: "#E4F3F8",
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: 20,
          paddingRight: 20,

          [breakpoints.up("sm")]: {
            paddingLeft: 20,
            paddingRight: 20,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: "DM Sans, sans-serif",
          fontSize: typography.pxToRem(40),
          lineHeight: typography.pxToRem(48),
          fontWeight: 400,
          color: "#0f0f0f",
        },
        h2: {
          fontFamily: "DM Sans, sans-serif",
          fontSize: typography.pxToRem(33),
          lineHeight: typography.pxToRem(44),
          fontWeight: 400,
          color: "#0f0f0f",
        },
        h3: {
          fontFamily: "DM Sans, sans-serif",
          fontSize: typography.pxToRem(28),
          lineHeight: typography.pxToRem(36),
          fontWeight: 400,
          color: "#0f0f0f",
        },
        h4: {
          fontFamily: "DM Sans, sans-serif",
          fontSize: typography.pxToRem(23),
          lineHeight: typography.pxToRem(32),
          fontWeight: 400,
          color: "#0f0f0f",
        },
        h5: {
          fontFamily: "DM Sans, sans-serif",
          fontSize: typography.pxToRem(19),
          lineHeight: typography.pxToRem(28),
          fontWeight: 400,
          color: "#0f0f0f",
        },
        h6: {
          fontFamily: "DM Sans, sans-serif",
          fontSize: typography.pxToRem(16),
          lineHeight: typography.pxToRem(24),
          fontWeight: 400,
          color: "#0f0f0f",
        },
        body1: {
          fontSize: typography.pxToRem(16),
          lineHeight: typography.pxToRem(24),
          fontWeight: 400,
          color: "#0f0f0f",
        },
        body2: {
          fontSize: typography.pxToRem(14),
          lineHeight: typography.pxToRem(20),
          fontWeight: 400,
          color: "#0f0f0f",
        },
        subtitle1: {
          fontSize: typography.pxToRem(16),
          lineHeight: typography.pxToRem(24),
          fontWeight: 500,
          color: "#0f0f0f",
        },
        subtitle2: {
          fontSize: typography.pxToRem(14),
          lineHeight: typography.pxToRem(20),
          fontWeight: 500,
          color: "#0f0f0f",
        },
        caption: {
          fontSize: typography.pxToRem(12),
          lineHeight: typography.pxToRem(16),
          // color: "#555",
          color: "#969bab",
          fontWeight: 500,
        },
        gutterBottom: {
          marginBottom: typography.pxToRem(8),
        },
        subtitle1DM: {
          fontSize: typography.pxToRem(18),
          fontWeight: 500,
          fontFamily: "DM Sans, sans-serif",
        },
        subtitle2DM: {
          fontSize: typography.pxToRem(14),
          fontFamily: "DM Sans, sans-serif",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 10,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: typography.pxToRem(16),
          lineHeight: typography.pxToRem(24),
        },
        colorDefault: {
          backgroundColor: "#EFEFEF",
          color: "#262626",
        },
        rounded: {
          borderRadius: 8,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",

          MuiTableCell: {
            head: {
              backgroundColor: "transparent",
            },
            stickyHeader: {
              backgroundColor: "transparent",
            },
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "8px 24px",
          borderBottom: "1px solid #efefef",
        },
        title: {
          fontSize: typography.pxToRem(14),
          lineHeight: typography.pxToRem(20),
          fontWeight: 600,
          color: "#555555",
        },
        action: {
          marginBottom: -8,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 20,
          "&:last-child": {
            paddingBottom: 20,
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "12px 20px 20px",
          marginTop: "auto",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.selected": {
            backgroundColor: "#f6fbff",
          },
        },
        rounded: {
          borderRadius: typography.pxToRem(8),
        },
        elevation1: {
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
        },
        outlined: {
          border: "1px solid #DAE3E8",

          "&.selected": {
            borderColor: "#0094ff",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: "4.5px 9px",
          '&&[class*="MuiOutlinedInput-root"] $input': {
            padding: 3,
          },
          '&&[class*="MuiOutlinedInput-root"] $endAdornment': {
            right: 14,
          },
        },
        popupIndicator: {
          color: "#0094FF",
        },
        clearIndicator: {
          padding: 2,
          color: "#9D9D9D",
        },
        endAdornment: {
          top: 12,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: "1.5em",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          transform: `translate(${typography.pxToRem(16)}, ${typography.pxToRem(
            12
          )}) scale(1)`,

          "&.MuiInputLabel-root	": {
            transformOrigin: "top center",
          },

          "&.MuiInputLabel-shrink": {
            // transform: `translate(${typography.pxToRem(
            //   16
            // )}, ${typography.pxToRem(-10)}) scale(1)`,
            transform: "translate(7px, -6px) scale(0.75)",
            // fontSize: typography.pxToRem(12),
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: typography.pxToRem(8),

          "&:hover:not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
            borderWidth: 3,
            borderColor: "#E3F2FF",
          },

          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: 1,
          },
        },
        input: {
          fontSize: typography.pxToRem(16),
          lineHeight: typography.pxToRem(24),
          padding: `${typography.pxToRem(12)} ${typography.pxToRem(16)}`,
          color: "#262626",
        },
        notchedOutline: {
          borderColor: "#DAE3E8",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: typography.pxToRem(16),
          lineHeight: typography.pxToRem(24),
          color: "#555555",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: typography.pxToRem(12),
          lineHeight: typography.pxToRem(16),
          marginTop: typography.pxToRem(8),
        },
        contained: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          height: 36,
          width: 36,
          fontSize: typography.pxToRem(14),
          lineHeight: typography.pxToRem(20),
          color: "#9D9D9D",
          borderWidth: 4,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&$hover": {
            "&:hover": {
              backgroundColor: "#f9f9f9",
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: typography.pxToRem(14),
          lineHeight: typography.pxToRem(20),
          color: "#262626",
          borderBottomColor: "#EFEFEF",
        },
        head: {
          backgroundColor: "#F5F5F5",
          color: "#555555",
          padding: "14px",
          textTransform: "uppercase",
          fontWeight: 600,
          fontSize: typography.pxToRem(10),
          lineHeight: typography.pxToRem(12),
          letterSpacing: typography.pxToRem(1),
          border: "0 none",

          "&:first-child": {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            paddingLeft: "64px",
          },

          "&:last-child": {
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            paddingRight: "24px",
          },
        },
        stickyHeader: {
          backgroundColor: "#F5F5F5",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "#e3f2ff",
          color: "#262626",
          fontSize: typography.pxToRem(14),
          lineHeight: typography.pxToRem(20),
          borderRadius: 4,
          height: 32,
        },
        label: {
          paddingLeft: 8,
          paddingRight: 8,
        },
        outlined: {
          border: "1px solid #dae3e8",
          color: "#555555",
        },
        outlinedPrimary: {
          border: "1px solid #7054fc",
          color: "#7054fc",
          backgroundColor: "#f8f6ff",
        },
        sizeSmall: {
          fontSize: typography.pxToRem(12),
          lineHeight: typography.pxToRem(16),
          height: 24,
        },
        deleteIcon: {
          margin: "0 8px 0 0",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthMd: {
          maxWidth: 1040,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "8px 40px",
          borderBottom: "1px solid #DAE3E8",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "32px 40px",

          ".alert-dialog-box &": {
            paddingTop: 48,

            "&:not(:last-child)": {
              paddingBottom: 22,
            },
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "10px 40px",
          boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2)",

          ".alert-dialog-box &": {
            padding: "10px 40px 40px",
            boxShadow: "none",
          },
        },
        spacing: {
          "& > :not(:first-child)": {
            marginLeft: 12,
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          height: 40,
          minWidth: 40,
          borderRadius: "50%",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#dae3e8",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          "&:hover": {
            backgroundColor: alpha("#0094FF", 0.08),
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
        icon: {
          top: "calc(50% - 8px)",
          color: "#0094FF",
        },
        iconOutlined: {
          right: 14,
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: "#122632",
          padding: "16px 24px",
          fontSize: typography.pxToRem(14),
          lineHeight: typography.pxToRem(20),
          fontWeight: 600,
          borderRadius: 8,
          borderLeft: "6px solid transparent",

          ".success &": {
            borderColor: "#00D087",
          },

          ".error &": {
            borderColor: "#eb5757",
          },
        },
        message: {
          padding: 0,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#0f0f0fe6",
          borderRadius: 12,
          padding: "8px 16px",
          lineHeight: "18px",
        },
      },
    },
    MUIRichTextEditor: {
      styleOverrides: {
        root: {
          border: "0.5px solid #DAE3E8",
          borderRadius: "0.5rem",
        },
        placeHolder: {
          position: "initial",
          padding: 16,
        },
        editor: {
          padding: 16,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 20,
          height: 20,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: typography.pxToRem(16),
          lineHeight: typography.pxToRem(24),
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "*, *::before, *::after": {
          boxSizing: "border-box",
        },
        "::-webkit-scrollbar": {
          width: 5,
        },
        "::-webkit-scrollbar-track": {
          borderRadius: 5,
          background: "rgba(255,255,255,0.01)",
        },
        "::-webkit-scrollbar-thumb": {
          borderRadius: 5,
          background: "rgba(0,0,0,0.16)",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "rgba(0,0,0,0.4)",
        },
        "::-webkit-scrollbar-thumb:window-inactive": {
          background: "rgba(0,0,0,0.16)",
        },
        a: {
          color: "#0094FF",
          textDecoration: "none",
          "&:hover, &:focus": {
            textDecoration: "none",
            outline: "none",
            color: darken("#0094FF", 1),
          },
          "&.disable-link": {
            pointerEvents: "none",
            cursor: "default",
          },
        },
        body: {
          fontFamily: "DM Sans, sans-serif",
          fontWeight: 400,
          fontSize: typography.pxToRem(16),
          lineHeight: 1.43,
        },
        /* Change autocomplete styles in WebKit */
        // eslint-disable-next-line max-len
        "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,textarea:-webkit-autofill,textarea:-webkit-autofill:hover,textarea:-webkit-autofill:focus,select:-webkit-autofill, select:-webkit-autofill:hover,select:-webkit-autofill:focus":
          {
            WebkitTextFillColor: "inherit",
            WebkitBoxShadow: "0 0 0px 1000px rgba(255,255,255,1) inset",
          },
        "input:-webkit-autofill::first-line": {
          fontSize: typography.pxToRem(16),
          lineHeight: typography.pxToRem(24),
          fontFamily: "DM Sans, sans-serif",
          fontWeight: 400,
          latterSpacing: "normal",
        },
        img: {
          maxWidth: "100%",
        },
        ".cursor-pointer": {
          cursor: "pointer",
        },
        ".text-truncate": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
        ".btn-icon": {
          padding: 8,
          minWidth: "auto",
        },
        ".btn-gradient": {
          background:
            "linear-gradient(to top, #3928d2, #190c93), linear-gradient(to bottom, #fff, #fff)",
        },
        ":focus-visible": {
          outlineColor: lighten("#0094FF", 0.9),
          outlineStyle: "solid",
          outlineWidth: "3px",
          borderRadius: 8,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          "& .MuiSwitch-thumb": {
            backgroundColor: "#fff",
            boxShadow: "0px 3.2px 3.2px 0px rgba(0, 0, 0, 0.25)",
          },
          "& .Mui-checked+.MuiSwitch-track": {
            opacity: "1 !important",
          },
        },
      },
    },
  },
  typography: {
    // htmlFontSize: 10,
    fontSize: 16,
    fontFamily: {
      dm: "DM Sans, sans-serif",
      inter: "Inter, sans-serif",
    },
    // fontFamily: "DM Sans, sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 500,
  },
});

export default theme;
