import { Box, Slider, TextField, Typography } from "@mui/material";
import styles from "./index.module.css";
import { Controller, useForm } from "react-hook-form";
import { WarningRed } from "components/newSVG";

const MessageWithOptionsNode = (props) => {
  const { onSave, distance, setLoader } = props;

  const {
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      distance: distance ?? 0.17,
    },
    mode: "onChange",
  });

  return (
    <Box padding="32px 32px 0 32px" width="100%">
      <Box className={styles.cardContainer}>
        <Typography variant="subtitle1">Timeout Configuration</Typography>
        <Typography variant="subtitle2" mt={1} color="#505050" fontWeight={400}>
          Configure the maximum wait time for an API response before
          automatically terminating the request.
        </Typography>

        <Box mt={4}>
          <Typography variant="caption" color="#0f0f0f">
            Distance
          </Typography>

          <Box display="flex" gap={2} alignItems="center">
            <Controller
              control={control}
              name="distance"
              render={({ field: { onChange, value } }) => (
                <Slider
                  min={0.1}
                  max={1}
                  valueLabelDisplay="auto"
                  step={0.01}
                  sx={{
                    flex: "1 1 80%",
                    height: "2.5px",
                    "& .MuiSlider-rail": { backgroundColor: "#DADADA" },
                    "& .MuiSlider-track": {
                      backgroundColor: "#0F0F0F",
                      border: 0,
                    },
                    "& .MuiSlider-thumb": {
                      backgroundColor: "#0F0F0F",
                      width: 15,
                      height: 15,
                    },
                    marginBottom: 0,
                  }}
                  marks={[
                    {
                      value: 0.1,
                    },
                    {
                      value: 1,
                    },
                  ]}
                  value={+value}
                  onChange={(e, value) => {
                    setLoader(false);
                    onChange(value);
                    onSave(+e.target.value);
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="distance"
              rules={{
                required: `Distance is required`,
                min: {
                  value: 0.1,
                  message: `Minimum required value is 0.1`,
                },
                max: {
                  value: 1,
                  message: `Maximum required value is 1`,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  className={styles.modelTextField}
                  variant="standard"
                  type="number"
                  onChange={(e) => {
                    setLoader(false);
                    onChange(e.target.value);
                    onSave(+e.target.value);
                  }}
                  value={value}
                />
              )}
            />
          </Box>
        </Box>

        {errors?.distance && (
          <Box
            component="span"
            display="flex"
            alignItems="center"
            padding="5px 4px"
          >
            <WarningRed style={{ marginRight: 8 }} />
            <Typography
              fontSize={10}
              fontWeight={500}
              color="error"
              sx={{ opacity: 0.8 }}
            >
              {errors?.distance?.message}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MessageWithOptionsNode;
