import { Box, Button, IconButton, Typography } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import classes from "./index.module.css";
import {
  FlowArrow,
  Path,
  PencilSimple,
  SparkleCircle,
} from "components/newSVG";
import { PlusCircle, Trash2 } from "react-feather";
import { useURLState } from "custom-hooks/useUrlState";
import { useEffect, useState } from "react";
import { IconCirclePlus } from "components/SVG";
import CreateWorkFlowModal from "./components/CreateWorkFlowModal";
import {
  createWorkFlow as createWorkFlowApi,
  getAllWorkFlow as getAllWorkFlowApi,
  editWorkFlow as editWorkFlowApi,
} from "services";
import { useSkillrToast } from "context/toast";
import FundamentoTable from "components/FundamentoTable";
import moment from "moment";
import DeleteModal from "components/DeleteModal";
import { useNavigate } from "react-router-dom";
import WorkFlowLinked from "./components/WorkFlowLinked";
import Header from "components/Header";
import Loader from "components/Loader";
import EmptyState from "components/EmptyState";

const sortList = [
  {
    name: "Date created",
    value: "dateCreated",
  },
  {
    name: "Last modified",
    value: "lastModified",
  },
  {
    name: "A to Z",
    value: "atoz",
  },
  {
    name: "Z to A",
    value: "ztoa",
  },
];

const WorkFlow = () => {
  const { showSkillrToast } = useSkillrToast();
  const [sortby, setSortBy] = useURLState("dateCreated", "sort");
  const [workflows, setWorkFlows] = useState();
  const [showWorkFlowModal, setShowWorkFlowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [workFlowLinkedModal, setWorkFlowLinkedModal] = useState(false);
  const tabs = ["System workflows", "Custom workflows"];
  const [isDefault, setIsDefault] = useURLState("true", "isDefault");
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useURLState("", "search");
  const [page, setCurrentPage] = useURLState(1, "page");
  const recordsPerPage = 10;
  const navigate = useNavigate();
  const handleOnCloseWorkFlowModal = () => {
    setShowWorkFlowModal(false);
    setSelectedRow();
  };

  const createWorkFlow = () => {
    setShowWorkFlowModal(true);
  };

  const createNewWorkFlow = async (data) => {
    try {
      const response = await createWorkFlowApi(data);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getTableData = (workflows) => {
    setWorkFlows(workflows);
  };

  const getAllWorkFlow = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams();
      params.set("isDefault", isDefault);
      isDefault === "false" && params.set("search", search);
      params.set("limit", recordsPerPage);
      params.set("page", page);
      const response = await getAllWorkFlowApi(params.toString());
      getTableData(response);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const updateWorkFlow = async (workFlowId, updatedWorkFlow) => {
    try {
      const response = await editWorkFlowApi(workFlowId, updatedWorkFlow);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const saveWorkFlow = async (data) => {
    if (selectedRow) {
      await updateWorkFlow(selectedRow?._id, { name: data?.name });
    } else {
      await createNewWorkFlow(data);
    }
    getAllWorkFlow();
    setShowWorkFlowModal(false);
    setSelectedRow();
  };

  const handleEditWorkFlow = (event, rowInfo) => {
    event.stopPropagation();
    setShowWorkFlowModal(true);
    setSelectedRow(rowInfo);
  };

  const handleDeleteWorkFlow = async (event, rowInfo) => {
    event.stopPropagation();
    if (rowInfo?.questionsCount > 0) {
      setWorkFlowLinkedModal(true);
    } else {
      setSelectedRow(rowInfo);
      setDeleteModal(true);
    }
  };

  const deleteWorkFlow = async () => {
    await updateWorkFlow(selectedRow?._id, { isActive: false });
    getAllWorkFlow();
    setSelectedRow();
    setDeleteModal(false);
  };

  const handleOnCloseDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRow();
  };

  const columns = [
    {
      field: "icon",
      header: " ",
      style: { width: "0%" },
      render: (row) =>
        row?.isDefault ? (
          <Path width={20} height={20} />
        ) : (
          <FlowArrow width={20} height={20} />
        ),
    },
    {
      field: "name",
      header: " ",
      style: { width: "50%" },
      render: (rowInfo) => (
        <Typography variant="subtitle2" fontWeight={500}>
          {rowInfo?.name}
        </Typography>
      ),
    },
    {
      field: "dateTime",
      header: " ",
      style: { width: "12%" },
      render: (rowInfo) => {
        return (
          <Typography variant="caption">
            {moment(rowInfo?.createdAt).format("D MMM YYYY, hh:mm A")}
          </Typography>
        );
      },
    },
    {
      field: "nodes",
      header: " ",
      style: { width: "0%" },
      render: (rowInfo) => (
        <Box className={classes.nodes}>
          <Box className={classes.dot} />
          <Typography variant="caption">
            {rowInfo?.nodes ? rowInfo.nodes - 1 : "0"} Nodes
          </Typography>
        </Box>
      ),
    },
    {
      field: "actions",
      header: " ",
      style: { width: "0%" },
      render: (rowInfo) => (
        <Box display="flex" ml="auto" gap={2}>
          {!rowInfo?.isDefault && (
            <IconButton onClick={(e) => handleEditWorkFlow(e, rowInfo)}>
              <PencilSimple color="#0f0f0f" width={16} height={16} />
            </IconButton>
          )}
          {!rowInfo?.isDefault && (
            <IconButton onClick={(e) => handleDeleteWorkFlow(e, rowInfo)}>
              <Trash2 width={16} height={16} color="#eb5757" />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const handleTableOnClick = (event) => {
    const row = event.target.closest("tr[id]");
    if (row?.id) {
      navigate(`/workflows/${row.id}`);
    }
  };

  const handleOnHeaderSearch = (searchedValue) => {
    setSearch(searchedValue);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    getAllWorkFlow();
  }, [search, isDefault, page]);

  // useEffect(() => {
  //   getAllWorkFlow();
  // }, [isDefault]);

  return (
    <PageWrapper className={classes.container}>
      <Header
        title="Workflows"
        subTitle="Create and manage your AI-powered workflows"
        tabs={tabs}
        defaultActiveTab={
          isDefault === "true" ? "System workflows" : "Custom workflows"
        }
        onTabChange={() => {
          setIsDefault(isDefault === "true" ? "false" : "true");
          setCurrentPage(1);
          setLoading(true);
        }}
        searchBox={isDefault === "false"}
        onSearch={handleOnHeaderSearch}
        searchDisabled={loading}
        defaultSearchValue={search}
        onClearSearch={() => setSearch("")}
      />
      {loading ? (
        <Loader />
      ) : (
        <Box p={"24px 48px"} height="100%">
          <Box height="100%">
            {workflows?.workFlows?.length > 0 && (
              <>
                <Box display="flex" alignItems="center" gap={2}>
                  {/* <FundamentoPopup
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                triggeringComponent={
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: 82,
                      padding: "8px 14px",
                      minWidth: 0,
                    }}
                    startIcon={<SortAscending width={16} height={16} />}
                    endIcon={<ChevronDown width={16} height={16} />}
                  >
                    Sort by: {sortList.find((s) => s.value === sortby).name}
                  </Button>
                }
                className={classes.popup}
              >
                <Box>
                  {sortList.map((sort) => (
                    <Box className={classes.popupItem} key={sort.value}>
                      <Checkbox
                        icon={<Circle />}
                        checkedIcon={<RadioButton />}
                        value={sort.value}
                        onChange={() => {}}
                        checked={sortby === sort.value}
                        id={sort.value}
                      />
                      <Typography
                        variant="body2"
                        component="label"
                        htmlFor={sort.value}
                      >
                        {sort.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </FundamentoPopup>

              <Box className={classes.searchContainer}>
                <SearchBox onChange={() => {}} placeholder="Search by name" />
              </Box> */}
                  {isDefault !== "true" && (
                    <Button
                      variant="dark"
                      className={classes.darkButton}
                      onClick={createWorkFlow}
                      startIcon={<PlusCircle width={16} height={16} />}
                    >
                      New Workflow
                    </Button>
                  )}
                </Box>
                <Box
                  sx={{
                    mt: 4,
                    "& th": {
                      display: "none",
                    },
                    "& tr": {
                      transitionDuration: "0.3s",
                    },
                    "& tr:hover": {
                      cursor: "pointer",
                      backgroundColor: "#f6f6f6",
                    },
                  }}
                >
                  <FundamentoTable
                    columns={columns}
                    data={workflows?.workFlows || []}
                    passRowToRender={true}
                    pagination={true}
                    totalPageCount={Math.ceil(
                      workflows?.totalCount / recordsPerPage
                    )}
                    currentPage={page}
                    onPageChange={handlePageChange}
                    tableOnClick={handleTableOnClick}
                    rowIdKey="_id"
                  />
                </Box>
              </>
            )}

            {workflows?.workFlows?.length === 0 && !search && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                mt={13}
              >
                <SparkleCircle />
                <Typography variant="h4" fontSize={26} mt={8} fontWeight={500}>
                  Create your first workflow
                </Typography>
                <Typography variant="body2" mt={2}>
                  Create your AI-power workflow
                </Typography>

                <Button
                  startIcon={<IconCirclePlus />}
                  className={classes.newWorkFlowButton}
                  variant="outline"
                  onClick={createWorkFlow}
                >
                  New workflow
                </Button>
              </Box>
            )}

            {workflows?.workFlows?.length === 0 && search && (
              <EmptyState title={"No workflow found"} />
            )}
          </Box>
          <CreateWorkFlowModal
            open={showWorkFlowModal}
            onClose={handleOnCloseWorkFlowModal}
            edit={selectedRow ? true : false}
            data={selectedRow}
            handleSave={saveWorkFlow}
          />

          <DeleteModal
            open={deleteModal}
            onClose={handleOnCloseDeleteModal}
            onConfirm={() => deleteWorkFlow()}
            title={"Delete WorkFlow?"}
            subtitle={
              "This action can't be undone and the data will be deleted from your account permanently"
            }
            list={["This will delete the workflow permanently"]}
          />

          <WorkFlowLinked
            title="This workflow is linked to another resource. Please manage that resource before deleting this workflow."
            open={workFlowLinkedModal}
            onClose={() => setWorkFlowLinkedModal(false)}
          />
        </Box>
      )}
    </PageWrapper>
  );
};

export default WorkFlow;
