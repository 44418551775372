import {
  Typography,
  Box,
  Radio,
  Tabs,
  Tab,
  Divider,
  TextField,
  Button,
  IconButton,
  Dialog,
  Tooltip,
  Grow,
  Collapse,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import {
  Controller,
  useForm,
  FormProvider,
  useFieldArray,
  get,
} from "react-hook-form";
import { Circle, Loader, Plus, Send, Trash2, X } from "react-feather";
import { RadioButton } from "components/newSVG";
import styles from "./index.module.css";
import theme from "theme";
import { ChevronRight } from "react-feather";
import AddFields from "../../AddFields";
import { IconInfo2, RedAlert } from "components/SVG";
import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { createWorkFlow, getSampleResponse } from "services";
import { useSkillrToast } from "context/toast";
import VariableDropdown from "../VariableDropdown";
import _ from "lodash";
import CustomJsonRenderer from "../../CustomRenderer";
import InputModal from "components/InputModal";
import AutoComplete from "components/AutoComplete";
import { checkDuplicateName } from "utils/utils";

const RestApiProperties = (props) => {
  const { onSave, data, workflows, onEdit, workFlowId } = props;
  const tabs = ["params", "headers", "body"];
  const restApiRegex =
    /^(https?:\/\/)?(localhost|([\w\d-]+\.)*[\w\d-]+\.[\w\d]{2,})(:[\d]{2,5})?(\/[\w\d-.{}]+)*\/?$/;
  // /^(https?:\/\/)?([\w\d-]+\.)*[\w\d-]+\.[\w\d]{2,}(\/[\w\d-.{}]+)*\/?$/;

  // /^(https?:\/\/)?([\w\d-]+\.)*[\w\d-]+\.[\w\d]{2,}(\/[\w\d-{}]+)*$/;

  const methods = useForm({
    defaultValues: {
      method: "GET",
      apiUrl: "",
      tab: tabs[0],
      params: [],
      // authorization: "",
      headers: [],
      body: "",
      variables: [],
      errorInResponse: false,
      apiLoader: false,
      selectedWorkflow: null,
      defaultType: "triggerWorkflow",
    },
  });

  const {
    control,
    watch,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = methods;
  const [responseFromSampleAPI, setResponseFromSampleAPI] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [variableValues, setVariableValues] = useState([]);
  const [optionsList, setOptionsList] = useState();
  const { showSkillrToast } = useSkillrToast();
  const [showInputModal, setShowInputModal] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "variables",
  });

  const variablePattern = /{{(.*?)}}/g;
  const hasVariables = (template) => {
    return variablePattern.test(JSON.stringify(template));
  };

  const handleOnSave = async (formData) => {
    if (
      formData.defaultType === "triggerWorkflow" &&
      !formData.selectedWorkflow._id
    ) {
      showSkillrToast("Please select an error workflow", "error");
      return;
    }

    formData.selectedWorkflow = {
      workflowName: formData.selectedWorkflow?.name,
      workflowId: formData.selectedWorkflow?._id,
    };

    if (formData.customName) {
      const isDuplicate = await checkDuplicateName(
        workFlowId,
        formData.customName,
        props.data.name
      );
      if (isDuplicate) {
        showSkillrToast("Node name already exists", "error");
        return;
      }
    }

    delete formData.response;
    onSave(formData);
  };

  const handleSampleResponse = () => {
    const variablePatternCopy = new RegExp(variablePattern);

    let formData = getValues();
    formData = JSON.stringify(formData);

    if (hasVariables(formData)) {
      const matches = [];
      let match;

      while ((match = variablePatternCopy.exec(formData)) !== null) {
        matches.push(match[1].trim());
      }
      setVariableValues(matches.map((name) => ({ name, value: "" })));
      setShowModal(true);

      return;
    } else {
      setValue("response", "");
      sendSampleResponse(formData);
    }
  };

  const sendSampleResponse = async (formData) => {
    try {
      setValue("apiLoader", true);
      const response = await getSampleResponse({ data: formData });
      if (response && response.message === "success") {
        const formattedJSON = JSON.stringify(
          JSON.parse(response.responseData),
          null,
          2
        );
        setResponseFromSampleAPI(formattedJSON);
        setValue("response", formattedJSON);
        setValue("errorInResponse", false);
        setValue("apiLoader", false);
      }
      if (response && response.message === "error") {
        setResponseFromSampleAPI(response.responseData.message);
        setValue("response", response.responseData.message);
        setValue("errorInResponse", true);
        setValue("apiLoader", false);
      }
    } catch (error) {
      setValue("apiLoader", false);
      console.log(error);
    }
  };

  const handleVariableChange = (index, value) => {
    const newVariableValues = [...variableValues];
    newVariableValues[index].value = value;
    setVariableValues(newVariableValues);
  };

  const handleSaveVariables = () => {
    const formData = getValues();
    const formDataCopy = _.cloneDeep(formData);

    const values = variableValues.reduce((acc, curr) => {
      acc[curr.name] = curr.value;
      return acc;
    }, {});

    const replaceVariables = (template, variables) => {
      return template.replace(/{{(.*?)}}/g, (_, v) => {
        return variables[v.trim()] || `{{${v.trim()}}}`;
      });
    };

    formDataCopy.apiUrl = replaceVariables(formDataCopy.apiUrl, values);
    formDataCopy.body = replaceVariables(formDataCopy.body, values);
    formDataCopy.params.forEach((param) => {
      param.value = replaceVariables(param.value, values);
    });
    formDataCopy.headers.forEach((header) => {
      header.value = replaceVariables(header.value, values);
    });

    sendSampleResponse(JSON.stringify(formDataCopy));
    setShowModal(false);
  };

  // const handleOnClickApiResponse = (event) => {
  //   const selectedData = event.target.outerText;
  //   const match = selectedData.match(/"([^",]+)":\s*/);
  //   if (match) {
  //     const key = match[1];
  //     const keyReference = getKey(key, JSON.parse(getValues("response")));
  //     const keyIndex = fields?.findIndex(
  //       (f) => f.key?.toLowerCase() === key?.toLowerCase()
  //     );
  //     if (keyIndex === -1) {
  //       append({ key, value: keyReference });
  //       showSkillrToast("New variable added", "success");
  //     }
  //   }
  // };

  const handleClickOnKeys = (event) => {
    const dataSet = event.target?.dataset;
    if (dataSet?.keyreference) {
      append({ key: dataSet?.keyname, value: dataSet?.keyreference });
    }
  };

  const createNewWorkFlow = async (data) => {
    try {
      const response = await createWorkFlow(data);
      setOptionsList((prev) => [
        { name: response.name, _id: response._id },
        ...prev,
      ]);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };
  const handleCreateWorkflow = (name) => {
    setShowInputModal(false);
    createNewWorkFlow({ name }).then((response) => {
      if (response) {
        const newWorkflow = {
          name: response.name,
          _id: response._id,
        };
        reset({
          ...getValues(),
          selectedWorkflow: newWorkflow,
        });
      }
    });
  };

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        defaultType: data.defaultType || "triggerWorkflow",
        selectedWorkflow: {
          name: data.selectedWorkflow?.workflowName || "",
          _id: data.selectedWorkflow?.workflowId,
        },
      });
    }
  }, [data]);

  useEffect(() => {
    setOptionsList([
      { name: "Create Workflow", _id: "create_workflow" },
      ...(workflows?.workFlows || []),
    ]);
  }, [workflows]);

  useEffect(() => {
    onEdit(isDirty);
  }, [isDirty]);

  return (
    <Box mt={2} sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Typography variant="caption" color="text.primary" mb={1}>
        Node Name
      </Typography>

      <Controller
        control={control}
        name="customName"
        rules={{
          validate: (value) => {
            if (/[^a-zA-Z\s]/.test(value))
              return "Node name can only contain letters and spaces";
            return true;
          },
        }}
        render={({ field: { onChange, value } }) => (
          <TextField
            variant="outlined"
            inputProps={{ maxLength: 20 }}
            fullWidth
            placeholder="Add Name"
            value={value}
            onChange={onChange}
            error={errors?.customName}
            helperText={errors?.customName?.message}
            sx={{
              mb: 4,
              "& div": {
                borderRadius: "12px",
                padding: 0,
              },
            }}
          />
        )}
      />

      <Typography variant="caption" color="#0F0F0F">
        Select method
      </Typography>
      <Box mt={2}>
        <Controller
          control={control}
          name="method"
          defaultValue="GET"
          render={({ field: { onChange, value } }) => (
            <>
              <Box mt={2} display="flex" gap={5}>
                <FlexBox
                  component="label"
                  columnGap={1}
                  sx={{ cursor: "pointer" }}
                >
                  <Radio
                    onClick={() => onChange("GET")}
                    checked={value === "GET"}
                    sx={{ padding: 0, minWidth: 24 }}
                    icon={<Circle width={22} height={22} />}
                    checkedIcon={<RadioButton width={24} height={24} />}
                  />

                  <Typography variant="caption" color="#0F0F0F">
                    GET
                  </Typography>
                </FlexBox>
                <FlexBox
                  sx={{ cursor: "pointer" }}
                  columnGap={1}
                  component="label"
                >
                  <Radio
                    onClick={() => onChange("POST")}
                    checked={value === "POST"}
                    sx={{ padding: 0, minWidth: 24 }}
                    icon={<Circle width={22} height={22} />}
                    checkedIcon={<RadioButton width={24} height={24} />}
                  />
                  <Typography variant="caption" color="#0F0F0F">
                    POST
                  </Typography>
                </FlexBox>
              </Box>
            </>
          )}
        />
      </Box>

      <Typography
        variant="caption"
        color="#0F0F0F"
        sx={{ marginTop: 6 }}
        fontWeight={500}
      >
        URL
      </Typography>

      <FlexBox mt={2}>
        <Controller
          control={control}
          name="apiUrl"
          rules={{
            required: "Url is required",
            pattern: {
              value: restApiRegex,
              message: "Please enter valid api endpoint",
            },
          }}
          render={({ field: { onChange, value } }) => (
            <VariableDropdown
              control={control}
              watch={watch}
              setValue={setValue}
              value={value}
              onChange={onChange}
            >
              <TextField
                fullWidth
                value={value}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/ /g, "");
                  onChange(e.target.value);
                }}
                placeholder="Enter URL"
                sx={{
                  "& div": {
                    borderRadius: 3,
                  },
                }}
                helperText={
                  errors?.apiUrl && (
                    <Box component="span" display="flex" alignItems="center">
                      <RedAlert style={{ marginRight: 8 }} />
                      {errors?.apiUrl?.message}
                    </Box>
                  )
                }
              />
            </VariableDropdown>
          )}
        />

        <IconButton
          className={watch("apiLoader") ? styles.disabled : {}}
          sx={{
            backgroundColor: theme.palette.text.primary,
            "&:hover": {
              backgroundColor: theme.palette.grey[600],
            },
          }}
          onClick={() => handleSampleResponse()}
        >
          {watch("apiLoader") ? (
            <Loader color="white" width={20} height={20} />
          ) : (
            <Send color="white" width={20} height={20} />
          )}
        </IconButton>
      </FlexBox>

      <Box mt={6}>
        <Controller
          control={control}
          name="tab"
          render={({ field: { onChange, value } }) => (
            <Box>
              {tabs?.length > 0 && (
                <Tabs
                  value={value}
                  onChange={(event, newTab) => onChange(newTab)}
                  aria-label="tabs"
                  className={styles.tabs}
                  TabIndicatorProps={{ style: { background: "#0f0f0f" } }}
                  sx={{
                    "& span.MuiTabs-indicator": {
                      borderRadius: "10px 10px 0 0",
                      height: 3,
                    },
                  }}
                >
                  {tabs.map((tab, index) => {
                    if (watch("method") === "GET" && tab === "body")
                      return null;
                    return (
                      <Tab
                        className={styles.tab}
                        label={tab}
                        value={tab}
                        key={tab}
                      />
                    );
                  })}
                </Tabs>
              )}
            </Box>
          )}
        />

        <Divider
          sx={{ backgroundColor: theme.palette.borderColor.light, mx: -6 }}
        />
      </Box>

      <Box mt={4} mb={1}>
        {watch("tab") === "body" && (
          <Controller
            control={control}
            name="body"
            render={({ field: { onChange, value } }) => (
              <VariableDropdown
                control={control}
                watch={watch}
                setValue={setValue}
                value={value}
                onChange={onChange}
              >
                <TextField
                  placeholder="add request body here"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  rows={8}
                  multiline
                  sx={{
                    "& div": {
                      padding: 0,
                    },
                  }}
                />
              </VariableDropdown>
            )}
          />
        )}

        {watch("tab") === "params" && (
          <FormProvider {...methods}>
            <AddFields path={watch("tab")} buttonText="Add Params" />
          </FormProvider>
        )}

        {watch("tab") === "headers" && (
          <FormProvider {...methods}>
            <AddFields path={watch("tab")} buttonText="Add Headers" />
          </FormProvider>
        )}
      </Box>

      <Box mt={3} mb={1}>
        <FlexBox columnGap={1}>
          <Typography variant="body2" fontWeight={500}>
            Response
          </Typography>

          <Tooltip
            title="Clicks on response keys to add the variables"
            sx={{ cursor: "pointer" }}
          >
            <IconInfo2 />
          </Tooltip>
        </FlexBox>

        <Controller
          control={control}
          name="response"
          render={({ field: { onChange, value } }) => {
            return watch("errorInResponse") ? (
              <Typography variant="body2" color="error">
                {value}
              </Typography>
            ) : (
              <CustomJsonRenderer
                className={styles.customJsonRenderer}
                json={value ? JSON.parse(value) : {}}
                handleOnClick={handleClickOnKeys}
              />
            );
          }}
        />
      </Box>

      <FlexBox>
        {watch("variables") && watch("variables").length > 0 && (
          <>
            <Typography
              variant="body2"
              fontWeight={500}
              sx={{ width: "40%" }}
              mb={2}
            >
              Variable name
            </Typography>
            <Typography variant="body2" fontWeight={500} mb={2}>
              JSON key name
            </Typography>
          </>
        )}
      </FlexBox>

      {/* <FormProvider sx={{ mb: 2 }} {...methods}>
        <AddFields
          path={"variables"}
          buttonText="Add variables"
          keyPlaceholder="variable_name"
          valuePlaceholder="json key name"
        />
      </FormProvider> */}

      {/* variables field array */}
      {fields.map((item, index) => (
        <FlexBox key={item.id} sx={{ mb: 2 }}>
          <Controller
            control={control}
            name={`variables.${index}.key`}
            rules={{ required: "key is required" }}
            render={({ field: { onChange, value } }) => (
              <TextField
                placeholder="variable_name"
                value={value}
                onChange={onChange}
                sx={{
                  "& div": {
                    borderRadius: 3,
                  },
                }}
                helperText={
                  get(errors, `variables.${index}.key`) && (
                    <Box component="span" display="flex" alignItems="center">
                      <RedAlert style={{ marginRight: 8 }} />
                      {get(errors, `variables.${index}.key`)?.message}
                    </Box>
                  )
                }
              />
            )}
          />

          <Controller
            control={control}
            name={`variables.${index}.value`}
            rules={{ required: "value is required" }}
            render={({ field: { onChange, value } }) => (
              <Tooltip title={value?.length > 9 ? value : ""}>
                <TextField
                  placeholder="json key name"
                  value={value}
                  onChange={onChange}
                  sx={{
                    "& div": {
                      borderRadius: 3,
                    },
                  }}
                  helperText={
                    get(errors, `variables.${index}.value`) && (
                      <Box component="span" display="flex" alignItems="center">
                        <RedAlert style={{ marginRight: 8 }} />
                        {get(errors, `variables.${index}.value`)?.message}
                      </Box>
                    )
                  }
                  InputProps={{
                    startAdornment: (
                      <Typography variant="caption">root</Typography>
                    ),
                  }}
                />
              </Tooltip>
            )}
          />

          <IconButton onClick={() => remove(index)}>
            <Trash2 width={16} height={16} />
          </IconButton>
        </FlexBox>
      ))}

      <Button
        variant="text"
        color="inherit"
        startIcon={
          <Plus
            fill={theme.palette.primary.main}
            stroke="#0F0F0F"
            width={16}
            height={16}
          />
        }
        onClick={() => {
          append({ key: "", value: "" });
        }}
        sx={{
          my: 2,
          justifyContent: "flex-start",
          minWidth: 0,
          width: "max-content",
        }}
      >
        Add Variables
      </Button>

      {/* Failure Configuration */}
      <Box style={{ width: "100%" }} mt={4} mb={6}>
        <FlexBox columnGap={1}>
          <Typography variant="body2" fontWeight={500}>
            Failure Configuration
          </Typography>
        </FlexBox>

        <FlexBox mt={2} mb={4}>
          <FlexBox columnGap={1} component="label" sx={{ cursor: "pointer" }}>
            <Controller
              control={control}
              name="defaultType"
              render={({ field: { onChange, value } }) => (
                <Radio
                  onClick={() => onChange("addNode")}
                  checked={value === "addNode"}
                  sx={{ padding: 0 }}
                  icon={<Circle width={20} height={20} />}
                  checkedIcon={<RadioButton width={20} height={20} />}
                />
              )}
            />
            <Typography
              variant="caption"
              fontWeight={400}
              color="text.primary"
              sx={{ cursor: "pointer" }}
            >
              Add Node
            </Typography>
          </FlexBox>

          <FlexBox columnGap={1} component="label" sx={{ cursor: "pointer" }}>
            <Controller
              control={control}
              name="defaultType"
              render={({ field: { onChange, value } }) => (
                <Radio
                  onClick={() => onChange("triggerWorkflow")}
                  checked={value === "triggerWorkflow"}
                  sx={{ padding: 0 }}
                  icon={<Circle width={20} height={20} />}
                  checkedIcon={<RadioButton width={20} height={20} />}
                />
              )}
            />

            <Typography
              variant="caption"
              fontWeight={400}
              color="text.primary"
              sx={{ cursor: "pointer" }}
            >
              Trigger Workflow
            </Typography>
          </FlexBox>
        </FlexBox>

        {watch("defaultType") === "addNode" && (
          <FlexBox mb={6} gap={2}>
            <IconInfo2 style={{ width: 20, height: 20 }} />
            <Typography variant="caption" color="#0f0f0f">
              Configure directly from the node.
            </Typography>
          </FlexBox>
        )}

        <Collapse
          in={watch("defaultType") === "triggerWorkflow"}
          mountOnEnter
          unmountOnExit
        >
          <div key={watch("defaultType")}>
            <Typography variant="caption" mt={4} color="#0f0f0f">
              Failure Workflow
            </Typography>
            <Controller
              control={control}
              name="selectedWorkflow"
              rules={{ required: "Workflow is required" }}
              render={({ field: { onChange, value } }) => (
                <AutoComplete
                  value={value}
                  renderOptionKey="name"
                  autoComplete
                  error={errors?.workflow}
                  // optionList={workflows?.workFlows}
                  optionList={optionsList}
                  // onChange={onChange}
                  closeIcon={false}
                  ListboxProps={{ style: { maxHeight: "12rem" } }}
                  className={styles.formField}
                  onChange={(newValue) => {
                    if (newValue && newValue._id === "create_workflow") {
                      setShowInputModal(true);
                    } else {
                      onChange(newValue);
                    }
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Typography variant="body2">
                        {option._id === "create_workflow" ? (
                          <FlexBox
                            style={{ backgroundColor: `white !important` }}
                          >
                            <Plus size={16} />
                            {option.name}
                          </FlexBox>
                        ) : (
                          option.name
                        )}
                      </Typography>
                    </li>
                  )}
                  PaperComponent={({ children }) => (
                    <Grow in={true} mountOnEnter unmountOnExit>
                      <div className={styles.autoCompleteList}>{children}</div>
                    </Grow>
                  )}
                />
              )}
            />
            <InputModal
              open={showInputModal}
              onClose={() => setShowInputModal(false)}
              onConfirm={(name) => {
                handleCreateWorkflow(name);
              }}
              title={"Create Workflow"}
              submitText={"Create"}
              placeholder="Enter workflow name"
            />
          </div>
        </Collapse>
        {errors?.selectedWorkflow && (
          <Box
            mb={4}
            mt={2}
            component="span"
            display="flex"
            alignItems="center"
          >
            <RedAlert style={{ marginRight: 8 }} />
            <Typography variant="caption" color="error">
              {errors?.selectedWorkflow?.message}
            </Typography>
          </Box>
        )}
      </Box>

      <Button
        variant="dark"
        sx={{
          borderRadius: 82,
          minWidth: 0,
          mt: "auto",
          width: "max-content",
        }}
        onClick={handleSubmit(handleOnSave)}
        endIcon={<ChevronRight width={20} height={20} />}
      >
        Save
      </Button>

      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: 12,
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            padding: "40px 25px",
          }}
        >
          <IconButton
            onClick={() => setShowModal(false)}
            sx={{ position: "absolute", right: 5, top: 5 }}
          >
            <X width={20} height={20} />
          </IconButton>

          <Typography variant="h3" fontWeight={500} mb={4} textAlign="center">
            Add Value
          </Typography>

          {variableValues.map((variable, index) => (
            <FlexBox
              key={index}
              sx={{ mb: 2, justifyContent: "center", gap: 4 }}
            >
              <Typography
                variant="body2"
                fontWeight={500}
                sx={{ width: "30%", textAlign: "right" }}
              >
                {variable.name}
              </Typography>
              <TextField
                placeholder="Value"
                value={variable.value}
                onChange={(e) => handleVariableChange(index, e.target.value)}
                sx={{
                  width: "30%",
                  "& div": {
                    borderRadius: 3,
                  },
                }}
              />
            </FlexBox>
          ))}

          <FlexBox justifyContent="center" columnGap={4} mt={2}>
            <Button
              variant="whiteRound"
              onClick={() => {
                setVariableValues([]);
                setShowModal(false);
              }}
            >
              Cancel
            </Button>

            <Button
              variant="dark"
              sx={{
                minWidth: 0,
                width: "fit-content",
              }}
              onClick={handleSaveVariables}
              endIcon={<ChevronRight width={20} height={20} />}
            >
              Send
            </Button>
          </FlexBox>
        </Box>
      </Dialog>
    </Box>
  );
};

RestApiProperties.propTypes = {
  onSave: PropTypes.func,
};

export default RestApiProperties;
