import { Box, Collapse } from "@mui/material";
import { ChevronRight } from "components/SVG";
import styles from "./index.module.css";
import { cloneElement, useEffect, useState } from "react";
import PropTypes from "prop-types";

const FunCollapse = ({
  icon,
  header,
  children,
  iconPosition = "end",
  headerStyle,
  headerClassName,
  childrenStyle,
  childrenClassName,
  collapsed,
  manualOpen,
}) => {
  const [show, setShow] = useState(false);

  const clonedIcon = icon
    ? cloneElement(icon, {
        className: styles.chevron,
        style: { transform: show && "rotate(90deg)" },
      })
    : null;

  useEffect(() => {
    setShow(collapsed);
  }, [collapsed]);

  return (
    <>
      <Box
        className={styles.collapseContainer}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 4,
          width: "100%",
          cursor: "pointer",
        }}
        onClick={() => setShow(!show)}
      >
        {iconPosition === "start" &&
          (icon ? (
            clonedIcon
          ) : (
            <ChevronRight
              width={16}
              height={16}
              color="#0f0f0f"
              className={styles.chevron}
              style={{ transform: show && "rotate(90deg)" }}
            />
          ))}
        <Box
          style={headerStyle}
          sx={{ width: "100% " }}
          className={headerClassName}
        >
          {header}
        </Box>

        {iconPosition === "end" &&
          (icon ? (
            clonedIcon
          ) : (
            <ChevronRight
              width={16}
              height={16}
              className={styles.chevron}
              style={{ transform: show && "rotate(90deg)" }}
            />
          ))}
      </Box>
      <Collapse in={show || manualOpen} unmountOnExit={true}>
        <Box style={childrenStyle} className={childrenClassName}>
          {children}
        </Box>
      </Collapse>
    </>
  );
};

FunCollapse.propTypes = {
  collapsed: PropTypes.bool,
};

FunCollapse.defaultProps = {
  collapsed: false,
};

export default FunCollapse;
