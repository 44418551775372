import {
  Box,
  Button,
  Checkbox,
  Fade,
  Grow,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Radio,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CheckSquare,
  Eye,
  EyeClose,
  RadioButton,
  TextFlatMp3,
  TextFlatWav,
} from "components/newSVG";
import { ChevronRight, Circle, Plus, Trash2 } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { IconInfo2, IconUpload2, RedAlert } from "components/SVG";
import { useSkillrToast } from "context/toast";
import uploadAudioFileApi from "services/uploadAudioFile";
import { gscUriPrefix } from "text-constants/common";
import FlexBox from "components/FlexBox";
import theme from "theme";
import styles from "./index.module.css";
import VariableDropdown from "../VariableDropdown";
import {
  checkDuplicateName,
  getAudioDuration,
  getNewFileName,
} from "utils/utils";
import AutoComplete from "components/AutoComplete";
import { languages as defaultLanguages } from "text-constants/common";
import AudioPlayer from "pages/Bots/components/AudioPlayer";
import AddFillerModal from "../../AddFillerModal";
import { createWorkFlow } from "services";
import InputModal from "components/InputModal";
import { useUserContext } from "context/user";
const MAX_AUDIO_TIME = 20; // 20 sec

const ConversationProperties = (props) => {
  const {
    onSave,
    data,
    workFlowId,
    workflows,
    localizationMode,
    currentLanguage,
    token,
    onEdit,
  } = props;
  const fileInputRef = useRef();
  const { showSkillrToast } = useSkillrToast();
  const [uploadedDetails, setUploadedDetails] = useState(data);
  const [uploadProgress, setUploadProgress] = useState();
  const supportedFileTypes = ["audio/wav", "audio/mpeg"];
  const MAX_AUDIO_SIZE = 2 * 1024 * 1024; // 2MB
  const [showDefault, setShowDefault] = useState(false);
  const [showFillerModal, setShowFillerModal] = useState(false);
  const [optionsList, setOptionsList] = useState();
  const [showInputModal, setShowInputModal] = useState(false);
  const { bot } = useUserContext();

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      message: "",
      type: "text",
      mode: "hybrid",
      repeatLastStep: true,
      repeatValidationMethod: "counterValidate",
      repeatValidationCount: 2,
      wait: true,
      waitValidationMethod: "counterValidate",
      waitValidationCount: 2,
      waitTime: 10,
      defaultType: "addNode",
      selectedWorkflow: "",
      localizedAudio: "",
      localizedMessage: "",
      filler: [],
      intentMatching: false,
      saveUserInput: false,
      variableName: "",
    },
  });

  const filesType = {
    "audio/mpeg": <TextFlatMp3 width={24} height={32} />,
    "audio/wav": <TextFlatWav width={24} height={32} />,
  };

  const handleOnSave = async (data) => {
    data.selectedWorkflow = {
      workflowName: data.selectedWorkflow?.name,
      workflowId: data.selectedWorkflow?._id,
    };

    if (data.customName) {
      const isDuplicate = await checkDuplicateName(
        workFlowId,
        data.customName,
        props.data.name
      );
      if (isDuplicate) {
        showSkillrToast("Node name already exists", "error");
        return;
      }
    }

    if (data.type === "audio") {
      if (localizationMode && !data.localizedAudio) {
        showSkillrToast("Please upload file", "error");
        return;
      } else if (!localizationMode && !uploadedDetails?.gcsUri) {
        showSkillrToast("Please upload file", "error");
        return;
      }
      const updatedLanguageVariations = { ...data.languageVariations };
      if (localizationMode && currentLanguage) {
        updatedLanguageVariations[currentLanguage] = {
          message: data.localizedMessage,
          gcsUri: data.localizedAudio,
        };
      }
      onSave({
        ...data,
        gcsUri: uploadedDetails.gcsUri,
        languageVariations: updatedLanguageVariations,
      });
    } else {
      if (
        data.defaultType === "triggerWorkflow" &&
        !data.selectedWorkflow?.workflowName &&
        !localizationMode
      ) {
        showSkillrToast("Please select default workflow to trigger", "error");
        return;
      }

      const updatedLanguageVariations = { ...data.languageVariations };

      // Add or update current language variation if in localization mode
      if (localizationMode && currentLanguage) {
        updatedLanguageVariations[currentLanguage] = {
          ...(data?.languageVariations?.[currentLanguage] || {}),
          message: data.localizedMessage,
        };
      }
      console.log(updatedLanguageVariations);
      onSave({
        ...data,
        languageVariations: updatedLanguageVariations,
      });
    }
  };

  const handleUploadProgress = (progressEvent) => {
    if (progressEvent?.loaded > 0) {
      setUploadProgress(
        Math.floor((progressEvent?.loaded / progressEvent?.total) * 100)
      );
    }
  };

  const uploadFile = async (file, isLocalized = false) => {
    try {
      const formData = new FormData();
      formData.append("audioFile", file);
      const config = {
        onUploadProgress: handleUploadProgress,
      };
      const uploadResponse = await uploadAudioFileApi(
        workFlowId,
        formData,
        config
      );

      if (isLocalized) {
        setValue("localizedAudio", uploadResponse.gcsUri);
      } else {
        setUploadedDetails(uploadResponse);
      }
      fileInputRef.current.value = "";
    } catch (error) {
      fileInputRef.current.value = "";
      setUploadProgress();
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleFileChange = async (event, isLocalized = false) => {
    try {
      const file = event.target.files[0];
      const renamedFile = getNewFileName(file);
      const audioDuration = await getAudioDuration(renamedFile);
      if (audioDuration < MAX_AUDIO_TIME) {
        uploadFile(renamedFile, isLocalized);
      } else {
        showSkillrToast("Audio duration should not exceed 20 seconds", "error");
      }
    } catch (error) {
      showSkillrToast(error?.message, "error");
    }
  };

  const renderAudioSelector = (isLocalized = false) => (
    <div style={{ marginBottom: 16 }}>
      {(isLocalized ? watch("localizedAudio") : uploadedDetails?.gcsUri) ? (
        <Box display="flex" gap={3} alignItems="center">
          <audio controls>
            <source
              src={(isLocalized
                ? watch("localizedAudio")
                : uploadedDetails?.gcsUri
              ).replace("gs://", gscUriPrefix)}
              type={
                "audio/" +
                (isLocalized
                  ? watch("localizedAudio")
                  : uploadedDetails?.gcsUri
                )
                  .split(".")
                  .pop()
              }
              // src={`${
              //   process.env.REACT_APP_BACKEND_API_ENDPOINT || window.location.protocol + "//" + window.location.hostname
              // }/api/asset/${
              //   uploadedDetails?.gcsUri.split("//")[1]
              // }?authToken=${token}`}
            ></source>
          </audio>

          <IconButton onClick={() => handleOnRemoveAudio(isLocalized)}>
            <Trash2 width={16} height={16} />
          </IconButton>
        </Box>
      ) : (
        <Box
          display="flex"
          sx={{
            border: `1px dashed #0f0f0f`,
            background: "#fff",
            gap: 2,
            padding: 4,
            borderRadius: 3,
            cursor: "pointer",
          }}
          onClick={() => handleFileUpload(isLocalized)}
        >
          {uploadProgress ? (
            <LinearProgress
              value={uploadProgress}
              color="primary"
              variant="determinate"
              style={{ width: "100%" }}
              sx={{
                height: 5,
                borderRadius: 5,
                bgcolor: "#E9E9E9",
                "& .MuiLinearProgress-bar": {
                  borderRadius: 5,
                  bgcolor: "#0F0F0F",
                },
              }}
            />
          ) : (
            <>
              <IconUpload2 width={16} height={16} />
              <Typography variant="caption" color="#0f0f0f">
                Upload File
              </Typography>

              <Typography variant="caption" sx={{ fontSize: 10, ml: "auto" }}>
                Max time: 20 Sec
              </Typography>
            </>
          )}
        </Box>
      )}

      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        accept={supportedFileTypes.join(",")}
        onChange={(event) => handleFileChange(event, true)}
      />
    </div>
  );

  const handleOnRemoveAudio = (isLocalized = false) => {
    if (isLocalized) {
      setValue("localizedAudio", "");
    } else {
      setUploadedDetails(null);
    }
    setUploadProgress();
  };

  const handleFileUpload = (isLocalized = false) => {
    fileInputRef.current.click();
  };

  const handleOnSaveFillerDetails = (newFillerDetails) => {
    const currentFillers = getValues().filler || [];
    const newFiller = newFillerDetails?.text
      ? {
          type: "text",
          text: { value: newFillerDetails.text },
          language: {
            languageCode: currentLanguage,
            languageName: defaultLanguages.find(
              (l) => l.value === currentLanguage
            ).name,
          },
        }
      : {
          type: "audio",
          audio: {
            type: newFillerDetails?.file?.type,
            name: newFillerDetails?.file?.name,
            url: newFillerDetails?.fileUrl,
            duration: newFillerDetails?.fileDuration,
          },
          language: {
            languageCode: currentLanguage,
            languageName: defaultLanguages.find(
              (l) => l.value === currentLanguage
            ).name,
          },
        };

    const updatedFillers = currentFillers.filter(
      (filler) => filler.language.languageCode !== currentLanguage
    );
    updatedFillers.push(newFiller);

    setValue("filler", updatedFillers);
    setShowFillerModal(false);
  };

  const handleOnDelete = () => {
    const currentFillers = getValues().filler;
    setValue(
      "filler",
      currentFillers.filter(
        (filler) => filler.language.languageCode !== currentLanguage
      )
    );
  };

  const createNewWorkFlow = async (data) => {
    try {
      const response = await createWorkFlow(data);
      setOptionsList((prev) => [
        { name: response.name, _id: response._id },
        ...prev,
      ]);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };
  const handleCreateWorkflow = (name) => {
    setShowInputModal(false);
    createNewWorkFlow({ name }).then((response) => {
      if (response) {
        const newWorkflow = {
          name: response.name,
          _id: response._id,
        };
        reset({
          ...getValues(),
          selectedWorkflow: newWorkflow,
        });
      }
    });
  };

  useEffect(() => {
    if (
      data?.message ||
      (data?.languageVariations &&
        data?.languageVariations[currentLanguage]?.message)
    ) {
      reset({
        ...data,
        selectedWorkflow: {
          name: data.selectedWorkflow?.workflowName || "",
          _id: data.selectedWorkflow?.workflowId,
        },
        localizedMessage:
          data.languageVariations?.[currentLanguage]?.message || "",
        localizedAudio:
          data.languageVariations?.[currentLanguage]?.gcsUri || "",
      });
    }
  }, [data, currentLanguage]);

  useEffect(() => {
    setOptionsList([
      { name: "Create Workflow", _id: "create_workflow" },
      ...(workflows?.workFlows || []),
    ]);
  }, [workflows]);

  useEffect(() => {
    onEdit(isDirty);
  }, [isDirty]);

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
      {/* type selector */}
      <Controller
        control={control}
        name="type"
        render={({ field: { onChange, value } }) => (
          <div style={{ width: "100%", marginBottom: 16 }}>
            <Box display="flex" gap={5}>
              <Box
                display="flex"
                gap={1}
                alignItems="center"
                component={"label"}
                sx={{ cursor: "pointer" }}
              >
                <Radio
                  onClick={() => onChange("text")}
                  checked={value === "text"}
                  sx={{ padding: 0 }}
                  icon={<Circle width={24} height={24} />}
                  checkedIcon={<RadioButton width={24} height={24} />}
                />
                <Typography
                  variant="caption"
                  fontWeight={400}
                  color={
                    watch("type") === "text" ? "text.primary" : "text.secondary"
                  }
                >
                  Text
                </Typography>
              </Box>
              <Box
                display="flex"
                gap={1}
                alignItems="center"
                component={"label"}
                sx={{ cursor: "pointer" }}
              >
                <Radio
                  onClick={() => onChange("audio")}
                  checked={value === "audio"}
                  sx={{ padding: 0 }}
                  icon={<Circle width={20} height={20} />}
                  checkedIcon={<RadioButton width={20} height={20} />}
                />
                <Typography
                  variant="caption"
                  fontWeight={400}
                  color="text.primary"
                >
                  Audio
                </Typography>
              </Box>
            </Box>
          </div>
        )}
      />
      {localizationMode ? (
        <>
          <Button
            variant="text"
            endIcon={
              showDefault ? (
                <Eye width={20} height={20} />
              ) : (
                <EyeClose width={20} height={20} />
              )
            }
            color="inherit"
            sx={{ minWidth: 0, width: "fit-content", mb: 4 }}
            onClick={() => setShowDefault(!showDefault)}
          >
            View Default
          </Button>

          <Grow in={showDefault} mountOnEnter unmountOnExit>
            <Box
              fullWidth
              sx={{
                backgroundColor: theme.palette.grey[50],
                borderRadius: 3,
                border: `1px solid ${theme.palette.grey[100]}`,
                padding: `16px 20px`,
                mb: 4,
              }}
            >
              {watch("type") === "text" ? (
                <Typography variant="body2">{data?.message}</Typography>
              ) : (
                <Box display="flex" gap={3} alignItems="center">
                  {data?.gcsUri && (
                    <audio controls>
                      <source
                        src={data?.gcsUri.replace("gs://", gscUriPrefix)}
                        type={"audio/" + data?.gcsUri.split(".").pop()}
                      ></source>
                    </audio>
                  )}
                </Box>
              )}
            </Box>
          </Grow>

          {/* Localized audio file selector */}
          {watch("type") === "audio" && renderAudioSelector(true)}

          <Controller
            control={control}
            name="localizedMessage"
            rules={{
              required:
                watch("type") === "text"
                  ? "Message is required"
                  : "Transcript is required",
            }}
            render={({ field: { onChange, value } }) => (
              <VariableDropdown
                control={control}
                watch={watch}
                setValue={setValue}
                value={value}
                onChange={onChange}
              >
                <TextField
                  variant="outlined"
                  multiline
                  rows={5}
                  fullWidth
                  placeholder={
                    watch("type") === "text"
                      ? `Add message for ${
                          defaultLanguages.find(
                            (l) => l.value === currentLanguage
                          )?.name
                        }`
                      : `Add transcript for ${
                          defaultLanguages.find(
                            (l) => l.value === currentLanguage
                          )?.name
                        }`
                  }
                  sx={{
                    "& div": {
                      borderRadius: "12px",
                      padding: 0,
                    },
                    "& textarea": {
                      marginBottom: "32px",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          alignSelf: "flex-end",
                          mb: 4,
                          mr: 2,
                          position: "absolute",
                          right: 0,
                        }}
                      >
                        {`${value?.length || 0} / 500`}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 500 }}
                />
              </VariableDropdown>
            )}
          />

          {errors?.localizedMessage && (
            <FlexBox
              mt={2}
              component="span"
              columnGap={1}
              fontSize={12}
              color={"error.main"}
            >
              <RedAlert style={{ marginRight: 8 }} />
              {errors?.localizedMessage?.message}
            </FlexBox>
          )}

          {watch("filler") && watch("filler").length > 0 && (
            <Box mt={4}>
              <Typography variant="body2" fontWeight={500}>
                Filler Message
              </Typography>

              {watch("filler")
                .filter(
                  (filler) => filler.language.languageCode === currentLanguage
                )
                .map((filler, index) => (
                  <FlexBox
                    key={index}
                    sx={{
                      border: `1px solid ${theme.palette.borderColor.light}`,
                      borderRadius: 3,
                      mt: 1,
                    }}
                    padding={4}
                  >
                    {filler.type === "text" ? (
                      <Tooltip
                        title={
                          filler.text?.value?.length > 64
                            ? filler.text?.value
                            : ""
                        }
                      >
                        <Typography
                          variant="caption"
                          color={theme.palette.text.primary}
                          fontWeight={400}
                          className={styles.textOverFlow}
                          ml={2}
                        >
                          {filler.text?.value}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <>
                        {filesType[filler.audio?.type]}
                        <Tooltip
                          title={
                            filler.audio?.name?.length > 64
                              ? filler.audio?.name
                              : ""
                          }
                        >
                          <Typography
                            variant="caption"
                            color={theme.palette.text.primary}
                            fontWeight={400}
                            className={styles.textOverFlow}
                            ml={3}
                          >
                            {filler.audio?.name}
                          </Typography>
                        </Tooltip>
                        <Box sx={{ ml: "auto" }}>
                          <AudioPlayer
                            key={filler.audio?.url}
                            url={filler.audio?.url.replace(
                              "gs://",
                              gscUriPrefix
                            )}
                            type={filler.audio?.type}
                          />
                        </Box>
                      </>
                    )}
                    <IconButton
                      sx={{ marginLeft: "auto" }}
                      onClick={() => handleOnDelete(index)}
                    >
                      <Trash2 style={{ width: 20, height: 20 }} />
                    </IconButton>
                  </FlexBox>
                ))}
            </Box>
          )}

          <Button
            fullWidth
            variant="outlined"
            sx={{ borderRadius: 82, my: 4 }}
            onClick={() => setShowFillerModal(true)}
          >
            Add Filler
          </Button>

          <AddFillerModal
            open={showFillerModal}
            onClose={() => setShowFillerModal(false)}
            onSave={handleOnSaveFillerDetails}
          />
        </>
      ) : (
        <>
          <Typography variant="caption" color="text.primary" mb={1}>
            Node Name
          </Typography>

          <Controller
            control={control}
            name="customName"
            rules={{
              validate: (value) => {
                if (/[^a-zA-Z\s]/.test(value))
                  return "Node name can only contain letters and spaces";
                return true;
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                variant="outlined"
                inputProps={{ maxLength: 20 }}
                fullWidth
                placeholder="Add Name"
                value={value}
                onChange={onChange}
                error={errors?.customName}
                helperText={errors?.customName?.message}
                sx={{
                  mb: 4,
                  "& div": {
                    borderRadius: "12px",
                    padding: 0,
                  },
                }}
              />
            )}
          />

          {/* audio selector if type is audio */}
          <Grow in={watch("type") === "audio"} mountOnEnter unmountOnExit>
            <div style={{ marginBottom: 16 }}>
              {typeof uploadedDetails?.gcsUri === "string" && (
                <Box display="flex" gap={3} alignItems="center">
                  <audio controls>
                    <source
                      src={uploadedDetails?.gcsUri?.replace(
                        "gs://",
                        gscUriPrefix
                      )}
                      type={"audio/" + uploadedDetails?.gcsUri.split(".").pop()}
                    ></source>
                  </audio>

                  <IconButton onClick={() => handleOnRemoveAudio(false)}>
                    <Trash2 width={16} height={16} />
                  </IconButton>
                </Box>
              )}

              {!uploadedDetails?.gcsUri && (
                <Box
                  display="flex"
                  sx={{
                    border: `1px dashed #0f0f0f`,
                    background: "#fff",
                    gap: 2,
                    padding: 4,
                    borderRadius: 3,
                    cursor: "pointer",
                  }}
                  onClick={handleFileUpload}
                >
                  {uploadProgress ? (
                    <LinearProgress
                      value={uploadProgress}
                      color="primary"
                      variant="determinate"
                      style={{ width: "100%" }}
                      sx={{
                        height: 5,
                        borderRadius: 5,
                        bgcolor: "#E9E9E9",
                        "& .MuiLinearProgress-bar": {
                          borderRadius: 5,
                          bgcolor: "#0F0F0F",
                        },
                      }}
                    />
                  ) : (
                    <>
                      <IconUpload2 width={16} height={16} />
                      <Typography variant="caption" color="#0f0f0f">
                        Upload File
                      </Typography>

                      <Typography
                        variant="caption"
                        sx={{ fontSize: 10, ml: "auto" }}
                      >
                        Max time: 20 Sec
                      </Typography>
                    </>
                  )}
                </Box>
              )}

              <input
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
                ref={fileInputRef}
                accept={supportedFileTypes.join(",")}
              />
            </div>
          </Grow>

          <Controller
            control={control}
            name="message"
            rules={{
              required:
                watch("type") === "text"
                  ? "Message is required"
                  : "Transcript is required",
            }}
            render={({ field: { onChange, value } }) => (
              <VariableDropdown
                control={control}
                watch={watch}
                setValue={setValue}
                value={value}
                onChange={onChange}
              >
                <TextField
                  variant="outlined"
                  multiline
                  rows={5}
                  fullWidth
                  placeholder={
                    watch("type") === "text"
                      ? "Add Message*"
                      : "Add Transcript*"
                  }
                  sx={{
                    "& div": {
                      // overflow: "hidden",
                      borderRadius: "12px",
                      padding: 0,
                    },
                    "& textarea": {
                      marginBottom: "32px",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          alignSelf: "flex-end",
                          mb: 4,
                          mr: 2,
                          position: "absolute",
                          right: 0,
                        }}
                      >
                        {`${watch("message")?.length} / 500`}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 500 }}
                />
              </VariableDropdown>
            )}
          />
          {errors?.message && (
            <FlexBox
              mt={2}
              component="span"
              columnGap={1}
              fontSize={12}
              color={"error.main"}
            >
              <RedAlert style={{ marginRight: 8 }} />
              {watch("type") === "text"
                ? errors?.message?.message
                : "Transcript is required"}
            </FlexBox>
          )}

          {/* mode selector */}
          <Controller
            control={control}
            name="mode"
            render={({ field: { onChange, value } }) => (
              <div style={{ width: "100%", margin: "24px 0" }}>
                <FlexBox justifyContent="space-between">
                  <FlexBox
                    columnGap={1}
                    component={"label"}
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      onClick={() => onChange("prompt")}
                      checked={value === "prompt"}
                      sx={{ padding: 0 }}
                      icon={<Circle width={24} height={24} />}
                      checkedIcon={<RadioButton width={24} height={24} />}
                    />
                    <Typography
                      variant="caption"
                      fontWeight={400}
                      color={
                        watch("mode") === "prompt"
                          ? "text.primary"
                          : "text.secondary"
                      }
                    >
                      Prompt
                    </Typography>
                  </FlexBox>
                  <FlexBox
                    columnGap={1}
                    component={"label"}
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      onClick={() => onChange("hybrid")}
                      checked={value === "hybrid"}
                      sx={{ padding: 0 }}
                      icon={<Circle width={20} height={20} />}
                      checkedIcon={<RadioButton width={20} height={20} />}
                    />
                    <Typography
                      variant="caption"
                      fontWeight={400}
                      color={
                        watch("mode") === "hybrid"
                          ? "text.primary"
                          : "text.secondary"
                      }
                    >
                      Hybrid
                    </Typography>
                  </FlexBox>
                  <FlexBox
                    columnGap={1}
                    component={"label"}
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      onClick={() => onChange("vectors")}
                      checked={value === "vectors"}
                      sx={{ padding: 0 }}
                      icon={<Circle width={20} height={20} />}
                      checkedIcon={<RadioButton width={20} height={20} />}
                    />
                    <Typography
                      variant="caption"
                      fontWeight={400}
                      color={
                        watch("mode") === "vectors"
                          ? "text.primary"
                          : "text.secondary"
                      }
                    >
                      Vectors
                    </Typography>
                  </FlexBox>
                </FlexBox>
              </div>
            )}
          />

          <Box>
            <Fade
              in={watch("mode") === "prompt"}
              mountOnEnter
              unmountOnExit
              timeout={{ enter: 500, exit: 0 }}
            >
              <div>
                <Typography variant="body2" fontWeight={500} mb={2}>
                  Prompt
                </Typography>

                <Typography
                  variant="caption"
                  color="text.primary"
                  mb={3}
                  component={"p"}
                  fontWeight={400}
                >
                  Conversation outcomes are driven through LLM decision making
                </Typography>
              </div>
            </Fade>
            <Fade
              in={watch("mode") === "hybrid"}
              mountOnEnter
              unmountOnExit
              timeout={{ enter: 500, exit: 0 }}
            >
              <div>
                <Typography variant="body2" fontWeight={500} mb={2}>
                  Hybrid
                </Typography>

                <Typography
                  variant="caption"
                  color="text.primary"
                  mb={3}
                  component={"p"}
                  fontWeight={400}
                >
                  Conversation outcomes are driven through a mix of vector
                  matching and LLM decision making
                </Typography>
              </div>
            </Fade>
            <Fade
              in={watch("mode") === "vectors"}
              mountOnEnter
              unmountOnExit
              timeout={{ enter: 500, exit: 0 }}
            >
              <div>
                <Typography variant="body2" fontWeight={500} mb={2}>
                  Vectors
                </Typography>

                <Typography
                  variant="caption"
                  color="text.primary"
                  mb={3}
                  component={"p"}
                  fontWeight={400}
                >
                  Conversation outcomes are driven through vector matching
                </Typography>
              </div>
            </Fade>

            {/* <FlexBox columnGap={2}>
              <Typography variant="body2" fontWeight={500}>
                Next node
              </Typography>

              <Tooltip title="The next node is triggered when the response is positive with respect to the question">
                <IconInfo2 style={{ flexShrink: 0 }} />
              </Tooltip>
            </FlexBox> */}

            {/* intent matching switch */}
            <Controller
              control={control}
              name="intentMatching"
              render={({ field: { onChange, value } }) => (
                <FlexBox
                  columnGap={2}
                  mt={3}
                  component="label"
                  sx={{ cursor: "pointer" }}
                >
                  {/* <Checkbox
                    onChange={onChange}
                    checked={value}
                    sx={{ padding: 0 }}
                    checkedIcon={<CheckSquare width={20} height={20} />}
                  /> */}
                  <Typography variant="body2" fontWeight={500}>
                    Intent Matching
                  </Typography>

                  <Switch
                    size="small"
                    checked={value}
                    onChange={onChange}
                    sx={{
                      "& .MuiSwitch-thumb": {
                        backgroundColor: theme.palette.common.white,
                        boxShadow: "0px 3.2px 3.2px 0px rgba(0, 0, 0, 0.25)",
                      },
                      "& .Mui-checked+.MuiSwitch-track": {
                        opacity: `1 !important`,
                      },
                    }}
                  />

                  <Tooltip
                    title="When enabled, the bot will attempt to match user input with a knowledge base  intent. The match threshold can be adjusted in the bot settings."
                    placement="top"
                  >
                    <IconInfo2 style={{ flexShrink: 0 }} />
                  </Tooltip>
                </FlexBox>
              )}
            />
            <Typography variant="body2" sx={{ opacity: 0.8 }} mt={1}>
              Match threshold is set to {bot.threshold}
            </Typography>

            {/* save user input switch */}
            <Controller
              control={control}
              name="saveUserInput"
              render={({ field: { onChange, value } }) => (
                <FlexBox
                  columnGap={2}
                  mt={3}
                  component="label"
                  sx={{ cursor: "pointer" }}
                >
                  <Typography variant="body2" fontWeight={500}>
                    Save User Input
                  </Typography>

                  <Switch
                    size="small"
                    checked={value}
                    onChange={onChange}
                    sx={{
                      "& .MuiSwitch-thumb": {
                        backgroundColor: theme.palette.common.white,
                        boxShadow: "0px 3.2px 3.2px 0px rgba(0, 0, 0, 0.25)",
                      },
                      "& .Mui-checked+.MuiSwitch-track": {
                        opacity: `1 !important`,
                      },
                    }}
                  />

                  <Tooltip
                    title="Captures the user's response in a variable, visible in the call transcript on the call details page."
                    placement="top"
                  >
                    <IconInfo2 style={{ flexShrink: 0 }} />
                  </Tooltip>
                </FlexBox>
              )}
            />

            <Grow in={watch("saveUserInput")} mountOnEnter unmountOnExit>
              <div style={{ margin: "12px 0 16px" }}>
                <Typography
                  variant="caption"
                  fontWeight={500}
                  color={"text.primary"}
                >
                  Variable Name
                </Typography>

                <Controller
                  control={control}
                  name="variableName"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ borderRadius: "12px" }}
                      variant="outlined"
                      fullWidth
                      placeholder="Enter variable name"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </Grow>

            {/* repeat last step checkbox */}
            <Controller
              control={control}
              name="repeatLastStep"
              render={({ field: { onChange, value } }) => (
                <FlexBox
                  columnGap={2}
                  mt={3}
                  component="label"
                  sx={{ cursor: "pointer" }}
                >
                  {/* <Checkbox
                    onChange={onChange}
                    checked={value}
                    sx={{ padding: 0 }}
                    checkedIcon={<CheckSquare width={20} height={20} />}
                  /> */}
                  <Typography variant="body2" fontWeight={500}>
                    Repeat Command
                  </Typography>

                  <Switch
                    size="small"
                    checked={value}
                    onChange={onChange}
                    sx={{
                      "& .MuiSwitch-thumb": {
                        backgroundColor: theme.palette.common.white,
                        boxShadow: "0px 3.2px 3.2px 0px rgba(0, 0, 0, 0.25)",
                      },
                      "& .Mui-checked+.MuiSwitch-track": {
                        opacity: `1 !important`,
                      },
                    }}
                  />

                  <Tooltip
                    title="Resend the previous message when the customer requests a repeat. Useful for ensuring clarity or repeating important information."
                    placement="top"
                  >
                    <IconInfo2 style={{ flexShrink: 0 }} />
                  </Tooltip>
                </FlexBox>
              )}
            />

            <Grow in={watch("repeatLastStep")} unmountOnExit mountOnEnter>
              <div>
                {/* validation method */}
                <Controller
                  control={control}
                  name="repeatValidationMethod"
                  render={({ field: { onChange, value } }) => (
                    <FlexBox my={3}>
                      <FlexBox
                        columnGap={2}
                        component={"label"}
                        sx={{ cursor: "pointer" }}
                      >
                        <Radio
                          onClick={() => onChange("counterValidate")}
                          checked={value === "counterValidate"}
                          sx={{ padding: 0 }}
                          icon={<Circle width={20} height={20} />}
                          checkedIcon={<RadioButton width={20} height={20} />}
                        />

                        <Typography
                          variant="caption"
                          fontWeight={400}
                          color="text.primary"
                        >
                          Counter Validate
                        </Typography>
                      </FlexBox>

                      <FlexBox
                        columnGap={2}
                        component={"label"}
                        sx={{ cursor: "pointer" }}
                      >
                        <Radio
                          onClick={() => onChange("indefinite")}
                          checked={value === "indefinite"}
                          sx={{ padding: 0 }}
                          icon={<Circle width={20} height={20} />}
                          checkedIcon={<RadioButton width={20} height={20} />}
                        />

                        <Typography
                          variant="caption"
                          fontWeight={400}
                          color="text.primary"
                        >
                          Indefinite
                        </Typography>
                      </FlexBox>
                    </FlexBox>
                  )}
                />
                {/* validation count */}
                <Grow
                  in={
                    watch("repeatValidationMethod") === "counterValidate" &&
                    watch("repeatLastStep")
                  }
                  mountOnEnter
                  unmountOnExit
                >
                  <div style={{ marginTop: 16 }}>
                    <Typography
                      variant="caption"
                      fontWeight={500}
                      color={"text.primary"}
                    >
                      Max Count
                    </Typography>

                    <Controller
                      name="repeatValidationCount"
                      control={control}
                      rules={{
                        required: "Count is required",
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            borderRadius: 0,
                            boxShadow: "none",
                            backgroundColor: theme.palette.grey[50],
                          },
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          value={value}
                          onChange={onChange}
                          className={styles.select}
                          fullWidth
                        >
                          {[...Array(10)].map((_, i) => (
                            <MenuItem key={i} value={i + 1}>
                              {i + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </div>
                </Grow>
              </div>
            </Grow>

            {/* wait checkbox */}
            <Controller
              control={control}
              name="wait"
              render={({ field: { onChange, value } }) => (
                <FlexBox
                  columnGap={2}
                  my={3}
                  component="label"
                  sx={{ cursor: "pointer" }}
                >
                  {/* <Checkbox
                    onChange={onChange}
                    checked={value}
                    sx={{ padding: 0 }}
                    checkedIcon={<CheckSquare width={20} height={20} />}
                  /> */}
                  <Typography variant="body2" fontWeight={500}>
                    Wait Command
                  </Typography>

                  <Switch
                    size="small"
                    checked={value}
                    onChange={onChange}
                    sx={{
                      "& .MuiSwitch-thumb": {
                        backgroundColor: theme.palette.common.white,
                        boxShadow: "0px 3.2px 3.2px 0px rgba(0, 0, 0, 0.25)",
                      },
                      "& .Mui-checked+.MuiSwitch-track": {
                        opacity: `1 !important`,
                      },
                    }}
                  />

                  <Tooltip
                    title="Pause the conversation for a set duration when the customer asks the bot to wait. The bot will resume once the customer responds or the wait time expires."
                    placement="top"
                  >
                    <IconInfo2 style={{ flexShrink: 0 }} />
                  </Tooltip>
                </FlexBox>
              )}
            />
            <Grow in={watch("wait")} unmountOnExit mountOnEnter>
              <div>
                {/* number of seconds */}
                <Controller
                  name="waitTime"
                  control={control}
                  rules={{
                    required: "Wait seconds are required",
                    min: { value: 1, message: "Minimum 1 second" },
                    max: { value: 60, message: "Maximum 60 seconds" },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FlexBox>
                      <TextField
                        type="number"
                        width="30%"
                        value={value}
                        onChange={onChange}
                        inputProps={{
                          min: 1,
                          max: 60,
                        }}
                        helperText={
                          errors?.waitTime && (
                            <Box
                              component="span"
                              display="flex"
                              alignItems="center"
                            >
                              <RedAlert style={{ marginRight: 8 }} />
                              {errors?.waitTime?.message}
                            </Box>
                          )
                        }
                      />
                      <Typography variant="body2" color="text.secondary">
                        Seconds
                      </Typography>
                    </FlexBox>
                  )}
                />

                {/* validation method */}
                <Controller
                  control={control}
                  name="waitValidationMethod"
                  render={({ field: { onChange, value } }) => (
                    <FlexBox my={3}>
                      <FlexBox
                        columnGap={2}
                        component={"label"}
                        sx={{ cursor: "pointer" }}
                      >
                        <Radio
                          onClick={() => onChange("counterValidate")}
                          checked={value === "counterValidate"}
                          sx={{ padding: 0 }}
                          icon={<Circle width={20} height={20} />}
                          checkedIcon={<RadioButton width={20} height={20} />}
                        />

                        <Typography
                          variant="caption"
                          fontWeight={400}
                          color="text.primary"
                        >
                          Counter Validate
                        </Typography>
                      </FlexBox>

                      <FlexBox
                        columnGap={2}
                        component={"label"}
                        sx={{ cursor: "pointer" }}
                      >
                        <Radio
                          onClick={() => onChange("indefinite")}
                          checked={value === "indefinite"}
                          sx={{ padding: 0 }}
                          icon={<Circle width={20} height={20} />}
                          checkedIcon={<RadioButton width={20} height={20} />}
                        />

                        <Typography
                          variant="caption"
                          fontWeight={400}
                          color="text.primary"
                        >
                          Indefinite
                        </Typography>
                      </FlexBox>
                    </FlexBox>
                  )}
                />
                {/* validation count */}
                <Grow
                  in={
                    watch("waitValidationMethod") === "counterValidate" &&
                    watch("wait")
                  }
                  mountOnEnter
                  unmountOnExit
                >
                  <div style={{ margin: "16px 0" }}>
                    <Typography
                      variant="caption"
                      fontWeight={500}
                      color={"text.primary"}
                    >
                      Max Count
                    </Typography>
                    <Controller
                      name="waitValidationCount"
                      control={control}
                      rules={{
                        required: "Count is required",
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            borderRadius: 0,
                            boxShadow: "none",
                            backgroundColor: theme.palette.grey[50],
                          },
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          value={value}
                          onChange={onChange}
                          className={styles.select}
                          fullWidth
                        >
                          {[...Array(10)].map((_, i) => (
                            <MenuItem key={i} value={i + 1}>
                              {i + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </div>
                </Grow>
              </div>
            </Grow>

            {watch("filler") && watch("filler").length > 0 && (
              <Box mt={4}>
                <Typography variant="body2" fontWeight={500}>
                  Filler Message
                </Typography>

                {watch("filler")
                  .filter(
                    (filler) => filler.language.languageCode === currentLanguage
                  )
                  .map((filler, index) => (
                    <FlexBox
                      key={index}
                      sx={{
                        border: `1px solid ${theme.palette.borderColor.light}`,
                        borderRadius: 3,
                        mt: 1,
                      }}
                      padding={4}
                    >
                      {filler.type === "text" ? (
                        <Tooltip
                          title={
                            filler.text?.value?.length > 64
                              ? filler.text?.value
                              : ""
                          }
                        >
                          <Typography
                            variant="caption"
                            color={theme.palette.text.primary}
                            fontWeight={400}
                            className={styles.textOverFlow}
                            ml={2}
                          >
                            {filler.text?.value}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <>
                          {filesType[filler.audio?.type]}
                          <Tooltip
                            title={
                              filler.audio?.name?.length > 64
                                ? filler.audio?.name
                                : ""
                            }
                          >
                            <Typography
                              variant="caption"
                              color={theme.palette.text.primary}
                              fontWeight={400}
                              className={styles.textOverFlow}
                              ml={3}
                            >
                              {filler.audio?.name}
                            </Typography>
                          </Tooltip>
                          <Box sx={{ ml: "auto" }}>
                            <AudioPlayer
                              key={filler.audio?.url}
                              url={filler.audio?.url.replace(
                                "gs://",
                                gscUriPrefix
                              )}
                              type={filler.audio?.type}
                            />
                          </Box>
                        </>
                      )}
                      <IconButton
                        sx={{ marginLeft: "auto" }}
                        onClick={() => handleOnDelete(index)}
                      >
                        <Trash2 style={{ width: 20, height: 20 }} />
                      </IconButton>
                    </FlexBox>
                  ))}
              </Box>
            )}

            <Button
              fullWidth
              variant="outlined"
              sx={{ borderRadius: 82, mt: 4 }}
              onClick={() => setShowFillerModal(true)}
            >
              {/* check if current language filler already exists, if it does say replace filler else add filler */}
              {watch("filler") &&
              watch("filler").find(
                (filler) => filler.language.languageCode === currentLanguage
              )
                ? "Replace Filler"
                : "Add Filler"}
            </Button>

            <AddFillerModal
              open={showFillerModal}
              onClose={() => setShowFillerModal(false)}
              onSave={handleOnSaveFillerDetails}
            />

            <div style={{ width: "100%" }}>
              <FlexBox columnGap={2} mt={4}>
                <Typography variant="body2" fontWeight={500}>
                  Default
                </Typography>
                <Tooltip
                  title="If nothing matches, default is triggered."
                  placement="top"
                >
                  <IconInfo2 style={{ flexShrink: 0 }} />
                </Tooltip>
              </FlexBox>

              <FlexBox mt={2} mb={4}>
                <FlexBox
                  columnGap={2}
                  component="label"
                  sx={{ cursor: "pointer" }}
                >
                  <Controller
                    control={control}
                    name="defaultType"
                    render={({ field: { onChange, value } }) => (
                      <Radio
                        onClick={() => onChange("addNode")}
                        checked={value === "addNode"}
                        sx={{ padding: 0 }}
                        icon={<Circle width={20} height={20} />}
                        checkedIcon={<RadioButton width={20} height={20} />}
                      />
                    )}
                  />

                  <Typography
                    variant="caption"
                    fontWeight={400}
                    color="text.primary"
                    sx={{ cursor: "pointer" }}
                  >
                    Add Node
                  </Typography>
                </FlexBox>

                <FlexBox
                  columnGap={2}
                  component="label"
                  sx={{ cursor: "pointer" }}
                >
                  <Controller
                    control={control}
                    name="defaultType"
                    render={({ field: { onChange, value } }) => (
                      <Radio
                        onClick={() => onChange("triggerWorkflow")}
                        checked={value === "triggerWorkflow"}
                        sx={{ padding: 0 }}
                        icon={<Circle width={20} height={20} />}
                        checkedIcon={<RadioButton width={20} height={20} />}
                      />
                    )}
                  />
                  <Typography
                    variant="caption"
                    fontWeight={400}
                    color="text.primary"
                    sx={{ cursor: "pointer" }}
                  >
                    Trigger Workflow
                  </Typography>
                </FlexBox>
              </FlexBox>

              {watch("defaultType") === "triggerWorkflow" && (
                <Controller
                  control={control}
                  name="selectedWorkflow"
                  rules={{ required: "Workflow is required" }}
                  render={({ field: { onChange, value } }) => (
                    <AutoComplete
                      value={value}
                      renderOptionKey="name"
                      autoComplete
                      error={errors?.workflow}
                      // optionList={workflows?.workFlows}
                      optionList={optionsList}
                      // onChange={onChange}
                      closeIcon={false}
                      ListboxProps={{ style: { maxHeight: "12rem" } }}
                      className={styles.formField}
                      onChange={(newValue) => {
                        if (newValue && newValue._id === "create_workflow") {
                          setShowInputModal(true);
                        } else {
                          onChange(newValue);
                        }
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Typography variant="body2">
                            {option._id === "create_workflow" ? (
                              <FlexBox
                                style={{ backgroundColor: `white !important` }}
                              >
                                <Plus size={16} />
                                {option.name}
                              </FlexBox>
                            ) : (
                              option.name
                            )}
                          </Typography>
                        </li>
                      )}
                      PaperComponent={({ children }) => (
                        <Grow in={true} mountOnEnter unmountOnExit>
                          <div className={styles.autoCompleteList}>
                            {children}
                          </div>
                        </Grow>
                      )}
                    />
                  )}
                />
              )}
              {errors?.selectedWorkflow && (
                <Box mb={4} component="span" display="flex" alignItems="center">
                  <RedAlert style={{ marginRight: 8 }} />
                  <Typography variant="caption" color="error">
                    {errors?.selectedWorkflow?.message}
                  </Typography>
                </Box>
              )}

              <InputModal
                open={showInputModal}
                onClose={() => setShowInputModal(false)}
                onConfirm={(name) => {
                  handleCreateWorkflow(name);
                }}
                title={"Create Workflow"}
                submitText={"Create"}
                placeholder="Enter workflow name"
              />
            </div>
          </Box>
        </>
      )}
      <Button
        variant="dark"
        sx={{
          borderRadius: 82,
          minWidth: 0,
          mt: "auto",
          width: "max-content",
        }}
        onClick={handleSubmit(handleOnSave)}
        endIcon={<ChevronRight width={20} height={20} />}
      >
        Save
      </Button>
    </Box>
  );
};

export default ConversationProperties;
