import {
  Box,
  Button,
  Collapse,
  Grow,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ChevronRight, Circle, Plus } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { IconInfo2, RedAlert } from "components/SVG";
import AutoComplete from "components/AutoComplete";
import InputModal from "components/InputModal";
import { createWorkFlow } from "services";
import { useSkillrToast } from "context/toast";
import FlexBox from "components/FlexBox";
import styles from "./index.module.css";
import { RadioButton } from "components/newSVG";
import { checkDuplicateName } from "utils/utils";

const ScriptProperties = (props) => {
  const { data, onSave, workflows, onEdit, workFlowId } = props;
  const {
    reset,
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      code: "",
      defaultType: "triggerWorkflow",
      selectedWorkflow: null,
    },
  });

  const [optionsList, setOptionsList] = useState([]);
  const [showInputModal, setShowInputModal] = useState(false);
  const { showSkillrToast } = useSkillrToast();

  const handleOnSave = async (formData) => {
    if (
      formData.defaultType === "triggerWorkflow" &&
      !formData.selectedWorkflow._id
    ) {
      showSkillrToast("Please select an error workflow", "error");
      return;
    }

    if (formData.code === "") {
      showSkillrToast("Please enter a valid script.", "error");
      return;
    }

    if (formData.customName) {
      const isDuplicate = await checkDuplicateName(
        workFlowId,
        formData.customName,
        props.data.name
      );
      if (isDuplicate) {
        showSkillrToast("Node name already exists", "error");
        return;
      }
    }

    formData.selectedWorkflow = {
      workflowName: formData.selectedWorkflow?.name,
      workflowId: formData.selectedWorkflow?._id,
    };
    onSave(formData);
  };

  const createNewWorkFlow = async (data) => {
    try {
      const response = await createWorkFlow(data);
      setOptionsList((prev) => [
        { name: response.name, _id: response._id },
        ...prev,
      ]);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };
  const handleCreateWorkflow = (name) => {
    setShowInputModal(false);
    createNewWorkFlow({ name }).then((response) => {
      if (response) {
        const newWorkflow = {
          name: response.name,
          _id: response._id,
        };
        reset({
          ...getValues(),
          selectedWorkflow: newWorkflow,
        });
      }
    });
  };

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        defaultType: data.defaultType || "triggerWorkflow",
        selectedWorkflow: {
          name: data.selectedWorkflow?.workflowName || "",
          _id: data.selectedWorkflow?.workflowId,
        },
      });
    }
  }, [data]);

  useEffect(() => {
    setOptionsList([
      { name: "Create Workflow", _id: "create_workflow" },
      ...(workflows?.workFlows || []),
    ]);
  }, [workflows]);

  useEffect(() => {
    onEdit(isDirty);
  }, [isDirty]);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="caption" color="text.primary" mb={1}>
        Node Name
      </Typography>

      <Controller
        control={control}
        name="customName"
        rules={{
          validate: (value) => {
            if (/[^a-zA-Z\s]/.test(value))
              return "Node name can only contain letters and spaces";
            return true;
          },
        }}
        render={({ field: { onChange, value } }) => (
          <TextField
            variant="outlined"
            inputProps={{ maxLength: 20 }}
            fullWidth
            placeholder="Add Name"
            value={value}
            onChange={onChange}
            error={errors?.customName}
            helperText={errors?.customName?.message}
            sx={{
              mb: 4,
              "& div": {
                borderRadius: "12px",
                padding: 0,
              },
            }}
          />
        )}
      />

      <Typography variant="body1" fontWeight={500} mb={4}>
        Script
      </Typography>

      <Controller
        name="code"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <CodeMirror
            value={value}
            height="40vh"
            language="js"
            placeholder="Please enter JS code."
            onChange={onChange}
            extensions={[javascript()]}
            padding={15}
            style={{
              fontFamily:
                "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
              border: `0 !important`,
              borderRadius: 12,
            }}
          />
        )}
      />

      {/* Failure Configuration */}
      <Box style={{ width: "100%" }} mt={4} mb={6}>
        <FlexBox columnGap={1}>
          <Typography variant="body2" fontWeight={500}>
            Failure Configuration
          </Typography>
        </FlexBox>

        <FlexBox mt={2} mb={4}>
          <FlexBox columnGap={1} component="label" sx={{ cursor: "pointer" }}>
            <Controller
              control={control}
              name="defaultType"
              render={({ field: { onChange, value } }) => (
                <Radio
                  onClick={() => onChange("addNode")}
                  checked={value === "addNode"}
                  sx={{ padding: 0 }}
                  icon={<Circle width={20} height={20} />}
                  checkedIcon={<RadioButton width={20} height={20} />}
                />
              )}
            />
            <Typography
              variant="caption"
              fontWeight={400}
              color="text.primary"
              sx={{ cursor: "pointer" }}
            >
              Add Node
            </Typography>
          </FlexBox>

          <FlexBox columnGap={1} component="label" sx={{ cursor: "pointer" }}>
            <Controller
              control={control}
              name="defaultType"
              render={({ field: { onChange, value } }) => (
                <Radio
                  onClick={() => onChange("triggerWorkflow")}
                  checked={value === "triggerWorkflow"}
                  sx={{ padding: 0 }}
                  icon={<Circle width={20} height={20} />}
                  checkedIcon={<RadioButton width={20} height={20} />}
                />
              )}
            />

            <Typography
              variant="caption"
              fontWeight={400}
              color="text.primary"
              sx={{ cursor: "pointer" }}
            >
              Trigger Workflow
            </Typography>
          </FlexBox>
        </FlexBox>

        {watch("defaultType") === "addNode" && (
          <FlexBox mb={6} gap={2}>
            <IconInfo2 style={{ width: 20, height: 20 }} />
            <Typography variant="caption" color="#0f0f0f">
              Configure directly from the node.
            </Typography>
          </FlexBox>
        )}
        <Collapse
          in={watch("defaultType") === "triggerWorkflow"}
          mountOnEnter
          unmountOnExit
        >
          <div key={watch("defaultType")}>
            <Typography variant="caption" mt={4} color="#0f0f0f">
              Failure Workflow
            </Typography>
            <Controller
              control={control}
              name="selectedWorkflow"
              rules={{ required: "Workflow is required" }}
              render={({ field: { onChange, value } }) => (
                <AutoComplete
                  value={value}
                  renderOptionKey="name"
                  autoComplete
                  error={errors?.workflow}
                  // optionList={workflows?.workFlows}
                  optionList={optionsList}
                  // onChange={onChange}
                  closeIcon={false}
                  ListboxProps={{ style: { maxHeight: "12rem" } }}
                  className={styles.formField}
                  onChange={(newValue) => {
                    if (newValue && newValue._id === "create_workflow") {
                      setShowInputModal(true);
                    } else {
                      onChange(newValue);
                    }
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Typography variant="body2">
                        {option._id === "create_workflow" ? (
                          <FlexBox
                            style={{ backgroundColor: `white !important` }}
                          >
                            <Plus size={16} />
                            {option.name}
                          </FlexBox>
                        ) : (
                          option.name
                        )}
                      </Typography>
                    </li>
                  )}
                  PaperComponent={({ children }) => (
                    <Grow in={true} mountOnEnter unmountOnExit>
                      <div className={styles.autoCompleteList}>{children}</div>
                    </Grow>
                  )}
                />
              )}
            />
            <InputModal
              open={showInputModal}
              onClose={() => setShowInputModal(false)}
              onConfirm={(name) => {
                handleCreateWorkflow(name);
              }}
              title={"Create Workflow"}
              submitText={"Create"}
              placeholder="Enter workflow name"
            />
          </div>
        </Collapse>
        {errors?.selectedWorkflow && (
          <Box
            mb={4}
            mt={2}
            component="span"
            display="flex"
            alignItems="center"
          >
            <RedAlert style={{ marginRight: 8 }} />
            <Typography variant="caption" color="error">
              {errors?.selectedWorkflow?.message}
            </Typography>
          </Box>
        )}
      </Box>

      <Button
        variant="dark"
        sx={{
          borderRadius: 82,
          minWidth: 0,
          marginTop: "auto",
          width: "max-content",
          mt: "auto",
        }}
        onClick={handleSubmit(handleOnSave)}
        endIcon={<ChevronRight width={20} height={20} />}
      >
        Save
      </Button>
    </div>
  );
};

export default ScriptProperties;
