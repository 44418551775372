import { getRequest } from "./common";

const downloadIntentsCSV = (sample, type, config = {}) => {
  // the sample prop is a boolean that indicates whether to download a sample CSV or the actual data csv
  return getRequest(
    `/quesAndAnsInKb/kb-sample-csv?typeValue=${type}${!sample ? "&queryValue=all" : ""}`,
    config
  );
};

export default downloadIntentsCSV;
