import { useSkillrToast } from "context/toast";
import EditFaqModal from "pages/DatasetDashboard/components/EditFaqModal";
import { useEffect, useState } from "react";
import {
  editQuesAnsInKnowledgeBase as editQuesAnsInKnowledgeBaseApi,
  getAllWorkFlow as getAllWorkFlowApi,
  getCategoriesByCompany as getCategoriesByCompanyApi,
  getSingleFAQ,
} from "services";

const EditContainer = ({
  show,
  onClose,
  question,
  onChangeIntent,
  questionVariantToAdd,
  addQuestion,
}) => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [workFlows, setWorkFlows] = useState([]);
  const { showSkillrToast } = useSkillrToast();

  const getSingleQuestion = async (id) => {
    try {
      const response = await getSingleFAQ(id);
      setSelectedQuestion(response);
    } catch (error) {
      console.log(error);
    }
  };

  const editQuesAns = async (editedQuesAns) => {
    try {
      const apiData = {
        _id: editedQuesAns.id,
        question: editedQuesAns.question?.question,
        primaryQuestionReplacedWithVariantId:
          editedQuesAns?.question === selectedQuestion
            ? null
            : editedQuesAns.question?.id,
        questionVariant: editedQuesAns.questionVariant,
        ...(editedQuesAns.answer && { answer: editedQuesAns.answer }),
        ...(editedQuesAns.workflow && { workFlow: editedQuesAns.workflow }),
        category: editedQuesAns?.category,
        type: editedQuesAns?.type,
        ...(editedQuesAns?.tags && { tags: editedQuesAns?.tags }),
      };
      const editQuesAnsResponse = await editQuesAnsInKnowledgeBaseApi(
        editedQuesAns.id,
        apiData
      );
      showSkillrToast("Edited successfully", "success");
      return editQuesAnsResponse;
    } catch (error) {
      throw error;
    }
  };

  const handleSaveQAManually = async (formData) => {
    try {
      await editQuesAns(formData);
      onClose();
      onChangeIntent();
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getCategory = async () => {
    try {
      const response = await getCategoriesByCompanyApi();
      setCategoryList(response);
      return response;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage);
    }
  };

  const getAllWorkFlow = async () => {
    try {
      const params = new URLSearchParams();
      params.set("fetchQuestionCount", false);
      const response = await getAllWorkFlowApi(params.toString());
      setWorkFlows(response);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  useEffect(() => {
    if (show) {
      getCategory();
      getAllWorkFlow();
    }
    if (show && !addQuestion) {
      getSingleQuestion(question);
    }

    if (addQuestion) {
      setSelectedQuestion(question);
    }
  }, [show, addQuestion]);

  return (
    <>
      {workFlows?.workFlows && categoryList && selectedQuestion && (
        <EditFaqModal
          open={show}
          onClose={onClose}
          question={selectedQuestion}
          onSave={handleSaveQAManually}
          workflows={workFlows?.workFlows}
          categories={categoryList}
          categoryKey="category"
          questionIdKey="_id"
          loading={false}
          variantToAdd={!addQuestion && questionVariantToAdd}
          addQuestion={addQuestion}
        />
      )}
    </>
  );
};

export default EditContainer;
