import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  Grow,
  Radio,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AutoComplete from "components/AutoComplete";
import { ChevronDown, IconInfo, RedAlert } from "components/SVG";
import React, { useEffect } from "react";
import { Circle, X } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import styles from "./index.module.css";
import { RadioButton, WarningRed } from "components/newSVG";
import { statusTextMapping } from "text-constants/campaign";

const AddCallOutCome = (props) => {
  const { open, onClose, onSave, outComeOptions } = props;

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      outComes: [],
      type: "interval",
      hours: "1",
      minutes: "30",
    },
  });

  const handleOnSave = (formData) => {
    onSave(formData);
  };

  useEffect(() => {
    if (!open) {
      reset({
        outComes: [],
        type: "interval",
        hours: "1",
        minutes: "30",
      });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        style: {
          borderRadius: 20,
          width: 500,
          minHeight: 400,
        },
      }}
    >
      <Box padding="32px 24px" display="flex" flexDirection="column" gap={8}>
        <Typography
          fontSize={26}
          fontWeight={500}
          color="#0f0f0f"
          textAlign="center"
        >
          Add Call Outcome
        </Typography>

        <Box>
          <Box display="flex" gap={2} px="1" alignItems="center">
            <Typography color="#0f0f0f" variant="caption" sx={{ opacity: 0.8 }}>
              Call Outcome
            </Typography>
            <IconInfo width={20} height={20} />
          </Box>

          <Box mt={1}>
            <Controller
              control={control}
              name="outComes"
              rules={{ required: "call Outcome is required" }}
              render={({ field: { onChange, value } }) => (
                <AutoComplete
                  multiple
                  onChange={onChange}
                  value={value}
                  error={errors.outComes}
                  popupIcon={<ChevronDown style={{ stroke: "#505050" }} />}
                  optionList={outComeOptions}
                  renderOptionKey="name"
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={statusTextMapping[option?.name] ?? option?.name}
                        {...getTagProps({ index })}
                        deleteIcon={
                          <X
                            color="#224C61"
                            style={{ height: 16, width: 16 }}
                          />
                        }
                        sx={{
                          background: "#C9E4F1",
                          borderRadius: "56px",
                          padding: "4px 8px",
                        }}
                      />
                    ))
                  }
                  ListboxProps={{
                    style: { maxHeight: "12rem" },
                  }}
                  PaperComponent={({ children }) => (
                    <Grow in={true} mountOnEnter unmountOnExit>
                      <div className={styles.autoCompleteList}>{children}</div>
                    </Grow>
                  )}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        sx={{
                          "& > div": {
                            borderRadius: "12px",
                          },
                        }}
                        variant="outlined"
                        error={errors?.outComes?.message ? true : false}
                        helperText={
                          errors?.outComes && (
                            <Box
                              component="span"
                              display="flex"
                              alignItems="center"
                            >
                              <RedAlert style={{ marginRight: 8 }} />
                              {errors?.outComes?.message}
                            </Box>
                          )
                        }
                      />
                    );
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <>
                        <Checkbox
                          color="primary"
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        <Typography variant="body2">
                          {statusTextMapping[option?.name] ?? option?.name}
                        </Typography>
                      </>
                    </li>
                  )}
                />
              )}
            />
          </Box>

          <Box display="flex" gap={8} my={4}>
            <Controller
              control={control}
              name="type"
              render={({ field: { onChange, value } }) => (
                <>
                  <Box
                    display="flex"
                    gap={1}
                    alignItems="center"
                    component={"label"}
                    sx={{ cursor: "pointer" }}
                  >
                    <Radio
                      onClick={() => onChange("interval")}
                      checked={value === "interval"}
                      sx={{ padding: 0 }}
                      icon={<Circle width={20} height={20} />}
                      checkedIcon={<RadioButton width={20} height={20} />}
                    />
                    <Typography
                      variant="caption"
                      fontWeight={400}
                      color="text.primary"
                    >
                      Interval
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    gap={1}
                    alignItems="center"
                    component={"label"}
                    sx={{ cursor: "pointer", opacity: 0.8 }}
                  >
                    <Radio
                      disabled={true}
                      onClick={() => onChange("slot")}
                      checked={value === "slot"}
                      sx={{ padding: 0 }}
                      icon={<Circle width={20} height={20} />}
                      checkedIcon={<RadioButton width={20} height={20} />}
                    />
                    <Typography
                      variant="caption"
                      fontWeight={400}
                      color="text.primary"
                    >
                      Slot
                    </Typography>
                  </Box>
                </>
              )}
            />
          </Box>

          <Box display="flex" gap={2} mt={4} alignItems="center">
            <Typography variant="caption" fontWeight={500} color="#0f0f0f">
              Repeat Time
            </Typography>
            <Tooltip
              placement="top"
              title="Time interval before retrying the call"
            >
              <IconInfo width={20} height={20} />
            </Tooltip>
          </Box>

          <Box
            display="flex"
            gap={4}
            mt={2}
            position="relative"
            paddingBottom={4}
          >
            <Box
              display="flex"
              alignItems="center"
              gap={2}
              sx={{ width: "30%", textAlign: "center" }}
            >
              <Controller
                rules={{ required: "Hours is required" }}
                control={control}
                name="hours"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    type="number"
                    sx={{ "& input": { textAlign: "center" } }}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <Typography variant="body2">Hour</Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              gap={2}
              sx={{ width: "30%" }}
            >
              <Controller
                rules={{ required: "Minutes is required" }}
                control={control}
                name="minutes"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    type="number"
                    sx={{ "& input": { textAlign: "center" } }}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <Typography variant="body2">Minute</Typography>
            </Box>
          </Box>

          {(errors?.hours || errors?.minutes) && (
            <Box
              component="span"
              display="flex"
              alignItems="center"
              padding="5px 4px"
            >
              <WarningRed style={{ marginRight: 8 }} />
              <Typography
                fontSize={10}
                fontWeight={500}
                color="error"
                sx={{ opacity: 0.8 }}
              >
                {errors?.hours?.message
                  ? errors?.hours?.message
                  : errors?.minutes?.message}
              </Typography>
            </Box>
          )}
        </Box>

        <Box className={styles.buttonContainer}>
          <Button variant="outline" className={styles.button} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="dark"
            className={styles.button}
            onClick={handleSubmit(handleOnSave)}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddCallOutCome;
