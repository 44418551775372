import { Box, Fade, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Handle } from "reactflow";
import styles from "./index.module.css";
import { Minus, Plus, Trash2 } from "react-feather";
import theme from "theme";
import CustomHandle from "../../CustomHandle";
import NodeBase from "../../NodeBase";
import { gscUriPrefix } from "text-constants/common";

const MessageNode = (props) => {
  const {
    type,
    gcsUri,
    title,
    message,
    isActive,
    isLastNode,
    onPlusClick,
    onMinusClick,
    onDelete,
    localizationMode,
    fullyLocalized,
    error,
    customName,
    onDuplicate,
    onDuplicateToAnotherWorkflow,
  } = props.data;

  // const [showPlus, setShowPlus] = useState();
  // const [isParentHovered, setIsParentHovered] = useState(false);

  const handleClick = () => {
    if (!isLastNode) {
      onMinusClick();
    } else {
      onPlusClick({ x: props.xPos, y: props.yPos });
    }
  };

  // useEffect(() => {
  //   setShowPlus(isLastNode);
  // }, [isLastNode]);

  return (
    <NodeBase
      isActive={isActive}
      onDelete={onDelete}
      localizationMode={localizationMode}
      fullyLocalized={fullyLocalized}
      error={error}
      onDuplicate={onDuplicate}
      onDuplicateToAnotherWorkflow={onDuplicateToAnotherWorkflow}
    >
      <Typography variant="body2" fontWeight={500}>
        {customName
          ? customName
          : (props.data.name || "")
              .replace(/-/g, " ")
              .replace(/\b\w/g, (l) => l.toUpperCase())
              .replace(/ (\d+)/, " ($1)")}
      </Typography>

      <Typography variant="body2" fontSize={10} color="text.secondary">
        {message ? message : "configure"}

        {type === "audio" && gcsUri && (
          <Box mt={4}>
            <audio controls style={{ width: "100%" }} key={gcsUri}>
              <source
                src={gcsUri.replace("gs://", gscUriPrefix)}
                type={"audio/" + gcsUri.split(".").pop()}
              ></source>
            </audio>
          </Box>
        )}
      </Typography>

      <Handle
        type="target"
        position="top"
        style={{
          background: "transparent",
          top: 5,
          ...(localizationMode
            ? { border: `1px solid #333` }
            : { border: `1px solid #666` }),
        }}
      />

      <CustomHandle
        id="source"
        position="bottom"
        clickHandler={handleClick}
        icon={
          isLastNode ? (
            <Plus color={theme.palette.text.primary} width={12} height={12} />
          ) : (
            <Minus color={theme.palette.text.primary} width={12} height={12} />
          )
        }
        style={{
          bottom: 0,
          left: "50%",
          transform: "translate(-50%, 50%)",
        }}
      />
    </NodeBase>
  );
};

export default MessageNode;
