import { Box, Dialog, IconButton, Typography } from "@mui/material";
import FlexBox from "components/FlexBox";
import FundamentoTable from "components/FundamentoTable";
import { useEffect, useState } from "react";
import { Download, X } from "react-feather";
import { getFilesAssociated } from "services";
import theme from "theme";
import FileColors from "utils/fileColors";
import assetsAPI from "services/assetAPI";

const FilesModal = ({ open, onClose, processId }) => {
  const [filesData, setFilesData] = useState([]);
  const token = localStorage.getItem("token");

  const handleDownload = async (url) => {
    try {
      const signedUrl = await assetsAPI(
        url?.split("//")?.[1],
        `authToken=${token}`
      );

      const link = document.createElement("a");
      link.target = "_blank";
      link.href = signedUrl.url;
      link.download = url.split("/").pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file", error);
    }
  };

  const columns = [
    {
      field: "name",
      header: "File",
      render: (row) => {
        return (
          <FlexBox>
            <Typography
              variant="caption"
              color={FileColors[row.extension]?.color}
            >
              {row.extension}
            </Typography>
            {""}
            {row.name.length < 25 ? row.name : row.name.slice(0, 25) + "..."}

            {row.status === "file_processing_failed" ||
              (row.status === "question_generation_failed" && (
                <Typography variant="caption" color={theme.palette.error.main}>
                  failed
                </Typography>
              ))}
          </FlexBox>
        );
      },
    },
    {
      field: "size",
      header: "Size",
      render: (row) => {
        return `${(row.size / 1000000).toString().slice(0, 3)} MB`;
      },
    },
    {
      field: "createdAt",
      header: "Upload Date",
      render: (row) => {
        // make it to 24 Jan 2024 without day of the week
        const date = new Date(row.createdAt);
        return date.toDateString().slice(4);
      },
    },
    {
      field: "download",
      header: " ",
      style: { width: 30 },
      render: (row) => {
        return (
          <IconButton
            size="small"
            onClick={() => {
              handleDownload(row.gcsUri);
            }}
          >
            <Download size={16} />
          </IconButton>
        );
      },
    },
  ];

  const getFilesData = async () => {
    // API call to get files data
    try {
      const response = await getFilesAssociated(processId);
      if (response) setFilesData(response);
    } catch (error) {
      console.error("Error fetching files data", error);
    }
  };

  useEffect(() => {
    getFilesData();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 20,
          width: 650,
          height: 600,
          padding: 40,
          position: "relative",
        },
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <IconButton
          onClick={() => onClose()}
          sx={{ position: "absolute", right: 20, top: 20 }}
        >
          <X size={20} />
        </IconButton>
        <Typography variant="h3" fontWeight={500} color="#0f0f0f">
          Files Associated
        </Typography>
        <Typography variant="body2" color="rgba(15, 15, 15, 0.5)" mt={2} mb={4}>
          These files have been processed to generate intents
        </Typography>

        <FundamentoTable
          sx={{ margin: "0 auto" }}
          passRowToRender={true}
          columns={columns}
          data={filesData}
        />
      </Box>
    </Dialog>
  );
};

export default FilesModal;
