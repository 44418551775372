import { createContext, useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  getCompanyDetails as getCompanyDetailsApi,
  getDataSets as getDataSetsApi,
  getBot as getBotApi,
  updateCompany as updateCompanyApi,
  getAllBot as getAllBotApi,
} from "services";
import { useSkillrToast } from "./toast";

const UserContext = createContext();

const UserProvider = (props) => {
  const { children } = props;
  const [token, setToken] = useState();
  const [userDetails, setUserDetails] = useState();
  const [companyDetails, setCompanyDetails] = useState();
  const [dataSets, setDataSets] = useState([]);
  const { showSkillrToast } = useSkillrToast();
  const [dataSetsLoading, setDataSetsLoading] = useState(true);
  const [bot, setBot] = useState();
  const [allBot, setAllBot] = useState();
  const playgroundCurrentChat = useRef();
  const buildMode = process.env.REACT_APP_ENV;

  const getCompanyDetails = async () => {
    try {
      const response = await getCompanyDetailsApi();
      setCompanyDetails(response);
    } catch (error) {
      const errorMessage = error?.message ?? "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const updateCompanyDetails = async (details) => {
    try {
      const response = await updateCompanyApi(details);
      setCompanyDetails(response);
      return response;
    } catch (error) {
      const errorMessage = error?.message ?? "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getBot = async () => {
    try {
      const response = await getBotApi();
      setBot(response.bot);
    } catch (error) {
      if (error?.response?.status === 400) {
        setBot("no bot");
        return;
      }
      const errorMessage = error?.message ?? "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getAllBot = async () => {
    try {
      const response = await getAllBotApi();
      setAllBot(response);
    } catch (error) {
      if (error?.response?.status === 400) {
        setBot("no bot");
        return;
      }
      const errorMessage = error?.message ?? "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getDataSets = async () => {
    try {
      setDataSetsLoading(true);
      const response = await getDataSetsApi();
      setDataSets(response);
      setDataSetsLoading(false);
    } catch (error) {
      const errorMessage = error?.message ?? "Something went wrong";
      showSkillrToast(errorMessage, "error");
      setDataSetsLoading(false);
    }
  };

  const initialLoad = () => {
    getCompanyDetails();
    // getDataSets();
    // getBot();
    getAllBot();
  };

  const checkLocalStorage = () => {
    const agentToken = new URLSearchParams(window.location.search.slice(1)).get(
      "token"
    );
    const token = agentToken ?? localStorage.getItem("token");
    if (token) {
      setToken(token);
      initialLoad();
    }
  };

  useEffect(() => {
    checkLocalStorage();
  }, []);

  return (
    <UserContext.Provider
      value={{
        token,
        setToken,
        getBot,
        setBot,
        bot,
        userDetails,
        setUserDetails,
        getCompanyDetails,
        setCompanyDetails,
        companyDetails,
        getDataSets,
        dataSetsLoading,
        dataSets,
        setDataSets,
        initialLoad,
        playgroundCurrentChat,
        updateCompanyDetails,
        allBot,
        buildMode,
        getAllBot,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

export const useUserContext = () => {
  return useContext(UserContext);
};

export default UserProvider;
