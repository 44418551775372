import {
  Box,
  Grow,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import FunCollapse from "components/FunCollapse";
import { Trash2 } from "react-feather";
import theme from "theme";
import VariableDropdown from "../VariableDropdown";
import { Controller, get, useForm } from "react-hook-form";
import { useEffect } from "react";
import styles from "./index.module.css";

const ConditionBlock = ({
  name,
  argument,
  operator,
  value,
  onChange,
  onRemove,
  fields,
  id,
  onEdit,
}) => {
  const {
    control,
    watch,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      name: "",
      argument: "",
      operator: "",
      value: "",
    },
  });

  useEffect(() => {
    reset({ name, argument, operator, value });
  }, [name, argument, operator, value]);

  useEffect(() => {
    onEdit(isDirty);
  }, [isDirty]);

  return (
    <Grow in={true} mountOnEnter unmountOnExit timeout={400}>
      <Box
        sx={{
          padding: "10px 16px",
          borderRadius: 3,
          backgroundColor: "#FDFDFD",
          border: `1px solid ${theme.palette.borderColor.light}`,
          "& + &": {
            marginTop: 2,
          },
        }}
      >
        <FunCollapse
          header={
            <FlexBox>
              <Typography variant="body2" fontWeight={500}>
                Condition{" "}
                {fields.map((field, index) => {
                  return field.id === id
                    ? String.fromCharCode(65 + index)
                    : null;
                })}
              </Typography>
              <IconButton
                sx={{ borderRadius: 3, marginLeft: "auto" }}
                onClick={onRemove}
              >
                <Trash2 width={20} height={20} />
              </IconButton>
            </FlexBox>
          }
          collapsed={fields.findIndex((f) => f.id === id) === 0 ? true : false}
          iconPosition={"end"}
        >
          <Typography variant="body2" fontWeight={500} mt={4}>
            Argument
          </Typography>
          <Controller
            control={control}
            name={"argument"}
            rules={{ required: `Argument is required` }}
            render={({ field: { onChange: fieldOnChange, value } }) => (
              <VariableDropdown
                control={control}
                watch={watch}
                setValue={setValue}
                value={value}
                onChange={(newValue) => {
                  fieldOnChange(newValue);
                  onChange("argument", newValue);
                }}
              >
                <TextField
                  fullWidth
                  sx={{
                    mt: 2,
                    "& div": {
                      borderRadius: 3,
                    },
                  }}
                  helperText={get(errors, name, "").message}
                  error={!!get(errors, name)}
                />
              </VariableDropdown>
            )}
          />

          <Typography variant="body2" fontWeight={500} mt={4}>
            Operator
          </Typography>

          <Controller
            name="operator"
            control={control}
            rules={{
              required: "Operator is required",
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  borderRadius: 0,
                  boxShadow: "none",
                  backgroundColor: theme.palette.grey[50],
                },
              },
            }}
            render={({ field: { onChange: fieldOnChange, value } }) => (
              <Select
                value={value}
                // onChange={onChange}
                onChange={(e) => {
                  fieldOnChange(e.target.value);
                  onChange("operator", e.target.value);
                }}
                className={styles.select}
                fullWidth
                displayEmpty
                sx={{ mt: 2, mb: 4 }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      borderRadius: 0,
                      boxShadow: "none",
                      backgroundColor: theme.palette.grey[50],
                      maxHeight: "40vh",
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography variant="body2" color={theme.palette.grey[800]}>
                    Select Operator
                  </Typography>
                </MenuItem>
                <MenuItem
                  value={"equalTo"}
                  classes={{ selected: styles.selected }}
                >
                  <Typography variant="body2" color={theme.palette.grey[800]}>
                    Equal to
                  </Typography>
                </MenuItem>
                <MenuItem
                  classes={{ selected: styles.selected }}
                  value={"greaterThan"}
                >
                  <Typography variant="body2" color={theme.palette.grey[800]}>
                    Greater than
                  </Typography>
                </MenuItem>
                <MenuItem
                  classes={{ selected: styles.selected }}
                  value={"lessThan"}
                >
                  <Typography variant="body2" color={theme.palette.grey[800]}>
                    Less than
                  </Typography>
                </MenuItem>
                <MenuItem
                  classes={{ selected: styles.selected }}
                  value={"greaterThanEqualTo"}
                >
                  <Typography variant="body2" color={theme.palette.grey[800]}>
                    Greater than equal to
                  </Typography>
                </MenuItem>
                <MenuItem
                  classes={{ selected: styles.selected }}
                  value={"lessThanEqualTo"}
                >
                  <Typography variant="body2" color={theme.palette.grey[800]}>
                    Less than equal to
                  </Typography>
                </MenuItem>
                <MenuItem
                  classes={{ selected: styles.selected }}
                  value={"notEqualTo"}
                >
                  <Typography variant="body2" color={theme.palette.grey[800]}>
                    Not equal to
                  </Typography>
                </MenuItem>
                <MenuItem
                  classes={{ selected: styles.selected }}
                  value={"isEmpty"}
                >
                  <Typography variant="body2" color={theme.palette.grey[800]}>
                    Is empty
                  </Typography>
                </MenuItem>
                <MenuItem
                  classes={{ selected: styles.selected }}
                  value={"isNotEmpty"}
                >
                  <Typography variant="body2" color={theme.palette.grey[800]}>
                    Is not empty
                  </Typography>
                </MenuItem>
              </Select>
            )}
          />

          {/**
           * Do not render value field for "isEmpty" and "isNotEmpty"
           */}
          {!["isEmpty", "isNotEmpty"].includes(watch("operator")) && (
            <>
              <Typography variant="body2" fontWeight={500} mt={4}>
                Value
              </Typography>

              <Controller
                control={control}
                name={"value"}
                rules={{ required: `Value is required` }}
                render={({ field: { onChange: fieldOnChange, value } }) => (
                  <VariableDropdown
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    value={value}
                    onChange={(newValue) => {
                      fieldOnChange(newValue);
                      onChange("value", newValue);
                    }}
                  >
                    <TextField
                      fullWidth
                      sx={{
                        mt: 2,
                        "& div": {
                          borderRadius: 3,
                        },
                      }}
                      helperText={get(errors, name, "").message}
                      error={!!get(errors, name)}
                    />
                  </VariableDropdown>
                )}
              />
            </>
          )}
        </FunCollapse>
      </Box>
    </Grow>
  );
};

export default ConditionBlock;
