import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import styles from "./index.module.css";
import theme from "theme";
import { ChevronRight, Loader } from "react-feather";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import getIntegrations from "services/getIntegrations";
import { useSkillrToast } from "context/toast";
import TabSwitcher from "components/TabSwitcher";
import getGoogleSheets from "services/getGoogleSheets";
import getWorksheets from "services/getWorksheets";
import getColumnsInWorksheet from "services/getColumnsInWorksheet";
import VariableDropdown from "../VariableDropdown";
import FlexBox from "components/FlexBox";
import { checkDuplicateName } from "utils/utils";

const GoogleSheetProperties = (props) => {
  const { data, onSave, onEdit, workFlowId } = props;
  const [accounts, setAccounts] = useState([]);
  const [googleSheets, setGoogleSheets] = useState([]);
  const [worksheets, setWorksheets] = useState([]);
  const [columns, setColumns] = useState([]);
  const addTabs = ["Action"];
  const searchTabs = ["Action", "Fetch Information"];
  const updateTabs = ["Action", "Update Information"];
  const [activeTab, setActiveTab] = useState(0);
  const { showSkillrToast } = useSkillrToast();
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      accountName: "",
      action: "",
      dataFields: [],
    },
  });

  // insert a field array here for the columns
  const { fields } = useFieldArray({
    control,
    name: "dataFields",
  });

  // dummy columns
  // const columns = ["name", "email", "phone", "address"];

  const getAllIntegrations = async () => {
    setLoading(true);
    try {
      const response = await getIntegrations("google-sheet");
      setAccounts(response);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
      console.log(error);
    }
  };

  const getAllSheetsFromAccount = async () => {
    setLoading(true);
    try {
      const response = await getGoogleSheets(watch("accountName")._id);
      setGoogleSheets(response);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
      console.log(error);
    }
  };

  const getAllWorksheets = async () => {
    setLoading(true);
    try {
      const response = await getWorksheets({
        integrationId: watch("accountName")._id,
        sheetId: watch("googleSheetName").id,
      });
      setWorksheets(response);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
      console.log(error);
    }
  };

  const getAllColumns = async () => {
    setLoading(true);
    try {
      const response = await getColumnsInWorksheet({
        sheetId: watch("googleSheetName").id,
        tabName: watch("workSheetName").title,
        integrationId: watch("accountName")._id,
      });
      setColumns(response);
      // response.forEach((column) => {
      //   append({ value: "" });
      // });
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
      console.log(error);
    }
  };

  const handleOnSave = async (data) => {
    let formattedData = {};

    // if (data.action === "addData") formattedData.action = "add-data";
    // if (data.action === "searchData") formattedData.action = "search-data";
    // if (data.action === "updateData") formattedData.action = "update-data";

    formattedData.sheetId = data.googleSheetName.id;
    formattedData.tabName = data.workSheetName.title;
    formattedData.columnValues = data.dataFields.map((field) => {
      return {
        column: field.column,
        variable: field.value,
      };
    });

    if (data.action === "searchData" || data.action === "updateData") {
      formattedData.searchColumn = data.searchLocation;
      formattedData.searchValue = data.searchValue;
    }

    const hasNonEmptyValue = data.dataFields.some(
      (field) => field.value && field.value !== ""
    );

    if (!hasNonEmptyValue) {
      showSkillrToast("At least one field must have a value", "error");
      return;
    }

    if (data.customName) {
      const isDuplicate = await checkDuplicateName(
        workFlowId,
        data.customName,
        props.data.name
      );
      if (isDuplicate) {
        showSkillrToast("Node name already exists", "error");
        return;
      }
    }

    onSave({ ...data, ...formattedData });
  };

  const emptyOutFields = () => {
    fields.forEach((field, index) => {
      setValue(`dataFields.${index}.value`, "");
    });
  };

  useEffect(() => {
    getAllIntegrations();
    reset(data);
  }, []);

  useEffect(() => {
    if (watch("accountName")) {
      getAllSheetsFromAccount();
    }
  }, [watch("accountName")]);

  useEffect(() => {
    if (watch("googleSheetName")?.id) {
      getAllWorksheets();
    }
  }, [watch("googleSheetName")]);

  useEffect(() => {
    if (watch("workSheetName")?.title) {
      // setValue("dataFields", []);
      getAllColumns();
    }
  }, [watch("workSheetName")]);

  useEffect(() => {
    onEdit(isDirty);
  }, [isDirty]);

  return (
    <>
      <Typography variant="caption" color="text.primary" mb={1}>
        Node Name
      </Typography>

      <Controller
        control={control}
        name="customName"
        rules={{
          validate: (value) => {
            if (/[^a-zA-Z\s]/.test(value))
              return "Node name can only contain letters and spaces";
            return true;
          },
        }}
        render={({ field: { onChange, value } }) => (
          <TextField
            variant="outlined"
            inputProps={{ maxLength: 20 }}
            fullWidth
            placeholder="Add Name"
            value={value}
            onChange={onChange}
            error={errors?.customName}
            helperText={errors?.customName?.message}
            sx={{
              mb: 4,
              "& div": {
                borderRadius: "12px",
                padding: 0,
              },
            }}
          />
        )}
      />

      <Controller
        name="accountName"
        control={control}
        rules={{ required: "Google Account is required" }}
        render={({ field: { onChange, value } }) => (
          <Select
            className={styles.select}
            displayEmpty={true}
            onChange={onChange}
            value={value}
            sx={{ mt: 2 }}
            MenuProps={{
              PaperProps: {
                style: {
                  borderRadius: 0,
                  boxShadow: "none",
                  backgroundColor: theme.palette.grey[50],
                  maxHeight: "40vh",
                },
              },
            }}
            renderValue={(value) => {
              if (!value)
                return (
                  <Typography variant="caption" color={theme.palette.grey[800]}>
                    Select Google Account
                  </Typography>
                );
              else
                return (
                  <Typography variant="caption" color={theme.palette.grey[800]}>
                    {value.name}
                  </Typography>
                );
            }}
          >
            {accounts?.map((account) => (
              <MenuItem
                value={account}
                key={account.id}
                classes={{ selected: styles.selected }}
              >
                <Typography variant="caption" color={theme.palette.grey[800]}>
                  {account.name}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        )}
      />

      {errors.accountName && (
        <Typography variant="caption" color="error">
          {errors.accountName.message}
        </Typography>
      )}

      <Controller
        name="action"
        control={control}
        rules={{ required: "Action is required" }}
        render={({ field: { onChange, value } }) => (
          <Select
            displayEmpty
            className={styles.select}
            onChange={(e) => {
              onChange(e);
              emptyOutFields();
            }}
            value={value}
            sx={{ mt: 4 }}
            MenuProps={{
              PaperProps: {
                style: {
                  borderRadius: 0,
                  boxShadow: "none",
                  // backgroundColor: theme.palette.grey[50],
                  maxHeight: "40vh",
                },
              },
            }}
            renderValue={(value) => {
              if (!value)
                return (
                  <Typography variant="caption" color={theme.palette.grey[800]}>
                    Select Action
                  </Typography>
                );
              else
                return (
                  <Typography variant="caption" color={theme.palette.grey[800]}>
                    {value === "add-data" && "Add Data"}
                    {value === "search-data" && "Search Data"}
                    {value === "update-data" && "Update Data"}
                  </Typography>
                );
            }}
          >
            <MenuItem value="add-data" key="add-data">
              <Typography variant="caption" color={theme.palette.grey[800]}>
                Add Data
              </Typography>
            </MenuItem>
            <MenuItem value="search-data" key="search-data">
              <Typography variant="caption" color={theme.palette.grey[800]}>
                Search Data
              </Typography>
            </MenuItem>
            <MenuItem value="update-data" key="update-data">
              <Typography variant="caption" color={theme.palette.grey[800]}>
                Update Data
              </Typography>
            </MenuItem>
          </Select>
        )}
      />

      {errors.action && (
        <Typography variant="caption" color="error">
          {errors.action.message}
        </Typography>
      )}

      {watch("action") === "add-data" && (
        <Box
          sx={{
            mt: 4,
            mb: 8,
            "& div": {
              justifyContent: "flex-start",
            },
            "& >div >span": {
              padding: "8px 20px",
            },
          }}
        >
          <TabSwitcher
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={addTabs}
          />

          <Controller
            name="googleSheetName"
            control={control}
            rules={{ required: "Google Sheet is required" }}
            render={({ field: { onChange, value } }) => (
              <Select
                fullWidth
                displayEmpty
                className={styles.select}
                onChange={onChange}
                value={value}
                sx={{ mt: 4 }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      borderRadius: 0,
                      boxShadow: "none",
                      backgroundColor: theme.palette.grey[50],
                      maxHeight: "40vh",
                    },
                  },
                }}
                renderValue={(value) => {
                  if (!value) {
                    return (
                      <Typography
                        variant="caption"
                        color={theme.palette.grey[800]}
                      >
                        Select Google Sheet
                      </Typography>
                    );
                  } else {
                    return (
                      <Typography
                        variant="caption"
                        color={theme.palette.grey[800]}
                      >
                        {value?.name}
                      </Typography>
                    );
                  }
                }}
              >
                {loading ? (
                  <MenuItem>
                    <Typography
                      variant="caption"
                      color={theme.palette.grey[800]}
                      textAlign={"center"}
                    >
                      <Loader
                        width={12}
                        height={12}
                        color={theme.palette.grey[600]}
                      />
                    </Typography>
                  </MenuItem>
                ) : (
                  googleSheets?.map((sheet) => (
                    <MenuItem value={sheet} key={sheet.id}>
                      <Typography
                        variant="caption"
                        color={theme.palette.grey[800]}
                      >
                        {sheet.name}
                      </Typography>
                    </MenuItem>
                  ))
                )}
              </Select>
            )}
          />

          {errors.googleSheetName && (
            <Typography variant="caption" color="error">
              {errors.googleSheetName.message}
            </Typography>
          )}

          <Controller
            name="workSheetName"
            control={control}
            rules={{ required: "Work Sheet is required" }}
            render={({ field: { onChange, value } }) => (
              <Select
                fullWidth
                displayEmpty
                className={styles.select}
                onChange={onChange}
                value={value}
                sx={{ mt: 4 }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      borderRadius: 0,
                      boxShadow: "none",
                      backgroundColor: theme.palette.grey[50],
                      maxHeight: "40vh",
                    },
                  },
                }}
                renderValue={(value) => {
                  if (!value) {
                    return (
                      <Typography
                        variant="caption"
                        color={theme.palette.grey[800]}
                      >
                        Select Work Sheet
                      </Typography>
                    );
                  } else {
                    return (
                      <Typography
                        variant="caption"
                        color={theme.palette.grey[800]}
                      >
                        {value?.title}
                      </Typography>
                    );
                  }
                }}
              >
                {loading ? (
                  <MenuItem>
                    <Typography
                      variant="caption"
                      color={theme.palette.grey[800]}
                      textAlign={"center"}
                    >
                      <Loader
                        width={12}
                        height={12}
                        color={theme.palette.grey[600]}
                      />
                    </Typography>
                  </MenuItem>
                ) : (
                  worksheets?.map((sheet) => (
                    <MenuItem value={sheet} key={sheet.id}>
                      <Typography
                        variant="caption"
                        color={theme.palette.grey[800]}
                      >
                        {sheet.title}
                      </Typography>
                    </MenuItem>
                  ))
                )}
              </Select>
            )}
          />

          {errors.workSheetName && (
            <Typography variant="caption" color="error">
              {errors.workSheetName.message}
            </Typography>
          )}

          {loading ? (
            <FlexBox justifyContent="center" my={8}>
              <Loader
                style={{ margin: "0 auto" }}
                width={20}
                height={20}
                color={theme.palette.grey[400]}
              />
            </FlexBox>
          ) : (
            columns.map((column, index) => (
              <Box sx={{ mt: 4 }}>
                <Typography
                  variant="caption"
                  component="div"
                  fontWeight={500}
                  color="text.primary"
                >
                  {column}
                </Typography>

                <Controller
                  name={`dataFields.${index}.value`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <VariableDropdown
                      control={control}
                      watch={watch}
                      setValue={setValue}
                      value={value}
                      onChange={onChange}
                    >
                      <TextField
                        fullWidth
                        onChange={onChange}
                        value={value}
                        sx={{ mt: 2 }}
                      />
                    </VariableDropdown>
                  )}
                />
              </Box>
            ))
          )}
        </Box>
      )}

      {(watch("action") === "search-data" ||
        watch("action") === "update-data") && (
        <Box
          sx={{
            mt: 4,
            mb: 8,
            "& div": {
              justifyContent: "flex-start",
            },
            "& >div >span": {
              padding: "8px 20px",
            },
          }}
        >
          <TabSwitcher
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={watch("action") === "search-data" ? searchTabs : updateTabs}
          />

          {activeTab === 0 && (
            <>
              <Controller
                name="googleSheetName"
                control={control}
                rules={{ required: "Google Sheet is required" }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    fullWidth
                    displayEmpty
                    className={styles.select}
                    onChange={onChange}
                    value={value}
                    sx={{ mt: 4 }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          borderRadius: 0,
                          boxShadow: "none",
                          backgroundColor: theme.palette.grey[50],
                          maxHeight: "40vh",
                        },
                      },
                    }}
                    renderValue={(value) => {
                      if (!value) {
                        return (
                          <Typography
                            variant="caption"
                            color={theme.palette.grey[800]}
                          >
                            Select Google Sheet
                          </Typography>
                        );
                      } else {
                        return (
                          <Typography
                            variant="caption"
                            color={theme.palette.grey[800]}
                          >
                            {value?.name}
                          </Typography>
                        );
                      }
                    }}
                  >
                    {googleSheets?.map((sheet) => (
                      <MenuItem value={sheet} key={sheet.id}>
                        <Typography
                          variant="caption"
                          color={theme.palette.grey[800]}
                        >
                          {sheet.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />

              {errors.googleSheetName && (
                <Typography variant="caption" color="error">
                  {errors.googleSheetName.message}
                </Typography>
              )}

              <Controller
                name="workSheetName"
                control={control}
                rules={{ required: "Work Sheet is required" }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    fullWidth
                    displayEmpty
                    className={styles.select}
                    onChange={onChange}
                    value={value}
                    sx={{ mt: 4 }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          borderRadius: 0,
                          boxShadow: "none",
                          backgroundColor: theme.palette.grey[50],
                          maxHeight: "40vh",
                        },
                      },
                    }}
                    renderValue={(value) => {
                      if (!value) {
                        return (
                          <Typography
                            variant="caption"
                            color={theme.palette.grey[800]}
                          >
                            Select Work Sheet
                          </Typography>
                        );
                      } else {
                        return (
                          <Typography
                            variant="caption"
                            color={theme.palette.grey[800]}
                          >
                            {value?.title}
                          </Typography>
                        );
                      }
                    }}
                  >
                    {worksheets?.map((sheet) => (
                      <MenuItem value={sheet} key={sheet.id}>
                        <Typography
                          variant="caption"
                          color={theme.palette.grey[800]}
                        >
                          {sheet.title}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />

              <Typography
                variant="caption"
                component={"div"}
                fontWeight={500}
                color="text.primary"
                sx={{ mt: 4 }}
              >
                Search Location
              </Typography>

              <Controller
                name="searchLocation"
                control={control}
                rules={{ required: "Search Location is required" }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    fullWidth
                    displayEmpty
                    className={styles.select}
                    onChange={onChange}
                    value={value}
                    sx={{ mt: 2 }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          borderRadius: 0,
                          boxShadow: "none",
                          backgroundColor: theme.palette.grey[50],
                          maxHeight: "40vh",
                        },
                      },
                    }}
                    renderValue={(value) => {
                      if (!value) {
                        return (
                          <Typography
                            variant="caption"
                            color={theme.palette.grey[800]}
                          >
                            Select Search Location
                          </Typography>
                        );
                      }
                      return (
                        <Typography
                          variant="caption"
                          color={theme.palette.grey[800]}
                        >
                          {value}
                        </Typography>
                      );
                    }}
                  >
                    {columns.map((column) => (
                      <MenuItem value={column} key={column}>
                        <Typography
                          variant="caption"
                          color={theme.palette.grey[800]}
                        >
                          {column}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />

              {errors.searchLocation && (
                <Typography variant="caption" color="error">
                  {errors.searchLocation.message}
                </Typography>
              )}

              <Controller
                name="searchValue"
                control={control}
                rules={{ required: "Search Value is required" }}
                render={({ field: { onChange, value } }) => (
                  <VariableDropdown
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    value={value}
                    onChange={onChange}
                  >
                    <TextField
                      placeholder="Value to search for"
                      fullWidth
                      onChange={onChange}
                      value={value}
                      sx={{ mt: 4 }}
                      error={errors.searchValue}
                      helperText={errors.searchValue?.message}
                    />
                  </VariableDropdown>
                )}
              />
            </>
          )}

          {activeTab === 1 && (
            <>
              {columns.map((column, index) => (
                <Box sx={{ mt: 4 }}>
                  <Typography
                    variant="caption"
                    component="div"
                    fontWeight={500}
                    color="text.primary"
                  >
                    {column}
                  </Typography>

                  <Controller
                    name={`dataFields.${index}.value`}
                    control={control}
                    render={({ field: { onChange, value } }) =>
                      watch("action") === "update-data" ? (
                        <VariableDropdown
                          control={control}
                          watch={watch}
                          setValue={setValue}
                          value={value}
                          onChange={onChange}
                        >
                          <TextField
                            fullWidth
                            onChange={onChange}
                            value={value}
                            sx={{ mt: 2 }}
                          />
                        </VariableDropdown>
                      ) : (
                        <TextField
                          fullWidth
                          onChange={onChange}
                          value={value}
                          sx={{ mt: 2 }}
                        />
                      )
                    }
                  />
                </Box>
              ))}
            </>
          )}
        </Box>
      )}

      <Button
        variant="dark"
        sx={{
          borderRadius: 82,
          minWidth: 0,
          mt: "auto",
          width: "max-content",
        }}
        onClick={handleSubmit(handleOnSave)}
        endIcon={<ChevronRight width={20} height={20} />}
      >
        Save
      </Button>
    </>
  );
};

export default GoogleSheetProperties;
