import { Attachment } from "components/newSVG";
import styles from "./index.module.css";
import { Button, Fade, Typography } from "@mui/material";
import Proptypes from "prop-types";

const EmptyState = ({
  title,
  description,
  buttonText,
  buttonAction,
  buttonIcon,
  emptyIcon,
  style,
}) => (
  <div className={styles.container} style={style}>
    <Fade in={true} timeout={600} mountOnEnter={true}>
      <div>
        {emptyIcon}
        <Typography
          variant="h3"
          fontWeight={500}
          mt={2}
          mb={2}
          fontSize={24}
          color="#7b7b7b"
        >
          {title}
        </Typography>
        <Typography mb={6} variant="body2" color="#7b7b7b">
          {description}
        </Typography>

        {buttonText && (
          <Button
            variant="outlined"
            className={styles.button}
            startIcon={buttonIcon}
            onClick={buttonAction}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </Fade>
  </div>
);

EmptyState.propTypes = {
  emptyIcon: Proptypes.element,
};

EmptyState.defaultProps = {
  // emptyIcon: <EmptyStateFile />,
  emptyIcon: <Attachment />,
};

export default EmptyState;
