import { patchRequest } from "./common";

const updateVoiceConfig = (voiceId, data, config) => {
  return patchRequest(
    `voiceConfig/CompanyVoiceConfig/${voiceId}`,
    data,
    config
  );
};

export default updateVoiceConfig;
