import { Typography } from "@mui/material";
import { Handle } from "reactflow";
import { Minus, Plus } from "react-feather";
import theme from "theme";
import CustomHandle from "../../CustomHandle";
import NodeBase from "../../NodeBase";

const GoogleSheetNode = (props) => {
  const {
    name,
    customName,
    message,
    isActive,
    isLastNode,
    onPlusClick,
    onMinusClick,
    onDelete,
    localizationMode,
    error,
  } = props.data;

  const handleClick = () => {
    if (!isLastNode) {
      onMinusClick();
    } else {
      onPlusClick({ x: props.xPos, y: props.yPos });
    }
  };

  return (
    <NodeBase
      isActive={isActive}
      onDelete={onDelete}
      error={error}
      onDuplicate={props.data.onDuplicate}
      onDuplicateToAnotherWorkflow={props.data.onDuplicateToAnotherWorkflow}
    >
      <Typography variant="body2" fontWeight={500}>
        {customName
          ? customName
          : (name || "")
              .replace(/-/g, " ")
              .replace(/\b\w/g, (l) => l.toUpperCase())
              .replace(/ (\d+)/, " ($1)")}
      </Typography>

      <Typography variant="body2" fontSize={10} mb={3} color="text.secondary">
        {/* {message} */}
        Request User Data
      </Typography>

      <Handle
        type="target"
        position="top"
        style={{
          background: "transparent",
          top: 5,
          ...(localizationMode
            ? { border: `1px solid #333` }
            : { border: `1px solid #666` }),
        }}
      />

      <CustomHandle
        id="source"
        position="bottom"
        clickHandler={handleClick}
        icon={
          isLastNode ? (
            <Plus color={theme.palette.text.primary} width={12} height={12} />
          ) : (
            <Minus color={theme.palette.text.primary} width={12} height={12} />
          )
        }
        style={{
          bottom: 0,
          left: "50%",
          transform: "translate(-50%, 50%)",
        }}
      />
    </NodeBase>
  );
};

export default GoogleSheetNode;
