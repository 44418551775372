import {
  Dialog,
  IconButton,
  Box,
  Typography,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { X } from "react-feather";
import theme from "theme";

const CopyNodeModal = ({ open, onClose, onConfirm, workflows }) => {
  const [selectedWorkflow, setSelectedWorkflow] = useState();

  const handleClose = () => {
    setSelectedWorkflow();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          width: 500,
          borderRadius: 20,
        },
      }}
    >
      <Box sx={{ position: "relative", padding: 10 }}>
        <IconButton
          sx={{ position: "absolute", right: 8, top: 8 }}
          onClick={handleClose}
        >
          <X width={20} height={20} />
        </IconButton>
        <Typography textAlign="center" variant="h3" fontWeight={500}>
          Copy to Different Workflow
        </Typography>

        <Typography
          component={"div"}
          variant="caption"
          mt={8}
          mb={1}
          color="grey.600"
        >
          Workflow Name
        </Typography>
        <Autocomplete
          fullWidth
          options={workflows}
          getOptionLabel={(option) => option.name}
          onChange={(event, value) => setSelectedWorkflow(value?._id)}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select workflow"
              variant="outlined"
              size="small"
              sx={{ border: 0, outline: 0 }}
            />
          )}
        />

        <Box
          mt={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "22px",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              border: `1px solid ${theme.palette.text.primary}`,
            }}
          >
            Cancel
          </Button>
          <Button
            variant="dark"
            sx={{ padding: "10px 20px", borderRadius: 3 }}
            onClick={() => {
              onConfirm(selectedWorkflow);
              handleClose();
            }}
          >
            Proceed
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CopyNodeModal;
