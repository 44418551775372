import styles from "./index.module.css";
import {
  Box,
  Button,
  Dialog,
  Grow,
  IconButton,
  MobileStepper,
  TextField,
  Typography,
} from "@mui/material";
import {
  Archive,
  ChevronRight,
  Edit2,
  MoreVertical,
  PlusCircle,
  X,
} from "react-feather";
import theme from "theme";
import FlexBox from "components/FlexBox";
import { useEffect, useState } from "react";
import {
  getProcess,
  createProcess as createProcessAPI,
  editProcess,
} from "services";
import { useSkillrToast } from "context/toast";
import { useNavigate } from "react-router-dom";
import FundamentoPopup from "components/FundamentoPopup";
import { PencilSimple } from "components/newSVG";
import NameModal from "components/NameModal";
import DeleteModal from "components/DeleteModal";

const AllProcess = () => {
  const [textfieldValue, setTextfieldValue] = useState("");
  const { showSkillrToast } = useSkillrToast();
  const [nameModal, setNameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processList, setProcessList] = useState([]);
  const navigate = useNavigate();
  const [selectedProcess, setSelectedProcess] = useState(null);

  const getAllProcess = async () => {
    try {
      const response = await getProcess();
      setProcessList(response);
    } catch (error) {
      console.log(error);
    }
  };

  const createProcess = async (name) => {
    try {
      const response = await createProcessAPI({ name: name.trim() });
      if (response.status === 200) {
        showSkillrToast("Process created successfully", "success");
      }
      setNameModal(false);
      getAllProcess();
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 409) {
        showSkillrToast("Process name already exists", "error");
      } else {
        const errorMessage =
          error?.response?.data?.message ?? "Something went wrong";
        showSkillrToast(errorMessage, "error");
      }
    }
  };

  const renameProcess = async (newName) => {
    try {
      const response = await editProcess(selectedProcess._id, {
        name: newName,
      });
      if (response) {
        showSkillrToast("Process renamed successfully", "success");
        setNameModal(false);
        getAllProcess();
        setSelectedProcess(null);
      }
    } catch (error) {
      console.log(error);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const archiveProcess = async () => {
    try {
      const response = await editProcess(selectedProcess._id, {
        isArchived: true,
      });
      if (response) {
        showSkillrToast("Process archived successfully", "success");
        getAllProcess();
      }
    } catch (error) {
      console.log(error);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getStep = (status) => {
    switch (status) {
      case "readyForQueGen":
        return 0;
      case "readyForGroup":
        return 1;
      case "readyForAnsGen":
        return 2;
      case "readyForAnnotation":
        return 3;
      default:
        return 0;
    }
  };

  useEffect(() => {
    getAllProcess();
  }, []);

  return (
    <>
      <Box className={styles.cardContainer}>
        <Grow in={true} timeout={300} mountOnEnter>
          <Box
            className={styles.generateCard}
            onClick={() => setNameModal(true)}
          >
            <PlusCircle size={32} color="#C7C7C7" />
            <Typography
              variant="body1"
              color="rgba(15,15,15,0.3)"
              fontWeight={500}
            >
              Generate data
            </Typography>
          </Box>
        </Grow>

        {processList?.length > 0 &&
          processList?.map((process) => (
            <Grow in={true} timeout={300} key={process._id} mountOnEnter>
              <div>
                <Box
                  className={styles.card}
                  onClick={() => navigate(`${process._id}`)}
                >
                  <Box style={{ position: "absolute", top: 16, right: 16 }}>
                    <FundamentoPopup
                      className={styles.popup}
                      onParentClick={(e) => e.stopPropagation()}
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      transformOrigin={{ vertical: "top", horizontal: "right" }}
                      triggeringComponent={
                        <IconButton>
                          <MoreVertical width={16} height={16} />
                        </IconButton>
                      }
                    >
                      <Box onClick={(e) => e.stopPropagation()}>
                        <FlexBox
                          className={styles.popupItem}
                          columnGap={3}
                          onClick={() => {
                            setSelectedProcess(process);
                            setNameModal(true);
                          }}
                        >
                          <PencilSimple width={18} height={18} />

                          <Typography variant="body2">Rename</Typography>
                        </FlexBox>
                        <FlexBox
                          className={styles.popupItem}
                          columnGap={3}
                          onClick={() => {
                            setSelectedProcess(process);
                            setShowDeleteModal(true);
                          }}
                        >
                          <Archive
                            width={16}
                            height={16}
                            color={theme.palette.error.main}
                          />

                          <Typography variant="body2" color="error.main">
                            Archive
                          </Typography>
                        </FlexBox>
                      </Box>
                    </FundamentoPopup>
                  </Box>
                  <Typography variant="subtitle1" fontWeight={500}>
                    {process.name}
                  </Typography>

                  {/* <Typography
                  variant="caption"
                  color={theme.palette.tag.main}
                  mb={4}
                >
                  All dues cleared
                </Typography> */}

                  <Box my={4}>
                    <FlexBox columnGap={1}>
                      <Typography variant="caption" color="#0f0f0fb3">
                        Status
                      </Typography>
                      <MobileStepper
                        variant="dots"
                        steps={4}
                        position="static"
                        activeStep={
                          process?.status ? getStep(process?.status) : null
                        }
                        sx={{
                          "& .MuiMobileStepper-dot": {
                            backgroundColor: "#0f0f0f1f",
                            width: 5,
                            height: 5,
                          },
                          maxWidth: 400,
                          flexGrow: 1,
                          "& .MuiMobileStepper-dotActive": {
                            backgroundColor: "#0f0f0f",
                          },
                        }}
                      />
                    </FlexBox>

                    <Typography variant="body2" fontWeight={500}>
                      {process?.status === "readyForQueGen" &&
                        "Ready for question generation"}
                      {process?.status === "readyForGroup" &&
                        "Ready for grouping"}
                      {process?.status === "readyForAnsGen" &&
                        "Ready for answer generation"}
                      {process?.status === "readyForAnnotation" &&
                        "Ready for annotation"}
                      {!process?.status && "Upload files to start processing"}
                    </Typography>
                  </Box>

                  <Button
                    variant="outlined"
                    endIcon={<ChevronRight width={12} height={12} />}
                    sx={{ borderRadius: 82, minWidth: 0, mt: "auto" }}
                  >
                    View
                  </Button>
                </Box>
              </div>
            </Grow>
          ))}
      </Box>

      {/* rename process modal */}
      <NameModal
        open={nameModal}
        onClose={() => setNameModal(false)}
        onConfirm={(name) => {
          if (selectedProcess) {
            renameProcess(name);
          } else {
            createProcess(name);
          }
        }}
        prevName={selectedProcess?.name}
        submitOnRename={"Rename"}
        submitOnCreate={"Create"}
        titleOnCreate={"Create Process"}
        titleOnRename={"Rename Process"}
      />

      <DeleteModal
        title={"Archive process"}
        subtitle={"Are you sure you want to archive this process?"}
        onConfirm={() => archiveProcess()}
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        confirmText={"Archive"}
        mainIcon={<Archive color={"white"} />}
      />

      {/* create new process modal */}
      {/* <Dialog
        open={nameModal}
        onClose={() => setNameModal(false)}
        TransitionComponent={Grow}
        PaperProps={{
          sx: {
            width: 500,
            padding: 10,
            borderRadius: 5,
            position: "relative",
          },
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 20, right: 20 }}
          onClick={() => setNameModal(false)}
        >
          <X />
        </IconButton>

        <Typography align="center" variant="h4" fontWeight={500}>
          Create process
        </Typography>

        <Typography
          variant="caption"
          color={theme.palette.grey[600]}
          sx={{ mt: 8, ml: 2 }}
        >
          Process name *
        </Typography>
        <TextField
          autofocus
          fullWidth
          placeholder="Enter process name here"
          sx={{ "& >div": { borderRadius: 82 }, mt: 1 }}
          value={textfieldValue}
          onChange={(e) => setTextfieldValue(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              createProcess();
            }
          }}
        />

        <FlexBox justifyContent="center" mt={4}>
          <Button
            variant="whiteRound"
            onClick={() => {
              setNameModal(false);
              setTextfieldValue("");
            }}
          >
            Cancel
          </Button>

          <Button
            variant="dark"
            sx={{ borderRadius: 82 }}
            endIcon={<ChevronRight />}
            onClick={() => {
              createProcess();
              setTextfieldValue("");
            }}
            disabled={!textfieldValue}
          >
            Create
          </Button>
        </FlexBox>
      </Dialog> */}
    </>
  );
};

export default AllProcess;
