import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import FlexBox from "components/FlexBox";
import { IconFilter2, IconGoogle, IconPause, IconPlay } from "components/SVG";
import { Heart, X } from "react-feather";
import theme from "theme";
import styles from "./index.module.css";
import avatarMale from "assets/images/voice-male.png";
import avatarFemale from "assets/images/voice-female.png";
import avatarNeutral from "assets/images/voice-neutral.png";
import elevenLabs from "assets/images/elevenLabs.png";
import sarvam from "assets/images/sarvam.png";
import smallest from "assets/images/smallest.jpeg";
import { updateVoiceConfig, getAllBots, addVoiceConfig } from "services";
import { useEffect, useRef, useState } from "react";
import { editBot, getAudio as getAudioApi } from "services";
import { useSkillrToast } from "context/toast";

const ChangeVoiceModal = ({
  open,
  onClose,
  currentLanguage,
  bot,
  voices: defaultVoices,
  onSave,
  voice,
}) => {
  const { showSkillrToast } = useSkillrToast();
  const [voices, setVoices] = useState([]);
  const audioRef = useRef();
  // const [bot, setBot] = useState();

  const handleEditBot = async (data) => {
    try {
      const response = await editBot(bot._id, data);
      if (response) {
        showSkillrToast("Bot updated successfully", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchAllBots = async () => {
  //   try {
  //     const response = await getAllBots();
  //     setBot(response[0]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const changeFavorite = async (voice) => {
    try {
      let companyVoiceConfig = voice.companyVoiceConfig || [];
      let updateConfig = companyVoiceConfig.find(
        (item) =>
          item.name === voice.name && item.languageCode === currentLanguage
      );
      let response;

      if (updateConfig) {
        // If config exists, toggle the favourite status
        // update case
        response = await updateVoiceConfig(updateConfig._id, {
          botId: bot._id,
          companyId: bot.companyId,
          name: voice.name,
          languageCode: currentLanguage,
          favourite: !updateConfig.favourite,
        });
      } else {
        // If config doesn't exist, create a new one
        // add case
        updateConfig = {
          botId: bot._id,
          companyId: bot.companyId,
          name: voice.name,
          languageCode: currentLanguage,
          favourite: true,
          configurationValues: {},
          pronounciation: {},
        };
        companyVoiceConfig.push(updateConfig);
        response = await addVoiceConfig(updateConfig);
      }

      if (response) {
        // getVoices();
        onSave("voiceFavourite");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeSelectedVoice = async (voice) => {
    let botVoices = bot.botVoices;

    if (botVoices.length > 0) {
      botVoices = botVoices.map((v) =>
        v.languageCode === currentLanguage
          ? { ...v, name: voice.name, provider: voice.provider }
          : v
      );
    }

    try {
      await handleEditBot({ ...bot, botVoices });
      onSave("voiceSelected");
      // setBot((prevBot) => ({ ...prevBot, botVoices }));

      // getVoices();
      // fetchAllBots();
    } catch (error) {
      console.log(error);
    }
  };

  // const getVoices = async () => {
  //   try {
  //     const response = await getVoicesAPI();
  //     setVoices(response.voiceData);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   if (open) {
  //     getVoices();
  //     fetchAllBots();
  //   }
  // }, [open]);

  const handleOnVoiceHover = (voiceIndex) => {
    const updateVoiceByHover = [...voices];
    updateVoiceByHover[voiceIndex].hovered = true;
    setVoices(updateVoiceByHover);
  };

  const handleOnVoiceLeave = (voiceIndex) => {
    const updateVoiceByHover = [...voices];
    updateVoiceByHover[voiceIndex].hovered = false;
    setVoices(updateVoiceByHover);
  };

  const getAudio = async (audioData) => {
    try {
      const response = await getAudioApi(audioData);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data
        ? error.response.data
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handlePlay = async (voiceIndex) => {
    const updateVoiceByAudio = [...voices].map((v) => ({
      ...v,
      isplaying: false,
    }));
    setVoices(updateVoiceByAudio);
    if (updateVoiceByAudio[voiceIndex].base64) {
      audioRef.current.src = `data:audio/wav;base64,${updateVoiceByAudio[voiceIndex].base64}`;
      audioRef.current.play();
      updateVoiceByAudio[voiceIndex].isplaying = true;
    } else {
      const base64Audio = await getAudio({
        text: "hello how are you",
        botVoice: {
          name: updateVoiceByAudio[voiceIndex].name,
          languageCode: updateVoiceByAudio[voiceIndex].languageCode,
        },
      });
      audioRef.current.src = `data:audio/wav;base64,${base64Audio}`;
      audioRef.current.play();
      updateVoiceByAudio[voiceIndex].isplaying = true;
      updateVoiceByAudio[voiceIndex].base64 = base64Audio;
    }
    setVoices(updateVoiceByAudio);
  };

  const handlePause = (voiceIndex) => {
    const updateVoiceByAudioPause = [...voices];
    audioRef.current.pause();
    updateVoiceByAudioPause[voiceIndex].isplaying = false;
    setVoices(updateVoiceByAudioPause);
  };

  /**
   * This function will sort the voices based on favourite, so it will
   * first show favourite and then unfavourite one
   */
  const sortByFavourite = () => {
    const formattedVoices = [...defaultVoices];
    const favourite = [];
    const unFavourite = [];
    formattedVoices.forEach((fVoice) => {
      const index = fVoice.companyVoiceConfig.findIndex(
        (companyVoice) =>
          voice?.languageCode === companyVoice.languageCode &&
          companyVoice?.favourite
      );
      if (fVoice.name === voice.name) {
        favourite.unshift(fVoice);
      } else if (index > -1) {
        favourite.push(fVoice);
      } else {
        unFavourite.push(fVoice);
      }
    });

    setVoices([...favourite, ...unFavourite]);
  };

  useEffect(() => {
    if (open) {
      sortByFavourite();
    }
  }, [open, defaultVoices]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          width: 1000,
          borderRadius: 5,
        },
      }}
    >
      <Box sx={{ padding: "24px", position: "relative" }}>
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 20, right: 20 }}
        >
          <X width={20} height={20} />
        </IconButton>
        <Typography variant="h3" fontWeight={500} textAlign={"center"}>
          Change Voice
        </Typography>

        <audio
          controls
          style={{ width: "100%", display: "none" }}
          ref={audioRef}
        />

        <FlexBox mt={8} mb={2}>
          <Button
            color="inherit"
            startIcon={<IconFilter2 />}
            className={styles.button}
            style={{ display: "none" }}
          >
            Filter
          </Button>
        </FlexBox>
        {voices.length > 0 &&
          voices.map((voice, voiceIndex) => (
            <FlexBox key={voice._id} className={styles.boxContainer}>
              <Box
                sx={{ position: "relative", cursor: "pointer", flexShrink: 0 }}
                onMouseEnter={() => handleOnVoiceHover(voiceIndex)}
                onMouseLeave={() => handleOnVoiceLeave(voiceIndex)}
              >
                <img
                  src={
                    voice.gender === "FEMALE"
                      ? avatarFemale
                      : voice.gender === "MALE"
                      ? avatarMale
                      : voice.gender === "NEUTRAL"
                      ? avatarNeutral
                      : avatarNeutral
                  }
                  alt="avatar"
                  width={56}
                  height={56}
                />
                {voice.hovered && (
                  <Box>
                    {voice.isplaying ? (
                      <IconPause
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          stroke: "#fff",
                          fill: "#fff",
                        }}
                        onClick={() => handlePause(voiceIndex)}
                      />
                    ) : (
                      <IconPlay
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          stroke: "#fff",
                          fill: "#fff",
                        }}
                        onClick={() => handlePlay(voiceIndex)}
                      />
                    )}
                  </Box>
                )}
              </Box>
              <Box>
                <FlexBox>
                  <Typography variant="body2" fontWeight={500}>
                    {voices.find((v) => v.name === voice.name)?.displayName ||
                      voice.name}
                  </Typography>
                </FlexBox>

                <Typography variant="body2" color="grey.600">
                  {voices.find((v) => v.name === voice.name)?.description || ""}
                </Typography>

                <FlexBox columnGap={1}>
                  {voices.find((v) => v.name === voice.name)?.provider ===
                  "google" ? (
                    <IconGoogle width={11} height={11} />
                  ) : voices.find((v) => v.name === voice.name)?.provider ===
                    "elevenLabs" ? (
                    <img
                      src={elevenLabs}
                      width={11}
                      height={11}
                      alt="elevenLabs"
                    />
                  ) : voices.find((v) => v.name === voice.name)?.provider ===
                    "sarvam" ? (
                    <img src={sarvam} width={11} height={11} alt="sarvam" />
                  ) : voices.find((v) => v.name === voice.name)?.provider ===
                    "smallest" ? (
                    <img src={smallest} width={11} height={11} alt="smallest" />
                  ) : null}
                  <Typography fontSize={10} color="grey.400">
                    {voices.find((v) => v.name === voice.name)?.provider ===
                    "google"
                      ? "Google"
                      : voices.find((v) => v.name === voice.name)?.provider ===
                        "elevenLabs"
                      ? "Eleven Labs"
                      : voices.find((v) => v.name === voice.name)?.provider ===
                        "sarvam"
                      ? "Sarvam"
                      : voices.find((v) => v.name === voice.name)?.provider ===
                        "smallest"
                      ? "Smallest AI"
                      : voices.find((v) => v.name === voice.name)?.provider}
                  </Typography>
                </FlexBox>
              </Box>
              <Box ml="auto">
                <FlexBox mt={1} columnGap={1}>
                  <Box
                    className={styles.favoriteButton}
                    onClick={() => changeFavorite(voice)}
                  >
                    <Heart
                      key={voice._id}
                      width={16}
                      height={16}
                      fill={
                        voice?.companyVoiceConfig?.find(
                          (item) =>
                            item.name === voice.name &&
                            item.languageCode === currentLanguage
                        )?.favourite
                          ? "#000"
                          : "#fff"
                      }
                    />
                  </Box>

                  <Button
                    color="inherit"
                    sx={{
                      height: 32,
                      width: 72,
                      minWidth: 0,
                      borderColor: theme.palette.grey[600],
                    }}
                    variant="outlined"
                    onClick={() => {
                      changeSelectedVoice(voice);
                    }}
                  >
                    {bot?.botVoices?.find(
                      (v) => v.languageCode === currentLanguage
                    )?.name === voice.name
                      ? "Selected"
                      : "Select"}
                  </Button>
                </FlexBox>
              </Box>
            </FlexBox>
          ))}

        {/* <FlexBox justifyContent={"center"} mt={4}>
          <Button
            variant="whiteRound"
            onClick={onClose}
            sx={{ borderRadius: "12px" }}
            className={styles.modalButton}
          >
            Cancel
          </Button>

          <Button
            className={styles.modalButton}
            variant="dark"
            sx={{ borderRadius: "12px" }}
          >
            Apply
          </Button>
        </FlexBox> */}
      </Box>
    </Dialog>
  );
};

export default ChangeVoiceModal;
